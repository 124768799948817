import React from 'react';
import {useDataProvider, useRecordContext, useRefresh} from 'react-admin';

import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import FaceRetouchingOffIcon from '@mui/icons-material/FaceRetouchingOff';

import {Button} from '../../../components';
import {useAdminGroups} from '../../../hooks';
import {ERole} from '../../../models';



export const ButtonDeactivate = () => {
    const dataProvider = useDataProvider();
    const {hasPermissions} = useAdminGroups();
    const record = useRecordContext();
    const refresh = useRefresh();

    const isExcludedFromMatching = record?.profile?.isExcludedFromMatching;

    const handlePortalClick = async () => {
        const data = {
            ...record
        };

        data.profile.isExcludedFromMatching = !isExcludedFromMatching;

        try {
            const response = await dataProvider.put(`admin/freelancers/${record.id}`, {data});

            console.log(response);
            refresh();
        } catch (error) {

        }
    };

    if (!hasPermissions([ERole.SuperAdmin])) {
        return null;
    }

    return (
        <Button
            startIcon={isExcludedFromMatching ? <FaceRetouchingNaturalIcon /> : <FaceRetouchingOffIcon/>}
            title={isExcludedFromMatching ? 'Freelancer should be included in the matching process again' : 'Freelancer should not be included in the matching process anymore'}
            variant="outlined"
            onClick={handlePortalClick}
        >
            {isExcludedFromMatching ? 'Activate' : 'Deactivate'}
        </Button>
    );
};
