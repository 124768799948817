import React from 'react';
import {useAuthProvider, useDataProvider, useRecordContext} from 'react-admin';

import VisibilityIcon from '@mui/icons-material/Visibility';

import {Button} from '../../components';
import {useAdminGroups} from '../../hooks';
import {ERole} from '../../models';
import {configKeycloak} from '../../modules';

export const ButtonPortal = () => {
    const auth = useAuthProvider();
    const dataProvider = useDataProvider();
    const {hasPermissions} = useAdminGroups();
    const record = useRecordContext();

    const getToken = async (): Promise<string> => {
        const {clientId, realm} = configKeycloak;
        const path = `realms/${realm}/protocol/openid-connect/token`;
        const token = auth.getToken();

        const payload = {
            grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
            subject_token: token,
            client_id: clientId,
            requested_subject: record.email,
            audience: 'freelancer-web',
        };
        const response = await dataProvider.auth(path, payload);


        return response.access_token;
    };
    const handlePortalClick = async (e: React.MouseEvent) => {
        console.log(e.button);
        const newWindow = window.open();

        try {
            const portalUrl = location.origin.replace('/admin.', '/');
            const url = portalUrl + '/impersonal?token=' + encodeURIComponent(await getToken());

            if (newWindow) {
                newWindow.location = url;
            }
        } catch (error) {

        }
    };

    const handleCopy = async (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        console.log('/impersonal?token=' + encodeURIComponent(await getToken()));
    };

    if (!hasPermissions([ERole.SuperAdmin])) {
        return null;
    }

    return (
        <Button startIcon={<VisibilityIcon/>} variant="outlined" onClick={handlePortalClick} onContextMenu={handleCopy}>
            Check User Portal
        </Button>
    );
};
