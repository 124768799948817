import React from 'react';
import {DateField, Show, SimpleShowLayout} from 'react-admin';

import {Grid, Typography} from '@mui/material';

import {styledLabelShow} from '../../styles';

export const PaymentShow = () => {
    return (
        <Show>
            <SimpleShowLayout>
                <Grid container spacing={2} sx={{maxWidth: 800}}>
                    <Grid item xs={6}>
                        <Typography sx={styledLabelShow}>ID</Typography>
                        <DateField source="id"/>
                    </Grid>
                </Grid>
            </SimpleShowLayout>
        </Show>
    );
};
