import React from 'react';
import {Pagination} from 'react-admin';

import DatagridEmpty from '../DatagridEmpty/DatagridEmpty';

const PaginationCoreUI = () => (
    <Pagination
        limit={<DatagridEmpty/>}
        sx={{
            '& .MuiPagination-ul': {
                '& .Mui-selected': {
                    backgroundColor: '#5046e5',
                    color: '#fff',
                },
                '& .MuiPaginationItem-circular': {
                    minWidth: '32px',
                    height: '32px',
                    borderRadius: '16px',
                }
            }

        }}
    />
);

export default PaginationCoreUI;
