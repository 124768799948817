import {useDataProvider} from 'react-admin';
import {useQuery} from 'react-query';

import {IUser} from '../models';

export const useReviewers = () => {
    const dataProvider = useDataProvider();

    const {data: reviewersGroup} = useQuery(['users?group=/Administrators/Reviewers'],
        () => dataProvider.get('users?group=/Administrators/Reviewers', {area: 'admin'}),
        {
            staleTime: 30 * 60 * 1000,   // cache this request for 30 minutes
            select: (data: {data: {users: IUser[]}}) => data.data.users
        }
    );

    const {data: reviewers} = useQuery(['users?group=/Administrators/Super'],
        () => dataProvider.get('users?group=/Administrators/Super', {area: 'admin'}),
        {
            enabled: !!reviewersGroup,
            staleTime: 30 * 60 * 1000,
            select: (data: {data: {users: IUser[]}}) => {
                const superAdmins = data.data.users;
                const superAdminIds = superAdmins?.map(it => it.id);
                const result = [...superAdmins];

                reviewersGroup?.forEach(reviewer => {
                    if (!superAdminIds.includes(reviewer.id)) {
                        result.push(reviewer);
                    }
                });

                return result.sort((a: IUser, b: IUser) => a.firstName.localeCompare(b.firstName));
            }
        }
    );

    return {
        reviewers
    };
};
