import React, {useCallback} from 'react';
import {Title, useDataProvider, useNotify} from 'react-admin';

import {Card, Tab, Tabs} from '@mui/material';

import {MatcherSettingsForm} from '../../components';
import {useAdminGroups, useMatcher} from '../../hooks';
import {ERole, IMatcherConfig} from '../../models';

import GlobalSettings from './components/GlobalSettings';
import {Recommendations} from './components';

enum EDisplayMode {
    Rules,
    Settings,
    Recommendations,
}

export const MatcherSettings = () => {
    const [activeTab, setActiveTab] = React.useState(EDisplayMode.Rules);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const {refetch} = useMatcher();
    const {hasPermissions} = useAdminGroups();

    const handleTabChange = (event: React.SyntheticEvent, newValue: EDisplayMode) => {
        setActiveTab(newValue);
    };

    const handleSubmit = useCallback(async (settings: IMatcherConfig[]) => {
        await dataProvider.put(
            'admin/matchings/configuration',
            {
                data: {settings}
            }
        );
        refetch();  // mark Global setting as expired for reloading them
        notify('Successfully updated');
    }, [dataProvider, notify, refetch]);

    return (

        <Card>
            <Tabs
                aria-label="global matcher settings tab"
                value={activeTab}
                onChange={handleTabChange}
            >
                <Tab label="Global Rules" />
                {hasPermissions([ERole.SuperAdmin]) && (
                    <Tab label="Global Settings" />
                )}
                <Tab label="Recommendation Templates" />
            </Tabs>


            {activeTab === EDisplayMode.Rules && (
                <>
                    <Title title="Matcher Global Rules" />
                    <MatcherSettingsForm
                        buttonLabel="Save"
                        onSubmit={handleSubmit}
                    />
                </>
            )}

            {activeTab === EDisplayMode.Settings && (
                <GlobalSettings title="Matcher Global Settings" />
            )}

            {activeTab === EDisplayMode.Recommendations && (
                <Recommendations/>
            )}

        </Card>
    );
};
