import React, {FC} from 'react';

import {Avatar, Box, styled, Typography} from '@mui/material';

import {IMatchMeta, IMatchMetaStrings} from '../../../models';
import theme from '../../../theme';
import {getNameLetters, stringToColor} from '../../../utils';

import CheckboxIcon from './CheckboxIcon';
import CloseIcon from './CloseIcon';
import LocationIcon from './LocationIcon';

const MD = `${theme.breakpoints.values.md}px`;

const Wrp = styled('div')`
  &::before {
    content: '';
    margin: -24px -24px 0;
    display: block;
    height: 80px;
    background-image: linear-gradient(90deg, #CBDAEE 0%, #E2E6F5 26.04%, #E5E7F4 52.08%, #DEDCE8 71.87%, #E1D1E1 86.46%, #E2C7DC 100%);
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;

    @media (min-width: ${MD}) {
      height: 133px;
    }
  }
`;

const AvailabilityItem = styled('div')<{ isAvailable?: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 6px 16px;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  border-radius: 50px;
  color: ${props => props.isAvailable ? theme.palette.purple.main : '#999999'};
  background-color: ${props => props.isAvailable ? theme.palette.lilac.main : theme.palette.lightGray.light};

  svg, .close-icon {
    width: 20px;
    height: 20px;
  }

  path {
    fill: currentColor;
  }
`;

const avatarStyles = {
    mt: '-24px',
    mb: '11px',
    ml: '-5px',
    width: '90px',
    height: '90px',
    backgroundColor: stringToColor('You'),
    fontSize: '30px',
    lineHeight: 1.2,
    fontWeight: 500,
    border: '5px solid',
    borderColor: theme.palette.white.main,
    'img': {
        display: 'block',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        aspectRatio: '1/1'
    },
    [theme.breakpoints.up('md')]: {
        mt: '-26px',
        mb: 0,
        width: '126px',
        height: '126px',
        fontSize: '54px'
    }
};

interface IFreelancerTopInfo {
    data: {
        avatar?: string;
        businessModels?: IMatchMetaStrings;
        country: string;
        isAvailableForNewProject: boolean;
        isOpenToHireRole: boolean;
        timezones?: IMatchMeta;
        firstName: string;
        lastName: string;
        yearsOfExperience: number;
    };
    children?: React.ReactNode;
}

const FreelancerTopInfo: FC<IFreelancerTopInfo> = ({data, children}) => {
    return (
        <Wrp>
            <Box
                sx={{
                    [theme.breakpoints.up('sm')]: {
                        display: 'grid',
                        gridTemplateAreas: "'avatar nameAndExperience'" +
                            "'avatar location'" +
                            "'. availability'",
                        gridTemplateColumns: '85px auto',
                        columnGap: '19px'
                    },
                    [theme.breakpoints.up('md')]: {
                        gridTemplateAreas: "'avatar nameAndExperience nameAndExperience buttons'" +
                            "'avatar location location buttons'" +
                            "'. availability availability buttons'",
                        gridTemplateColumns: '121px auto auto',
                        gridTemplateRows: 'repeat(3, auto)'
                    }
                }}
            >
                <Avatar
                    alt={`${data.firstName} ${data.lastName}`}
                    className="avatar"
                    src={data.avatar}
                    sx={{
                        ...avatarStyles,
                        backgroundColor: stringToColor(`${data.firstName} ${data.lastName}`),
                        gridArea: 'avatar'
                    }}
                >
                    {getNameLetters(`${data.firstName} ${data.lastName}`)}
                </Avatar>
                <Box
                    sx={{
                        gridArea: 'nameAndExperience',
                        [theme.breakpoints.up('sm')]: {
                            display: 'flex',
                            alignItems: 'center',
                            gap: '24px',
                            mt: '12px'
                        },
                        [theme.breakpoints.up('md')]: {
                            mt: '24px',
                            mb: '2px'
                        }
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 600,
                            fontSize: '24px',
                            lineHeight: 1.5,
                            color: 'black',
                            [theme.breakpoints.up('sm')]: {
                                position: 'relative',
                                '&::after': {
                                    content: '""',
                                    position: 'absolute',
                                    right: '-12px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    width: '1px',
                                    height: '16px',
                                    backgroundColor: theme.palette.lightGray.main
                                }
                            }
                        }}
                    >
                        {data.firstName} {data.lastName}
                    </Typography>
                    <Typography
                        sx={{
                            mb: '8px',
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: '21px',
                            color: 'black',
                            'span': {
                                font: 'inherit',
                                lineHeight: 'inherit',
                                color: '#999999'
                            },
                            [theme.breakpoints.up('sm')]: {
                                mb: 0
                            }
                        }}
                    >
                        {data.yearsOfExperience} year{data.yearsOfExperience > 1 ? 's' : ''}
                        &nbsp;
                        <span>
                            of experience
                            {data.businessModels
                                ? `, ${[...data.businessModels.common, ...data.businessModels.additional].join(', ')}`
                                : null}
                        </span>
                    </Typography>
                </Box>
                <Typography
                    sx={{
                        gridArea: 'location',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                        mb: '8px',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '21px',
                        color: '#999999',
                        [theme.breakpoints.up('md')]: {
                            mb: '20px'
                        }
                    }}
                >
                    <LocationIcon/>
                    {data.country} {data.timezones?.common && `- ${data.timezones?.common.join(', ')} available timezones`}
                </Typography>
                <Box
                    sx={{
                        gridArea: 'availability',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '8px',
                        [theme.breakpoints.up('md')]: {
                            mt: '-8px'
                        }
                    }}
                >
                    <AvailabilityItem isAvailable={data.isAvailableForNewProject}>
                        {
                            data.isAvailableForNewProject ? (
                                <><CheckboxIcon/>Available for work</>
                            ) : (
                                <><CloseIcon/>Not available for work</>
                            )
                        }
                    </AvailabilityItem>
                    <AvailabilityItem isAvailable={data.isOpenToHireRole}>
                        {
                            data.isOpenToHireRole ? (
                                <><CheckboxIcon/>Available for instant hire</>
                            ) : (
                                <><CloseIcon/>Not available for instant hire</>
                            )
                        }
                    </AvailabilityItem>
                </Box>
                {
                    children
                }
            </Box>
        </Wrp>
    );
};

export default React.memo(FreelancerTopInfo);
