import React, {FC, memo, SyntheticEvent} from 'react';
import {RaRecord, useRecordContext} from 'react-admin';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {Button} from '@mui/material';

import {IClientRole} from '../../../models';
import {styledIconButton} from '../../../styles';

interface Props {
    label?: string;
    recordLocal?: RaRecord;
    source: string;
}

export const HubspotIdField: FC<Props> = memo(({recordLocal, source}) => {
    const record = useRecordContext<IClientRole>();

    if (!record) {
        return null;
    }

    const hubspotId = (recordLocal || (record as any))[source];

    if (!hubspotId) {
        return null;
    }

    const handleClick = (event: SyntheticEvent) => {
        event.stopPropagation();
        event.preventDefault();

        window.open(`https://app.hubspot.com/contacts/${process.env.REACT_APP_HUBSPOT_ENV_ID}/record/0-1/${hubspotId}`, '_blank');
    };

    return (
        <>
            {hubspotId}&nbsp;
            <Button sx={styledIconButton} onClick={handleClick}>
                <OpenInNewIcon />
            </Button>
        </>
    );
});
