import React from 'react';

import {Box, Typography} from '@mui/material';

import {styledLabelSearchForm} from '../../../../styles';

import {RoleFilterForm} from './RoleFilterForm';

export const ListActions = () => (
    <Box width="100%">
        <Typography sx={{...styledLabelSearchForm, py: 1}} variant="h6">Filters</Typography>
        <RoleFilterForm />
    </Box>
);