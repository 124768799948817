import React from 'react';

import {Grid, Typography} from '@mui/material';

import {IWorkDay} from '../../../models';
import {formatHoursFromDuration} from '../../../utils';

import DayLabel from './DayLabel';

const WorkDayGrid: React.FC<IWorkDay & {isCompact?: boolean}> = ({date, isCompact, workDescription, workTime}) => {
    return (
        <Grid
            container
            spacing={isCompact ? '2px' : '24px'}
            alignItems="center"
        >
            <Grid item xs={6} md={2} flexShrink={0}>
                <DayLabel date={date}/>
            </Grid>

            <Grid item xs={12} md={8} order={{xs: 3, md: 2}}>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: 1.5
                    }}
                >
                    {workDescription || '-'}
                </Typography>
            </Grid>

            <Grid item xs={6} md={2} order={{xs: 2, md: 3}}>
                <Typography
                    sx={{
                        textAlign: 'right',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: 1.5
                    }}
                >
                    {workTime ? formatHoursFromDuration(workTime) : '0:00'}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default React.memo(WorkDayGrid);
