import React from 'react';

import {FormControl, FormHelperText, InputLabel, MenuItem, Select as MUISelect, SelectProps} from '@mui/material';

interface ISelectProps extends SelectProps {
    clearable?: boolean;
    helperText?: string;
    listItems: ReadonlyArray<{ value: string; text: string }>;
}

const Select = ({error, fullWidth, helperText, label, listItems, ...props}: ISelectProps): JSX.Element => {
    return (
        <FormControl sx={{minWidth: 120}} error={!!error} fullWidth={fullWidth}>
            <InputLabel>{label}</InputLabel>
            <MUISelect
                {...props}
                label={label}
            >
                {
                    props.clearable && (
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                    )
                }

                {
                    listItems.map(item => <MenuItem key={item.value} value={item.value}>{item.text}</MenuItem>)
                }
            </MUISelect>
            <FormHelperText>{error || helperText}</FormHelperText>
        </FormControl>
    );
};

export default Select;
