import React, {FC, Fragment, useEffect, useMemo, useState} from 'react';
import {ImageInput, useDataProvider, useEditContext, useNotify, useRecordContext} from 'react-admin';
import {FormProvider, SubmitHandler, useFieldArray, useForm} from 'react-hook-form';
import {any, number, object, string, TypeOf} from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import DeleteIcon from '@mui/icons-material/Delete';
import {Box, DialogActions, DialogContent, Divider, FormHelperText, Grid, Stack, Typography} from '@mui/material';

import {Button} from '../../../../components';
import {InputForm, SelectForm} from '../../../../components/Forms';
import {ACCEPTED_IMAGE_TYPES, getAcceptedExtentions, MAX_FILE_SIZE, MAX_FILE_SIZE_MB} from '../../../../constants';
import {EApplicationStatus, ICaseStudy, ICoverImage, optionsDocumentStatus} from '../../../../models';
import {styledButton, styledFileField, styledInput, styledLabel, styledLabelShow, styledResizeableTextArea, styledSelect} from '../../../../styles';
import theme from '../../../../theme';

const ACCEPTED_EXTENSIONS = getAcceptedExtentions(ACCEPTED_IMAGE_TYPES);
const CASE_STUDY_MAX_TEXT_LENGTH = 2000;
const CASE_STUDY_MAX_REVIEW_LENGTH = 1000;

const DEFAULT_VALUES = {
    coverImage: JSON.stringify({name: 'black', variant: 1} as ICoverImage),
    outcomes: [{
        description: '',
        value: ''
    }, {
        description: '',
        value: ''
    }]
};

const schema = object({
    id: number().optional(),

    // STEP - 1
    name: string()
        .min(1, 'Name is required')
        .max(100, 'Name is too long'),
    brief: string()
        .min(1, 'Description is required')
        .max(100, 'Description is too long'),
    coverImage: string()
        .min(1, 'Cover image is required'),

    // STEP - 2
    companyName: string()
        .min(1, 'Company Name is required')
        .max(100, 'Company Name is too long'),
    companyLogoUrl: any(),
    fileCompanyLogo: any()
        .refine((files) => files?.length ? (files?.[0]?.size <= MAX_FILE_SIZE_MB) : true,
            `Max file size is ${MAX_FILE_SIZE}MB.`
        )
        .refine(
            (file) => file && file.rawFile.size ? !!ACCEPTED_EXTENSIONS.find(ext => file.rawFile.name?.toLowerCase().endsWith(ext)) : true,
            '.jpg, .jpeg, and .png files are accepted.'
        ),

    // STEP - 3
    challenge: string()
        .min(1, 'Challenge is required')
        .max(CASE_STUDY_MAX_TEXT_LENGTH, 'Challenge is too long'),
    solution: string()
        .min(1, 'Solution is required')
        .max(CASE_STUDY_MAX_TEXT_LENGTH, 'Solution is too long'),
    summary: any()
        .refine((text) => text?.length ? text.length < CASE_STUDY_MAX_TEXT_LENGTH : true,
            `Max length is ${CASE_STUDY_MAX_TEXT_LENGTH}.`
        ),

    // STEP - 4
    outcomes: object({
        description: string()
            .trim()
            .min(1, 'Title is required')
            .max(100, 'Title is too long'),
        value: string()
            .refine((val: string) => {
                const value = val.toLowerCase();
                // const data = value.split('');

                if (value.length < 1) return false;

                // Allow any special symbol at the any position

                // // %|x - required
                // if (value.indexOf('%') === -1 && value.indexOf('x') === -1) return false;

                // // %|x - don't allow both
                // if (value.indexOf('%') !== -1 && value.indexOf('x') !== -1) return false;

                // // duplicates not allowed
                // if (data.filter(it => it === '%').length > 1 || data.filter(it => it === 'x').length > 1) return false;

                // // %|x - suffix required
                // if (!value.endsWith('%') && !value.endsWith('x')) return false;

                return true;
            })
    }).array().min(2),

    // STEP - 5
    clientsName: any()
        .refine(name => !name || name.length < 100, 'Name is too long'),
    clientsPosition: any()
        .refine(position => !position || position.length < 100, 'Position is too long'),
    clientsReview: any()
        .refine(review => !review || review.length < CASE_STUDY_MAX_REVIEW_LENGTH, 'Review is too long'),
    status: string()
        .min(1, 'Status is required'),
    type: string().optional(),
});

type CaseStudyInput = TypeOf<typeof schema>;

interface Props {
    item: Partial<ICaseStudy>;
    onClose: () => void;
}

const CaseStudyForm: React.FC<Props> = ({item, onClose}) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const {refetch} = useEditContext();
    const {id: recordId} = useRecordContext();
    const [previewLogoUrl, setPreviewUrl] = useState<string>('');

    const methods = useForm<CaseStudyInput>({
        resolver: zodResolver(schema),
        defaultValues: item || {...DEFAULT_VALUES}
    });

    const {
        control,
        formState: {isDirty, errors},
        handleSubmit,
        getValues,
        reset,
        watch,
    } = methods;

    const {fields, append, remove} = useFieldArray({
        control,
        name: 'outcomes',
    });

    const companyLogoUrl = watch('companyLogoUrl');
    const coverImage = watch('coverImage');
    const fileCompanyLogo = watch('fileCompanyLogo');
    const outcomes = watch('outcomes', []);

    const onSubmitHandler: SubmitHandler<CaseStudyInput> = async () => {
        const data = getValues();

        if (!data.id) {
            data.status = EApplicationStatus.Submitted;
        }

        if (!data.fileCompanyLogo) {
            delete data.fileCompanyLogo;
        } else if (data.fileCompanyLogo?.rawFile?.size) {
            delete (data as Partial<ICaseStudy>).companyLogoUrl;
            (data as Partial<ICaseStudy>).companyLogo = data.fileCompanyLogo.rawFile;
        }

        delete data.fileCompanyLogo;
        delete data.type;

        console.log('submit', data);

        await dataProvider.putFormData(
            `admin/applications/${recordId}/review/case-studies`,
            {data},
        );

        if (data.id) {
            notify('Successfully updated.');
        } else {
            notify('Successfully created.');
        }

        if (refetch) refetch();
        onClose();
    };

    useEffect(() => {
        if (item && Object.keys(item).length > 1) {     // empty object with single props 'type: case-study' can be
            reset({...item});
        } else {
            reset({...DEFAULT_VALUES});
        }
    }, [item, reset]);

    useEffect(() => {
        if (fileCompanyLogo) {
            setPreviewUrl(window.URL.createObjectURL(fileCompanyLogo.rawFile));
        }
    }, [fileCompanyLogo]);

    const coverImagePath = useMemo(() => {
        const value = coverImage || item.coverImage || DEFAULT_VALUES.coverImage;
        const data: ICoverImage = JSON.parse(value || '');

        return value ? `${process.env.REACT_APP_S3_PUBLIC_BUCKET_URL}/case_study_covers/${data.name}/${data.variant}/tablet_img.png` : '';
    }, [coverImage, item]);

    return (
        <>
            <DialogContent dividers>
                <FormProvider {...methods}>
                    <form
                        autoComplete="off"
                        noValidate
                        onSubmit={handleSubmit(onSubmitHandler)}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={6}/>
                            <Grid item xs={6}>
                                <SelectForm
                                    label="Status"
                                    variant="standard"
                                    listItems={optionsDocumentStatus}
                                    name="status"
                                    sx={styledSelect}
                                />
                            </Grid>

                            <StepName title="Title & Description"/>

                            <Grid item xs={12}>
                                <InputForm label="Case study name" name="name" sx={styledInput}/>
                            </Grid>

                            <Grid item xs={12}>
                                <InputForm
                                    label="Briefly describe applicant case study"
                                    name="brief"
                                    minRows={4}
                                    multiline
                                />
                            </Grid>

                            {coverImagePath && (
                                <Grid item xs={12}>
                                    <Box
                                        sx={{
                                            height: '100px',
                                            backgroundImage: `url(${coverImagePath})`,
                                            backgroundSize: 'cover',
                                            backgroundPositionY: '50%',
                                            borderRadius: '30px',
                                        }}
                                    />
                                </Grid>
                            )}

                            <StepName title="Company information"/>

                            <Grid item xs={6}>
                                <InputForm label="Company applicant completed work for" name="companyName" sx={styledInput}/>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography sx={{marginBottom: '0.5rem'}} variant="body1">
                                    Upload a logo of this company (optional)
                                </Typography>
                                <Stack direction="row" alignItems="flex-start" spacing={2}>
                                    {
                                        (companyLogoUrl || previewLogoUrl) && (
                                            <img src={previewLogoUrl || companyLogoUrl} style={{maxWidth: '30%'}} alt="company logo" />
                                        )
                                    }
                                    <ImageInput
                                        accept="image/*"
                                        label=" "
                                        name="fileCompanyLogo"
                                        maxSize={MAX_FILE_SIZE_MB}
                                        source="fileCompanyLogo"
                                        sx={{
                                            ...styledFileField,
                                            '& .RaLabeled-label': {
                                                fontSize: '1rem',
                                            }
                                        }}
                                    />
                                </Stack>
                            </Grid>

                            <StepName title="Challenge, Solution & Process"/>

                            <Grid item xs={12}>
                                <InputForm
                                    label="What challenge did applicant solve for the client company?"
                                    name="challenge"
                                    minRows={5}
                                    multiline
                                    sx={styledResizeableTextArea}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <InputForm
                                    label="What solution did applicant come up with?"
                                    name="solution"
                                    minRows={5}
                                    multiline
                                    sx={styledResizeableTextArea}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <InputForm
                                    label="Add a short summary (optional)"
                                    name="summary"
                                    minRows={5}
                                    multiline
                                    sx={styledResizeableTextArea}
                                />
                            </Grid>

                            <StepName title="Outcomes"/>

                            {fields.map((item, index) => (
                                <Fragment key={item.id}>
                                    <Grid item xs={8}>
                                        <Typography sx={styledLabel} variant="body2">
                                            Outcome #{index + 1}
                                        </Typography>
                                        <Typography sx={styledLabelShow} variant="caption">Example: {index % 2 === 0 ? '10% increase in sales' : '2x doubled reply rates'}</Typography>
                                        <Stack direction="row" spacing={2}>

                                            <InputForm placeholder="% or x (optional)" name={`outcomes.${index}.value`} sx={styledInput}/>

                                            <InputForm placeholder="Title of the outcome" name={`outcomes.${index}.description`} sx={styledInput}/>

                                        </Stack>
                                    </Grid>
                                    <Grid item xs={4} sx={{display: 'flex', alignItems: 'flex-end', px: 2}}>
                                        <Button
                                            color="error"
                                            startIcon={<DeleteIcon />}
                                            variant="outlined"
                                            onClick={() => remove(index)}
                                        >
                                            Delete
                                        </Button>
                                    </Grid>
                                </Fragment>
                            ))}

                            {errors.outcomes && (
                                <Grid item xs={12}>
                                    <FormHelperText id="error-cover-image" sx={{color: theme.palette.error.main}}>
                                        {errors.outcomes?.message}
                                    </FormHelperText>
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <Button
                                    disabled={outcomes ? outcomes.length >= 4 : false}
                                    sx={styledButton}
                                    variant="contained"
                                    onClick={() => append({description: '', value: ''})}
                                >
                                    + Add outcome
                                </Button>
                            </Grid>

                            <StepName title="Testimonials"/>

                            <Grid item xs={12}>
                                <InputForm
                                    label="Add a customer’s testimonial if applicant have one (optional)"
                                    name="clientsReview"
                                    minRows={4}
                                    multiline
                                    placeholder="Add some description to give us more details of what applicant did exactly"
                                    sx={styledResizeableTextArea}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <InputForm label="Customer’s name (optional)" name="clientsName" placeholder="John Doe" sx={styledInput}/>
                            </Grid>
                            <Grid item xs={6}>
                                <InputForm label="Customer’s position (optional)" name="clientsPosition" placeholder="Senior Sales Manager" sx={styledInput}/>
                            </Grid>

                            <Grid item xs={12}>
                                <Box sx={{mb: 4}}/>
                            </Grid>

                        </Grid>
                    </form>
                </FormProvider>

            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={onClose}
                >
                    Close
                </Button>
                <Button
                    disabled={!isDirty}
                    variant="contained"
                    onClick={handleSubmit(onSubmitHandler)}
                >
                    Save
                </Button>
            </DialogActions>
        </>
    );
};

interface IStepNameProps {
    title: string;
}

const StepName: FC<IStepNameProps> = ({title}) => (
    <>
        <Grid item xs={12}>
            <Divider />
        </Grid>

        <Grid item xs={12}>
            <Typography align="center" sx={{opacity: 0.2}} variant="h6">
                                    —  {title}  —
            </Typography>
        </Grid>
    </>
);

export default CaseStudyForm;
