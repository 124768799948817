import {EDocumentStatus} from './application.model';

export enum EAttachmentType {
    certificate = 'certificate',
    reference = 'reference',
    study = 'case_study'
}

export interface IAttachment {
    approved: string;
    approvedBy: string;
    created: string;
    id: number;
    name: string;
    status: EDocumentStatus;
    type: EAttachmentType;
}

export type LegalDocType = 'W8' | 'W9';

export interface ILegalDoc {
    completedAt: string;
    id: string;
    pandaDocStatus: 'COMPLETED' | 'SENT';
    taxYear: number;
    type: LegalDocType;
}

export const optionsDocTypes = [
    {text: 'Certificate', value: EAttachmentType.certificate},
    {text: 'Reference', value: EAttachmentType.reference},
    {text: 'Case Study', value: EAttachmentType.study}
];
