import {convertDateToString} from '../../utils';

const now = new Date();
const weekAgo = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);

export const defaultNotificationFiltersValues = {
    createdAfter: convertDateToString(weekAgo),
    createdBefore: convertDateToString(now),
    eventTypes: 'ALL'
};
