import React from 'react';

import {Box, Typography} from '@mui/material';

import {styledLabelSearchForm} from '../../../../styles';

import {ListFilterForm} from './ListFilterForm';

export const ListActions = ({isCompact = false}: {isCompact: boolean}) => (
    <Box width="100%">
        <Typography sx={{...styledLabelSearchForm, py: 1}} variant="h6">Filters</Typography>
        <ListFilterForm isCompact={isCompact}/>
    </Box>
);