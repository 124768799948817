import React, {FC} from 'react';
import {RaRecord, useRecordContext} from 'react-admin';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {Box, Typography} from '@mui/material';

type Props = {
    recordLocal?: RaRecord;
    variant?: 'body1' | 'body2' | 'h6';
};

const RoleNameField: FC<Props> = ({recordLocal, variant = 'body2'}) => {
    const record = useRecordContext();
    const field = recordLocal || record;

    if (field?.hasOverdueTimesheets || field?.hasRejectedTimesheets) {
        return (
            <Typography component="span" variant={variant}>
                {field?.hasOverdueTimesheets && (
                    <Box
                        component="span"
                        sx={{
                            verticalAlign: variant === 'body2' ? 'middle' : 'text-top',
                            mr: 1
                        }}
                        title="Overdue time card(s) exists"
                    >
                        <ErrorOutlineIcon color="primary"/>
                    </Box>
                )}

                {field?.hasRejectedTimesheets && (
                    <Box
                        component="span"
                        sx={{
                            verticalAlign: variant === 'body2' ? 'middle' : 'text-top',
                            mr: 1
                        }}
                        title="Rejected time card(s) exists"
                    >
                        <WarningAmberIcon color="error"/>
                    </Box>
                )}
                {field?.name}
            </Typography>
        );
    }

    return (
        <Typography variant={variant}>
            {field?.name}
        </Typography>
    );
};

export default RoleNameField;
