import React, {useCallback, useState} from 'react';
import {Datagrid, DateField, List, TextField, useDataProvider, useListContext, useNotify, useRecordContext, WrapperField} from 'react-admin';

import {Button} from '@mui/material';

import {LoadingMask, PaginationCoreUI} from '../../components';
import {styledDatagrid, styledList, styledPrimaryButton} from '../../styles';

import {NameField} from './components/Fields/NameField';
import {filterDefaultValues} from './components/Filters/FilterForm';
import {ListActions} from './components/List/ListActions';


export const TimesheetsList = () => (
    <List
        actions={<ListActions/>}
        filterDefaultValues={filterDefaultValues}
        pagination={<PaginationCoreUI/>}
        sort={{field: 'id', order: 'DESC'}}
        sx={styledList}
    >
        <Datagrid
            bulkActionButtons={false}
            sx={styledDatagrid}
        >
            <TextField source="id"/>
            <WrapperField label="Period" sortBy="fromDate">
                <DateField source="fromDate"/><br/>
                <DateField source="toDate"/>
            </WrapperField>
            <NameField label="Freelancer" idSource="freelancerId" valueSource="freelancerName" sortable={false} navigationPattern="/freelancers/{id}/show"/>
            <NameField label="Client" idSource="clientId" valueSource="clientName" sortable={false} navigationPattern="/clients/{id}/show"/>
            <DateField source="submittedAt"/><br/>
            <TextField source="status" sortable={false}/><br/>
            <WrapperField label="Payment/Transaction" sortable={false}>
                <DateField source="latestTransactionDate"/><br/>
                <TextField source="paymentStatus"/><br/>
                <TextField source="latestTransactionStatus"/><br/>
                <TextField source="latestTransactionStripeId"/>
            </WrapperField>
            <WrapperField label="Payout" sortable={false}>
                <TextField source="payoutStatus"/>
                <DateField label="Payout date" source="paymentPaidOutAt"/>
            </WrapperField>
            <ActionButtons/>
        </Datagrid>
    </List>
);

const ActionButtons = () => {
    const {id, status, paymentStatus, latestTransactionPaymentMethodType} = useRecordContext();
    const dataProvider = useDataProvider();
    const {refetch} = useListContext();
    const [isSubmitting, serIsSubmitting] = useState(false);
    const notify = useNotify();

    const handlePayClick = useCallback(async () => {
        serIsSubmitting(true);

        try {
            await dataProvider.post(`admin/timesheets/${id}`, {});

            refetch();
            notify('Request for payment executed');
        } catch (error: any) {
            console.error(error);
            notify(`Error: ${error.message}`, {type: 'warning'});
        }

        serIsSubmitting(false);
    }, [id, dataProvider, notify, refetch]);

    if (status == 'APPROVED' && ((!paymentStatus || paymentStatus == 'NEW') && latestTransactionPaymentMethodType != 'MANUAL_INVOICING')) {
        return (
            <Button
                className="primary"
                color="primary"
                disableElevation
                type="submit"
                variant="contained"
                sx={styledPrimaryButton}
                onClick={handlePayClick}
            >
                Pay {isSubmitting && <LoadingMask size={16}/>}
            </Button>
        );
    }

    return (
        <span></span>
    );
};
