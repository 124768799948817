import React from 'react';

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import {Stack} from '@mui/material';

import {SectionTitle} from '../../../../components';
import {formatterNumber} from '../../../../utils';
import {IAdminDashboardFreelancers} from '../../models';
import {Tile} from '..';

type SectionFreelancerProps = {
    data: IAdminDashboardFreelancers;
};

const SectionFreelancer: React.FC<SectionFreelancerProps> = ({data}) => {
    return (
        <Stack spacing={2}>
            <SectionTitle>Freelancers</SectionTitle>
            <Stack direction="row" spacing={1}>
                <Tile
                    icon={<AccountCircleOutlinedIcon/>}
                    title="Total number"
                    value={formatterNumber.format(data.totalUsers)}
                />
                <Tile
                    icon={<LocalFireDepartmentOutlinedIcon/>}
                    title="Active freelancers"
                    value={formatterNumber.format(data.activeUsers)}
                />
                <Tile
                    icon={<QueryBuilderOutlinedIcon/>}
                    title="Applicants in queue"
                    value={formatterNumber.format(data.applicantsInQueue)}
                />
            </Stack>
        </Stack>
    );
};

export default React.memo(SectionFreelancer);
