export const formatNameToCapital = (name: string) => {
    return name.charAt(0).toUpperCase() + name.substring(1).toLowerCase();
};

export const ellipsisText = (str: string, limit: number) => str?.length > limit ? str.substring(0, limit) + '...' : (str || '');

export const getNameLetters = (name: string) => {
    const nameAsArr = name.split(' ');

    if (!name) return '';

    return (nameAsArr[0][0] + nameAsArr[nameAsArr.length - 1][0]).toUpperCase();
};

// {fileName}...{[0-3].ext} -> Microsoft_2023_Cert...te.pdf
export const getFileName = (fileName: string = '', max = 50) => {
    const suffixLength = 7;
    const name = fileName.split('/').pop() || '';
    const extPlusSuffix = fileName.substring(fileName.length - suffixLength);

    return name.length - suffixLength > max ? (name.substring(0, max) + '...' + extPlusSuffix) : name;
};
