import React, {useMemo} from 'react';
import {required, SelectInput} from 'react-admin';

import {IEventType} from '../../../../models';
import {styledSelectInline} from '../../../../styles';
import {formatNameToCapital} from '../../../../utils';

interface EventTypesFilterProps {
    alwaysOn: boolean;
    items: IEventType[];
    source: string;
}

const EventTypesFilter = ({items, ...props}: EventTypesFilterProps) => {
    const optionsEventType = useMemo(() => {
        return items ? [
            {id: 'ALL', name: 'All'},
            ...items
                .sort((a: IEventType, b: IEventType) => a.type.localeCompare(b.type))
                .map((it: IEventType) => ({id: it.type, name: it.type.replace(/_/g, ' ')}))
                .map((it) => ({
                    ...it,
                    name: formatNameToCapital(it.name)
                }))
        ] : [];
    }, [items]);

    return (
        items &&
        <SelectInput
            {...props}
            choices={optionsEventType}
            fullWidth
            helperText={false}
            sx={styledSelectInline}
            validate={required()}
        />
    );
};

export default React.memo(EventTypesFilter);
