import React, {FC, ReactNode} from 'react';

import {Typography} from '@mui/material';

import Dialog from '../Dialog/Dialog';
import {Button} from '..';

export interface DialogConfirmProps {
    ariaDescribedby: string;
    confirmationText: ReactNode;
    open: boolean;
    title: string;
    noBtnText?: string;
    yesBtnText?: string;
    onNo: () => void;
    onYes: () => void;
}

const DialogConfirm: FC<DialogConfirmProps> = ({ariaDescribedby, title, confirmationText, noBtnText, yesBtnText, open, onYes, onNo}) => {
    return (
        <Dialog
            actions={(
                <>
                    <Button
                        fullWidth
                        variant="outlined"
                        onClick={onNo}
                    >
                        {noBtnText || 'No'}
                    </Button>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={onYes}
                    >
                        {yesBtnText || 'Yes'}
                    </Button>
                </>
            )}
            aria-describedby={ariaDescribedby}
            open={open}
            title={title}
            onClose={onNo}
        >
            <Typography
                align="center"
                sx={{
                    mb: '32px'
                }}
            >
                {confirmationText}
            </Typography>
        </Dialog>
    );
};

export default React.memo(DialogConfirm);
