import React, {memo} from 'react';
import {useRecordContext} from 'react-admin';

import {IPayment} from '../../../../models';
import {PaymentStatus} from '..';

export const PaymentField = memo(({source}: {source: string}) => {
    const record = useRecordContext<IPayment>();

    if (!record) {
        return null;
    }

    return (
        <PaymentStatus data={record.payment}/>
    );
});