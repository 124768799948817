import React, {useCallback, useState} from 'react';
import {EmailField, Labeled, useDataProvider, useGetOne, useNotify, useRecordContext} from 'react-admin';

import DeleteIcon from '@mui/icons-material/Delete';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import ReviewsIcon from '@mui/icons-material/Reviews';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {Box, Divider, Grid, Link, Stack, Typography} from '@mui/material';

import {Button, EmptyText, ListField, LoadingMask, ModerationListField, RateRange, SubChannelsField} from '../../../../components';
import {useAdminGroups} from '../../../../hooks';
import {ERole, IClientRole, IFreelancer, IMatchingResult, IRecommendation} from '../../../../models';
import {styledLabelShow} from '../../../../styles';
import {formatterWithoutCents} from '../../../../utils';

import DialogRecommendation from './ResultList/DialogRecommendation';
import RecommendationPreview from './ResultList/RecommendationPreview';

interface Props {
    expanded: boolean;
    isIgnore?: boolean;
    isResult: boolean;
    item: IMatchingResult;
    recommendation: IRecommendation | false;
    rejectionReason: string;
    onRefresh?: () => void;
    onRemove: () => void;

}

export const FreelancerDetails: React.FC<Props> = React.memo(({
    expanded, isIgnore, isResult, item, recommendation, rejectionReason, onRemove, onRefresh
}) => {
    const dataProvider = useDataProvider();

    const notify = useNotify();
    const record = useRecordContext<IClientRole>();

    const {hasPermissions} = useAdminGroups();
    const [selectedRecommendation, setRecommendation] = useState<IRecommendation | null>(null);
    const {data, isLoading} = useGetOne<IFreelancer>(
        'freelancers',
        {id: item.freelancerId},
        {
            enabled: expanded,
            staleTime: 15 * 60 * 1000   // cache this request for 15 minutes
        }
    );

    const handleAddRecommendation = useCallback(() => {
        setRecommendation({} as IRecommendation);
    }, []);

    const handleEditRecommendation = useCallback(() => {
        if (recommendation) {
            setRecommendation(recommendation);
        }
    }, [recommendation]);

    const handleRecommendationClose = useCallback((isNeedRefetch: boolean) => {
        setRecommendation(null);
        if (isNeedRefetch && onRefresh) {
            onRefresh();
        }
    }, [onRefresh]);

    const handleRecommendationChange = useCallback(async (recommendation: IRecommendation | null) => {
        const {position, notes} = item;
        const data = {notes, recommendation, position};

        await dataProvider.put(`admin/roles/${record?.id}/results/${item.id}`, {data});
        notify('Successfully saved.');

        // update localy without refresh because Drag&Drop with infinity scroll will be broken
        item.recommendation = recommendation as IRecommendation;

        setRecommendation(null);
        onRefresh && onRefresh();
    }, [dataProvider, item, record?.id, notify, onRefresh]);

    const handleDeleteRecommendation = useCallback(() => {
        handleRecommendationChange(null);
    }, [handleRecommendationChange]);

    if (isLoading) return (
        <Box textAlign="center" sx={{p: 4, opacity: 0.2}}>
            <LoadingMask />
        </Box>
    );

    if (!data) return null;

    return (
        <>
            <Grid container spacing={2}>
                {rejectionReason && (
                    <Grid item sm={12}>
                        <Typography sx={{fontWeight: 700}} variant="caption">Rejection Reason:</Typography>
                        <Typography variant="body2">{rejectionReason}</Typography>
                    </Grid>
                )}
                <Grid item sm={12} lg={4}>
                    <Labeled label="Email">
                        <EmailField record={data} source="email" variant="body2" />
                    </Labeled>
                </Grid>
                <Grid item sm={12} lg={2}>
                    <Labeled label="Phone">
                        <EmptyText recordLocal={data} source="phone" />
                    </Labeled>
                </Grid>
                <Grid item sm={6} lg={2}>
                    <Labeled label="Location">
                        <EmptyText recordLocal={data.profile} source="country" />
                    </Labeled>
                </Grid>
                <Grid item sm={6} lg={4}>
                    <Labeled label="Time zones">
                        <EmptyText recordLocal={data.profile} source="timezones" />
                    </Labeled>
                </Grid>
                <Grid item sm={12} md={6} lg={2}>
                    <Labeled label="Channels">
                        <ListField recordLocal={data.profile} source="channels" />
                    </Labeled>
                </Grid>
                <Grid item sm={12} md={6} lg={2}>
                    <Labeled label="Sub-Channels">
                        <SubChannelsField recordLocal={data.profile} />
                    </Labeled>
                </Grid>
                <Grid item sm={12} md={6} lg={2}>
                    <Labeled label="Verticals">
                        <ListField recordLocal={data.profile} source="verticals" />
                    </Labeled>
                </Grid>
                <Grid item sm={12} md={6} lg={2}>
                    <Typography sx={styledLabelShow}>Brands</Typography>
                    <ModerationListField
                        isEditable={hasPermissions([ERole.SuperAdmin, ERole.Admin])}
                        recordLocal={data.profile}
                        source="brands"
                    />
                </Grid>
                <Grid item sm={12} md={6} lg={4}>
                    <Typography sx={styledLabelShow}>Tools</Typography>
                    <ModerationListField
                        isEditable={hasPermissions([ERole.SuperAdmin, ERole.Admin])}
                        recordLocal={data.profile}
                        source="tools"
                    />
                </Grid>
                <Grid item sm={12} md={6} lg={2}>
                    <Labeled label="Years of experience">
                        <EmptyText recordLocal={data.profile} source="yearsOfExperience" />
                    </Labeled>
                </Grid>
                <Grid item sm={12} md={6} lg={2}>
                    <Labeled label="Business models">
                        <ListField recordLocal={data.profile} source="businessModels" />
                    </Labeled>
                </Grid>
                <Grid item sm={12} md={6} lg={2}>
                    <Labeled label="Engagement Type">
                        <ListField recordLocal={data.profile} source="workTypes" />
                    </Labeled>
                </Grid>
                <Grid item sm={12} md={6} lg={2}>
                    <Labeled label="Rate range">
                        <RateRange recordLocal={data.profile} />
                    </Labeled>
                </Grid>
                <Grid item sm={12} md={6} lg={2}>
                    <Typography sx={styledLabelShow}>Fixed rate</Typography>
                    <Typography variant="body1">
                        {data?.profile ? formatterWithoutCents.format(data.profile.fixedRate / 100) : ''}
                    </Typography>
                </Grid>
                <Grid item sm={12} md={6} lg={2}>
                    <Labeled label="Languages">
                        <ListField recordLocal={data.profile} source="languages" />
                    </Labeled>
                </Grid>

                {isResult && recommendation && (
                    <>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <RecommendationPreview
                                data={recommendation}
                                onEdit={handleEditRecommendation}
                                onDelete={handleDeleteRecommendation}
                            />
                        </Grid>
                    </>
                )}

                <Grid item xs={12}>
                    <Stack direction="row" spacing={2}>
                        <Link href={`${location.origin}/#/freelancers/${item.freelancerId}/show`} rel="noopener" target="_blank" underline="none">
                            <Button startIcon={<OpenInNewIcon />} variant="outlined">
                                View profile
                            </Button>
                        </Link>

                        <Button startIcon={<VisibilityIcon />} variant="outlined" onClick={() => window.open(location.origin + '/#/public-profile-client/' + item.id)}>
                            Public profile
                        </Button>

                        {isResult && !recommendation && (
                            <Button startIcon={<ReviewsIcon />} variant="outlined" onClick={handleAddRecommendation}>
                                Add recommendation
                            </Button>
                        )}

                        {isResult && (
                            <Button color="error" startIcon={<DeleteIcon />} variant="outlined" onClick={onRemove}>
                                Remove freelancer
                            </Button>
                        )}

                        {isIgnore && (
                            <Button startIcon={<RestoreFromTrashIcon />} variant="outlined" onClick={onRemove}>
                                Restore freelancer
                            </Button>
                        )}

                    </Stack>
                </Grid>
            </Grid>
            <DialogRecommendation
                recommendation={selectedRecommendation}
                open={!!selectedRecommendation}
                onClose={handleRecommendationClose}
                onSubmit={handleRecommendationChange}
            />
        </>
    );
}, isEqualProps);

function isEqualProps(prevProps: Props, nextProps: Props) {
    return prevProps.expanded === nextProps.expanded
        && prevProps.item.status === nextProps.item.status
        && JSON.stringify(prevProps.recommendation) === JSON.stringify(nextProps.recommendation);
}
