import React, {useState} from 'react';
import format from 'date-fns/format';
import {RaRecord, useAuthProvider, useDataProvider, useRecordContext} from 'react-admin';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import {Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemText} from '@mui/material';

import {ILegalDoc} from '../../../models';
import {getByPath} from '../../../utils';
import LoadingMask from '../../LoadingMask';

interface Props {
    recordLocal?: RaRecord;
    source: string;
}

export const LegalDocumentsField: React.FC<Props> = React.memo((props) => {
    const [isLoadingId, setIsLoadingId] = useState<string | null>(null);
    const auth = useAuthProvider();
    const dataProvider = useDataProvider();
    const record = useRecordContext();

    if (!record) return null;

    const field: ILegalDoc[] = getByPath(props.recordLocal || record, props.source);
    const items = field?.filter(it => it.pandaDocStatus === 'COMPLETED')
        .sort((a, b) => a.taxYear - b.taxYear)
        .reverse();

    const handleDownload = async (item: ILegalDoc) => {
        const fileName = `${item.type} form ${item.taxYear}.pdf`;
        const token = auth.getToken();
        const url = `admin/files/pandadoc/${item.id}`;

        setIsLoadingId(item.id);
        await dataProvider.downloadFile(url, fileName, token);
        setIsLoadingId(null);
    };


    if (!items?.length) {
        return (
            <>/</>
        );
    }

    return (
        <List dense={true} sx={{maxWidth: 300}}>
            {items?.map(item => (
                <ListItem
                    divider
                    key={item.id}
                    secondaryAction={
                        isLoadingId === item.id ? (
                            <LoadingMask size={16}/>
                        ) : (
                            <IconButton
                                aria-label="download"
                                disabled={!!isLoadingId}
                                edge="end"
                                title="Download"
                                onClick={() => handleDownload(item)}
                            >
                                <FileDownloadIcon />
                            </IconButton>
                        )

                    }
                >
                    <ListItemAvatar>
                        <Avatar>
                            <TextSnippetIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={`${item.type} form ${item.taxYear}.pdf`}
                        secondary={item.completedAt ? format(new Date(item.completedAt), 'PP') : ''}
                    />
                </ListItem>
            ))}
        </List>
    );
});
