import React, {useMemo} from 'react';
import {EmailField, Labeled, TextField, useRecordContext} from 'react-admin';

import {Grid, Typography} from '@mui/material';

import {Accordion, AccordionDetails, AccordionSummaryInfo, EmptyText, UrlField} from '../../../../components';
import {IClientRole} from '../../../../models';
import {styledLabelShow} from '../../../../styles';

export const ClientInfo: React.FC = React.memo(() => {
    const record = useRecordContext<IClientRole>();

    const clientSubtitle = useMemo(() => {
        const client = record?.client;

        if (!client) return '';

        const result = [];

        result.push(client.companyName);
        // result.push(`${client.firstName} ${client.lastName}`);
        result.push(client.email);
        result.push(client.phone);
        result.push(client.position);
        result.push(client.companyWebsite);

        return result.filter(it => !!it).join(', ');
    }, [record]);

    if (!record) return null;

    return (
        <Accordion>
            <AccordionSummaryInfo
                aria-controls="сlient-information"
                id="сlient-information"
                subtitle={clientSubtitle}
                title="Client Information"
            >
            </AccordionSummaryInfo>
            <AccordionDetails sx={{background: '#e7ebf0'}}>
                <Grid container spacing={2}>
                    <Grid item sm={4}>
                        <Typography sx={styledLabelShow}>Full Name</Typography>
                        <TextField source="client.firstName" variant="h6" />&nbsp;
                        <TextField source="client.lastName" variant="h6" />
                    </Grid>
                    <Grid item sm={4}>
                        <Labeled label="Email">
                            <EmailField record={record.client} source="email" variant="body2" />
                        </Labeled>
                    </Grid>
                    <Grid item sm={4}>
                        <Labeled label="Phone">
                            <EmptyText source="client.phone" />
                        </Labeled>
                    </Grid>
                    <Grid item sm={4}>
                        <Labeled label="Company Name">
                            <EmptyText source="client.companyName" />
                        </Labeled>
                    </Grid>
                    <Grid item sm={4}>
                        <Labeled label="Position">
                            <EmptyText source="client.position" />
                        </Labeled>
                    </Grid>
                    <Grid item sm={4}>
                        <Labeled label="Company Website">
                            {/* <EmptyText source="client.companyWebsite" /> */}
                            <UrlField source="client.companyWebsite" />
                        </Labeled>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
});
