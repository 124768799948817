import React from 'react';
import {RaRecord, useRecordContext} from 'react-admin';

import {Typography, TypographyProps} from '@mui/material';

import {formatter, getByPath} from '../../../utils';


type Props = {
    component?: string;
    source: string;
    startAdorment?: string;
    recordLocal?: RaRecord;
} & TypographyProps;

export const MoneyField = React.memo(({recordLocal, source, startAdorment, ...props}: Props) => {
    const record = useRecordContext();

    if (!record) return null;

    const field = getByPath(recordLocal || record, source);

    return (
        <Typography {...props}>
            {field
                ? formatter.format(field / 100) // convert without cents
                : '/'
            }
        </Typography>
    );
});
