import React, {FC} from 'react';

import {Box, Typography} from '@mui/material';

import {styledLabel} from '../Tile/Tile';

import {EColors} from './SectionGMV';

const styledDot = {
    fontSize: 36,
    lineHeight: '14px',
    verticalAlign: 'middle',
};

interface ILegendProp {
    color: EColors;
    label: string;
}

const Legend: FC<ILegendProp> = ({color, label}) => {
    return (
        <Typography component="span" sx={{...styledLabel, ml: 1}} variant='caption'>
            <Box component="span" sx={{...styledDot, color}}>•</Box> {label}
        </Typography>
    );
};

export default Legend;
