import React, {ReactNode} from 'react';
import {ExportButton} from 'react-admin';

import {Box, Typography} from '@mui/material';

import {styledLabelSearchForm} from '../../styles';

type SearchFiltersBottomProps = {
    children?: ReactNode;
};

const SearchFiltersBottom: React.FC<SearchFiltersBottomProps> = ({children}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'flex-end',
                background: 'white',
                px: 3,
                pt: 2,
                pb: '4px',
                mt: 2,
            }}
        >
            <Typography
                sx={{
                    ...styledLabelSearchForm,
                    marginRight: 'auto',
                }}
                variant="h6">Results</Typography>

            {children}

            <ExportButton />
        </Box>
    );
};

export default React.memo(SearchFiltersBottom);
