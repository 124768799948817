/**
 * Maps react-admin queries to a SpringBoot REST
 *
 * @see https://github.com/vishpat/ra-data-springboot-rest/blob/master/src/index.js
 *
 */

import {stringify} from 'query-string';
import {
    CREATE,
    CreateParams, DataProvider, DELETE,
    DELETE_MANY,
    DeleteManyParams,
    fetchUtils,
    GET_LIST,
    GET_MANY,
    GET_MANY_REFERENCE,
    GET_ONE,
    GetListParams, GetOneParams, UPDATE,
    UPDATE_MANY, UpdateManyParams, UpdateParams
} from 'react-admin';

import {getMIME} from '../utils/file.helper';

/**
 * Maps react-admin queries to a REST API implemented using Java Spring Boot and Swagger
 *
 * @example
 * GET_LIST     => GET http://my.api.url/posts?page=0&size=10
 * GET_ONE      => GET http://my.api.url/posts/123
 * getManyRef   => GET http://my.api.url/posts?author_id=345
 * GET_MANY     => GET http://my.api.url/posts?id=1234&id=5678
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
const requestApi = (baseApiUrl: string, httpClient = fetchUtils.fetchJson) => {
    /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} res Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {{ method?: string; body?: string }} { url, options } The HTTP request parameters
     */
    const convertDataRequestToHTTP = (type: string, res: string, params: any): {url: string; options: { method?: string; body?: string }} => {
        const {apiUrl, resource} = formatURL(baseApiUrl, res, params);

        let url = '';
        const options: { method?: string; body?: string } = {};

        switch (type) {
            case GET_LIST: {
                // const { page, perPage } = params.pagination;
                // const { field, order } = params.sort;

                url = `${apiUrl}/${resource}${formatParams(params)}`;
                break;
            }
            case GET_ONE:
                url = `${apiUrl}/${resource}${params.id ? '/' + params.id : ''}`;
                break;
            case GET_MANY: {
                // const query = {
                //     filter: JSON.stringify({ id: params.ids })
                // };
                const idStr = '';

                params.ids.map((id: string) => idStr + `id=${id}`);

                url = `${apiUrl}/${resource}?${idStr}`;
                break;
            }
            case GET_MANY_REFERENCE: {
                const query = {
                    // ...fetchUtils.flattenObject(params.filter),
                    [params.target]: params.id,
                };

                url = `${apiUrl}/${resource}${formatParams(params)}&${stringify(query)}`;
                break;
            }
            case UPDATE:
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = 'PUT';
                options.body = JSON.stringify(params.data);
                break;
            case CREATE:
                const {channelId, name} = params.data;

                if (resource === 'channels' && channelId) {
                    url = `${apiUrl}/${resource}/${channelId}/subchannels`;
                    options.method = 'POST';
                    options.body = JSON.stringify({name});
                } else {
                    url = `${apiUrl}/${resource}`;
                    options.method = 'POST';
                    options.body = JSON.stringify(params.data);
                }

                break;
            case DELETE:
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = 'DELETE';
                break;
            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }

        return {url, options};
    };

    /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} Data response
     */
    const convertHTTPResponse = (response: any, type: string, resource: string, params: any) => {
        const {json} = response;

        switch (type) {
            case GET_LIST:
            case GET_MANY_REFERENCE:
                if (json.hasOwnProperty('users')) {
                    return {
                        data: json.users,
                        total: json.users.length
                    };
                }

                if (json.hasOwnProperty('groups')) {
                    return {
                        data: json.groups.map((group: any, index: number) => ({name: group, id: index})),
                        total: json.groups.length
                    };
                }

                if (!json.hasOwnProperty('totalElements')) {
                    throw new Error(
                        'The numberOfElements property must be must be present in the Json response'
                    );
                }

                // Selects in the table not working with undefined
                if (resource === 'applications') {
                    json.content = json.content.map((it: any) => ({...it, reviewer: it.reviewer || ''}));
                }

                return {
                    data: json.content,
                    total: parseInt(json.totalElements, 10)
                };
            case CREATE:
                return {data: {...params.data, id: json?.id}};
            default:
                return {data: json || params};
        }
    };

    /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a data response
     */
    return (type: string, res: string, params: CreateParams | DeleteManyParams<any> | GetListParams | GetOneParams | UpdateManyParams) => {
        const {apiUrl, resource} = formatURL(baseApiUrl, res, params);

        // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
        if (type === UPDATE_MANY) {
            return Promise.all(
                (params as UpdateManyParams).ids.map(id =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: 'PUT',
                        body: JSON.stringify((params as UpdateManyParams).data)
                    })
                )
            ).then(responses => ({
                data: responses.map((response: any) => response.json)
            }));
        }

        // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
        if (type === DELETE_MANY) {
            return Promise.all(
                (params as DeleteManyParams).ids.map((id: number | string) =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: 'DELETE'
                    })
                )
            ).then(responses => ({
                data: responses.map(response => response.json)
            }));
        }

        if ((type === CREATE) && resource === 'brands') {
            const payload = (params as CreateParams).data;
            const formData = new FormData();

            formData.append('name', payload.name);
            formData.append('moderationStatus', payload.moderationStatus);
            if (payload.logo?.rawFile) {
                formData.append('logo', payload.logo.rawFile);
            }

            return httpClient(`${apiUrl}/${resource}`, {
                method: 'POST',
                body: formData,
            }).then(response =>
                convertHTTPResponse(response, type, resource, params) as any
            );
        }

        if (type === UPDATE && resource === 'brands') {
            const payload = (params as CreateParams).data;
            const meta = (params as CreateParams).meta;
            const formData = new FormData();

            formData.append('name', payload.name);
            formData.append('moderationStatus', payload.moderationStatus);
            formData.append('logoDeleted', meta.logoDeleted);

            if (payload.logo?.rawFile) {
                formData.append('logo', payload.logo.rawFile);
            }

            return httpClient(`${apiUrl}/${resource}/${payload.id}`, {
                method: 'PUT',
                body: formData,
            }).then(response =>
                convertHTTPResponse(response, type, resource, params) as any
            );
        }

        const {url, options} = convertDataRequestToHTTP(type, resource, params);

        return httpClient(url, options).then(response =>
            convertHTTPResponse(response, type, resource, params) as any
        ).catch(error => {
            // GC-520 clean store if query string params broke the page
            if (error.status === 500 && type === GET_LIST) {
                localStorage.removeItem(`RaStore.${resource}.listParams`);
            }
            throw error;
        });
    };
};

function formatURL(baseApiUrl: string, resource: string, params: any): { apiUrl: string; resource: string } {
    // we have /notifications under admin and publix apis
    // so format write url
    if (typeof params.area === 'string') {
        return {
            apiUrl: `${baseApiUrl}${params.area ? '/' + params.area : ''}`,
            resource
        };
    }

    switch (resource) {
        case 'brands':
        case 'channels':
        case 'subchannels':
        case 'verticals':
        case 'tools':
            return {
                apiUrl: baseApiUrl + '/admin/meta',
                resource
            };
        case 'applications':
        case 'clients':
        case 'engagements':
        case 'groups':
        case 'freelancers':
        case 'notifications':
        case 'notifications/rules':
        case 'notifications/rules/test/email':
        case 'payments':
        case 'timesheets':
        case 'roles':
        case 'widgets':
            return {
                apiUrl: baseApiUrl + '/admin',
                resource
            };
        case 'notifications-rules':
            return {
                apiUrl: baseApiUrl + '/admin',
                resource: 'notifications/rules'
            };
        case `admin/applications/${params?.previousData?.id}/review/documents`:
            return {
                apiUrl: baseApiUrl,
                resource
            };
        case 'users': {
            if (params.id === (window as any).GC_Identity.id) {
                params.id = 'me';
            }

            return {
                apiUrl: baseApiUrl + '/admin',
                resource
            };
        }

        default:
            console.warn('unsupported entity', resource);

            return {apiUrl: baseApiUrl, resource};
    }
}

function formatParams(params: GetListParams) {
    const {page, perPage} = params.pagination;
    const {field, order} = params.sort;

    let filter = '';
    let sort = '';

    for (const filterProp in params.filter) {
        switch (filterProp) {
            case 'createdAfter':
            case 'createdBefore':
                if (params.filter[filterProp]) {
                    params.filter[filterProp] = params
                        .filter[filterProp]
                        .split('-')
                        .map((num: string) => num.length < 2 ? `0${num}` : num)
                        .join('-');
                }
                break;
            case 'budgetType':
            case 'businessModels':
            case 'eventSources':
            case 'eventTypes':
            case 'group':
            case 'moderationStatus':
            case 'statusPayment':
            case 'statusPayout':
            case 'tier':
            case 'timesheetStatus':
            case 'workTypes':
                if (params.filter[filterProp] === 'ALL') {
                    delete params.filter[filterProp];
                }
                break;
        }
        const value = params.filter[filterProp];

        if (value !== undefined && value !== null && value !== '') {
            filter += `&${filterProp}=${encodeURIComponent(value)}`;
        }
    }

    if (params.sort) {
        sort += `&sort=${encodeURIComponent(`${field},${order}`)}`;
    }

    return `?page=${page - 1}&size=${perPage}${sort}${filter}`;
}

export default (apiUrl: string = '', httpClient = fetchUtils.fetchJson): DataProvider => ({
    getList: (resource, params) => {
        return requestApi(apiUrl, httpClient)(GET_LIST, resource, params);
    },

    getOne: (resource, params) => {
        return requestApi(apiUrl, httpClient)(GET_ONE, resource, params);
    },

    getMany: (resource, params) => {
        return requestApi(apiUrl, httpClient)(GET_MANY, resource, params);
    },

    getManyReference: (resource, params) => {
        return requestApi(apiUrl, httpClient)(GET_MANY_REFERENCE, resource, params);
    },

    update: (resource, params) => {
        return requestApi(apiUrl, httpClient)(UPDATE, resource, params);
    },

    updateMany: (resource, params) => {
        return requestApi(apiUrl, httpClient)(UPDATE_MANY, resource, params);
    },

    create: (resource, params) => {
        return requestApi(apiUrl, httpClient)(CREATE, resource, params);
    },

    delete: (resource, params) => {
        return requestApi(apiUrl, httpClient)(DELETE, resource, params);
    },

    deleteMany: (resource, params) => {
        return requestApi(apiUrl, httpClient)(DELETE_MANY, resource, params);
    },

    // Custom methods

    get: (resource: string, params: GetOneParams) => {
        return requestApi(apiUrl, httpClient)(GET_ONE, resource, params);
    },

    auth: (resource: string, payload: any) => {
        const url = `${process.env.REACT_APP_KEYCLOAK_URL}/${resource}`;

        const formBody = [];
        const headers = new Headers();

        // headers.append('Authorization', 'Bearer ' + payload.subject_token);
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        // headers.append('Access-Control-Allow-Origin', '*');

        for (const property in payload) {
            const encodedKey = encodeURIComponent(property);
            const encodedValue = encodeURIComponent(payload[property]);

            formBody.push(encodedKey + '=' + encodedValue);
        }

        return fetch(url, {
            body: formBody.join('&'),
            credentials: 'include',
            headers,
            method: 'POST',
            mode: 'cors', // cors, no-cors, *cors, same-origin
        }).then(response => response?.json());
    },

    downloadFile: (resource: string, fileName: string, token: string) => {
        const anchor = document.createElement('a');

        document.body.appendChild(anchor);
        const file = `${apiUrl}/${resource}`;

        const headers = new Headers();

        headers.append('Authorization', 'Bearer ' + token);

        return fetch(file, {headers})
            .then(response => response.blob())
            .then(blobby => {
                const objectUrl = window.URL.createObjectURL(blobby);

                anchor.href = objectUrl;
                anchor.download = fileName;
                anchor.click();

                window.URL.revokeObjectURL(objectUrl);
                document.body.removeChild(anchor);
            });
    },

    previewFile: (resource: string, fileName: string, token: string) => {
        const file = `${apiUrl}/${resource}`;

        const headers = new Headers();

        headers.append('Authorization', 'Bearer ' + token);

        return fetch(file, {headers})
            .then(response => response.blob())
            .then(blobby => {
                const blob = new Blob([blobby], {type: getMIME(fileName)});
                const blobURL = URL.createObjectURL(blob);

                window.open(blobURL);
                window.URL.revokeObjectURL(blobURL);
            });
    },

    post: (resource: string, params: CreateParams) => {
        return httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: params?.data ? JSON.stringify(params.data) : ''
        });
    },

    patch: (resource: string, params: UpdateParams) => {
        return httpClient(`${apiUrl}/${resource}`, {
            method: 'PATCH',
            body: params?.data ? JSON.stringify(params.data) : ''
        });
    },

    put: (resource: string, params: UpdateParams) => {
        return httpClient(`${apiUrl}/${resource}`, {
            method: 'PUT',
            body: params?.data ? JSON.stringify(params.data) : ''
        });
    },

    postFormData: (resource: string, params: UpdateParams) => {
        const formData = new FormData();

        Object.entries(params.data).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                value.forEach(it => formData.append(key, JSON.stringify(it)));
            } else {
                formData.append(key, value);
            }
        });

        return httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: formData,
        });
    },

    putFormData: (resource: string, params: UpdateParams) => {
        const formData = new FormData();

        Object.entries(params.data).forEach(([key, value]) => {
            if (key === 'outcomes') {
                formData.append('outcomes', JSON.stringify(value));
            } else if (Array.isArray(value)) {
                value.forEach(it => formData.append(key, JSON.stringify(it)));
            } else {
                formData.append(key, value);
            }
        });

        return httpClient(`${apiUrl}/${resource}`, {
            method: 'PUT',
            body: formData,
        });
    },

    remove: (resource: string) => {
        return httpClient(`${apiUrl}/${resource}`, {
            method: 'DELETE',
        });
    },
});
