import React from 'react';
import {useListContext} from 'react-admin';
import {useFormContext} from 'react-hook-form';

import {Button} from '../../../components';
import {defaultNotificationFiltersValues} from '../filters';

export const ClearFiltersButton = () => {
    const {displayedFilters, setFilters} = useListContext();
    const {setValue} = useFormContext();

    const onClearFilters = () => {
        setValue('createdAfter', defaultNotificationFiltersValues.createdAfter);
        setValue('createdBefore', defaultNotificationFiltersValues.createdBefore);
        setValue('eventTypes', defaultNotificationFiltersValues.eventTypes);

        setFilters(defaultNotificationFiltersValues, displayedFilters);
    };

    return (
        <Button
            className="secondary"
            color="primary"
            disableElevation
            variant="contained"
            onClick={onClearFilters}
        >
            Clear filters
        </Button>
    );
};