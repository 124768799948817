import React, {FC, memo} from 'react';

import {CircularProgress} from '@mui/material';

interface Props {
    color?: 'inherit' | 'primary' | 'secondary';
    extendClasses?: any;
    opaque?: boolean;
    size?: number;
}

export const LoadingMask: FC<Props> = memo(({opaque, extendClasses = {}, ...rest}): JSX.Element => {
    return (
        <div className="">
            <CircularProgress
                {...rest}
                className=""
            />
        </div>
    );
});

export default LoadingMask;
