import React, {ChangeEvent, useState} from 'react';
import {useDataProvider, useRefresh} from 'react-admin';

import {Dialog, DialogActions, DialogContent, DialogTitle, Link} from '@mui/material';

import {Button, Input} from '../index';

interface ISetFeeDialogProps {
    endpoint: string;
    value?: number;
    required?: boolean;
}

export const SetFeeDialog: React.FC<ISetFeeDialogProps> = ({endpoint, value, required}) => {
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const [feeDialogOpen, setFeeDialogOpen] = useState(false);
    const [newFee, setNewFee] = useState<number | undefined>(value);
    const [newFeeError, setNewFeeError] = useState<string>();
    const [strValue, setStrValue] = useState<string>(value !== undefined ? value.toFixed(2) : '');

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.trim().replace(',', '.');

        setStrValue(value);

        if (value === '') {
            setNewFee(undefined);
            setNewFeeError(required ? 'Fee is required' : undefined);

            return;
        }

        if (!/^\d{1,4}(\.\d{1,2})?$/.test(value) || !isFinite(parseFloat(value))) {
            setNewFeeError('Invalid value');

            return;
        }
        setNewFee(parseFloat(value));
        setNewFeeError(undefined);
    };

    const handleReset = () => {
        dataProvider.postFormData(endpoint, {data: {}})
            .then(refresh)
            .then(() => setFeeDialogOpen(false));
    };

    const handleSave = () => {
        if (newFeeError)
            return;
        dataProvider.postFormData(endpoint, {data: {fee: newFee}})
            .then(refresh)
            .then(() => setFeeDialogOpen(false));
    };

    return <>
        <Link
            style={{cursor: 'pointer'}}
            onClick={() => setFeeDialogOpen(true)}>
            {typeof value === 'number' ? value.toFixed(2) : 'Not set'}
        </Link>
        <Dialog
            maxWidth="xs"
            open={feeDialogOpen}
            onClose={() => setFeeDialogOpen(false)}
        >
            <DialogTitle>
                Set Growth Collective Fee
            </DialogTitle>
            <DialogContent dividers>
                <Input
                    value={strValue}
                    onChange={handleChange}
                    endAdornment={<b>%</b>}
                    error={newFeeError}
                />
            </DialogContent>
            <DialogActions>
                {!required && <Button
                    variant="contained"
                    onClick={handleReset}
                >Reset to default</Button>}
                <Button
                    variant="contained"
                    disabled={(typeof newFee === 'undefined') || !!newFeeError || newFee == value}
                    onClick={handleSave}
                >Save</Button>
                <Button onClick={() => setFeeDialogOpen(false)}>Cancel</Button>
            </DialogActions>
        </Dialog>
    </>;
};
