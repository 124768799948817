import React, {FC} from 'react';
import {RaRecord, useEditContext, useRecordContext} from 'react-admin';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {Box, Typography} from '@mui/material';

import {EPaymentMethod, IPaymentPreview} from '../../../../models';

type Props = {
    recordLocal?: RaRecord;
    variant?: 'body1' | 'body2' | 'h6';
};

const ClientNameField: FC<Props> = ({recordLocal, variant = 'body2'}) => {
    const contextEdit = useEditContext();
    const record = useRecordContext();
    const field = recordLocal || record;
    const message = 'Failed payment exists';
    const isEditPage = !!contextEdit.record;

    const isPaymentMethodInvoice = record?.paymentMethods?.some((it: IPaymentPreview) => it.method === EPaymentMethod.ManualInvoicing);

    // is hasAnyFailedPayments
    // on the table show alert icon + name
    // on the Edit page - show alert icon + alert message
    //
    // or
    // on the table show name
    // on the Edit page - nothing to show
    if (field?.hasAnyFailedPayments) {
        return (
            <>
                {isPaymentMethodInvoice && <InvoicePayInfo/>}
                <Typography component="span" color={isEditPage ? 'error' : 'inherit'} variant={variant}>
                    <Box
                        component="span"
                        sx={{
                            verticalAlign: variant === 'body2' ? 'middle' : 'text-top',
                            mr: 1
                        }}
                        title={message}
                    >
                        <WarningAmberIcon color="error"/>
                    </Box>
                    {isEditPage ? message : field?.companyName}
                </Typography>
            </>

        );
    } else if (isEditPage) {
        return isPaymentMethodInvoice && <InvoicePayInfo/>;
    }

    return (
        <Typography variant={variant}>
            {field?.companyName}
        </Typography>
    );
};

export default React.memo(ClientNameField);

const InvoicePayInfo = () => (
    <Typography color="primary" variant="body2">
        <Box
            component="span"
            sx={{
                verticalAlign: 'middle',
                mr: 1
            }}
        >
            <ErrorOutlineIcon color="primary"/>
        </Box>
        This Client Pay by Invoice
    </Typography>
);
