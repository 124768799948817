import React from 'react';
import {Datagrid, EditButton, List, TextField} from 'react-admin';

import {ColumnLabeled, PaginationCoreUI} from '../../components';
import {styledDatagrid, styledList, styledListEditButton} from '../../styles';

import {ListActions} from './components/List/ListActions';


export const VerticalList = () => (
    <List
        actions={<ListActions />}
        pagination={<PaginationCoreUI />}
        sort={{field: 'name', order: 'ASC'}}
        sx={styledList}
    >
        <Datagrid
            rowClick="edit"
            sx={{
                ...styledDatagrid,
                '& .MuiTableCell-root:last-of-type': {
                    maxWidth: '100px',
                    width: '100px'
                },
            }}
        >
            <TextField source="name" />
            <ColumnLabeled source="actions" sortable={false}>
                <EditButton label="" sx={styledListEditButton}/>
            </ColumnLabeled>
        </Datagrid>
    </List>
);