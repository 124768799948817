import React, {ChangeEvent, useCallback, useEffect, useMemo, useState} from 'react';
import {BooleanInput, FormDataConsumer, Labeled, NumberInput, RadioButtonGroupInput, RaRecord, required, SelectArrayInput, SelectInput,
    SimpleForm, TextInput, useDataProvider, useRecordContext} from 'react-admin';
import {useQuery} from 'react-query';

import {Card, CardContent, Divider, Grid, Typography} from '@mui/material';

import {CustomTagsHelper} from '../../components';
import {EChannel, ESendTo, ETime, IEventType, INotificationRule, IUser} from '../../models';
import {styledInput, styledLabel, styledResizeableTextArea, styledSelect} from '../../styles';
import {formatNameToCapital} from '../../utils';

import {PreviewButton, SendTestEmailButton} from './components';
import {prepareData} from './transform.helper';

const styledCard = {background: '#fafafa'};

export const NotificationForm = () => {
    const dataProvider = useDataProvider();
    const record = useRecordContext<INotificationRule>();
    const [selectedEventType, setEventType] = useState<IEventType | null>(null);

    const {data: admins} = useQuery(
        ['admin/users'],
        () => dataProvider.get('users', {area: 'admin'}),
        {select: (data: {data: {users: IUser[]}}) => data.data.users.sort((a, b) => a.firstName.localeCompare(b.firstName))}
    );

    const {data: eventTypes} = useQuery(
        ['notifications/event-types'],
        () => dataProvider.get('notifications', {id: 'event-types', area: ''}),
        {select: (data: {data: {eventTypes: IEventType[]}}) => data.data.eventTypes.sort((a, b) => a.type.localeCompare(b.type))}
    );

    const {data: groups} = useQuery(
        ['admin/groups'],
        () => dataProvider.get('groups', {area: 'admin'}),
        {select: (data: {data: {groups: string[]}}) => data.data.groups.sort((a, b) => a.localeCompare(b))}
    );

    const {data: optionsSlackChannels} = useQuery(
        ['admin/notifications'],
        () => dataProvider.get('slack-channels', {area: 'admin/notifications'}),
        {select: (data: {data: {slackChannels: {id: number; name: string}[]}}) => data.data.slackChannels}
    );

    const handleChangeEventType = useCallback((event: RaRecord | ChangeEvent<HTMLInputElement>) => {
        if (!eventTypes) return;
        const {value} = event.target;
        const eventType = eventTypes.find(item => item.type === value);

        if (eventType) {
            setEventType(eventType);
        }
    }, [eventTypes]);

    const optionsAdmins = useMemo(() => {
        return admins ? admins.map((it: IUser) => ({id: it.id, name: it.firstName})) : [];
    }, [admins]);

    const optionsEventType = useMemo(() => {
        return eventTypes
            ? eventTypes.map((it: IEventType) => ({id: it.type, name: it.type}))
                .map((it) => ({
                    ...it,
                    name: formatNameToCapital(it.name.replace(/_/g, ' '))
                }))
            : [];
    }, [eventTypes]);

    const optionsGroups = useMemo(() => {
        return groups ? groups.map((it: string) => ({id: it, name: it.replace('/Administrators/', '')})) : [];
    }, [groups]);

    const optionsSendTo = useMemo(() => {
        const options: {id: string; name: string}[] = [];

        if (!selectedEventType) {
            return;
        }

        if (selectedEventType.channels.includes(EChannel.EMAIL_USER)) {
            options.push({id: ESendTo.user, name: 'Target user'});
        }
        if (selectedEventType.channels.includes(EChannel.EMAIL_ADMIN)) {
            options.push({id: ESendTo.admin, name: 'Admin'});
        }

        return options;
    }, [selectedEventType]);

    const optionsTime = useMemo(() => {
        const options: {id: string; name: string}[] = [];

        if (!selectedEventType) {
            return;
        }

        if (selectedEventType.immediate) {
            options.push({id: ETime.immediate, name: 'Immediate'});
        }
        if (selectedEventType.delayed) {
            options.push({id: ETime.delayed, name: 'Delayed'});
        }

        return options;
    }, [selectedEventType]);

    // init form
    useEffect(() => {
        if (!record || !eventTypes) return;

        const eventType = eventTypes.find(item => item.type === record.eventType);

        if (eventType) {
            setEventType(eventType);
        }
    }, [eventTypes, record]);

    // populate form
    const defaultValues = useMemo(() => prepareData(record), [record]);

    const templateVariables = selectedEventType ? (
        <CustomTagsHelper
            customTags={selectedEventType.dynamicParameters}
            tooltipTitle="For selected event type you can use following custom tags:"
        />
    ) : undefined;

    return (
        <SimpleForm defaultValues={defaultValues}>
            <Grid container spacing={2} sx={{maxWidth: 800}}>
                <Grid item xs={8}>
                    <TextInput
                        autoComplete="new-password"
                        helperText={false}
                        fullWidth
                        label="Name of notification"
                        source="name"
                        sx={styledInput}
                        validate={required()}
                    />
                </Grid>
                <Grid item xs={4}>
                    <SelectInput
                        choices={optionsEventType}
                        helperText={false}
                        fullWidth
                        margin="none"
                        source="eventType"
                        sx={styledSelect}
                        validate={required()}
                        onChange={handleChangeEventType}
                    />
                </Grid>
                {
                    selectedEventType && (
                        <>
                            <Grid item xs={12}>
                                <Labeled label="Time *" sx={styledLabel}>
                                    <RadioButtonGroupInput source="time" validate={required()} choices={optionsTime} />
                                </Labeled>
                                <FormDataConsumer>
                                    {({formData}) => {
                                        if (formData.time && formData.time === 'delayed') {
                                            return (
                                                <NumberInput
                                                    label="Delayed time (in minutes)"
                                                    source="delayMinutes"
                                                    validate={required()}
                                                    sx={{
                                                        ...styledInput,
                                                        width: 250
                                                    }}
                                                />
                                            );
                                        }
                                    }}
                                </FormDataConsumer>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="caption">Communication channels *</Typography>
                                <Divider />
                            </Grid>
                        </>
                    )
                }

                {
                    selectedEventType?.channels.includes(EChannel.INTERNAL_USER) && (
                        <Grid item xs={12}>
                            <Card>
                                <CardContent sx={styledCard}>
                                    <BooleanInput disabled={!selectedEventType} label="Show in notifications area" source="internalUserEnabled" />
                                    <FormDataConsumer>
                                        {({formData: {internalUserEnabled}}) => internalUserEnabled && (
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <TextInput
                                                        autoComplete="off"
                                                        fullWidth
                                                        helperText={false}
                                                        label="Subject"
                                                        placeholder="e.g. Thanks for registering applicant account!"
                                                        source="internalUserSubject"
                                                        sx={styledInput}
                                                        validate={required()}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextInput
                                                        autoComplete="off"
                                                        fullWidth
                                                        helperText={templateVariables}
                                                        label="Template"
                                                        multiline
                                                        placeholder="e.g. Dear ${firstName} ${lastName}, Thanks!"
                                                        rows={4}
                                                        source="internalUserTemplate"
                                                        sx={styledResizeableTextArea}
                                                        validate={required()}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                    </FormDataConsumer>
                                </CardContent>
                            </Card>
                        </Grid>
                    )
                }

                {
                    selectedEventType?.channels.includes(EChannel.INTERNAL_ADMIN) && (
                        <Grid item xs={12}>
                            <Card>
                                <CardContent sx={styledCard}>
                                    <BooleanInput disabled={!selectedEventType} label="Show in admin notifications area" source="internalAdminEnabled" />
                                    <FormDataConsumer>
                                        {({formData: {internalAdminEnabled, internalAdminRecipients, internalAdminRoleRecipients}}) => internalAdminEnabled && (
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <TextInput
                                                        autoComplete="off"
                                                        fullWidth
                                                        helperText={false}
                                                        label="Subject"
                                                        placeholder="e.g. New applicant account"
                                                        source="internalAdminSubject"
                                                        validate={required()}
                                                        sx={styledInput}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextInput
                                                        autoComplete="off"
                                                        fullWidth
                                                        helperText={templateVariables}
                                                        label="Template"
                                                        multiline
                                                        placeholder="e.g. ${firstName} ${lastName} just registered an account"
                                                        rows={4}
                                                        source="internalAdminTemplate"
                                                        validate={required()}
                                                        sx={styledResizeableTextArea}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <SelectArrayInput
                                                        choices={optionsAdmins}
                                                        fullWidth
                                                        helperText={false}
                                                        label="Admin"
                                                        margin="none"
                                                        source="internalAdminRecipients"
                                                        sx={styledSelect}
                                                        validate={!internalAdminRoleRecipients?.length ? required() : undefined}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <SelectArrayInput
                                                        choices={optionsGroups}
                                                        fullWidth
                                                        helperText={false}
                                                        label="Admin roles"
                                                        margin="none"
                                                        source="internalAdminRoleRecipients"
                                                        sx={styledSelect}
                                                        validate={!internalAdminRecipients?.length ? required() : undefined}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                    </FormDataConsumer>
                                </CardContent>
                            </Card>
                        </Grid>
                    )
                }

                {
                    (selectedEventType?.channels.includes(EChannel.EMAIL_ADMIN) || selectedEventType?.channels.includes(EChannel.EMAIL_USER)) && (
                        <Grid item xs={12}>
                            <Card>
                                <CardContent sx={styledCard}>
                                    <BooleanInput disabled={!selectedEventType} label="Send email" source="isEmail" />
                                    <FormDataConsumer>
                                        {({formData: {sendTo, isEmail, emailSender, emailSubject, emailRecipients, emailRoleRecipients, emailTemplate}}) => isEmail && (
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <TextInput
                                                        autoComplete="new-password"
                                                        fullWidth
                                                        helperText={false}
                                                        label="From field"
                                                        placeholder="e.g. Growth Collective <backend@dev.v1.growthcollective.com>"
                                                        source="emailSender"
                                                        validate={required()}
                                                        sx={styledInput}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Labeled label="Send to *" sx={styledLabel}>
                                                        <RadioButtonGroupInput
                                                            choices={optionsSendTo}
                                                            source="sendTo"
                                                            validate={required()}
                                                        />
                                                    </Labeled>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <SelectArrayInput
                                                                choices={optionsAdmins}
                                                                fullWidth
                                                                helperText={false}
                                                                label={`${sendTo === ESendTo.user ? 'BCC' : ''} Admin`}
                                                                margin="none"
                                                                source="emailRecipients"
                                                                sx={styledSelect}
                                                                validate={!emailRoleRecipients?.length ? required() : undefined}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <SelectArrayInput
                                                                choices={optionsGroups}
                                                                fullWidth
                                                                helperText={false}
                                                                label={`${sendTo === ESendTo.user ? 'BCC' : ''} Admin roles`}
                                                                margin="none"
                                                                source="emailRoleRecipients"
                                                                sx={styledSelect}
                                                                validate={!emailRecipients?.length ? required() : undefined}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextInput
                                                        autoComplete="new-password"
                                                        fullWidth
                                                        helperText={false}
                                                        label="Subject"
                                                        placeholder="e.g. Thanks for registering applicant account!"
                                                        source="emailSubject"
                                                        validate={required()}
                                                        sx={styledInput}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextInput
                                                        autoComplete="off"
                                                        fullWidth
                                                        helperText={
                                                            <CustomTagsHelper
                                                                customTags={selectedEventType?.dynamicParameters}
                                                                tooltipTitle="For selected event type you can use following custom tags:"
                                                            />
                                                        }
                                                        label="Body"
                                                        multiline
                                                        placeholder="e.g. <h1>Dear ${firstName} ${lastName}, Thanks!</h1>"
                                                        rows={4}
                                                        source="emailTemplate"
                                                        validate={required()}
                                                        sx={styledResizeableTextArea}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sx={{marginTop: '20px', textAlign: 'right'}}>
                                                    <PreviewButton template={emailTemplate} />
                                                    <SendTestEmailButton disabled={!emailSender || !emailSubject || !emailTemplate} />
                                                </Grid>
                                            </Grid>
                                        )}
                                    </FormDataConsumer>
                                </CardContent>
                            </Card>
                        </Grid>
                    )
                }

                {
                    selectedEventType?.channels.includes(EChannel.SLACK_ADMIN) && (
                        <Grid item xs={12}>
                            <Card>
                                <CardContent sx={styledCard}>
                                    <BooleanInput disabled={!selectedEventType} label="Send Slack message" source="isSlack" />
                                    <FormDataConsumer>
                                        {({formData: {isSlack}}) => isSlack && (
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <TextInput
                                                        autoComplete="off"
                                                        fullWidth
                                                        helperText={false}
                                                        label="Subject"
                                                        placeholder="e.g. New applicant account!"
                                                        source="slackSubject"
                                                        validate={required()}
                                                        sx={styledInput}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextInput
                                                        fullWidth
                                                        helperText={templateVariables}
                                                        label="Template"
                                                        multiline
                                                        placeholder="e.g. ${firstName} ${lastName} just registered an account"
                                                        rows={4}
                                                        source="slackTemplate"
                                                        validate={required()}
                                                        sx={styledResizeableTextArea}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <SelectInput
                                                        choices={optionsSlackChannels}
                                                        fullWidth
                                                        helperText={false}
                                                        label="Slack channel"
                                                        margin="none"
                                                        source="slackChannel"
                                                        sx={styledSelect}
                                                        validate={isSlack ? required() : undefined}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                    </FormDataConsumer>
                                </CardContent>
                            </Card>
                        </Grid>
                    )
                }

            </Grid>

        </SimpleForm>
    );
};
