import React from 'react';

import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import {Box, Stack, Typography} from '@mui/material';

const DatagridEmpty = () => {
    return (
        <Box
            sx={{
                p: 5,
                background: 'white'
            }}
        >
            <Stack spacing={2} alignItems="center">
                <ManageSearchIcon sx={{fontSize: 100, opacity: 0.2}}/>
                <Typography sx={{fontWeight: 500, opacity: 0.5}} variant="caption">No results found</Typography>
            </Stack>

        </Box>
    );
};

export default React.memo(DatagridEmpty);
