import React, {SyntheticEvent, useEffect, useState} from 'react';
import {useDataProvider, useEditContext, useNotify, useRecordContext} from 'react-admin';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {Button, MenuItem, Select, TableCell, TableRow, TextField} from '@mui/material';

import {EApplicationStatus, ICaseStudy, ICertificate, IReference, optionsDocumentStatus} from '../../../models';
import {styledListDeleteButton, styledListEditButton} from '../../../styles';

import {DocumentNotes} from './Fields/DocumentNotes';

interface Prop {
    applicationId: number;
    item: ICaseStudy | ICertificate | IReference;
    type: 'Certificate' | 'Reference' | 'Case Study';
    onDelete: () => void;
    onEdit: () => void;
}

export const DocumentRow = React.memo(({applicationId, item, type, onDelete, onEdit}: Prop) => {
    const [isMutable, setIsMutable] = useState(false);
    const {status: ApplicationStatus} = useRecordContext();

    useEffect(() => {
        setIsMutable(![EApplicationStatus['In Progress'], EApplicationStatus.Accepted].includes(ApplicationStatus));
    }, [ApplicationStatus]);

    const handleDeleteClick = (event: SyntheticEvent) => {
        event.stopPropagation();
        onDelete();
    };

    const handleEditClick = (event: SyntheticEvent) => {
        event.stopPropagation();
        onEdit();
    };

    return (
        <TableRow
            hover
            sx={{'&:last-child td, &:last-child th': {border: 0}}}
        >
            <TableCell>
                <DocumentNotes item={item} applicationId={applicationId} />
            </TableCell>
            <TableCell>{type}</TableCell>
            <TableCell>{item.name}</TableCell>
            <TableCell>{new Date(item.created).toLocaleDateString()}</TableCell>
            <TableCell><ApprovedBy item={item} /></TableCell>
            <TableCell><ApprovedOn item={item}/></TableCell>
            <TableCell>
                {
                    type === 'Reference' ?
                        <LastContactDateField disabled={!isMutable} applicationId={applicationId} item={item} /> :
                        null
                }
            </TableCell>
            <TableCell>
                <DocumentStatusSelect applicationId={applicationId} disabled={!isMutable} item={item} />
            </TableCell>
            <TableCell>
                {
                    isMutable ? (
                        <>
                            <Button sx={styledListEditButton} onClick={handleEditClick}>
                                <EditIcon />
                            </Button>
                            <Button sx={styledListDeleteButton} onClick={handleDeleteClick}>
                                <DeleteIcon />
                            </Button>
                        </>
                    ) : null
                }
            </TableCell>
        </TableRow>
    );
});


const ApprovedBy = ({item}: {item: ICaseStudy | ICertificate | IReference}) => {
    return <span>{item.approvedBy || '/'}</span>;
};

const ApprovedOn = ({item}: {item: ICaseStudy | ICertificate | IReference}) => {
    return <span>{item.approved ? new Date(item.approved).toLocaleDateString() : '/'}</span>;
};

const LastContactDateField = ({applicationId, item, disabled}: any) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const {refetch} = useEditContext();

    const handleChange = async (event: { target: { value: string } }) => {
        const date = new Date(event.target.value);

        await dataProvider.put(
            `admin/applications/${applicationId}/review/references`,
            {
                data: {
                    jobTitle: 'developer',
                    companyName: 'Gecko',
                    position: 'Developer',
                    email: 'test@geckodynamics.com',
                    ...item,
                    lastContactDate: date,
                }
            }
        );

        if (refetch) refetch();
        notify('Successfully updated');
    };

    const formatDate = (num: number) => num < 10 ? `0${num}` : num;

    const day = item.lastContactDate ? new Date(item.lastContactDate) : null;
    const test = day ? `${day.getFullYear()}-${formatDate(day.getMonth() + 1)}-${formatDate(day.getDate())}` : '';

    return (
        <TextField
            id={item.id.toString()}
            label={false}
            type="date"
            value={test}
            onChange={handleChange}
            disabled={disabled}
            sx={{
                marginBottom: '0',
                '& .MuiInputBase-input': {
                    padding: '8.5px 14px',
                },
                '& .MuiFormLabel-root': {
                    display: 'none',
                }
            }}
        />
    );
};

interface StatusSelectProps {
    applicationId: number;
    disabled: boolean;
    item: ICaseStudy | ICertificate | IReference;
}

const DocumentStatusSelect = ({applicationId, disabled, item}: StatusSelectProps) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const {refetch} = useEditContext();

    const handleChange = async (event: { target: { value: string } }) => {
        await dataProvider.put(
            `admin/applications/${applicationId}/review/documents`,
            {
                data: [{
                    status: event.target.value,
                    id: item.id
                }]
            }
        );

        if (refetch) refetch();
        notify('Successfully updated');
    };

    return (
        <Select
            disabled={disabled}
            id="status-select"
            label={false}
            sx={{
                minWidth: '135px',
                '& .MuiSelect-select': {
                    padding: '8.5px 14px',
                },
                'fieldset span': {
                    display: 'none',
                },
                '&.Mui-focused fieldset': {
                    boxShadow: '0 0 0 0.25rem rgba(80,70,229,0.25)',
                    borderColor: '#a8a3f2!important',
                    borderWidth: '1px!important',
                }
            }}
            value={item.status}
            onChange={handleChange}
        >
            {optionsDocumentStatus.map(item => (
                <MenuItem key={item.value} value={item.value}>{item.text}</MenuItem>
            ))}
        </Select>
    );
};
