import React from 'react';
import {useRecordContext} from 'react-admin';

import {Badge} from '@mui/material';

import {EEngagementStatus, EEngagementStatusDisplay} from '../../models';

import {styledBadge} from './styles';

const StatusEngagementBadge = ({source}: any) => {
    const record = useRecordContext();

    const status: EEngagementStatus = record[source];
    let color = '';
    let backgroundColor = '';

    switch (status) {
        case EEngagementStatus.ACTIVE:
            color = '#34B20D';
            backgroundColor = '#E1F8D9';
            break;
        case EEngagementStatus.PAUSED:
            color = '#999999';
            backgroundColor = '#F3F3F3';
            break;
        case EEngagementStatus.PENDING:
            color = '#3C94F7';
            backgroundColor = '#ECF4FE';
            break;
        case EEngagementStatus.CLOSED:
            color = '#EF462C';
            backgroundColor = '#FDEDEA';
            break;
    }

    return (
        <Badge
            sx={{
                ...styledBadge,
                color,
                backgroundColor,
            }}>
            {EEngagementStatusDisplay[status]}
        </Badge>
    );
};


export default StatusEngagementBadge;