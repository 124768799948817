import React from 'react';
import {useListContext} from 'react-admin';
import {useFormContext} from 'react-hook-form';

import {Button} from '../../../../components';

import {defaultFiltersValues} from './ListFilterForm';

export const ClearFiltersButton = () => {
    const {displayedFilters, setFilters} = useListContext();
    const {setValue} = useFormContext();

    const onClearFilters = () => {
        setValue('clients', defaultFiltersValues.clients);
        setValue('createdAfter', defaultFiltersValues.createdAfter);
        setValue('createdBefore', defaultFiltersValues.createdBefore);
        setValue('freelancers', defaultFiltersValues.freelancers);
        setValue('q', defaultFiltersValues.q);
        setValue('roles', defaultFiltersValues.roles);
        setValue('statusPayment', defaultFiltersValues.statusPayment);
        setValue('statusPayout', defaultFiltersValues.statusPayout);

        setFilters(defaultFiltersValues, displayedFilters);
    };

    return (
        <Button
            className="secondary"
            color="primary"
            disableElevation
            variant="contained"
            onClick={onClearFilters}
        >
            Clear filters
        </Button>
    );
};