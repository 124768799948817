import React, {FC} from 'react';
import {DateInput} from 'react-admin';
import {FormProvider, useForm, useWatch} from 'react-hook-form';

import {Box, Stack} from '@mui/material';

import {FieldTitle} from '../../../../components';
import {styledInput} from '../../../../styles';
import theme from '../../../../theme';
import {ISearchParams} from '../../models/dashboard.model';

const styledContainedInput = {
    ...styledInput,
    '& .MuiFormLabel-root': {
        marginBottom: '0 !important',
    },
    '& .MuiOutlinedInput-root': {
        background: 'white',
        color: theme.palette.primary.main,
        fontSize: 14,
        fontWeight: 500,
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    }
};

interface IProps {
    values: ISearchParams;
    onChange: (values: ISearchParams) => void;
}

export const SearchForm: FC<IProps> = React.memo(({values, onChange}) => {
    const methods = useForm<ISearchParams>({defaultValues: values});

    const {
        control,
        // handleSubmit,
        getValues,
        // setValue,
        // setError,
    } = methods;

    const {from, to} = useWatch({control});

    const handleChange = (v: any) => {
        const values = getValues();

        console.log('submit', values);
        onChange(values);
    };

    // const onSubmitHandler: SubmitHandler<ISearchParams> = async (values) => {
    //     console.log('values', values);
    //     try {
    //         // await emailService.check('email', values.email);
    //         // mutate(values);
    //     } catch (error) {

    //     }
    // };

    return (
        <FormProvider {...methods}>
            <form
                onChange={handleChange}
                // onSubmit={handleSubmit(onSubmitHandler)}
            >
                <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                >

                    <FieldTitle>From</FieldTitle>
                    <DateInput helperText={false} inputProps={{max: to}} label="" source="from" sx={styledContainedInput} />
                    <Box sx={{w: 1}}/>

                    <FieldTitle>To</FieldTitle>
                    <DateInput helperText={false} inputProps={{min: from}} label="" source="to" sx={styledContainedInput} />
                    <Box sx={{w: 1}}/>

                </Stack>
            </form>
        </FormProvider>
    );
});
