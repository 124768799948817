import React, {useMemo} from 'react';
import {required, SelectInput, useDataProvider} from 'react-admin';
import {useQuery} from 'react-query';

import {EEventSource} from '../../../../models';
import {styledSelectInline} from '../../../../styles';
import {formatNameToCapital} from '../../../../utils';

const EventSourcesFilter = (props: any) => {
    const dataProvider = useDataProvider();

    const {data: eventSources} = useQuery(
        ['notifications/event-sources'],
        () => dataProvider.get('notifications', {id: 'event-sources', area: ''}),
        {select: (data: any) => data.data.sources}
    );

    const optionsEventSource = useMemo(() => {
        return eventSources ? [
            {id: 'ALL', name: 'All'},
            ...eventSources
                .sort((a: EEventSource, b: EEventSource) => a.localeCompare(b))
                .map((it: EEventSource) => ({id: it, name: formatNameToCapital( it.replace(/_/g, ' '))}))
        ] : [];
    }, [eventSources]);

    return (
        eventSources &&
        <SelectInput
            {...props}
            choices={optionsEventSource}
            fullWidth
            helperText={false}
            sx={styledSelectInline}
            validate={required()}
        />
    );
};

export default React.memo(EventSourcesFilter);
