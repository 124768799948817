import React, {useMemo} from 'react';
import {required, SelectInput} from 'react-admin';

import {useAdminGroups} from '../../../../hooks';
import {styledSelectInline} from '../../../../styles';


const GroupFilter = (props: any) => {
    const {data} = useAdminGroups();
    const optionsEventSource = useMemo(() => {
        return data ? [
            {id: 'ALL', name: 'All'},
            ...data
                .map((it: string) => ({id: it, name: it.replace('/Administrators/', '')}))
        ] : [];
    }, [data]);

    return (
        data && (
            <SelectInput
                {...props}
                choices={optionsEventSource}
                helperText={false}
                fullWidth
                sx={styledSelectInline}
                validate={required()}
            />
        )

    );
};

export default React.memo(GroupFilter);
