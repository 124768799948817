import React from 'react';
import {useRecordContext} from 'react-admin';

import {Typography} from '@mui/material';

interface Props {
    source: string;
    sortable?: boolean;
}

export const ApplicantField = (props: Props) => {
    const {applicant} = useRecordContext();

    return <Typography>{ applicant ? `${applicant.firstName} ${applicant.lastName}` : ''}</Typography>;
};
