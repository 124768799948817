import React, {EventHandler} from 'react';

import {FormControl, IconButton, InputAdornment as MuiInputAdornment, InputLabel, OutlinedInput} from '@mui/material';

interface IInputAdornment {
    fullWidth?: boolean;
    icon: JSX.Element;
    label: string;
    type?: string;
    position: 'start' | 'end';
    onIconClick?: EventHandler<any>;
}

const InputAdornment = (props: IInputAdornment): JSX.Element => {
    return (
        <FormControl
            fullWidth={props.fullWidth}
            margin="dense"
            sx={{m: 1}}
            variant="outlined"
        >
            <InputLabel>{props.label}</InputLabel>
            <OutlinedInput
                label="Password"
                endAdornment={
                    props.position === 'end' &&
                  <MuiInputAdornment
                      position="end"
                      sx={{
                          position: 'relative',
                          '&::before': {
                              content: '""',
                              position: 'absolute',
                              top: 0,
                              right: 0,
                              transform: 'translate(calc(50% - 11px), -50%)',
                              height: 55,
                              width: 48,
                              backgroundColor: 'secondaryDisabledColor.main',
                              zIndex: -1,
                              borderRadius: '0 4px 4px 0',
                              borderRight: '1px solid inputDisabledBorderColor.main'
                          },
                          '.MuiSvgIcon-root': {
                              color: 'labelColor.main'
                          },
                          '&.MuiInputAdornment-positionEnd': {
                              marginLeft: 3
                          },
                      }}
                  >
                      {
                          props.onIconClick
                              ? <IconButton
                                  edge="end"
                                  onClick={props.onIconClick}
                              >
                                  {props.icon}
                              </IconButton>
                              : props.icon
                      }
                  </MuiInputAdornment>
                }
                type={props.type}
                startAdornment={
                    props.position === 'start' &&
                  <MuiInputAdornment
                      position="start"
                      sx={{
                          position: 'relative',
                          '&::before': {
                              content: '""',
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              transform: 'translate(calc(50% - 37px), -50%)',
                              height: 55,
                              width: 48,
                              backgroundColor: 'secondaryDisabledColor.main',
                              zIndex: -1,
                              borderRadius: '4px 0 0 4px',
                              borderRight: '1px solid inputDisabledBorderColor.main'
                          },
                          '.MuiSvgIcon-root': {
                              color: 'labelColor.main'
                          },
                          '&.MuiInputAdornment-positionStart': {
                              marginRight: 3
                          }
                      }}
                  >
                      {
                          props.onIconClick
                              ? <IconButton
                                  edge="start"
                                  onClick={props.onIconClick}
                              >
                                  {props.icon}
                              </IconButton>
                              : props.icon
                      }
                  </MuiInputAdornment>
                }
            />
        </FormControl>
    );
};

export default InputAdornment;
