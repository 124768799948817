import React, {ChangeEvent, useEffect, useState} from 'react';
import clsx from 'clsx';

import {Checkbox as MUICheckbox, CheckboxProps as MuiCheckboxProps, FormControlLabel} from '@mui/material';

interface CheckboxProps extends MuiCheckboxProps {
    label?: string;
}

const Checkbox = ({label, onChange, ...props}: CheckboxProps): JSX.Element => {
    const [checked, setChecked] = useState(!!props.checked);

    const handleChange = (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setChecked(checked);
        if (onChange) {
            onChange(e, checked);
        }
    };

    useEffect(() => {
        setChecked(!!props.checked);
    }, [props.checked]);

    return (
        <FormControlLabel
            control={<MUICheckbox
                checked={checked}
                className={clsx(props.className)}
                sx={{
                    color: `${props.color ? props.color : 'primary'}.main`,
                    '&.Mui-disabled': {
                        color: `${props.color ? props.color : 'primary'}.main`,
                        opacity: .65
                    }
                }}
                {...props}
                onChange={handleChange}
            />}
            label={label}
        />
    );
};

export default Checkbox;
