import React, {useEffect, useState} from 'react';
import {SimpleForm, Toolbar} from 'react-admin';

import {Stack, Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material';

import {useMatcher} from '../../hooks';
import {EMatcherType, IMatcherConfig, IMatcherProperty} from '../../models';
import {Button} from '..';

import CuttingRow from './CuttingRow';
import MultiplierRow from './MultiplierRow';


interface Props {
    buttonLabel: string;
    configurationId?: number;
    onSubmit: (settings: IMatcherConfig[]) => void;
}

export const MatcherSettingsForm = ({buttonLabel, configurationId, onSubmit}: Props) => {
    const {settings} = useMatcher(configurationId);
    const [values, setValues] = useState<IMatcherProperty[]>([]);

    useEffect(() => {
        if (!settings?.length) return;
        setValues(settings);
    }, [settings]);

    const handleChange = (item: IMatcherProperty, value: number | boolean, prop: 'isCutting' | 'multiplier' | 'weight') => {
        setValues((prevValues) => {
            if (!prevValues) {
                prevValues = [...settings];
            }
            const newValues = prevValues.map((prevValue) => {
                if (prevValue.property === item.property) {
                    return {
                        ...prevValue,
                        [prop]: value,
                    };
                } else return prevValue;
            });

            return newValues;
        });
    };

    return (
        <SimpleForm toolbar={<MatcherToolbar buttonLabel={buttonLabel} values={values || settings} onSubmit={onSubmit} />}>
            <Stack direction="row" spacing={10} sx={{width: '100%'}} alignItems="flex-start">
                <Table sx={{
                    '& .MuiTableCell-root': {
                        padding: '5px 16px',
                        '&:last-of-type': {
                            width: '140px'
                        }
                    },
                    maxWidth: '600px'
                }}>
                    <TableHead>
                        <TableRow sx={{'th': {color: 'rgba(44,56,74,.38)'}}}>
                            <TableCell>Property</TableCell>
                            <TableCell>Cutting</TableCell>
                            <TableCell>Scoring (weight)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {values.map((prop, index) => {
                                return prop.hasOwnProperty('isCutting') ?
                                    <CuttingRow
                                        item={prop}
                                        key={index}
                                        onChange={handleChange}
                                    />
                                    : null;
                            })}
                        </>
                    </TableBody>
                </Table>

                <Table sx={{
                    marginTop: '50px',
                    '& .MuiTableCell-root': {
                        padding: '5px 16px',
                        '&:last-of-type': {
                            width: '150px'
                        }
                    },
                    maxWidth: '500px'
                }}>
                    <TableHead>
                        <TableRow sx={{'th': {color: 'rgba(44,56,74,.38)'}}}>
                            <TableCell>Property</TableCell>
                            <TableCell>Multiplier</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {values.map((prop, index) => {
                                return prop.hasOwnProperty('multiplier') ?
                                    <MultiplierRow
                                        item={prop}
                                        key={index}
                                        onChange={handleChange}
                                    />
                                    : null;
                            })}
                        </>
                    </TableBody>
                </Table>
            </Stack>
        </SimpleForm>
    );
};

interface PropsMatcherToolbar {
    buttonLabel: string;
    values: IMatcherProperty[];
    onSubmit: (settings: IMatcherConfig[]) => void;
}

const MatcherToolbar = ({buttonLabel, values, onSubmit}: PropsMatcherToolbar) => {
    const handleClick = async () => {
        const payload = values.map(s => {
            const property: IMatcherConfig = {
                property: s.property,
            };

            if (s.types.includes(EMatcherType.Cutting)) {
                property.isCutting = s.isCutting;
            }

            if (s.types.includes(EMatcherType.Multiplying)) {
                property.multiplier = s.multiplier;
            }

            if (s.types.includes(EMatcherType.Scoring)) {
                property.weight = s.weight;
            }

            return property;
        });

        onSubmit(payload);
    };

    return (
        <Toolbar>
            <Button
                variant="contained"
                onClick={handleClick}
            >
                {buttonLabel}
            </Button>
        </Toolbar>
    );
};
