import React, {useEffect, useMemo} from 'react';
import {AutocompleteArrayInput, CheckboxGroupInput, SelectInput, TextInput, useListContext} from 'react-admin';
import {FormProvider, useForm} from 'react-hook-form';

import {Button, Grid, Stack} from '@mui/material';

import {SearchFiltersBottom} from '../../../../components';
import {NO_FILTER} from '../../../../constants';
import {useAsyncSearch, useMeta} from '../../../../hooks';
import {EWorkType, EWorkTypeDisplay, EWorkWeekStatus, EWorkWeekStatusDisplay, IChannel, ISelectOption, IVertical} from '../../../../models';
import {styledAutocompleteInline, styledCheckbox, styledInputInline, styledPrimaryButton, styledSelectInline} from '../../../../styles';
import {TIMEZONES_OPTIONS} from '../../../../utils/timezones';

import {ClearFiltersButton} from './ClearFiltersButton';

const optionsStatus = [
    {id: EWorkWeekStatus.APPROVED, name: EWorkWeekStatusDisplay.APPROVED},
    {id: EWorkWeekStatus.DRAFT, name: EWorkWeekStatusDisplay.DRAFT},
    {id: EWorkWeekStatus.REJECTED, name: EWorkWeekStatusDisplay.REJECTED},
    {id: EWorkWeekStatus.REJECTED_DRAFT, name: 'Sending rejected'},
    {id: EWorkWeekStatus.SENT, name: EWorkWeekStatusDisplay.SENT},
];

const optionsRateRange = [
    {id: 4999, name: 'less $50'},
    {id: 10000, name: '$50 - $100'},
    {id: 15000, name: '$100 - $150'},
    {id: 20000, name: '$150 - $200'},
    {id: 25000, name: '$200+'},
];

const optionsYearsOfExperience = [
    {id: 5, name: 'from 3 to 5 years'},
    {id: 7, name: 'from 6 to 7 years'},
    {id: 10, name: 'from 8 to 10 years'},
    {id: 15, name: 'from 11 to 15 years'},
    {id: 16, name: '16+ years'},
];

export const filterDefaultValues = {
    brands: null,
    businessModels: null,
    channels: null,
    countries: null,
    isAvailableForNewProject: null,
    q: '',
    languages: null,
    rateRange: null,
    subchannels: null,
    timesheetStatus: null,
    timezones: null,
    tools: null,
    verticals: null,
    workTypes: null,
    yearsOfExperience: null,
};

type FilterFromValues = typeof filterDefaultValues;
type RequestParams = Omit<FilterFromValues, 'yearsOfExperience'|'rateRange'> & {
    fromRateRange?: number | null;
    toRateRange?: number | null;

    fromYearsOfExperience?: number | null;
    toYearsOfExperience?: number | null;
};

export const FilterForm = () => {
    const {displayedFilters, filterValues, setFilters} = useListContext();
    const {businessModels: businessModelsData, channels: channelsData, countries, languages, verticals: verticalsData} = useMeta();
    const {
        isFilterValid: isFilterTools,
        options: optionsTools,
        setFilter: setFilterTools,
        setSelected: setSelectedTools
    } = useAsyncSearch(filterValues.tools, 'meta/tools');
    const {
        isFilterValid: isFilterBrands,
        options: optionsBrands,
        setFilter: setFilterBrands,
        setSelected: setSelectedBrands
    } = useAsyncSearch(filterValues.brands, 'meta/brands');

    const defaultValues = Object.keys(filterValues).length ? filterValues : filterDefaultValues;

    const form = useForm({defaultValues});
    const {
        // formState: {isDirty},
        // getValues,
        setValue,
        watch
    } = form;

    const brandsIds = watch('brands');
    const channelIds = watch('channels');
    const subchannels = watch('subchannels');
    const toolsIds = watch('tools');

    const onSubmit = (values: FilterFromValues) => {
        const {rateRange, yearsOfExperience, ...others} = values;
        const newFilters: RequestParams = others;

        if (rateRange && rateRange !== 'ALL') {
            switch (rateRange) {
                case 4999:
                    newFilters.fromRateRange = 0;
                    newFilters.toRateRange = 4999;
                    break;
                case 10000:
                    newFilters.fromRateRange = 50 * 100;
                    newFilters.toRateRange = 100 * 100;
                    break;
                case 15000:
                    newFilters.fromRateRange = 100 * 100;
                    newFilters.toRateRange = 150 * 100;
                    break;
                case 20000:
                    newFilters.fromRateRange = 150 * 100;
                    newFilters.toRateRange = 200 * 100;
                    break;
                case 25000:
                    newFilters.fromRateRange = 20001;
                    newFilters.toRateRange = null;
                    break;
            }
        } else {
            newFilters.fromRateRange = null;
            newFilters.toRateRange = null;
        }

        if (yearsOfExperience && yearsOfExperience !== 'ALL') {
            switch (yearsOfExperience) {
                case 5:
                    newFilters.fromYearsOfExperience = 3;
                    newFilters.toYearsOfExperience = 5;
                    break;
                case 7:
                    newFilters.fromYearsOfExperience = 6;
                    newFilters.toYearsOfExperience = 7;
                    break;
                case 10:
                    newFilters.fromYearsOfExperience = 8;
                    newFilters.toYearsOfExperience = 10;
                    break;
                case 15:
                    newFilters.fromYearsOfExperience = 11;
                    newFilters.toYearsOfExperience = 15;
                    break;
                case 16:
                    newFilters.fromYearsOfExperience = 16;
                    newFilters.toYearsOfExperience = null;
                    break;
            }
        } else {
            newFilters.fromYearsOfExperience = null;
            newFilters.toYearsOfExperience = null;
        }

        if (Object.keys(newFilters).length > 0) {
            setFilters(newFilters, displayedFilters);
        }
    };

    const optionsBusinessModels = useMemo(() => {
        return businessModelsData
            ? businessModelsData.map((it: string) => ({name: it, id: it}))
            : undefined;
    }, [businessModelsData]);

    const optionsChannels = useMemo(() => channelsData ? channelsData.map((it: IChannel) => ({id: it.id, name: it.name})) : undefined, [channelsData]);
    const optionsCountries = useMemo(() => countries ? countries.map(it => ({id: it.name, name: it.name})) : undefined, [countries]);
    const optionsLanguages = useMemo(() => languages ? languages.map(it => ({id: it, name: it})) : undefined, [languages]);

    const optionsSubChannels = useMemo(() => {
        if (!channelIds || !channelsData) return;

        const result: ISelectOption[] = [];

        channelsData
            .filter((it) => channelIds.includes(it.id))
            .forEach(it => result.push(...it.subchannels.map(it => ({id: it.id, name: it.name}))));

        return result.length ? result.map((it: ISelectOption) => ({id: it.id, name: it.name})) : undefined;
    }, [channelIds, channelsData]);

    const optionsWorkTypes = useMemo(() => [
        {id: EWorkType.fixed, name: EWorkTypeDisplay[EWorkType.fixed]},
        {id: EWorkType.hourly, name: EWorkTypeDisplay[EWorkType.hourly]},
        {id: EWorkType.retainer, name: EWorkTypeDisplay[EWorkType.retainer]},
    ], []);

    const optionsVerticals = useMemo(() => verticalsData ? verticalsData.map((it: IVertical) => ({id: it.id, name: it.name})) : undefined, [verticalsData]);

    // erase subchannels by clean channel
    useEffect(() => {
        if (!channelIds?.length && subchannels?.length) {
            setValue('subchannels', null);
        }
    }, [channelIds, subchannels, setValue]);

    useEffect(() => {
        setSelectedTools(toolsIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toolsIds]);

    useEffect(() => {
        setSelectedBrands(brandsIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandsIds]);

    useEffect(() => {
        if (filterValues.fromRateRange !== undefined) {
            setValue('rateRange', filterValues.toRateRange ? filterValues.toRateRange : 25000);
        }

        if (filterValues.fromYearsOfExperience !== undefined) {
            setValue('yearsOfExperience', filterValues.toYearsOfExperience ? filterValues.toYearsOfExperience : 16);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FormProvider {...form}>
            <form style={{width: '100%'}} onSubmit={form.handleSubmit(onSubmit)}>
                <Grid container spacing={2} alignItems="flex-end">
                    <Grid item xs={3}>
                        <TextInput
                            fullWidth
                            helperText={false}
                            label="Search"
                            resettable
                            source="q"
                            sx={{...styledInputInline}}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <SelectInput
                            choices={optionsStatus}
                            emptyText="All"
                            emptyValue="ALL"
                            fullWidth
                            helperText={false}
                            label="Timecards status"
                            source="timesheetStatus"
                            sx={styledSelectInline}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <SelectInput
                            choices={optionsYearsOfExperience}
                            emptyText="All"
                            emptyValue="ALL"
                            fullWidth
                            helperText={false}
                            label="Years of experience"
                            source="yearsOfExperience"
                            sx={styledSelectInline}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <SelectInput
                            choices={optionsRateRange}
                            emptyText="All"
                            emptyValue="ALL"
                            fullWidth
                            helperText={false}
                            label="By rate"
                            source="rateRange"
                            sx={styledSelectInline}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        {optionsChannels?.length && (   // fix GC-358
                            <AutocompleteArrayInput
                                choices={optionsChannels}
                                helperText={false}
                                fullWidth
                                key="channels"
                                limitTags={2}
                                source="channels"
                                sx={{...styledAutocompleteInline}}
                            />
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        {optionsChannels?.length && (
                            <AutocompleteArrayInput
                                choices={optionsSubChannels}
                                disabled={!optionsSubChannels?.length}
                                helperText={false}
                                fullWidth
                                label="Sub-channels"
                                limitTags={2}
                                key="subchannels"
                                source="subchannels"
                                sx={{...styledAutocompleteInline}}
                            />
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        {optionsVerticals && (
                            <AutocompleteArrayInput
                                choices={optionsVerticals}
                                helperText={false}
                                fullWidth
                                limitTags={2}
                                key="verticals"
                                source="verticals"
                                sx={{...styledAutocompleteInline}}
                            />
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        {optionsBusinessModels && (
                            <AutocompleteArrayInput
                                choices={optionsBusinessModels}
                                helperText={false}
                                fullWidth
                                label="Business model"
                                limitTags={2}
                                key="businessModels"
                                source="businessModels"
                                sx={{...styledAutocompleteInline}}
                            />
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        <AutocompleteArrayInput
                            choices={optionsTools}
                            helperText={false}
                            fullWidth
                            label="Tools"
                            limitTags={2}
                            key="tools"
                            noOptionsText={isFilterTools ? undefined : NO_FILTER}
                            setFilter={setFilterTools}
                            source="tools"
                            sx={{...styledAutocompleteInline}}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <AutocompleteArrayInput
                            choices={optionsBrands}
                            helperText={false}
                            fullWidth
                            label="Brands"
                            limitTags={2}
                            key="brands"
                            noOptionsText={isFilterBrands ? undefined : NO_FILTER}
                            setFilter={setFilterBrands}
                            source="brands"
                            sx={{...styledAutocompleteInline}}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <AutocompleteArrayInput
                            choices={optionsWorkTypes}
                            helperText={false}
                            fullWidth
                            limitTags={2}
                            key="Work Types"
                            source="workTypes"
                            sx={{...styledAutocompleteInline}}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        {optionsLanguages?.length && (
                            <AutocompleteArrayInput
                                choices={optionsLanguages}
                                helperText={false}
                                fullWidth
                                key="languages"
                                limitTags={2}
                                source="languages"
                                sx={{...styledAutocompleteInline}}
                            />
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        {optionsCountries?.length && (
                            <AutocompleteArrayInput
                                choices={optionsCountries}
                                helperText={false}
                                fullWidth
                                key="countries"
                                limitTags={2}
                                source="countries"
                                sx={{...styledAutocompleteInline}}
                            />
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        <AutocompleteArrayInput
                            choices={TIMEZONES_OPTIONS}
                            helperText={false}
                            fullWidth
                            key="timezones"
                            limitTags={2}
                            source="timezones"
                            sx={{...styledAutocompleteInline}}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="flex-end" spacing={1}>
                            <CheckboxGroupInput
                                choices={[
                                    {id: 'true', name: 'Available for new projects'},
                                ]}
                                key="isAvailableForNewProject"
                                source="isAvailableForNewProject"
                                sx={{
                                    ...styledCheckbox,
                                    flexDirection: 'row',
                                    alignItems: 'flex-end'
                                }}
                            />
                            <ClearFiltersButton />
                            <Button
                                className="primary"
                                color="primary"
                                disableElevation
                                type="submit"
                                variant="contained"
                                sx={styledPrimaryButton}
                            >
                                Search
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>

                <SearchFiltersBottom></SearchFiltersBottom>

            </form>
        </FormProvider>
    );
};
