import React from 'react';

import {styled, Typography} from '@mui/material';

const FooterWrp = styled('footer')(
    ({theme}) => `
    padding: 16px;
    background-color: ${theme.palette.white.main};
    display: flex;
    align-items: center;
    flex-basis: 100%;
    `
);

const Footer = (): JSX.Element => {
    return (
        <FooterWrp>
            <Typography variant="body1">
                &copy; {new Date().getFullYear()} Growth Collective
            </Typography>
        </FooterWrp>
    );
};

export default Footer;
