import React from 'react';
import {CreateButton, SelectInput, TextInput, useListContext} from 'react-admin';
import {FormProvider, useForm} from 'react-hook-form';

import {Button, Grid, Stack} from '@mui/material';

import {SearchFiltersBottom} from '../../../components';
import {optionsModeration} from '../../../models';
import {styledInputInline, styledPrimaryButton, styledSelectInline} from '../../../styles';

import {ClearFiltersButton} from './ClearFiltersButton';

export const ListFilterForm = () => {
    const {displayedFilters, filterValues, setFilters} = useListContext();
    const form = useForm({defaultValues: filterValues});

    const onSubmit = (values: any) => {
        if (Object.keys(values).length > 0) {
            setFilters(values, displayedFilters);
        }
    };

    return (
        <FormProvider {...form}>
            <form style={{width: '100%'}} onSubmit={form.handleSubmit(onSubmit)}>
                <Grid container spacing={2} alignItems="flex-end">
                    <Grid item xs={2}>
                        <TextInput
                            fullWidth
                            helperText={false}
                            label="Search"
                            resettable
                            source="q"
                            sx={{...styledInputInline}}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <SelectInput
                            alwaysOn
                            choices={optionsModeration}
                            emptyText="All"
                            emptyValue="ALL"
                            fullWidth
                            helperText={false}
                            key="status"
                            source="moderationStatus"
                            sx={{...styledSelectInline}}
                        />
                    </Grid>

                    <Grid item xs sx={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end'}}>
                        <Stack direction="row" spacing={1}>
                            <ClearFiltersButton />
                            <Button
                                className="primary"
                                color="primary"
                                disableElevation
                                type="submit"
                                variant="contained"
                                sx={styledPrimaryButton}
                            >
                                Search
                            </Button>
                        </Stack>
                    </Grid>

                </Grid>

                <SearchFiltersBottom>
                    <CreateButton/>
                </SearchFiltersBottom>

            </form>
        </FormProvider>
    );
};

export const defaultFiltersValues = {
    q: '',
    moderationStatus: 'ALL',
};
