import React, {useMemo} from 'react';
import {motion} from 'framer-motion';

import {Box, IconButton, Menu, Stack, styled, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {SxProps} from '@mui/system';

import {ICaseStudy, ICoverImage} from '../../../models';
import theme from '../../../theme';

import CaseStudyAnimatedGadient from './CaseStudyAnimatedGadient';
import ImagePlaceholder from './ImagePlaceholder';
import MoreVerticalIcon from './MoreVerticalIcon';

const SM = `${theme.breakpoints.values.sm}px`;

const ItemWrapper = styled('div')`
  width: 100%;
  background-color: ${theme.palette.white.main};
  border: 1px solid ${theme.palette.lightGray.main};
  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.03);
  border-radius: 16px;
  overflow: hidden;

  @media (min-width: ${SM}) {
    width: 100%;
    max-width: 366px;
  }
`;

interface ICaseStudyItem {
    data: ICaseStudy;
    isDeletable?: boolean;
    isDraft?: boolean;
    isMoreBtn: boolean;
    sx?: SxProps<Theme>;
    onDelete?: () => void;
}

const CaseStudyItem: React.FC<ICaseStudyItem> = React.forwardRef(({
    data,
    isDeletable,
    isDraft,
    isMoreBtn,
    sx,
    onDelete
}, ref?: React.Ref<HTMLDivElement>) => {
    const [moreBtnAnchorEl, setMoreBtnAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMoreBtnAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setMoreBtnAnchorEl(null);
    };

    // const handleViewClick = useCallback(() => {
    //     if (location.pathname.startsWith(RouteClientPortal.default)) {
    //         navigate(`${RouteClientPortal.publicFreelancerProfile}/${matchId}/${CASE_STUDIES}/${data.id}`); // FIXME - not working for now BE don't send ID in the response
    //     } else {
    //         navigate(`${RouteFreelancerPortal.caseStudy}/${data.id}${data.isDraft ? '?draft=true' : ''}`);
    //     }
    // }, [data, location.pathname, matchId, navigate]);

    const coverImageData = useMemo(() => {
        if (!data?.coverImage) {
            return '';
        }

        const json: ICoverImage = JSON.parse(data.coverImage);

        return {
            theme: json.name,
            url: getCoverImagePath(json.name, json.variant || 1)
        };
    }, [data]);

    return (
        <>
            <ItemWrapper ref={ref} sx={sx}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                        aspectRatio: '367 / 200',
                        background: coverImageData ? `url(${coverImageData.url})` : '',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                    }}
                >

                    {isMoreBtn && (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '18px',
                                right: '19px',
                                zIndex: 10,
                            }}
                        >
                            <IconButton
                                sx={{
                                    mt: '-8px',
                                    mr: '-8px',
                                    mb: '-8px',
                                    'path': {
                                        fill: 'white'
                                    }
                                }}
                                onClick={handleMenuOpen}
                            >
                                <MoreVerticalIcon/>
                            </IconButton>
                            <Menu
                                open={Boolean(moreBtnAnchorEl)}
                                anchorEl={moreBtnAnchorEl}
                                onClose={handleMenuClose}
                            >
                                {/* <MenuItem
                                    sx={styleMenuItem}
                                    onClick={() => navigate(`${RouteFreelancerPortal.caseStudy}/edit/${data.id}`)}
                                >
                                    <EditIcon/> Edit
                                </MenuItem> */}

                                {/* {isDeletable && (
                                    <MenuItem
                                        disabled={!isDeletable}
                                        sx={styleMenuItem}
                                        onClick={handleOpenDeleteModal}
                                    >
                                        <DeleteIcon/> Delete
                                    </MenuItem>
                                )} */}

                            </Menu>
                        </Box>
                    )}

                    <Box
                        sx={{
                            position: 'relative',
                            zIndex: 5,
                            padding: '46px 24px',
                            textAlign: 'center',
                            color: 'white',
                        }}
                    >

                        {data.outcomes?.length ? (
                            <>
                                <Typography
                                    sx={{
                                        mb: '8px',
                                        fontWeight: 600,
                                        fontSize: '45px',
                                        lineHeight: 1.3,
                                        letterSpacing: '-0.02em',
                                        '&.MuiTypography-root': {
                                            color:'white !important'
                                        }
                                    }}
                                >
                                    {data.outcomes[0].value}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        lineHeight: 1.3,
                                        '&.MuiTypography-root': {
                                            color:'white !important'
                                        }
                                    }}
                                >
                                    {data.outcomes[0].description}
                                </Typography>
                            </>
                        ) : null}

                    </Box>

                    <CaseStudyAnimatedGadient themeName={coverImageData ? coverImageData.theme : ''}/>

                </Box>

                <Box
                    sx={{
                        p: '40px 24px 24px',
                        position: 'relative'
                    }}
                >
                    <Box
                        sx={{
                            // width: '52px',
                            // height: '52px',
                            border: '2px solid white',
                            borderRadius: '50%',
                            position: 'absolute',
                            top: '-24px',
                            left: '24px'
                        }}
                    >
                        <ImagePlaceholder width="48" height="48">
                            {data?.companyLogoUrl &&
                              <img src={data.companyLogoUrl} width="48" height="48" alt={data.companyName}/>
                            }
                        </ImagePlaceholder>
                    </Box>

                    <Typography
                        sx={{
                            mb: '8px',
                            fontWeight: 600,
                            fontSize: '16px',
                            lineHeight: 1.5,
                        }}
                    >
                        {data.name}
                    </Typography>

                    <Typography
                        sx={{
                            mb: '10px',
                            fontWeight: 400,
                            fontSize: '13px',
                            lineHeight: 1.5,
                            color: '#999999'
                        }}
                    >
                        {data.brief}
                    </Typography>

                    <Stack direction="row" justifyContent="space-between">

                        {/* <BlackBorderButton
                            size="small"
                            onClick={handleViewClick}
                        >
                            View
                        </BlackBorderButton>

                        {isDraft && (
                            <Pill
                                icon={<EditIcon/>}
                                iconPosition="start"
                                variant="gray"
                            >Draft</Pill>
                        )} */}

                    </Stack>

                </Box>

            </ItemWrapper>
        </>
    );
});

export const getCoverImagePath = (themeName: string, variant: number, breakpoints?: {lgUp: boolean; mdUp: boolean; xsUp: boolean}) => {
    const base = `${process.env.REACT_APP_S3_PUBLIC_BUCKET_URL}/case_study_covers/${themeName}/${variant}`;

    // if it is preview
    if (!breakpoints) {
        return `${base}/preview_img.png`;
    } else if (breakpoints.lgUp) {
        return `${base}/desktop_img.png`;
    } else if (breakpoints.mdUp) {
        return `${base}/tablet_img.png`;
    } else if (breakpoints.xsUp) {
        return `${base}/mobile_img.png`;
    }

    return `${base}/desktop.png`;
};

export default React.memo(motion(CaseStudyItem));
