import React, {ReactNode} from 'react';

type ColumnLabeledProps = {
    children: ReactNode;
    label?: string;
    sortable?: boolean;
    source: string;
};

const ColumnLabeled: React.FC<ColumnLabeledProps> = ({children}) => {
    return (
        <>
            {children}
        </>
    );
};

export default ColumnLabeled;
