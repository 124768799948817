import React, {memo, useCallback, useEffect, useState} from 'react';
import {Button, useDataProvider, useEditContext, useListContext, useNotify, useRecordContext, useShowContext} from 'react-admin';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ReplayIcon from '@mui/icons-material/Replay';
import SaveIcon from '@mui/icons-material/Save';
import {Box, Divider, Grid, SelectChangeEvent, Stack} from '@mui/material';

import {Select} from '../../../../components';
import {EPaymentMethod, EPaymentMethodStatus, EPaymentStatus, EPaymentStatusDisplay, ERole, IPayment, IPaymentPreview} from '../../../../models';
import {styledButton, styledSelectInline} from '../../../../styles';
import theme from '../../../../theme';
import {hasPermissions} from '../../../Auth/keycloak';
import {PaymentStatus, PayoutStatus, TransactionRow} from '../index';

const optionsStatus = [
    {value: EPaymentStatus.FAILED, text: EPaymentStatusDisplay.FAILED},
    {value: EPaymentStatus.NEW, text: EPaymentStatusDisplay.PENDING},
    {value: EPaymentStatus.REFUNDED, text: EPaymentStatusDisplay.REFUNDED},
    {value: EPaymentStatus.SUCCESS, text: EPaymentStatusDisplay.SUCCESS},
] as const;


export const TransactionPanel = memo(() => {
    // const {hasPermissions} = useAdminGroups();
    const record = useRecordContext<IPayment>();
    const editContext = useEditContext();
    const showContext = useShowContext();
    const {refetch} = useListContext();
    const [isLoading, setIsLoading] = useState(false);
    const [invoiceStatus, setInvoiceStatus] = useState<EPaymentStatus | null>(null);
    const [paymentMethods, setPaymentMethods] = useState<IPaymentPreview[] | null>(null);
    const [paymentMethodId, setPaymentMethodId] = useState('');

    // const paymentMethodInvoice = paymentMethods?.find((it: IPaymentPreview) => it.method === EPaymentMethod.ManualInvoicing);

    const dataProvider = useDataProvider();
    const notify = useNotify();

    const getClientsPaymentMethods = useCallback(async (clientId: number) => {
        try {
            const response = await dataProvider.get('clients/' + clientId, {area: 'admin'});
            const paymentMethods = response.data.paymentMethods.filter((it: IPaymentPreview) => it.status === EPaymentMethodStatus.VERIFIED);

            setPaymentMethods(paymentMethods.length ? paymentMethods : null);
        } catch (error) {

        }
    }, [dataProvider]);

    const handleInvoiceStatusChange = (event: SelectChangeEvent<unknown>) => {
        setInvoiceStatus(event.target.value as EPaymentStatus);
    };

    const handleInvoiceSaveClick = useCallback(async () => {
        if (!invoiceStatus) {
            notify('New status required', {type: 'warning'});

            return;
        }

        setIsLoading(true);

        try {
            const data = {status: invoiceStatus};

            await dataProvider.put(`admin/payments/${record.id}`, {data});
            await dataProvider.put(`admin/payments/${record.id}/payout`, {data});

            refetch();
            notify('Status: Success');
        } catch (error: any) {
            notify(`Error: ${error.message}`, {type: 'warning'});
        }

        setIsLoading(false);
    }, [invoiceStatus, record?.id, dataProvider, notify, refetch]);

    const handlePaymentMethodChange = (event: SelectChangeEvent<unknown>) => {
        setPaymentMethodId(event.target.value as string);
    };

    const handleRetryPaymentClick = useCallback(async () => {
        if (!paymentMethods) return;

        setIsLoading(true);

        try {
            const id = paymentMethodId || paymentMethods[0].id;
            const data = {methodId: id};
            const response = await dataProvider.post(`admin/payments/${record.id}`, {data});

            refetch();
            notify('Status: ' + response.json.payment.status);
        } catch (error: any) {
            notify(`Error: ${error.message}`, {type: 'warning'});
        }

        setIsLoading(false);
    }, [record?.id, dataProvider, paymentMethodId, paymentMethods, notify, refetch]);

    const handleRetryPayoutClick = useCallback(async () => {
        setIsLoading(true);

        try {
            const response = await dataProvider.post(`admin/payments/${record.id}/payout`);

            refetch();
            notify('Status: ' + response.json.status);
        } catch (error: any) {
            notify(`Error: ${error.message}`, {type: 'warning'});
        }

        setIsLoading(false);
    }, [record?.id, dataProvider, notify, refetch]);

    useEffect(() => {
        if (!editContext) return;

        // At the Client Details page we already have payment methods
        if (editContext.record) {
            const paymentMethods = editContext.record.paymentMethods.filter((it: IPaymentPreview) => it.status === EPaymentMethodStatus.VERIFIED);

            setPaymentMethods(paymentMethods.length ? paymentMethods : null);

        // at the Engagement details page we should get client by id
        } else if (showContext.record) {
            getClientsPaymentMethods(showContext.record.clientId);
        }
    }, [editContext, showContext, record.payment.status, getClientsPaymentMethods]);

    useEffect(() => {
        setInvoiceStatus(record.payout.status);
    }, [record.payout.status]);

    if (!record) {
        return null;
    }

    return (
        <Box
            sx={{
                px: 2,
                py: 3,
                background: theme.palette.grey[100],
                '& .MuiFormLabel-root': {
                    marginBottom: '0 !important'
                }
            }}
        >
            <p>{record.description}</p>
            <Grid container spacing={2}>
                <TransactionRow
                    amount={record.payment?.amount}
                    isClient={true}
                    fee={record.payment?.serviceFee}
                    name={record.roleName}
                    payment={<>
                        **** {record.payment?.method?.last4} {record.payment?.method?.method === EPaymentMethod.ManualInvoicing
                            ? record.payment?.method?.name
                            : record.payment?.method?.method}
                    </>
                    }
                    problem={record.payment?.status === EPaymentStatus.FAILED ? record.payment?.problem : ''}
                    redirect={`/roles/${record.roleId}/show`}
                    status={<PaymentStatus data={record.payment} isTooltip={false}/>}
                />

                <Grid item xs={12}>
                    <Divider/>
                </Grid>

                <TransactionRow
                    amount={record.payout?.amount}
                    isClient={false}
                    fee={record.payout?.gcFee}
                    name={record.freelancerName}
                    payment="n/a"
                    problem={[EPaymentStatus.FAILED, EPaymentStatus.PENDING].includes(record.payout.status) ? record.payout.problem : ''}
                    redirect={`/freelancers/${record.freelancerId}/show`}
                    status={<PayoutStatus data={record.payout} isTooltip={false}/>}
                />

                <Grid item xs={12}>
                    <Stack direction="row" spacing={1}>

                        {record.payment?.method?.method !== EPaymentMethod.ManualInvoicing && (
                            <a
                                download={record.invoiceName + '.pdf'}
                                href={record.invoiceFile}
                            >
                                <Button
                                    disabled={isLoading || !record?.invoiceFile}
                                    label="Get invoice"
                                    startIcon={<FileDownloadIcon />}
                                    sx={styledButton}
                                    variant="outlined"
                                />
                            </a>
                        )}

                        {/* If this Client Pay by Invoice - admin can change status manualy */}
                        {/* If - no, then show retry buttons */}
                        {hasPermissions([ERole.SuperAdmin]) && (record.manualInvoicing || record.payment?.method?.method === EPaymentMethod.ManualInvoicing) ? (
                            <Stack direction="row" spacing={1}>
                                <Select
                                    disabled={isLoading}
                                    id={'payment-status-selector'}
                                    helperText=""
                                    variant="standard"
                                    listItems={optionsStatus}
                                    sx={styledSelectInline}
                                    value={invoiceStatus || ''}
                                    onChange={handleInvoiceStatusChange}
                                >
                                </Select>

                                <Button
                                    disabled={isLoading}
                                    label="Save pay by invoice Status"
                                    startIcon={<SaveIcon/>}
                                    sx={{
                                        ...styledButton,
                                        width: 250
                                    }}
                                    variant="outlined"
                                    onClick={handleInvoiceSaveClick}
                                />
                            </Stack>
                        ) : (
                            <>
                                {record.payment.status === EPaymentStatus.FAILED && paymentMethods?.length && (
                                    <Stack direction="row" spacing={1}>
                                        <Select
                                            disabled={isLoading}
                                            id={'payment-methods-selector'}
                                            fullWidth
                                            helperText=""
                                            variant="standard"
                                            listItems={paymentMethods.map(it => ({
                                                text: `${it.name} **${it.last4}`,
                                                value: it.id.toString()
                                            }))}
                                            sx={styledSelectInline}
                                            value={paymentMethodId || paymentMethods[0].id}
                                            onChange={handlePaymentMethodChange}
                                        >
                                        </Select>
                                        <Button
                                            disabled={isLoading}
                                            label="Retry payment"
                                            startIcon={<ReplayIcon/>}
                                            sx={{
                                                ...styledButton,
                                                width: 230
                                            }}
                                            variant="outlined"
                                            onClick={handleRetryPaymentClick}
                                        />
                                    </Stack>
                                )}

                                {record.payout.status === EPaymentStatus.FAILED && (
                                    <Button
                                        disabled={isLoading}
                                        label="Retry payout"
                                        startIcon={<ReplayIcon/>}
                                        sx={styledButton}
                                        variant="outlined"
                                        onClick={handleRetryPayoutClick}
                                    />
                                )}
                            </>
                        )}
                    </Stack>

                </Grid>

            </Grid>
        </Box>
    );
});
