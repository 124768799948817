import React, {Fragment, useState} from 'react';
import {RaRecord, useDataProvider, useEditContext, useNotify, useRecordContext, useShowContext} from 'react-admin';

import {Badge, Grid, SelectChangeEvent, Typography} from '@mui/material';

import {EModerationStatus, IApplication, ITool} from '../../../models';
import {getByPath} from '../../../utils/record.helper';
import {Button} from '../..';
import Dialog from '../../Dialog/Dialog';
import Select from '../../Select';

interface SimpleValue {
    id: number;
    name : string;
}

interface Props {
    isEditable: boolean;
    field?: SimpleValue[];
    recordLocal?: RaRecord;
    source: string;
}

export const ModerationListField = React.memo((props: Props) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const {refetch: refetchEdit} = useEditContext();
    const {refetch: refetchShow} = useShowContext();
    const record = useRecordContext<IApplication>();
    const [newItems, setNewItems] = useState<ITool[] | null>(null);

    if (!record) return null;

    const field = props.field || getByPath(props.recordLocal || record, props.source);

    const handleChange = (item: ITool) => (event: SelectChangeEvent<unknown>, value: React.ReactNode) => {
        item.moderationStatus = event.target.value as EModerationStatus;

        setNewItems([...newItems as ITool[]]);
    };

    const handleClose = () => setNewItems(null);

    const handleOpen = () => {
        const newItems = field?.filter((it: ITool) => it.moderationStatus === EModerationStatus.NEW)
            .map((it: ITool) => ({...it}));

        if (newItems.length) {
            setNewItems(newItems);
        }
    };

    const handleSave = async () => {
        if (!newItems || !field) return;

        const payload: ITool[] = [];

        // find dirty items
        newItems.forEach(newItem => {
            const dirty = field.find((prevItem: ITool) => prevItem.id === newItem.id && prevItem.moderationStatus !== newItem.moderationStatus);

            if (dirty) payload.push(newItem);
        });

        let arr;

        // /brands endpoint waiting for multiform-data format
        // all others works with application/json
        if (props.source === 'brands') {
            arr = payload.map(it => dataProvider.putFormData(`admin/meta/brands/${it.id}`, {data: it}));
        } else {
            arr = payload.map(it => dataProvider.put(`admin/meta/${props.source}/${it.id}`, {data: it}));
        }

        if (!arr) return;

        await Promise.all(arr);
        notify('Successfully updated');
        setNewItems(null);

        // this component can be used inside /show or /edit pages
        if (refetchEdit) refetchEdit();
        if (refetchShow) refetchShow();
    };

    const list = field?.map(
        (item: ITool, index: number) => {
            const content = index < field.length - 1 ? `${item.name}, ` : item.name;

            switch (item.moderationStatus) {
                case EModerationStatus.NEW:
                    const helperText = 'Admin needs to review new value: ' + item.name;

                    return (
                        <Badge
                            color="error"
                            invisible={false}
                            key={item.id}
                            title={helperText}
                            sx={{marginRight: '20px'}}
                            variant="dot"
                        >
                            <Typography
                                color="error"
                                component="span"
                                sx={{cursor: props.isEditable ? 'pointer' : 'default'}}
                                title={helperText}
                                onClick={props.isEditable ? handleOpen : undefined}
                            >
                                {content}
                            </Typography>
                        </Badge>
                    );
                case EModerationStatus.REJECTED:
                    return (
                        <Typography
                            component="span"
                            key={item.id}
                            sx={{color: 'grey', textDecoration: 'line-through solid grey'}}
                            title="New value is rejected"
                        >
                            {content}
                        </Typography>
                    );
                default:
                    return (
                        <Typography component="span" key={item.id}>
                            {content}
                        </Typography>
                    );
            }
        }
    );

    if (!list?.length) return <span>/</span>;

    return (
        <>
            {list}
            <Dialog
                actions={(
                    <>
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={handleSave}
                        >
                            Save
                        </Button>
                    </>
                )}
                aria-describedby="moderate new Tools and Brands"
                maxWidth="sm"
                open={!!newItems}
                // subtitle="Review new items"
                title={`Review new ${props.source.toUpperCase()} items`}
                onClose={handleClose}
            >
                <Grid container alignItems="center" sx={{width: 400}}>
                    {newItems?.map(item => (
                        <Fragment key={item.id}>
                            <Grid item xs={6}>
                                <Typography align="right" sx={{pr: 2}} variant="body1">{item.name}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Select
                                    id={`status-selector-${item.id}`}
                                    fullWidth
                                    listItems={[
                                        {text: 'New', value: EModerationStatus.NEW},
                                        {text: 'Approved', value: EModerationStatus.APPROVED},
                                        {text: 'Rejected', value: EModerationStatus.REJECTED},
                                    ]}
                                    value={item.moderationStatus}
                                    onChange={handleChange(item)}
                                >
                                </Select>
                            </Grid>
                        </Fragment>
                    ))}
                </Grid>
            </Dialog>
        </>
    );
});
