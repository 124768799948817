import React, {ReactNode} from 'react';

import {Typography} from '@mui/material';

type SectionTitleProps = {
    children: ReactNode;
};

export const SectionTitle: React.FC<SectionTitleProps> = React.memo(({children}) => {
    return (
        <Typography sx={{fontSize: 16, fontWeight: 500}} variant="body1">
            {children}
        </Typography>
    );
});
