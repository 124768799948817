import React from 'react';

import {Box} from '@mui/material';

const COVER_IMAGES = [{
    name: 'black',
    value: '#000000',
}, {
    name: 'white',
    value: '#f5f5f5',
}, {
    name: 'red',
    value: '#F9554B',
}, {
    name: 'orange',
    value: '#E99838',
}, {
    name: 'yellow',
    value: '#F3D539',
}, {
    name: 'green',
    value: '#7AC61A',
}, {
    name: 'blue',
    value: '#3894E9',
}, {
    name: 'purple',
    value: '#AB69ED',
}, {
    name: 'pink',
    value: '#FFBAEC',
}];

const getColorByName = (name?: string) => COVER_IMAGES.find(it => it.name === name)?.value;

type CaseStudyAnimatedGadientProps = {
    borderRadius?: string;
    themeName: string;
};

const CaseStudyAnimatedGadient: React.FC<CaseStudyAnimatedGadientProps> = ({borderRadius, themeName}) => {
    return (
        <Box
            sx={{
                position: 'absolute',
                background: themeName
                    ? `linear-gradient(45deg, ${getColorByName(themeName)}, rgba(255,255,255,0), rgba(255,255,255,0))`
                    : 'linear-gradient(45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)',
                backgroundSize: '200% 200%',
                animation: 'gradient 10s ease infinite',
                width: '100%',
                height: '100%',
                borderRadius: borderRadius || 0,
                '@keyframes gradient': {
                    '0%': {
                        backgroundPosition: '0% 50%',
                    },
                    '50%': {
                        backgroundPosition: '100% 50%',
                    },
                    '100%': {
                        backgroundPosition: '0% 50%',
                    },
                }
            }}
        >

        </Box>
    );
};

export default CaseStudyAnimatedGadient;
