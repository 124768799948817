import * as React from 'react';
import {Edit} from 'react-admin';

import {styledButton} from '../../styles';

import {NotificationForm} from './NotificationForm';
import {transform} from './transform.helper';

export const NotificationsRuleEdit = (props: any) => {
    return (
        <Edit
            {...props}
            redirect="list"
            transform={transform}
            sx={{
                '& button': styledButton,
                '& .ra-delete-button': {
                    border: '1px solid #cc1414',
                    '&:hover': {
                        backgroundColor: '#f25f5f',
                        borderColor: '#f14b7d',
                        color: '#000015'
                    }
                }
            }}
        >
            <NotificationForm />
        </Edit>
    );
};
