import React from 'react';
import {
    DateInput,
    Edit,
    email,
    maxLength,
    minLength,
    minValue,
    NumberInput,
    required,
    SimpleForm,
    Tab,
    TabbedShowLayout,
    TextInput,
    useRecordContext
} from 'react-admin';

import {Divider, Grid, Typography} from '@mui/material';

import {styledInput} from '../../styles';
import {PaymentsList} from '../Payments';

import EditFormToolbarCoreUI from './components/EditFormToolbarCoreUI';
import ClientNameField from './components/fields/ClientNameField';
import {NotificationSettings} from './components/NotificationSettings';

const ClientTitle = () => {
    const record = useRecordContext();

    return <span>Client {record ? ` [${record.companyName}]` : ''}</span>;
};


export const ClientEdit = () => {
    const transform = ({roles, ...other}: any) => ({
        ...other
    });

    return (
        <Edit title={<ClientTitle/>} transform={transform}>
            <TabbedShowLayout syncWithLocation={false}>
                <Tab label="summary">
                    <SimpleForm toolbar={<EditFormToolbarCoreUI/>}>
                        <Grid container spacing={2} sx={{maxWidth: '800px'}}>
                            <Grid item xs={12} md={3}>
                                <TextInput disabled fullWidth helperText={false} source="id" sx={styledInput}/>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DateInput disabled fullWidth helperText={false} source="created" sx={styledInput}/>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <NumberInput fullWidth helperText={false} label="GC Fee %" source="gcFeePercent" sx={styledInput} validate={[minValue(0),]}/>
                            </Grid>
                            <Grid item md={3}>
                                <ClientNameField/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput fullWidth helperText={false} source="companyName" sx={styledInput} validate={[required(), maxLength(100)]}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput fullWidth helperText={false} source="companyWebsite" sx={styledInput} validate={[required(), maxLength(100)]}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput fullWidth helperText={false} source="firstName" sx={styledInput} validate={[required(), maxLength(100)]}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput fullWidth helperText={false} source="lastName" sx={styledInput} validate={[required(), maxLength(100)]}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput fullWidth helperText={false} source="email" sx={styledInput} validate={[required(), email(), maxLength(100)]}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput fullWidth helperText={false} source="phone" sx={styledInput} validate={[required(), minLength(12), maxLength(100)]}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant='h6'>Billing Contact</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput fullWidth helperText={false} label="First name" source="billingContact.firstName" sx={styledInput}
                                    validate={[maxLength(100)]}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput fullWidth helperText={false} label="Last name" source="billingContact.lastName" sx={styledInput}
                                    validate={[maxLength(100)]}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput fullWidth helperText={false} label="Email" source="billingContact.email" sx={styledInput}
                                    validate={[email(), maxLength(100)]}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput fullWidth helperText={false} label="Phone" source="billingContact.phone" sx={styledInput}
                                    validate={[minLength(12), maxLength(100)]}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput fullWidth helperText={false} label="Position" source="billingContact.position" sx={styledInput}
                                    validate={[maxLength(100)]}/>
                            </Grid>

                        </Grid>
                    </SimpleForm>
                </Tab>
                <Tab label="Billing">
                    <Transactions/>
                </Tab>
                <Tab label="Notification Settings">
                    <NotificationSettings/>
                </Tab>
            </TabbedShowLayout>
        </Edit>
    );
};

const Transactions = () => {
    const record = useRecordContext();

    if (!record) {
        return null;
    }

    const roleIds = record.roles.length ? record.roles?.map((role: any) => role.id) : [-1];

    return (
        <PaymentsList isCompact={true} roleId={roleIds}/>
    );
};

export default ClientEdit;
