import {EMatchStatus} from './matching.model';

export interface IGlobalSetting {
    id: number;
    key: string;
    value: string;
}

export enum EMatcherType {
    Cutting = 'CUTTING',
    Multiplying = 'MULTIPLYING',
    Scoring = 'SCORING',
}

export interface IMatcherConfig {
    property: string;
    isCutting?: boolean;
    weight?: number;
    multiplier?: number;
}

export interface IMatcherProperty extends IMatcherConfig {
    types: EMatcherType[];
}

export interface IMatching {
    configurationId: number;
    created: string;
    id: number;
    notes: string;
    plannedShare: string;
    resultsCount: number;
    status: EMatchingResultStatus;
}

export interface IMatchingResult {
    freelancerId: number;
    freelancerName: string;
    freelancerEmail: string;
    id: number;
    isRemoved?: boolean;    // use for undobale deleting
    notes: string;
    position: number;
    profileId: number;
    recommendation: IRecommendation;
    rejectionReason: string;
    score: number;
    status: EMatchStatus;
}

export interface IRecommendation {
    id?: number;
    author: string;
    authorAvatar: string;
    authorPosition: string;
    header: string;
    text: string;
}

export const RECOMMENDATIONS_VARAIBLE = ['adminName', 'clientFirstName', 'freelancerFirstName'];

// IN_PROGRESS - potentially for async matching (?)
// INACTIVE  - the list generated, never activated
// ACTIVE - the structured, chosen list, never shared, can be modified.
// SHARED - the structured, chosen list shown to the client, can’t be modified
// PLANNED_SHARED - the structured, chosen list awaiting to be shown to the client, can’t be modified
// AFTER_REVIEW - the matching is after some rejections and is waiting to be shared again, with new matches
// FINISHED - proposal accepted

export enum EMatchingResultStatus {
    Active = 'ACTIVE',
    Finished = 'FINISHED',
    Inactive = 'INACTIVE',
    InProgress = 'IN_PROGRESS',
    PlannedShare = 'PLANNED_SHARED',
    Shared = 'SHARED',
    SharedAfterReview = 'AFTER_REVIEW',
}
