export enum EModerationStatus {
    APPROVED = 'APPROVED',
    NEW = 'NEW',
    REJECTED = 'REJECTED'
}

export const moderationStatuses = [
    {name: EModerationStatus.NEW},
    {name: EModerationStatus.APPROVED},
    {name: EModerationStatus.REJECTED},
];

export const optionsModeration = [
    {id: EModerationStatus.NEW, name: 'New'},
    {id: EModerationStatus.APPROVED, name: 'Approved'},
    {id: EModerationStatus.REJECTED, name: 'Rejected'},
];
