import React from 'react';

import {Container, styled} from '@mui/material';

import {AsideMenu, Footer, Header} from '../index';

const PageContent = styled('div')(
    () => `
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    `
);

const Main = styled('main')(
    () => `
    overflow-y: auto;
    height: 100vh;
    width: calc(100% - 256px);
    padding-top: 32px;
    padding-bottom: 32px;
    `
);

const InnerPageWrp = ({children}: any): JSX.Element => {
    const [asideCollapsed, setAsideCollapsed] = React.useState(false);

    const handleHeaderCollapse = () => {
        setAsideCollapsed(!asideCollapsed);
    };

    return (
        <>
            <Header onHeaderCollapse={handleHeaderCollapse} collapsed={asideCollapsed}/>
            <PageContent>
                <AsideMenu collapsed={asideCollapsed}/>
                <Main>
                    <Container>
                        {children}
                    </Container>
                </Main>
                <Footer/>
            </PageContent>
        </>
    );
};

export default InnerPageWrp;
