import React, {FocusEvent, KeyboardEvent, SyntheticEvent, useCallback, useEffect} from 'react';
import {Reorder, useDragControls} from 'framer-motion';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import FavoriteIcon from '@mui/icons-material/Favorite';
import {Accordion, AccordionDetails, AccordionSummary, Box, TextField, Typography} from '@mui/material';

import {Badge, Checkbox} from '../../../../components';
import {EMatchStatus, IMatchingResult} from '../../../../models';

import AntSwitch from './AntSwitch';
import {FreelancerDetails} from './FreelancerDetails';
import {getColorByMatchingStatus, getTitleByMatchStatus} from './list.helper';

const variants = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
    },
    exit: {
        opacity: 0,
    },
    transition: {
        ease: 'easeInOut',
    }
};

interface Props {
    isDragable: boolean;
    isIgnored?: boolean;
    isResult: boolean;
    isSelected?: boolean;
    item: IMatchingResult;
    onDragEnd: (item: IMatchingResult) => void;
    onPosition: (item: IMatchingResult, position: number) => void;
    onPropose?: (item: IMatchingResult) => void;
    onRefresh: () => void;
    onRemove: (item: IMatchingResult) => void;
    onSelect?: (item: IMatchingResult, selected: boolean) => void;
}

export const FreelancerItem: React.FC<Props> = React.memo(({
    isDragable, isIgnored, isResult, isSelected, item,
    onDragEnd, onPosition, onPropose, onRefresh, onRemove, onSelect
}) => {
    const dragControls = useDragControls();
    const [expanded, setExpanded] = React.useState(false);
    const [position, setPosition] = React.useState<number | null>(item.position);
    const [isProposed, setIsProposed] = React.useState<boolean>(item.status !== EMatchStatus.HIDDEN);

    // prevent to expand Accordion when user select all text in the input
    const handleExpandChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
        event.stopPropagation();
        const selection = window.getSelection();

        if (!selection?.toString()) {
            setExpanded(isExpanded);
        } else {
            selection.empty();
        }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            (e.target as HTMLElement).blur();
        }
    };

    const handlePositionChange = (e: FocusEvent<HTMLInputElement>) => {
        if (position) {
            onPosition(item, position);
        }
    };

    const handlePosition = (event: SyntheticEvent) => {
        const newValue = (event.target as HTMLInputElement).valueAsNumber || null;

        setPosition(newValue);
    };

    const handleRemove = useCallback(() => {
        onRemove(item);
    }, [item, onRemove]);

    const handleSelect = (event: SyntheticEvent) => {
        event.stopPropagation();
        event.preventDefault();

        if (onSelect) {
            onSelect(item, !isSelected);
        }
    };

    const handleStatusChange = useCallback((event: SyntheticEvent, item: IMatchingResult) => {
        event.stopPropagation();
        console.log('item', item, item.status);
        setIsProposed(!isProposed);
        if (onPropose) {
            onPropose(item);
        }
    }, [isProposed, onPropose]);

    useEffect(() => {
        setPosition(item.position);
    }, [item.position]);

    useEffect(() => {
        setIsProposed(item.status !== EMatchStatus.HIDDEN);
    }, [item.status]);

    return (
        <Reorder.Item
            {...variants}
            dragControls={dragControls}
            dragListener={false}
            key={item.freelancerId}
            value={item}
        >
            <Accordion
                expanded={expanded}
                sx={{
                    '& .MuiAccordionSummary-root': {paddingLeft: isDragable ? 0 : ''},
                    '& .MuiAccordionSummary-root .badge': {height: '19px'},
                    '& .MuiAccordionSummary-root.Mui-expanded': {minHeight: 'auto'},
                    '& .MuiAccordionSummary-content.Mui-expanded': {margin: '12px 0'},
                }}
                onChange={handleExpandChange}
            >
                <AccordionSummary
                    expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '14px'}} />}
                    aria-controls={'freelancer-panel-' + item.freelancerId}
                    id={(isResult ? 'result-' : '') + 'freelancer-id-' + item.freelancerId}
                    sx={{
                        '& .MuiAccordionSummary-content': {alignItems: 'center'},
                        '& .MuiAccordionSummary-expandIconWrapper': {
                            transform: 'rotate(180deg)',
                        },
                        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                            transform: 'rotate(90deg)',
                        },
                    }}
                    onPointerUp={() => onDragEnd(item)}
                >
                    {isDragable && (
                        <DragIndicatorIcon
                            sx={{cursor: 'grab', opacity: 0.3, marginRight: 1}}
                            onPointerDown={(event) => dragControls.start(event)}
                            onClick={(event) => event.stopPropagation()}
                        />
                    )}

                    {isResult ? (
                        <>
                            <AntSwitch
                                checked={isProposed}
                                onClick={(event) => handleStatusChange(event, item)}
                            />

                            <Typography sx={{opacity: 0.5, pt: '2px', px: 1}} variant="caption">
                                position
                            </Typography>

                            <TextField
                                sx={{
                                    pr: 1,
                                    mb: 0,
                                    '& input': {padding: '0 5px', width: 40}
                                }}
                                type="number"
                                value={position || ''}
                                onBlur={handlePositionChange}
                                onChange={handlePosition}
                                onClick={e => e.stopPropagation()}
                                onKeyDown={handleKeyDown}
                            />
                        </>
                    ) : (
                        <>
                            <Checkbox
                                checked={isSelected || false}
                                color="primary"
                                label=""
                                sx={{
                                    p: 0,
                                    pl: 1,
                                }}
                                onClick={handleSelect}
                            />
                            <Typography sx={{opacity: 0.5, paddingTop: '2px', paddingRight: 1}} variant="caption">
                                matching score
                            </Typography>
                            <Typography sx={{width: '60px', flexShrink: 0, fontWeight: 'bold', opacity: (item.score >= 0 && !isIgnored) ? 1 : 0.2}}>
                                {item.score >= 0 ? item.score : '- - -' }
                            </Typography>
                        </>
                    )}

                    <Typography sx={{flexGrow: 1, opacity: isIgnored ? 0.5 : 1}}>
                        {item.freelancerName}
                        <Typography component="span" sx={{ml: 2, opacity: 0.7}} variant="caption">{item.freelancerEmail}</Typography>
                    </Typography>

                    {isResult && (
                        <>
                            {[EMatchStatus.LIKED, EMatchStatus.LIKED_IN_TOUCH].includes(item.status) && (
                                <FavoriteIcon sx={{color: '#7E5FFF', mr: 1, verticalAlign: 'middle', width: 22}} />
                            )}
                            <Badge bgColor={getColorByMatchingStatus(item.status)}>
                                {getTitleByMatchStatus(item.status)}
                            </Badge>
                        </>

                    )}

                    {isIgnored && (
                        <Badge bgColor={getColorByMatchingStatus(EMatchStatus.HIDDEN)}>
                            Ignored
                        </Badge>
                    )}

                    <Box sx={{width: 15}}/>

                </AccordionSummary>
                <AccordionDetails>
                    <FreelancerDetails
                        expanded={expanded}
                        isResult={isResult}
                        item={item}
                        recommendation={item.recommendation}
                        rejectionReason=""
                        onRemove={handleRemove}
                        onRefresh={onRefresh}
                    />
                </AccordionDetails>
            </Accordion>
        </Reorder.Item>
    );
});
