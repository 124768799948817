import React from 'react';
import {RaRecord, useRecordContext} from 'react-admin';
import {useNavigate} from 'react-router-dom';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {Button} from '@mui/material';

import {styledIconButton} from '../../styles';

export const PLACEHOLDER_ID = '%ID%';

type ButtonRedirectProps = {
    path: string;
    recordLocal?: RaRecord;
    source: string;
};

const ButtonRedirect: React.FC<ButtonRedirectProps> = ({path, recordLocal, source}) => {
    const record = useRecordContext();

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(path.replace(PLACEHOLDER_ID, (recordLocal || record)[source]));
    };

    return (
        <Button sx={styledIconButton} onClick={handleClick}>
            <OpenInNewIcon />
        </Button>
    );
};

export default ButtonRedirect;
