import React, {useMemo} from 'react';

import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';

import {Select} from '../../../../components';
import {EAttachmentType, ICaseStudy, ICertificate, IReference, optionsDocTypes} from '../../../../models';
import {PageState} from '../../ApplicationEdit';

import CaseStudyForm from './CaseStudyForm';
import CertificateForm from './CertificateForm';
import ReferenceForm from './ReferenceForm';

interface Props {
    item: Partial<ICertificate | ICaseStudy | IReference> | null;
    open: boolean;
    dispatch: React.Dispatch<Partial<PageState>>;
    onClose: () => void;
}

export const DialogDocuments = ({item, open, dispatch, onClose}: Props) => {
    const maxWidth = useMemo(() => {
        if (!item) return 'xs';
        if (item?.type === EAttachmentType.reference) return 'md';
        if (item?.type === EAttachmentType.study) return 'md';

        return 'sm';
    }, [item]);

    const title = useMemo(() => {
        let type: string;

        switch (item?.type) {
            case EAttachmentType.study: {
                type = 'Case study';
                break;
            }
            case EAttachmentType.certificate: {
                type = 'Certificate';
                break;
            }
            case EAttachmentType.reference: {
                type = 'Reference';
                break;
            }
            default:
                type = 'Document';
        }

        return item?.id ? `${type}: [${item.name}]` : `Add ${type}`;
    }, [item]);

    return (
        <Dialog
            fullWidth
            maxWidth={maxWidth}
            open={open}
            onClose={onClose}
        >
            <DialogTitle>{title}</DialogTitle>

            {!item && (
                <>
                    <DialogContent>
                        <Select
                            fullWidth
                            variant="standard"
                            label="Document Type"
                            listItems={optionsDocTypes}
                            onChange={event => dispatch({selected: {type: event.target.value as EAttachmentType}, open: true})}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={onClose}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </>
            )}

            {item?.type === EAttachmentType.certificate && (
                <CertificateForm item={item} onClose={onClose} />
            )}

            {item?.type === EAttachmentType.reference && (
                <ReferenceForm item={item} onClose={onClose} />
            )}

            {item?.type === EAttachmentType.study && (
                <CaseStudyForm item={item} onClose={onClose} />
            )}

        </Dialog>
    );
};

export default React.memo(DialogDocuments);
