import React, {useCallback, useState} from 'react';
import {useRecordContext} from 'ra-core';

import {Box} from '@mui/material';

import {Accordion, AccordionDetails, AccordionSummaryInfo} from '../../../../../components';
import {IClientRole} from '../../../../../models';

import {FreelancerIgnoreList} from './FreelancerIgnoreList';


const UnwantedList: React.FC = () => {
    const record = useRecordContext<IClientRole>();
    const [open, setOpen] = useState<boolean>(false);

    const handleChange = useCallback((event: React.SyntheticEvent, isExpanded: boolean) => {
        setOpen(isExpanded);
    }, []);

    if (!record || !record.unwantedFreelancers.length) return null;

    return (
        <Box>
            <Accordion
                sx={{
                    borderTop: 'none'
                }}
                onChange={handleChange}
            >
                <AccordionSummaryInfo
                    id="unwanted-list"
                    isSubTitleStatic={true}
                    subtitle={`Freelancers (${record.unwantedFreelancers.length})`}
                    title="Ignore List"
                >
                </AccordionSummaryInfo>
                <AccordionDetails sx={{p: 0, background: '#ffffff'}}>
                    {open && <FreelancerIgnoreList items={record?.unwantedFreelancers}/>}
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default React.memo(UnwantedList);
