import React from 'react';
import {NumericFormat} from 'react-number-format';

import {Box} from '@mui/material';

const NumberInputCoreUI = (props: any) => (
    <Box sx={{
        '& .numeric-format': {
            fontSize: '16px',
            padding: '8.5px 14px',
            textAlign: 'right',
            borderRadius: '4px',
            height: '40px',
            border: 'solid 1px rgb(177 183 193)',
            maxWidth: '100px',
            '&:not([disabled]):hover': {
                borderColor: '#212121'
            },
            '&:focus': {
                border: '#a8a3f2 1px solid',
                outline: 'none',
                boxShadow: '0 0 0 0.25rem rgb(80 70 229 / 25%)',
            }
        }
    }}>
        <NumericFormat className="numeric-format" {...props} />
    </Box>
);

export default NumberInputCoreUI;