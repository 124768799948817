import {defaultTheme} from 'react-admin';

import {createTheme, responsiveFontSizes} from '@mui/material';

import palette from './styles/palette.module.scss';

import '@mui/material/styles/createPalette';

export const BASE_HEIGHT = '40px';

declare module '@mui/material/styles/createPalette' {
    interface Palette {
        labelColor: PaletteColor;
        secondaryDisabledColor: PaletteColor;
        inputDisabledBorderColor: PaletteColor;
        inputBorderColor: PaletteColor;
        white: PaletteColor;
        lightGray: PaletteColor;
        green: PaletteColor;
        purple: PaletteColor;
        lilac: PaletteColor;
        blue: PaletteColor;
        lightRed: PaletteColor;
        darkGreen: PaletteColor;
    }
    interface PaletteOptions {
        labelColor: PaletteColorOptions;
        secondaryDisabledColor: PaletteColorOptions;
        inputDisabledBorderColor: PaletteColorOptions;
        inputBorderColor: PaletteColorOptions;
        white: PaletteColorOptions;
        lightGray: PaletteColor;
        green: PaletteColor;
        purple: PaletteColor;
        lilac: PaletteColor;
        blue: PaletteColor;
        lightRed: PaletteColor;
        darkGreen: PaletteColor;
    }
}

const baseTheme = createTheme();
const theme = createTheme({
    ...defaultTheme,
    palette: {
        primary: {
            main: palette.primary
        },
        secondary: {
            main: palette.secondary
        },
        success: {
            main: palette.success
        },
        error: {
            main: palette.error
        },
        info: {
            main: palette.info
        },
        warning: {
            main: palette.warning
        },
        green: baseTheme.palette.augmentColor({
            color: {
                dark: palette.green,
                main: palette.green30,
                light: palette.green10
            },
            name: 'active-green'
        }),
        purple: baseTheme.palette.augmentColor({
            color: {
                main: palette.purple,
                light: palette.purple30
            },
            name: 'purple'
        }),
        lightGray: baseTheme.palette.augmentColor({
            color: {
                main: palette.lightGrey,
                light: palette.lightGrey10
            },
            name: 'lightGray'
        }),
        lilac: baseTheme.palette.augmentColor({
            color: {
                main: palette.purple10
            },
            name: 'lilac'
        }),
        blue: baseTheme.palette.augmentColor({
            color: {
                dark: palette.blue,
                main: palette.blue30,
                light: palette.blue10
            },
            name: 'blue'
        }),
        lightRed: baseTheme.palette.augmentColor({
            color: {
                main: palette.error10
            },
            name: 'lightRed'
        }),
        darkGreen: baseTheme.palette.augmentColor({
            color: {
                main: palette.darkGreen
            },
            name: 'darkGreen'
        }),
        labelColor: baseTheme.palette.augmentColor({
            color: {
                main: 'rgba(44, 56, 74, .95)',
            },
            name: 'labelColor'
        }),
        secondaryDisabledColor: baseTheme.palette.augmentColor({
            color: {
                main: '#ebedef'
            },
            name: 'secondaryDisabledColor'
        }),
        inputDisabledBorderColor: baseTheme.palette.augmentColor({
            color: {
                main: '#c4c9d0'
            },
            name: 'inputDisabledBorderColor'
        }),
        inputBorderColor: baseTheme.palette.augmentColor({
            color: {
                main: '#b1b7c1'
            },
            name: 'inputBorderColor'
        }),
        white: baseTheme.palette.augmentColor({
            color: {
                main: '#fff'
            },
            name: 'white'
        })
    },
    typography: {
        fontFamily: [
            'Roboto',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    transitions: {
        duration: {
            shortest: 150,
            shorter: 200,
            short: 250,
            // most basic recommended timing
            standard: 300,
            // this is to be used in complex animations
            complex: 375,
            // recommended when something is entering screen
            enteringScreen: 225,
            // recommended when something is leaving screen
            leavingScreen: 195,
        },
        easing: {
            // This is the most common easing curve.
            easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
            // Objects enter the screen at full velocity from off-screen and
            // slowly decelerate to a resting point.
            easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
            // Objects leave the screen at full velocity. They do not decelerate when off-screen.
            easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
            // The sharp curve is used by objects that may return to the screen at any time.
            sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                containedPrimary: {
                    '&:hover': {
                        backgroundColor: palette.primaryHoverBgColor,
                    },
                    '&:disabled': {
                        color: palette.white,
                        backgroundColor: palette.primaryDisabledBgColor,
                        opacity: 0.65
                    }
                },
                containedSecondary: {
                    '&:disabled': {
                        color: palette.black,
                        backgroundColor: palette.secondaryDisabledBgColor,
                        opacity: 0.65
                    }
                },
                containedSuccess: {
                    '&:hover': {
                        backgroundColor: palette.successHoverBgColor,
                    },
                    '&:disabled': {
                        color: palette.black,
                        backgroundColor: palette.success,
                        opacity: 0.65
                    }
                },
                containedError: {
                    color: palette.black,
                    '&:hover': {
                        backgroundColor: palette.errorHoverBgColor
                    },
                    '&:disabled': {
                        color: palette.black,
                        backgroundColor: palette.error,
                        opacity: 0.65
                    }
                },
                containedWarning: {
                    '&:hover': {
                        backgroundColor: palette.warningHoverBgColor
                    },
                    '&:disabled': {
                        color: palette.black,
                        backgroundColor: palette.warningDisabledBgColor,
                        opacity: 0.65
                    }
                },
                containedInfo: {
                    color: palette.black,
                    '&:hover': {
                        backgroundColor: palette.infoHoverBgColor,
                    },
                    '&:disabled': {
                        color: palette.black,
                        backgroundColor: palette.info,
                        opacity: 0.65
                    }
                },
                outlinedPrimary: {
                    '&:hover': {
                        color: palette.white,
                        backgroundColor: palette.primaryOutlineHoverBg
                    },
                    '&:disabled': {
                        color: palette.primaryOutlineDisabledColor,
                        borderColor: palette.primaryOutlineDisabledColor,
                        opacity: 0.65
                    }
                },
                outlinedSecondary: {
                    '&:hover': {
                        color: palette.secondaryHoverColor,
                        backgroundColor: palette.secondaryOutlineHoverBg,
                        borderColor: palette.secondaryHoverBorderColor
                    }
                },
                outlinedSuccess: {
                    '&:hover': {
                        color: palette.black,
                        backgroundColor: palette.successOutlineHoverBg,
                        borderColor: palette.successOutlineHoverBorder
                    },
                    '&:disabled': {
                        color: palette.success,
                        borderColor: palette.success,
                        opacity: 0.65
                    }
                },
                outlinedError: {
                    '&:hover': {
                        color: palette.black,
                        backgroundColor: palette.errorOutlineHoverBg,
                        borderColor: palette.errorOutlineHoverBorder
                    },
                    '&:disabled': {
                        color: palette.error,
                        borderColor: palette.error,
                        opacity: 0.65
                    }
                },
                outlinedWarning: {
                    '&:hover': {
                        color: palette.black,
                        backgroundColor: palette.warningOutlineHoverBg,
                        borderColor: palette.warningOutlineHoverBorder
                    },
                    '&:disabled': {
                        color: palette.warning,
                        borderColor: palette.warning,
                        opacity: 0.65
                    }
                },
                outlinedInfo: {
                    '&:hover': {
                        color: palette.black,
                        backgroundColor: palette.infoOutlineHoverBg,
                        borderColor: palette.infoOutlineHoverBorder
                    },
                    '&:disabled': {
                        color: palette.info,
                        borderColor: palette.info,
                        opacity: 0.65
                    }
                },
                textPrimary: {
                    '&:hover': {
                        color: palette.white,
                        backgroundColor: palette.primaryHoverBgColor,
                        borderColor: palette.primaryTextColor
                    }
                },
                textSecondary: {
                    '&:hover': {
                        color: palette.black,
                        backgroundColor: palette.secondaryTextBg,
                        borderColor: palette.secondaryHoverBgColor
                    }
                },
                textSuccess: {
                    '&:hover': {
                        color: palette.black,
                        backgroundColor: palette.successHoverBgColor,
                        borderColor: palette.successOutlineHoverBg
                    }
                },
                textError: {
                    '&:hover': {
                        color: palette.black,
                        backgroundColor: palette.errorHoverBgColor,
                        borderColor: palette.errorOutlineHoverBorder
                    }
                },
                textWarning: {
                    '&:hover': {
                        color: palette.black,
                        backgroundColor: palette.warningHoverBgColor,
                        borderColor: palette.warningOutlineHoverBg
                    }
                },
                textInfo: {
                    '&:hover': {
                        color: palette.black,
                        backgroundColor: palette.infoHoverBgColor,
                        borderColor: palette.infoOutlineHoverBorder
                    }
                },
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    position: 'static',
                    transform: 'none',
                    marginBottom: 0,
                    marginTop: 0,
                    color: palette.labelColor,
                    legend: {
                        display: 'none'
                    },
                    fieldset: {
                        top: 0,
                        borderColor: palette.inputBorderColor,
                    },
                    '& .MuiFormHelperText-root:empty': {
                        marginTop: 0,
                    },
                    '.Mui-focused': {
                        fieldset: {
                            borderColor: `${palette.inputFocusBorderColor} !important`,
                            borderWidth: '1px !important',
                            boxShadow: `0 0 0 0.25rem ${palette.inputFocusBoxshadow}`,
                        }
                    },
                    '.Mui-disabled': {
                        '&.MuiFormLabel-root': {
                            color: palette.labelColor
                        },
                        '&.MuiOutlinedInput-root': {
                            color: palette.inputDisabledTextColor,
                            backgroundColor: palette.secondaryDisabledBgColor
                        },
                        '&.MuiOutlinedInput-input': {
                            WebkitTextFillColor: palette.inputDisabledTextColor,
                        },
                        'fieldset.MuiOutlinedInput-notchedOutline': {
                            borderColor: palette.inputDisabledBorderColor
                        }
                    }
                },
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    '&.MuiFormLabel-root': {
                        position: 'static',
                        transform: 'none',
                        marginBottom: '.5rem',
                        color: palette.labelColor
                    }
                }
            }
        }
    }
});

export default responsiveFontSizes(theme);
