import React, {useCallback, useEffect, useMemo} from 'react';
import {maxLength, required, TextInput, useDataProvider} from 'react-admin';
import {FormProvider, useForm} from 'react-hook-form';
import {useQuery} from 'react-query';

import SaveIcon from '@mui/icons-material/Save';
import {Avatar, Box, Grid, SelectChangeEvent, Stack} from '@mui/material';

import {Button, Dialog, Select} from '../../../../../components';
import {IRecommendation} from '../../../../../models';
import {styledInput, styledSelect} from '../../../../../styles';
import {validateURL} from '../../../../../utils';
import {RecommendationVariables} from '../../../../MatcherRules';

import RecommendationPreview from './RecommendationPreview';

const DEFAULT_VALUES = {
    author: '',
    authorAvatar: '',
    authorPosition: '',
    header: '',
    text: '',
};

interface DialogRecommendationProps {
    open: boolean;
    recommendation: IRecommendation | null;
    onClose: (isNeedRefetch: boolean) => void;
    onSubmit: (values: IRecommendation) => void;
}

const DialogRecommendation: React.FC<DialogRecommendationProps> = ({open, recommendation, onClose, onSubmit}) => {
    const dataProvider = useDataProvider();
    const methods = useForm<IRecommendation>({
        defaultValues: {...DEFAULT_VALUES},
    });

    const {
        handleSubmit,
        reset,
        watch
    } = methods;

    const author = watch('author');
    const authorAvatar = watch('authorAvatar');
    const authorPosition = watch('authorPosition');
    const header = watch('header');
    const text = watch('text');

    const isPreview = author || authorAvatar || authorPosition || header || text;

    const preview = useMemo(() => ({
        author,
        authorAvatar,
        authorPosition,
        header,
        text
    }), [author, authorAvatar, authorPosition, header, text]);

    const handleClose = () => {
        onClose(false);
        reset({...DEFAULT_VALUES});
    };

    const {data: templates} = useQuery<IRecommendation[]>(['admin/recommendations'],
        () => dataProvider.get('admin/recommendations?page=0&size=100&sort=author,ASC', {area: ''}),
        {
            staleTime: 30 * 60 * 1000,   // cache this request for 30 minutes
            select: response => (response as any).data.content as IRecommendation[]
        }
    );

    const handleTemplate = useCallback((event: SelectChangeEvent<unknown>, value: React.ReactNode) => {
        const id = parseInt(event.target.value as string, 10);

        const template = templates?.find(it => it.id === id);

        reset(template);
    }, [templates, reset]);

    const handleClear = () => {
        reset({...DEFAULT_VALUES});
    };

    useEffect(() => {
        if (open) {
            reset(recommendation || {...DEFAULT_VALUES});
        }
    }, [open, recommendation, reset]);

    return (
        <FormProvider {...methods}>
            <form
                noValidate
                autoComplete="off"
            >
                <Dialog
                    actions={(
                        <>
                            <Stack direction="row" spacing={2}>
                                <Button startIcon={<SaveIcon/>} variant="contained" onClick={handleSubmit(onSubmit)}>
                                    Save
                                </Button>
                                <Button
                                    variant="outlined"
                                    onClick={handleClear}
                                >
                                    Clear
                                </Button>
                            </Stack>
                            <Button
                                variant="outlined"
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </>
                    )}
                    maxWidth="lg"
                    open={open}
                    title={recommendation?.id ? 'New Recommendation' : 'Edit Recommendation'}
                    onClose={() => onClose(false)}
                >
                    <Grid container spacing={0}>
                        <Grid item xs={7}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextInput fullWidth source="header" sx={styledInput} validate={[required(), maxLength(50)]}/>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextInput fullWidth label="Name" source="author" sx={styledInput} validate={[required(), maxLength(50)]}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextInput fullWidth label="Position" source="authorPosition" sx={styledInput} validate={[required(), maxLength(50)]}/>
                                </Grid>

                                {authorAvatar && (
                                    <Grid item xs={2}>
                                        <Avatar sx={{height: 80, width: 80}} src={authorAvatar}></Avatar>
                                    </Grid>
                                )}

                                <Grid item xs={authorAvatar ? 10 : 12}>
                                    <TextInput label="Avatar URL" fullWidth placeholder="https://..." source="authorAvatar" sx={styledInput} validate={validateURL}/>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextInput
                                        fullWidth
                                        helperText={<RecommendationVariables/>}
                                        multiline
                                        rows={4}
                                        source="text"
                                        sx={styledInput}
                                        validate={[required(), maxLength(400)]}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={5} sx={{paddingLeft: '50px'}}>
                            <Select
                                defaultValue=""
                                id="templates"
                                fullWidth
                                variant="standard"
                                label="Templates"
                                listItems={templates ? templates.map(it => ({text: it.author, value: it.id?.toString() || ''})) : []}
                                placeholder="- Select Template -"
                                sx={styledSelect}
                                onChange={handleTemplate}
                            >
                            </Select>

                            <Box sx={{marginBottom: '40px'}}></Box>

                            {isPreview && (
                                <RecommendationPreview data={preview} fullWidth label="Preview Recommendation" />
                            )}

                        </Grid>
                    </Grid>
                </Dialog>
            </form>
        </FormProvider>
    );
};

export default DialogRecommendation;
