import React, {FC} from 'react';

import {Box, styled, Typography} from '@mui/material';

import {EWorkType, EWorkTypeDisplay} from '../../../models';
import theme from '../../../theme';
import {formatter, formatterWithoutCents} from '../../../utils';

import OfficeBagIcon from './OfficeBagIcon';

const Wrp = styled('div')`
  color: ${theme.palette.white.main};
`;

const RateTypeItem = styled(Typography)`
  display: inline-block;
  vertical-align: middle;
  padding-right: 20px;
  position: relative;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: ${theme.palette.lightGray.main};

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${theme.palette.lightGray.main};
  }

  &:last-of-type {
    &::after {
      display: none;
    }
  }
`;

interface IFreelancerRateInfo {
    rate: number | number[];
    title?: string;
    workTypes: EWorkType[];
}

const FreelancerRateInfo: FC<IFreelancerRateInfo> = ({rate, title, workTypes}) => {
    return (
        <Wrp>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: '3px'
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 500,
                        fontSize: '13px',
                        lineHeight: '20px'
                    }}
                >
                    {title ? title : 'Rate'}
                </Typography>
                <OfficeBagIcon/>
            </Box>
            <Typography
                sx={{
                    mb: '3px',
                    fontWeight: 600,
                    fontSize: '32px',
                    lineHeight: '48px',
                    'span': {
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '21px'
                    }
                }}
            >
                {typeof rate !== 'number'
                    ? rate.map(it => formatCurrency(it)).join('-')
                    : formatCurrency(rate)} <span>/hour</span>
            </Typography>
            <Box>
                {
                    workTypes.map(item => (
                        <RateTypeItem
                            key={item}
                        >
                            {EWorkTypeDisplay[item]}
                        </RateTypeItem>
                    ))
                }
            </Box>
        </Wrp>
    );
};

const formatCurrency = (amount: number, isCents = false) => {
    return isCents
        ? formatter.format(amount / 100)
        : formatterWithoutCents.format(amount / 100);
};

export default React.memo(FreelancerRateInfo);
