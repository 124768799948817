import React, {FC, SyntheticEvent, useEffect, useState} from 'react';

import {Stack, styled, TextField} from '@mui/material';

import {convertDateTimeToString} from '../../utils';
import Dialog from '../Dialog/Dialog';
import {Button} from '..';

const ActionsWrp = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
`;

export interface DialogDateTimePresetsProps {
    subtitle: string;
    open: boolean;
    title: string;
    onCancel: () => void;
    onSave: (value: string) => void;
}

export const DialogDateTimePresets: FC<DialogDateTimePresetsProps> = React.memo(({open, subtitle, title, onSave, onCancel}) => {
    const [dateTime, setDateTime] = useState<string | null>(null);

    const handleDaysClick = (value: number) => (event: SyntheticEvent) => {
        const now = new Date();
        const days = new Date(now.getFullYear(), now.getMonth(), now.getDate() + value);

        setDateTime(convertDateTimeToString(days));
    };

    const handleSave = () => {
        try {
            const utc = new Date(new Date(dateTime as string).getTime()).toISOString();

            onSave(utc);
        } catch (error) {
            onSave('');
        }
    };

    useEffect(() => {
        setDateTime(null);
    }, [open]);

    return (
        <Dialog
            actions={(
                <ActionsWrp>
                    <Button
                        variant="outlined"
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={!dateTime}
                        variant="contained"
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                </ActionsWrp>
            )}
            maxWidth="sm"
            open={open}
            subtitle={subtitle}
            title={title}
            onClose={onCancel}
        >
            <Stack spacing={2}>
                <Button variant="outlined" onClick={handleDaysClick(1)}>Tomorrow</Button>
                <Button variant="outlined" onClick={handleDaysClick(3)}>in 3 days</Button>
                <Button variant="outlined" onClick={handleDaysClick(7)}>Next week</Button>
                <TextField
                    fullWidth
                    id="date"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    sx={{
                        '& .MuiInputBase-root': {
                            background: 'white'
                        },
                        '& .MuiInputBase-input': {
                            padding: '8.5px 14px'
                        }
                    }}
                    type="datetime-local"
                    value={dateTime || ''}
                    onChange={e => setDateTime(e.target.value)}
                />
            </Stack>
        </Dialog>
    );
});
