import React, {useEffect, useMemo, useState} from 'react';
import {Form, SelectInput, TextField, useDataProvider, useGetIdentity, useListContext, useNotify, useRecordContext} from 'react-admin';

import {Button, Stack} from '@mui/material';

import {useAdminGroups, useReviewers} from '../../../../hooks';
import {EApplicationStatus, ERole, IUser} from '../../../../models';

const AssigneeField = React.memo(() => {
    const {hasPermissions} = useAdminGroups();

    return hasPermissions([ERole.SuperAdmin]) ? <AssigneeSelect /> : <AssigneeText />;
});

const AssigneeAction = () => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const {refetch} = useListContext();
    const {identity: currentUser} = useGetIdentity();
    const {id: recordId, reviewer} = useRecordContext();

    const handleAssign = async () => {
        await dataProvider
            .put(
                `admin/applications/${recordId}/review/assignment`,
                currentUser
            );

        notify('Successfully assigned.');
        refetch();
    };

    const handleUnassign = async () => {
        await dataProvider.remove(`admin/applications/${recordId}/review/assignment`);

        notify('Successfully unassigned.');
        refetch();
    };

    return currentUser?.fullName !== reviewer ? (
        <Button
            variant="outlined"
            sx={buttonStyle}
            onClick={handleAssign}>
                Assign to me
        </Button>
    ) : (
        <Button
            variant="outlined"
            sx={buttonStyle}
            onClick={handleUnassign}>
                Unassign
        </Button>
    );
};

const AssigneeSelect = () => {
    const [isMutable, setIsMutable] = useState(false);
    const dataProvider = useDataProvider();
    const {id: recordId, status} = useRecordContext();
    const notify = useNotify();
    const {refetch} = useListContext();

    useEffect(() => {
        setIsMutable(![EApplicationStatus['In Progress'], EApplicationStatus.Accepted].includes(status));
    }, [status]);

    const {reviewers} = useReviewers();

    const optionsAssignee = useMemo(() => {
        return reviewers ? reviewers.map((it: IUser) => ({...it, name: it.firstName, id: it.email})) : [];
    }, [reviewers]);

    const handleChange = async ({target}: any) => {
        const assignee = optionsAssignee?.find((as: IUser) => as.email === target.value);

        if (assignee) {
            await dataProvider.put(
                `admin/applications/${recordId}/review/assignment`,
                {data: assignee},
            );

            notify(`Assigned to ${assignee?.firstName}`);

            if (status === EApplicationStatus.Submitted) {
                await dataProvider.put(
                    `admin/applications/${recordId}/status`,
                    {
                        data: {
                            status: EApplicationStatus['In Review']
                        }
                    }
                );
            }

            refetch();
        } else {
            await dataProvider.remove(`admin/applications/${recordId}/review/assignment`);

            notify('Successfully unassigned.');
            refetch();
        }
    };

    return (
        <Form>
            <Stack spacing={1} direction="row" alignItems="center">
                <SelectInput
                    choices={optionsAssignee}
                    emptyText="- None -"
                    emptyValue={''}
                    helperText={false}
                    label=""
                    source="reviewer"
                    disabled={!isMutable}
                    onChange={handleChange}
                />
                {isMutable && <AssigneeAction />}
            </Stack>
        </Form>
    );
};

const AssigneeText = () => {
    return (
        <Stack spacing={1} direction="row" alignItems="center">
            <AssigneeAction />
            <TextField source="reviewer" />
        </Stack>
    );
};

const buttonStyle = {
    fontSize: '13px',
    minWidth: '120px',
};

export default React.memo(AssigneeField);
