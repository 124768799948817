import React, {Fragment} from 'react';
import {ExportButton, List, ResourceContextProvider, TopToolbar, useListContext, useRecordContext} from 'react-admin';

import {Box, Divider, Grid, Typography} from '@mui/material';

import {ButtonRedirect, PaginationCoreUI, PLACEHOLDER_ID, SortButton} from '../../../../components';
import {IFreelancerWithEngagements, IWorkWeek} from '../../../../models';
import {styledLabelShow, styledList} from '../../../../styles';
import {ProposalHistory, Timesheet} from '../../../Engagements/components';

export const defaultFiltersValues = {
    freelancerId: null
};

const FreelancerEngagements = () => {
    const record = useRecordContext();

    return (
        <ResourceContextProvider value={`admin/freelancers/${record.id}/engagements`}>
            <List
                disableSyncWithLocation
                emptyWhileLoading
                actions={<ListActions />}
                filterDefaultValues={{...defaultFiltersValues}}
                pagination={<PaginationCoreUI />}
                sort={{field: 'startDate', order: 'DESC'}} // FIXME enable when BE will be ready
                sx={styledList}
                title={`| ${record.firstName} ${record.lastName}`}
            >
                <SimpleList/>
            </List>
        </ResourceContextProvider>
    );
};

const ListActions = () => (
    <Box width="100%">
        <TopToolbar sx={{justifyContent: 'space-between'}}>
            <SortButton
                fields={[
                    {label: 'Role', source: 'name'},
                    {label: 'Billing Period', source: 'startDate'},
                ]}
                label="Sort by"
            />
            <ExportButton />
        </TopToolbar>
    </Box>
);

const SimpleList = () => {
    const {data} = useListContext<IFreelancerWithEngagements>();

    return (
        <>
            {data.map(item => (
                <Grid container key={item.id} spacing={2} sx={{maxWidth: 800, mt: 1}}>
                    <Grid item xs={12}>
                        <Typography sx={styledLabelShow}>Role</Typography>
                        <Typography variant="body2">
                            {item.name}
                            &nbsp;
                            <ButtonRedirect recordLocal={item} source="roleId" path={`/roles/${PLACEHOLDER_ID}/show`}/>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Typography sx={styledLabelShow}>Client</Typography>
                        <Typography variant="body2">
                            {item.clientCompanyName}
                            &nbsp;
                            <ButtonRedirect recordLocal={item} source="clientId" path={`/clients/${PLACEHOLDER_ID}/show`}/>
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Typography sx={styledLabelShow}>Start date</Typography>
                        <Typography variant="body2">{item.startDate}</Typography>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Typography sx={styledLabelShow}>End date</Typography>
                        <Typography variant="body2">{item.endDate || '/'}</Typography>
                    </Grid>

                    {item.history?.length > 1 && item.history.map((item) => (
                        <Grid item xs={12} key={item.id}>
                            <ProposalHistory data={item}/>
                        </Grid>
                    ))}

                    {item.timesheets?.map((item: IWorkWeek) => (
                        <Fragment key={item.id}>
                            <Grid item xs={1}/>
                            <Grid item xs={11}>
                                <Timesheet data={item}/>
                            </Grid>
                        </Fragment>
                    ))}

                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                </Grid>
            ))}
        </>
    );
};

export default FreelancerEngagements;
