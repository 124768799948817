import React, {ReactNode} from 'react';

import {Typography} from '@mui/material';

type PageTitleProps = {
    children: ReactNode;
};

export const PageTitle: React.FC<PageTitleProps> = React.memo(({children}) => {
    return (
        <Typography sx={{fontSize: 24, fontWeight: 600}} variant="body1">
            {children}
        </Typography>
    );
});
