import React, {memo} from 'react';
import {useRecordContext} from 'react-admin';

import {ButtonRedirectUrl} from '../../../../components';
import {IPayment} from '../../../../models';

interface IProps {
    label: string;
    sortable: boolean;
    idSource: string;
    valueSource: string;
    navigationPattern: string;
}

export const NameField = memo(({valueSource, idSource, navigationPattern}: IProps) => {
    const record = useRecordContext<IPayment>();

    if (!record) {
        return null;
    }

    const id = (record as any)[idSource];
    const value = (record as any)[valueSource];

    return (
        <>
            {value}&nbsp;
            <ButtonRedirectUrl path={navigationPattern.replace('{id}', id)}/>
        </>
    );
});
