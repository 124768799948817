import React, {ReactNode} from 'react';

import {Typography} from '@mui/material';

import theme from '../../theme';

type FieldTitleProps = {
    children: ReactNode;
};

export const FieldTitle: React.FC<FieldTitleProps> = React.memo(({children}) => {
    return (
        <Typography sx={{fontSize: 13, fontWeight: 500, color: theme.palette.grey[500]}} variant="body1">
            {children}
        </Typography>
    );
});
