import React, {FC} from 'react';
import {RaRecord, useRecordContext} from 'react-admin';

import {Badge} from '@mui/material';

import {styledBadge} from './styles';

interface Props {
    recordLocal?: RaRecord;
    source: string;
}

const StatusBadge: FC<Props> = ({recordLocal, source}) => {
    const record = useRecordContext();

    let status = recordLocal ? recordLocal[source] : record[source];
    let color = '';

    if (status === 'IN_PROGRESS') {
        status = 'IN PROGRESS';
    }

    switch (status) {
        case 'APPROVED':
        case 'SUBMITTED':
            color = '#57cb8c';
            break;
        case 'REJECTED':
            color = '#ed3b70';
            break;
        case 'NEW':
        case 'IN PROGRESS':
            color = '#e8bb2e';
            break;
    }

    return (
        <Badge
            sx={{
                ...styledBadge,
                backgroundColor: color,
            }}>
            {status}
        </Badge>
    );
};


export default StatusBadge;