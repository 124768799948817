import * as React from 'react';
import {memo, ReactElement} from 'react';
import {
    shallowEqual,
    useListSortContext,
    useTranslate,
    useTranslateLabel,
} from 'ra-core';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SortIcon from '@mui/icons-material/Sort';
import {
    Button,
    IconButton,
    Menu,
    MenuItem,
    Theme,
    Tooltip,
    useMediaQuery,
} from '@mui/material';
import {styled} from '@mui/material/styles';

/**
 * A button allowing to change the sort field and order.
 *
 * To be used inside a ListContext (e.g. inside a <List> or <ReferenceManyField>)
 *
 * Expects one 'fields' prop, containing an array of field strings that shall
 * be used and displayed for sorting.
 *
 * When users clicks on the <SortButton>, they see a dropdown list with the
 * proposed sorting fields. Once they click on one of these fields, the related
 * list refreshes, re-sorted.
 *
 * @example
 *
 * import * as React from 'react';
 * import { TopToolbar, SortButton, CreateButton, ExportButton } from 'react-admin';
 *
 * const ListActions = () => (
 *     <TopToolbar>
 *         <SortButton fields={['reference', 'sales', 'stock']} />
 *         <CreateButton />
 *         <ExportButton />
 *     </TopToolbar>
 * );
 */

const defaultIcon = <SortIcon />;

const StyledButton = styled(Button, {
    name: 'RaSortButton',
    overridesResolver: (props, styles) => styles.root,
})({
    '&.MuiButton-sizeSmall': {
        // fix for icon misalignment on small buttons, see https://github.com/mui/material-ui/pull/30240
        lineHeight: 1.5,
    },
    '& .MuiButton-endIcon': {ml: 0},
});

interface IField {
    label: string;
    source: string;
}

export interface SortButtonProps {
    fields: Array<string | IField>;
    icon?: ReactElement;
    label?: string;
    resource?: string;
}

const SortButton = (props: SortButtonProps) => {
    const {fields, label = 'ra.sort.sort_by', icon = defaultIcon} = props;
    const {resource, sort, setSort} = useListSortContext();
    const translate = useTranslate();
    const translateLabel = useTranslateLabel();
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangeSort = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        field: any // string | IField
    ) => {
        if (!field) return;

        setSort({
            field: field.source || field,
            order: (field.source || field) === sort.field ? inverseOrder(sort.order) : 'ASC',
        });
        setAnchorEl(null);
    };

    const buttonLabel = React.useMemo(() => {
        const prefix = translate(label, {
            field: translateLabel({
                resource,
                source: sort.field,
            }),
            order: translate(`ra.sort.${sort.order}`),
            _: label,
        });

        const field = fields.find((it: any) => (it.source || it) === sort.field) as any;

        return `${prefix}: ${field.label || field} [${sort.order}]`;
    }, [fields, label, resource, sort, translate, translateLabel]);

    return (
        <>
            {isXSmall ? (
                <Tooltip title={buttonLabel}>
                    <IconButton
                        aria-label={buttonLabel}
                        color="primary"
                        onClick={handleClick}
                        size="large"
                    >
                        {icon}
                    </IconButton>
                </Tooltip>
            ) : (
                <StyledButton
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    color="primary"
                    onClick={handleClick}
                    startIcon={icon}
                    endIcon={<ArrowDropDownIcon />}
                    size="small"
                >
                    {buttonLabel}
                </StyledButton>
            )}
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {fields.map((field: any) => (
                    <MenuItem
                        selected={(field.source || field) === sort.field}
                        key={field.source || field}
                        onClick={(e) => handleChangeSort(e, field)}
                    >
                        {translateLabel({
                            resource,
                            source: field.label || field,
                        })}{' '}
                        (
                        {translate(
                            `ra.sort.${
                                sort.field === (field.source || field)
                                    ? inverseOrder(sort.order)
                                    : 'ASC'
                            }`
                        )})
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

const inverseOrder = (sort: string) => (sort === 'ASC' ? 'DESC' : 'ASC');

const arePropsEqual = (prevProps: SortButtonProps, nextProps: SortButtonProps) =>
    shallowEqual(prevProps.fields, nextProps.fields);

export default memo(SortButton, arePropsEqual);
