import React, {useState} from 'react';
import {Button, CheckboxGroupInput, DeleteButton, Edit, HttpError,
    Labeled, maxLength, required, SaveButton, SimpleForm,
    TextInput, Toolbar, useDataProvider, useGetIdentity, useNotify, useRecordContext,
    UseUpdateMutateParams} from 'react-admin';
import {useQueryClient} from 'react-query';
import {useParams} from 'react-router-dom';

import ContactMailIcon from '@mui/icons-material/ContactMail';
import {Grid, Stack, Typography} from '@mui/material';

import {useAdminGroups} from '../../hooks';
import {ERole, IUser} from '../../models';
import {styledButton, styledDeleteButton, styledInput, styledLabel} from '../../styles';

import {GroupsFieldPreview} from './components/GroupsField';
import {PhotoField} from './components';

const Title = () => {
    const record = useRecordContext<IUser>();
    const title = record ? `"${record.firstName} ${record.lastName}"` : '';

    return <span>User: {title.length > 35 ? title.substring(0, 35) + '...' : title}</span>;
};

const EditFormToolbarCoreUI = () => {
    const record = useRecordContext();
    const [isEmailSend, setIsEmailSent] = useState(false);
    const {id} = useParams();

    const {identity} = useGetIdentity();

    const {hasPermissions} = useAdminGroups();
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const isCurrentUser = identity?.id === id;

    const handleResent = async () => {
        try {
            await dataProvider.put(`admin/users/${record.id}/password`);
            setIsEmailSent(true);

            notify('Email has been sent successfully!');
        } catch (error: any) {
            console.log(error.body);
            const message = error.body?.errors?.map((it: {message: string}) => it.message).join(', ');

            notify(`Error: ${message || error.message}`, {type: 'warning'});
        }
    };

    return (
        <Toolbar sx={{justifyContent: 'space-between'}}>
            <Stack direction="row" spacing={2}>
                <SaveButton alwaysEnable={true} sx={styledButton} />
                {(isCurrentUser || hasPermissions([ERole.SuperAdmin])) && (
                    <Button
                        disabled={isEmailSend}
                        label="Resend email to set up a password"
                        startIcon={<ContactMailIcon/>}
                        sx={styledButton}
                        variant="outlined"
                        onClick={handleResent}
                    />
                )}
            </Stack>

            {hasPermissions([ERole.SuperAdmin]) && (
                <DeleteButton sx={styledDeleteButton} />
            )}

        </Toolbar>
    );
};

export const AdminsEdit = () => {
    const {data, hasPermissions} = useAdminGroups();
    const {id} = useParams();

    const {identity} = useGetIdentity();
    const notify = useNotify();
    const queryClient = useQueryClient();

    const isCurrentUser = identity?.id === id;

    const optionsGroup = React.useMemo(() => {
        return data ? data.map((it: string) => ({
            id: it,
            name: it.replace('/Administrators/', '')
        })) : [];
    }, [data]);

    const onError = (error: unknown, variables: UseUpdateMutateParams<any>, context: unknown) => {
        const data = (error as HttpError).message.split('AccountCreationException: ');
        const message = (data[1] || (error as HttpError).message).replace('username', 'email');

        notify(message, {type: 'warning'});
    };

    const onSuccess = (v: any) => {
        queryClient.invalidateQueries({queryKey: ['users']});
    };

    return (
        <Edit
            mutationMode={hasPermissions([ERole.SuperAdmin]) ? 'optimistic' : 'pessimistic'}
            mutationOptions={{onError, onSuccess}}
            redirect={hasPermissions([ERole.SuperAdmin]) ? '/users' : `/users/${id}`}
            title={<Title/>}
        >
            <SimpleForm sx={{maxWidth: 600}} toolbar={<EditFormToolbarCoreUI/>}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            disabled={!isCurrentUser && !hasPermissions([ERole.SuperAdmin])}
                            fullWidth
                            helperText={false}
                            source="firstName"
                            sx={styledInput}
                            validate={[maxLength(100)]}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            disabled={!isCurrentUser && !hasPermissions([ERole.SuperAdmin])}
                            fullWidth
                            helperText={false}
                            source="lastName"
                            sx={styledInput}
                            validate={[required(), maxLength(100)]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            disabled
                            fullWidth
                            helperText={false}
                            source="email"
                            sx={styledInput}
                            validate={[required(), maxLength(100)]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            disabled={!isCurrentUser && !hasPermissions([ERole.SuperAdmin])}
                            fullWidth
                            helperText={false}
                            source="position"
                            sx={styledInput}
                            validate={[maxLength(100)]}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <PhotoField source="photo"/>
                    </Grid>
                    <Grid item xs={12}>

                        {/* SuperAdmin can edit */}
                        {hasPermissions([ERole.SuperAdmin]) && (
                            <Labeled
                                label={<Typography component="span" sx={styledLabel} variant="body1">Groups</Typography>}
                                sx={{
                                    '& .MuiFormGroup-root': {flexDirection: 'column'}
                                }}
                            >
                                <CheckboxGroupInput source="groups" choices={optionsGroup} />
                            </Labeled>
                        )}

                        {/* for all read-only */}
                        {!hasPermissions([ERole.SuperAdmin]) && (
                            <GroupsFieldPreview source="groups"/>
                        )}

                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    );
};
