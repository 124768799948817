import React from 'react';
import {DeleteButton, Edit, ImageField, maxLength, RaRecord, required, SaveButton, SimpleForm, TextInput, Toolbar, ToolbarProps, useNotify, useRecordContext, useRefresh} from 'react-admin';

import {Grid, Stack} from '@mui/material';

import {Button} from '../../../components';
import {styledButton, styledDeleteButton, styledInput} from '../../../styles';
import {validateURL} from '../../../utils';

import RecommendationVariables from './RecommendationVariables';

interface IProps {
    record: RaRecord;
    onClose: (value: RaRecord | null) => void;
}

export const RecommendationEdit: React.FC<IProps> = ({record, onClose}) => {
    const notify = useNotify();
    const refresh = useRefresh();

    const onSuccess = () => {
        notify('Changes saved');
        refresh();
        onClose(null);
    };

    return (
        <Edit
            id={record.id}
            mutationOptions={{onSuccess}}
            mutationMode="pessimistic"
            resource="admin/recommendations"
            title={<RecommendationTitle/>}
        >
            <SimpleForm sx={{maxWidth: 700}} toolbar={<RecommendationEditToolbar onCancel={() => onClose(null)}/>}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextInput fullWidth source="header" sx={styledInput} validate={[required(), maxLength(50)]}/>
                    </Grid>

                    {record.authorAvatar && (
                        <Grid item xs={2}>
                            <ImageField
                                source="authorAvatar"
                                title="author"
                                sx={{'& img': {maxWidth: 70, maxHeight: 70, objectFit: 'contain'}}}
                            />
                        </Grid>
                    )}

                    <Grid item xs={record.authorAvatar ? 10 : 12}>
                        <TextInput label="Avatar URL" fullWidth placeholder="https://..." source="authorAvatar" sx={styledInput} validate={validateURL}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextInput fullWidth label="Name" source="author" sx={styledInput} validate={[required(), maxLength(50)]}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextInput fullWidth label="Position" source="authorPosition" sx={styledInput} validate={[required(), maxLength(50)]}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            helperText={<RecommendationVariables/>}
                            multiline
                            rows={4}
                            source="text"
                            sx={styledInput}
                            validate={[required(), maxLength(400)]}
                        />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    );};

const RecommendationTitle = () => {
    const record = useRecordContext();

    return <span>Recommendation Template {record ? `"${record.author}"` : ''}</span>;
};

interface IRecommendationEditToolbarProps extends ToolbarProps {
    onCancel: () => void;
}

const RecommendationEditToolbar: React.FC<IRecommendationEditToolbarProps> = ({onCancel, ...props}) => {
    const notify = useNotify();
    const refresh = useRefresh();

    return (
        <Toolbar {...props} sx={{justifyContent: 'space-between'}}>
            <Stack direction="row" spacing={2}>
                <SaveButton sx={styledButton}/>
                <Button
                    sx={styledButton}
                    variant="outlined"
                    onClick={onCancel}
                >
                    Cancel
                </Button>
            </Stack>

            <DeleteButton
                redirect={false}
                sx={styledDeleteButton}
                mutationOptions={{
                    onSuccess: data => {
                        notify('ra.notification.deleted', {
                            type: 'info',
                            messageArgs: {smart_count: 1},
                        });

                        onCancel();
                        refresh();
                    }}
                }
            />
        </Toolbar>
    );
};
