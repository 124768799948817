import React from 'react';
import {useRecordContext} from 'react-admin';

import {Typography} from '@mui/material';

import {IChannel, ISubChannel} from '../../../../models';

export const SubChannelsField = () => {
    const {channels} = useRecordContext();
    let subchannels: ISubChannel[] = [];

    channels?.forEach((channel: IChannel) => {
        subchannels = [...subchannels, ...channel.subchannels];
    });

    if (!channels?.length || !subchannels.length) {
        return (
            <Typography>/</Typography>
        );
    }

    return (
        <>
            {
                subchannels.map((subChannel: ISubChannel, index: number) => (
                    <Typography
                        key={subChannel.id}
                        sx={{display: 'contents'}}
                    >
                        {subChannel.name}
                        {index < subchannels.length - 1 && ', '}
                    </Typography>
                ))
            }
        </>
    );
};