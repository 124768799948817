import React, {FocusEvent, KeyboardEvent, useEffect, useState} from 'react';
import {TextInput, useDataProvider, useEditContext, useNotify, useRecordContext} from 'react-admin';

import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import {Button, Stack} from '@mui/material';

import {EllipsisText} from '../../../../components';
import {EApplicationStatus, ICaseStudy, ICertificate, IReference} from '../../../../models';
import {styledIconButton} from '../../../../styles';

export const DocumentNotes = ({item, applicationId}: {item: ICaseStudy | ICertificate | IReference; applicationId: number}) => {
    const [edit, setEdit] = useState(false);
    const [isMutable, setIsMutable] = useState(false);

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const {refetch} = useEditContext();

    const {status: ApplicationStatus} = useRecordContext();

    useEffect(() => {
        setIsMutable(![EApplicationStatus['In Progress'], EApplicationStatus.Accepted].includes(ApplicationStatus));
    }, [ApplicationStatus]);

    const handleClick = () => {
        setEdit(prevState => !prevState);
    };

    const handleBlur = async (e: FocusEvent<HTMLInputElement>) => {
        if (e.target.value === item.notes) return;

        await dataProvider.put(
            `admin/applications/${applicationId}/review/documents`,
            {
                data: [
                    {
                        id: item.id,
                        notes: e.target.value,
                        status: item.status,
                    }
                ]
            }
        );

        notify('Successfully updated');
        refetch && refetch();

        setEdit(false);
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            (e.target as HTMLElement).blur();
        }
    };

    if (!edit) {
        return (
            <Stack
                alignItems="flex-start"
                direction="row"
                spacing={1}
                sx={{marginTop: isMutable ? '10px' : '0px'}}
            >
                {
                    isMutable ? (
                        <>
                            <Button
                                sx={{
                                    ...styledIconButton,
                                    marginTop: '-2px',
                                    marginRight: '8px',
                                }}
                                onClick={handleClick}
                            >
                                <NoteAddOutlinedIcon />
                            </Button>
                            <span>
                                {item.notes?.length > 30 ? `${item.notes.substring(0, 30)}...` : item.notes}
                            </span>
                        </>
                    ) : <EllipsisText text={item.notes} max={30} />
                }
            </Stack>
        );
    } else {
        return (
            <Stack
                alignItems="flex-start"
                direction="row"
                spacing={1}
                sx={{marginTop: '10px',}}>
                <Button
                    sx={{...styledIconButton, marginTop: '8px'}}
                    onClick={handleClick}
                >
                    <DescriptionOutlinedIcon />
                </Button>
                <TextInput
                    autoComplete="off"
                    defaultValue={item.notes}
                    fullWidth
                    helperText={false}
                    inputProps={{maxLength: 225}}
                    label={false}
                    multiline
                    rows={2}
                    source={String(item.id)}
                    sx={{'& .MuiFormLabel-root': {display: 'none'}}}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                />
            </Stack>
        );
    }
};
