import React from 'react';
import {RaRecord, useRecordContext} from 'react-admin';

import {IChannel, ISubChannel} from '../../../models';
import {ListField} from '../..';

type Props = {
    component?: string;
    source?: string;
    startAdorment?: string;
    recordLocal?: RaRecord;
};

export const SubChannelsField: React.FC<Props> = React.memo(({recordLocal}) => {
    const {profile} = useRecordContext();
    let subchannels: ISubChannel[] = [];

    if (!profile && !recordLocal) {
        return null;
    }

    (recordLocal || profile)?.channels?.forEach((channel: IChannel) => {
        subchannels = [...subchannels, ...channel.subchannels];
    });

    return <ListField field={subchannels} source="" />;
});