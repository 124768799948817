import React from 'react';
import {useListContext} from 'react-admin';
import {useFormContext} from 'react-hook-form';

import {Button} from '../../../../components';

export const ClearFiltersButton = () => {
    const {displayedFilters, setFilters} = useListContext();
    const {setValue} = useFormContext();

    const onClearFilters = () => {
        setValue('q', '');
        setValue('status', null);

        setFilters({}, displayedFilters);
    };

    return (
        <Button
            className="secondary"
            color="primary"
            disableElevation
            variant="contained"
            onClick={onClearFilters}
        >
            Clear filters
        </Button>
    );
};