import React from 'react';
import {useListContext} from 'react-admin';
import {useFormContext} from 'react-hook-form';

import {Button} from '../../../../components';

export const ClearFiltersButton = () => {
    const {displayedFilters, setFilters} = useListContext();
    const {setValue} = useFormContext();

    const onClearFilters = () => {
        setValue('q', null);
        setValue('channels', null);
        setValue('subchannels', null);
        setValue('verticals', null);
        setValue('status', null);
        setValue('reviewers', null);
        setValue('businessModels', null);
        setValue('isAvailableForNewProject', null);
        setValue('tools', null);
        setValue('brands', null);
        setValue('minRate', null);
        setValue('maxRate', null);
        setValue('submittedAfter', null);
        setValue('submittedBefore', null);

        setFilters({}, displayedFilters);
    };

    return (
        <Button
            className="secondary"
            color="primary"
            disableElevation
            variant="contained"
            onClick={onClearFilters}
        >
            Clear filters
        </Button>
    );
};
