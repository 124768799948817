import * as React from 'react';
import {CheckboxGroupInput, Create, HttpError, Labeled, maxLength, required, SimpleForm, TextInput, UseCreateMutateParams, useNotify} from 'react-admin';

import {Grid} from '@mui/material';

import {useAdminGroups} from '../../hooks';
import {ERole} from '../../models';
import {styledInput} from '../../styles';

export const AdminsCreate = () => {
    const {data} = useAdminGroups();
    const {hasPermissions} = useAdminGroups();
    const notify = useNotify();

    const optionsGroup = React.useMemo(() => {
        if (!data) return [];

        return data.map((it: string) => ({
            id: it,
            name: it
        }));
    }, [data]);

    const onError = (error: unknown, variables: UseCreateMutateParams<any>, context: unknown) => {
        const data = (error as HttpError).message.split('AccountCreationException: ');
        const message = (data[1] || (error as HttpError).message).replace('username', 'email');

        notify(message, {type: 'warning'});
    };

    // Only the super admin could create or delete other admins
    if (!data || !hasPermissions([ERole.SuperAdmin])) {
        return null;
    }

    return (
        <Create redirect="list" mutationOptions={{onError}}>
            <SimpleForm sx={{maxWidth: 600}}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput fullWidth source="firstName" sx={styledInput} validate={[required(), maxLength(100)]}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput fullWidth source="lastName" sx={styledInput} validate={[required(), maxLength(100)]}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput fullWidth source="email" sx={styledInput} validate={[required(), maxLength(100)]}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Labeled label="Groups" sx={{'& .MuiFormGroup-root': {flexDirection: 'column'}}}>
                            <CheckboxGroupInput source="groups" choices={optionsGroup} />
                        </Labeled>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    );
};
