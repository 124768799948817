import React from 'react';
import {Create} from 'react-admin';

import {styledButton} from '../../styles';

import {NotificationForm} from './NotificationForm';
import {transform} from './transform.helper';

export const NotificationRuleCreate = (props: any) => {
    return (
        <Create {...props} redirect="list" transform={transform} sx={{'& button': styledButton}}>
            <NotificationForm />
        </Create>
    );
};
