import React from 'react';
import {RaRecord, useRecordContext} from 'react-admin';

import {Typography} from '@mui/material';

import {formatterWithoutCents} from '../../../utils';

interface Props {
    recordLocal?: RaRecord;
}

export const RateRange = React.memo((props: Props) => {
    const record = useRecordContext();

    if (!record) return null;

    const {rateRange} = (props.recordLocal || record);
    const from = rateRange.length ? rateRange[0] / 100 : '';
    const to = rateRange.length ? rateRange[1] / 100 : '';

    if (from && to) {
        return <Typography>{formatterWithoutCents.format(from)} - {formatterWithoutCents.format(to)}</Typography>;
    } else if (from) {
        return <Typography>From {formatterWithoutCents.format(from)}</Typography>;
    } else if (to) {
        return <Typography>To {formatterWithoutCents.format(to)}</Typography>;
    }

    return <Typography>/</Typography>;
});
