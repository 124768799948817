import React, {FC} from 'react';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {Badge, Box, Stack} from '@mui/material';

import {styledBadge} from '../../../../components/StatusBadge/styles';
import {EPaymentStatus, EPaymentStatusDisplay, ICharge, IPayout} from '../../../../models';
import theme from '../../../../theme';

interface IProps {
    data: ICharge | IPayout;
    isTooltip?: boolean;
}

const PaymentStatus: FC<IProps> = ({data, isTooltip = true}) => {
    let backgroundColor = '';
    const color = '#ffffff';

    switch (data?.status) {
        case EPaymentStatus.REFUNDED:
            backgroundColor = '#00f';
            break;
        case EPaymentStatus.FAILED:
            backgroundColor = theme.palette.error.main;
            break;
        case EPaymentStatus.NEW:
            backgroundColor = theme.palette.grey[500];
            break;
        case EPaymentStatus.PENDING:
            backgroundColor = '#ffae00';
            break;
        case EPaymentStatus.SUCCESS:
            backgroundColor = theme.palette.darkGreen.main;
            break;
    }

    if (!data) {
        return null;
    }

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <Badge
                sx={{
                    ...styledBadge,
                    color,
                    backgroundColor,
                }}>
                {EPaymentStatusDisplay[data.status]}
            </Badge>

            {isTooltip && data.problem && (
                <Box
                    title={data.problem}
                >
                    <HelpOutlineIcon
                        sx={{
                            fill: theme.palette.grey[500],
                            width: '14px'
                        }}
                    />
                </Box>
            )}
        </Stack>
    );
};

export default React.memo(PaymentStatus);
