import React from 'react';
import {RaRecord, useRecordContext} from 'react-admin';

import {Link, Typography, TypographyProps} from '@mui/material';

import {getByPath} from '../../../utils/record.helper';

const prefix = 'https://';

type Props = {
    component?: string;
    source: string;
    recordLocal?: RaRecord;
} & TypographyProps;

export const UrlField = React.memo(({recordLocal, source, ...props}: Props) => {
    const record = useRecordContext();

    if (!record) return null;

    const field = getByPath(recordLocal || record, source);

    if (field) {
        return <Link href={field.startsWith(prefix) ? field : (prefix + field)} rel="noopener" target="_blank" >{field}</Link>;
    }

    return (
        <Typography {...props}>
            /
        </Typography>
    );
});
