import React from 'react';
import {Button, Edit, HttpError, maxLength, required, SimpleForm, TextInput, useNotify, useRecordContext} from 'react-admin';
import {useFormContext, useWatch} from 'react-hook-form';

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import {Box, Grid, Typography} from '@mui/material';

import {EditFormToolbarCoreUI} from '../../components';
import {styledInput} from '../../styles';
import {getFieldErrors, validateURL} from '../../utils';

const styles = {
    fontFamily: 'monospace',
    fontSize: '14px',
    padding: '16px',
    margin: 0,
    // whiteSpace: 'inherit' as any,
};

const WidgetTitle = () => {
    const record = useRecordContext();

    return <span>Widget {record ? `"${record.title}"` : ''}</span>;
};

const CopyScript = () => {
    const notify = useNotify();
    const {
        control,
    } = useFormContext();

    const {id, location} = useWatch({control});

    const portalUrl = window.location.origin.replace('/admin.', '/');
    const text =
`<style>
        #gc-widget-wrapper {
        width: 1200px;
        max-width: 100%;
        aspect-ratio: 3/4;
        overflow: hidden;
    }

    @media screen and (min-width: 600px) {
        #gc-widget-wrapper {
            height: 100vh;
            aspect-ratio: auto;
        }
    }
</style>

<div id="gc-widget-wrapper">
    <iframe id="gc-widget" type="text/html"
        src="${portalUrl}/widget?utm_source=widget&utm_campaign=${id}&origin=${location}"
        style="border-style: none; width: 100%; height: 100%;">
    </iframe>
</div>`;

    const handleCustomTagClick = () => {
        navigator.clipboard.writeText(text);

        notify('Copied to clipboard', {autoHideDuration: 2000});
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="body2">Embed a GC using the IFrame</Typography>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{maxWidth: '60vw', overflowX: 'auto', backgroundColor: '#eee'}}>
                    <Button
                        color="secondary"
                        disableElevation
                        disableFocusRipple
                        disableTouchRipple
                        disableRipple
                        endIcon={ <ContentCopyOutlinedIcon
                            sx={{
                                width: '14px',
                                color: '#9f9f9f',
                            }}/>}
                        size="small"
                        title="Copy to clipboard"
                        variant="contained"
                        sx={{
                            height: 'auto !important',
                            fontSize: '12px !important',
                            textTransform: 'none',
                            margin: 2,
                            background: '#dddddd'
                        }}
                        onClick={handleCustomTagClick}>
                        <>Copy to clipboard</>
                    </Button>
                    <pre style={styles}>
                        {text}
                    </pre>
                </Box>
            </Grid>
        </Grid>
    );
};

export const WidgetEdit = () => {
    const notify = useNotify();
    const onError = (error: unknown, variables: any, context: unknown) => {
        const errors = getFieldErrors(error);

        if (errors) {
            errors.forEach(error => notify((error as any).message, {type: 'warning'}));
        } else {
            notify((error as HttpError).message, {type: 'warning'});
        }
    };

    return (
        <Edit title={<WidgetTitle />} mutationMode="pessimistic" mutationOptions={{onError}}>
            <SimpleForm toolbar={<EditFormToolbarCoreUI />}>
                <Box sx={{maxWidth: 600}}>
                    <TextInput source="id" fullWidth disabled sx={styledInput} />
                    <TextInput source="title" fullWidth validate={[required(), maxLength(100)]} sx={styledInput} />
                    <TextInput source="description" fullWidth multiline rows={4} validate={[required(), maxLength(1000)]} sx={styledInput} />
                    <TextInput source="location" fullWidth placeholder="https://..." validate={[required(), maxLength(1000), validateURL]} sx={styledInput} />
                </Box>
                <CopyScript/>
            </SimpleForm>
        </Edit>
    );
};
