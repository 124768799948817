import React, {useEffect, useState} from 'react';
import {Reorder} from 'framer-motion';

import {Box, Stack, styled, TextField, Typography} from '@mui/material';

import {IIgnoreFreelancer} from '../../../../../models';

import {IgnoreItem} from './IgnoreItem';

const ListWrapper = styled(Reorder.Group)(({theme}) => ({
    listStyle: 'none',
    padding: 0,
    paddingBottom: 20,
    margin: 0,
    '& li': {
        padding: '2px 0px'
    }
}));

interface Props {
    items: IIgnoreFreelancer[];
}

export const FreelancerIgnoreList: React.FC<Props> = React.memo((props) => {
    const [items, setItems] = useState<IIgnoreFreelancer[]>([]);

    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        setItems(props.items);
    }, [props.items]);

    useEffect(() => {
        if (!searchTerm.trim()) {
            setItems(props.items);
        } else {
            const result = props.items.filter(freelancer =>
                freelancer.email.includes(searchTerm) ||
                freelancer.firstName.includes(searchTerm) ||
                freelancer.lastName.includes(searchTerm)
            );

            setItems(result);
        }
    }, [props.items, searchTerm]);

    return (
        <Box sx={{py: 2, px: 2, background: '#e7ebf0', overflow: 'hidden'}}>
            <Stack alignItems="flex-end" direction="row" spacing={2} sx={{mb: 2}}>
                <TextField
                    autoComplete="off"
                    placeholder="Search by name, surname, email ..."
                    sx={{
                        marginBottom: 0,
                        width: 300,
                        '& input': {padding: '8px'}
                    }}
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                />
                {/* <Box sx={{flexGrow: 1}} />

                <Button color="primary" onClick={handleAddToResult}>
                    + Add {selectedCount || 'All'} Freelancer{!selectedCount || selectedCount > 1 ? '(s)' : ''}
                </Button> */}
            </Stack>

            {!items.length && <Typography align="center" sx={{p: 5, opacity: 0.5}}>Records not found</Typography>}

            <ListWrapper values={items} onReorder={setItems}>
                {items.map(item => (
                    <IgnoreItem
                        item={item}
                        key={item.id}
                    />
                ))}
            </ListWrapper>

        </Box>
    );
});
