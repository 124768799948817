import React from 'react';
import {EditButton, ShowButton, useRecordContext} from 'react-admin';

import {Box} from '@mui/material';

import {EApplicationStatus} from '../../../../models';
import {styledListEditButton, styledListShowButton} from '../../../../styles';

export const Actions = (props: {sortable: boolean; source: string}) => {
    const {status} = useRecordContext();

    return (
        <Box>
            {
                [EApplicationStatus['In Progress'], EApplicationStatus.Accepted].includes(status)
                    ? <ShowButton label="" sx={styledListShowButton} />
                    : <EditButton label="" sx={styledListEditButton} />
            }
        </Box>
    );
};