import React from 'react';
import {useRecordContext} from 'react-admin';

import {Badge} from '@mui/material';

import {EEventSource, IEventType} from '../../models';
import {formatNameToCapital} from '../../utils';

interface Props {
    eventTypes?: IEventType[];
    source: string;
    sortable?: boolean;
}

const EventSourceBadge = ({eventTypes}: Props) => {
    const {eventType} = useRecordContext();
    const item = eventTypes?.find(it => it.type === eventType);
    const eventSource = item?.source || '';

    let color;

    switch (eventSource) {
        case EEventSource.APPLICANT:
            color = '#d45a04';
            break;
        case EEventSource.FREELANCER:
            color = '#1a7df3';
            break;
        case EEventSource.ADMINISTRATOR:
            color = '#08a25d';
            break;
        case EEventSource.CLIENT:
            color = '#837fe9';
            break;
        case EEventSource.OTHER:
            color = '#87daae';
            break;
        default:
            color = '#8f8f8f';
    }

    return (
        <Badge
            sx={{
                backgroundColor: color,
                color: '#fff',
                borderRadius: '6px',
                fontSize: '11px',
                padding: '4px 7px',
                fontWeight: '700',
                lineHeight: '1',
            }}>
            {formatNameToCapital(eventSource)}
        </Badge>
    );
};

export default React.memo(EventSourceBadge);
