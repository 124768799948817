import React from 'react';
import clsx, {ClassValue} from 'clsx';
import {Controller, useFormContext} from 'react-hook-form';

import {SelectProps} from '@mui/material';

import {Select} from '../..';

type TextFieldProps = SelectProps & {
    classNames?: ClassValue[];
    helperText?: string;
    listItems: Array<{ value: string; text: string }>;
}

export const SelectForm = ({
    classNames,
    fullWidth = true,
    name = '',
    variant,
    ...props
}: TextFieldProps): JSX.Element => {
    const {
        control,
        formState: {errors},
    } = useFormContext();

    return (
        <Controller
            control={control}
            defaultValue=''
            name={name}
            render={({field: {ref, ...field}}) => (
                <Select
                    {...props}
                    {...field}
                    className={clsx(classNames)}
                    error={errors[name]?.message as any}
                    fullWidth
                    inputRef={ref}
                />
            )}
        />
    );
};
