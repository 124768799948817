import React from 'react';

import {Pagination as MUIPagination} from '@mui/material';

interface IPagination {
    count: number;
    disabled?: boolean;
}

const Pagination = (props: IPagination): JSX.Element => {
    return (
        <MUIPagination
            count={props.count}
            color="primary"
            disabled={props.disabled}
        />
    );
};

export default Pagination;

