import React, {FC} from 'react';
import format from 'date-fns/format';
import capitalize from 'lodash/capitalize';

import {Box, Stack, Typography} from '@mui/material';

import {formatterWithoutCents} from '../../../../utils';
import {EDashboardType, IChartItem} from '../../models';
import {styledLabel} from '../Tile/Tile';

import {EColors} from './SectionGMV';

interface ITooltipProp {
    active?: boolean;
    label?: string;
    payload?: {payload: IChartItem}[];
    type: EDashboardType;
}

const CustomTooltip: FC<ITooltipProp> = ({active, label, payload, type}) => {
    if (!payload?.length) return null;

    const data = payload[0].payload;
    const dateRange = label?.split(' - ') || [];
    let from = '';
    let to = '';

    try {
        from = format(new Date(dateRange[0]), 'PP');
        to = format(new Date(dateRange[1]), 'PP');
    } catch (e) {}

    if (active) {
        return (
            <Box
                sx={{
                    background: 'white',
                    border: '1px solid #eee',
                    p: 2,
                    minWidth: 200,
                }}
            >
                {type === EDashboardType.Weekly ? (
                    <Typography paragraph variant="body2">{from} - {to}</Typography>
                ) : (
                    <Typography paragraph variant="body2">{capitalize(label)}</Typography>
                )}


                <RowInfo color={EColors.Hourly} label="Hourly rate" value={data.hourly}/>
                <RowInfo color={EColors.Retainer} label="Retainer" value={data.retainer}/>
                <RowInfo color={EColors.Fixed} label="Fixed rate" value={data.fixed}/>
            </Box>
        );
    }

    return null;
};

interface RowProps {
    color: EColors;
    label: string;
    value: number;
}

const RowInfo: FC<RowProps> = ({color, label, value}) => {
    return (
        <Stack direction="row" justifyContent="space-between">
            <Typography sx={{...styledLabel, color}} variant='caption'>
                {label}
            </Typography>
            <Typography sx={{...styledLabel, color, fontSize: 14, fontWeight: 700}} variant='body1'>
                {formatterWithoutCents.format(value)}
            </Typography>
        </Stack>
    );
};

export default CustomTooltip;
