import {IChannel, ISubChannel} from './channel.model';
import {IMatching} from './matcher.model';
import {ITool} from './tools.model';
import {IContactPerson} from './user.model';
import {IVertical} from './verticals-brands.model';
import {EWorkType} from './work.model';

export interface IClient {
    billingContact?: IContactPerson;
    companyName: string;
    companyWebsite: string;
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    phone: string;
    position: string;
}

export interface IClientRole {
    allowEmails: boolean;
    budget: number;
    businessModels: string[];
    channel: IChannel;
    client?: IClient;
    companyHubspotId: string;
    countries: string[];
    created: string;
    expectedEngagementHours: number[];
    hubspotId: string;
    id: number;
    matchings: IMatching[];
    matchesReplenishmentDate: string;   // automatic background results replacing time
    notes: string;
    projectDescription: string;
    projectName: string;
    rateRange: number[];
    rolesCount: number;
    status: ERoleStatus;
    subchannels: ISubChannel[];
    submitted: string;
    tier: ETier;
    timezone: string;
    tools: ITool[];
    verticals: IVertical[];
    view: string;
    workType: EWorkType;
    unwantedFreelancers: IIgnoreFreelancer[];
    unwantedFreelancersIds: number[]; // ids
}

export enum ETier {
    Basic = 'BASIC',
    Premium = 'PREMIUM'
}

// DRAFT - not yet submitted. form in progress
//
// SUBMITTED - submitted but no matching has been started yet
//
// MATCHING_FINISHED - got some matchings but not yet shared to client
//
// IN_REVIEW - matching results shared to client
//
// ENGAGEMENT_ACTIVE - accepted match

export enum ERoleStatus {
    Draft = 'DRAFT',
    InReview = 'IN_REVIEW',
    Submitted = 'SUBMITTED',
    MatchingInProgress = 'MATCHING_IN_PROGRESS',
    MatchingFinished = 'MATCHING_FINISHED',
    EngagementActive = 'ENGAGEMENT_ACTIVE',
    EngagementPaused = 'ENGAGEMENT_PAUSED',
    EngagementPending = 'ENGAGEMENT_PENDING',
    Cancelled = 'CANCELLED',
    Closed = 'CLOSED'
}

export const optionsRoleStatus = Object.entries(ERoleStatus).map(([key, value]) => ({name: key, value, id: value}));

export interface IIgnoreFreelancer {
    created: string;
    email: string;
    firstName: string;
    id: number;
    lastName: string;
    phone: string;
    profileId: number;
}
