import * as React from 'react';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {Box, Typography} from '@mui/material';

import {Button, Checkbox, Input, InputAdornment, Pagination, Radio, Select} from '../../components';

const UIToolboxPage: React.FC = () => (
    <Box sx={{p: 8}}>

        <Typography variant="h1">h1</Typography>
        <Typography variant="h2">h2</Typography>
        <Typography variant="h3">h3</Typography>
        <Typography variant="h4">h4</Typography>
        <Typography variant="h5">h5</Typography>
        <Typography variant="h6">h6</Typography>
        <Typography variant="subtitle1">subtitle1</Typography>
        <Typography variant="subtitle2">subtitle2</Typography>
        <Typography variant="body1">body1</Typography>
        <Typography variant="body2">body2</Typography>
        <Typography variant="caption">caption</Typography> &nbsp;
        <Typography variant="button">button</Typography> &nbsp;
        <Typography variant="overline">overline</Typography> &nbsp;
        <br/>
        <br/>
        <Button variant="text">text</Button>&nbsp;
        <Button variant="contained">contained</Button>&nbsp;
        <Button variant="outlined">outlined</Button>&nbsp;
        <Button href="https://google.com" variant="outlined">href</Button>
        <br/>
        <br/>
        <Button variant="text" color="secondary">text</Button>&nbsp;
        <Button variant="contained" color="secondary">contained</Button>&nbsp;
        <Button variant="outlined" color="secondary">outlined</Button>
        <br/>
        <br/>
        <Button variant="text" color="success">text</Button>&nbsp;
        <Button variant="contained" color="success">contained</Button>&nbsp;
        <Button variant="outlined" color="success">outlined</Button>
        <br/>
        <br/>
        <Button variant="text" color="error">text</Button>&nbsp;
        <Button variant="contained" color="error">contained</Button>&nbsp;
        <Button variant="outlined" color="error">outlined</Button>
        <br/>
        <br/>
        <Button variant="text" color="info">text</Button>&nbsp;
        <Button variant="contained" color="info">contained</Button>&nbsp;
        <Button variant="outlined" color="info">outlined</Button>
        <br/>
        <br/>
        <Button variant="text" color="warning">text</Button>&nbsp;
        <Button variant="contained" color="warning">contained</Button>&nbsp;
        <Button variant="outlined" color="warning">outlined</Button>
        <br/>
        <br/>

        <hr/>

        <br/>
        <br/>
        <Input label="Outlined input" placeholder="Input" type="text" fullWidth disabled/> &nbsp;
        <Input label="Outlined input" placeholder="Input" type="text" fullWidth/> &nbsp;
        <Input label="Outlined input" placeholder="Text area" type="text" multiline rows={4}/> &nbsp;

        <br/>
        <br/>

        <InputAdornment
            label="Input Adornment just icon"
            type="text"
            icon={<Visibility/>}
            position="start"
        />
        <InputAdornment
            label="Input Adornment just icon"
            type="text"
            icon={<VisibilityOff/>}
            position="end"
        />
        <br/>
        <br/>

        <InputAdornment
            label="Input Adornment with button"
            type="text"
            icon={<Visibility/>}
            position="start"
            onIconClick={() => console.log('click')}
        />
        <InputAdornment
            label="Input Adornment with button"
            type="text"
            icon={<VisibilityOff/>}
            position="end"
            onIconClick={() => console.log('click')}
        />
        <br/>
        <br/>

        <hr/>

        <br/>
        <br/>

        <Checkbox name="checkbox" value="chkbx-val-1" label="Checkbox" color="error" disabled/>
        <Checkbox name="checkbox" value="chkbx-val-2" label="Checkbox" color="warning"/>
        <Checkbox name="checkbox" value="chkbx-val-3" label="Checkbox" color="success"/>
        <Checkbox name="checkbox" value="chkbx-val-4" label="Checkbox" color="secondary"/>
        <Checkbox name="checkbox" value="chkbx-val-5" label="Checkbox" color="primary"/>

        <br/>

        <Radio name="radio" value="radio-val-1" label="Radio" color="error" disabled/>
        <Radio name="radio" value="radio-val-2" label="Radio" color="warning"/>
        <Radio name="radio" value="radio-val-3" label="Radio" color="success"/>
        <Radio name="radio" value="radio-val-4" label="Radio" color="secondary"/>
        <Radio name="radio" value="radio-val-5" label="Radio" color="primary"/>

        <br/>
        <br/>

        <hr/>

        <br/>
        <br/>

        <Select
            label="Select label"
            variant="standard"
            listItems={[{value: '1', text: 'first'}, {value: '2', text: 'second'}]}
            helperText="has some error"
            onChange={e => console.log(e)}
        />

        <br/>
        <br/>
        <hr/>
        <br/>
        <br/>

        <Pagination count={10}/>

        <br/>
        <br/>
    </Box>
);

export default UIToolboxPage;
