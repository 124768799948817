import React from 'react';
import {Datagrid, DateField, List, ShowButton, TextField} from 'react-admin';

import {ColumnLabeled, PaginationCoreUI, StatusEngagementBadge} from '../../components';
import {styledDatagrid, styledList, styledListShowButton} from '../../styles';

import {ListActions} from './components/ListActions';
import {defaultFiltersValues} from './components/ListFilterForm';
import RoleNameField from './components/RoleNameField';

export const EngagementList = () => (
    <List
        actions={<ListActions />}
        filterDefaultValues={defaultFiltersValues}
        pagination={<PaginationCoreUI />}
        sort={{field: 'startDate', order: 'DESC'}}
        sx={styledList}
    >
        <Datagrid
            bulkActionButtons={false}
            rowClick="show"
            sx={{
                ...styledDatagrid,
                '& .MuiTableCell-root:nth-of-type(1)': {
                    maxWidth: '30%',
                    width: '30%'
                },
            }}
        >
            <ColumnLabeled source="Role name">
                <RoleNameField/>
            </ColumnLabeled>
            <TextField label="Company name" source="clientCompanyName" />
            <TextField label="Freelancer name" source="freelancerName" />
            <StatusEngagementBadge source="status" />
            <DateField source="startDate"/>
            <TextField source="budgetType" sortable={false}/>

            <ColumnLabeled source="actions" sortable={false}>
                <ShowButton label="" sx={styledListShowButton} />
            </ColumnLabeled>
        </Datagrid>
    </List>
);
