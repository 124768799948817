import {BASE_HEIGHT} from '../../theme';

export const styledCheckbox = {
    '& .MuiCheckbox-root': {
        backgroundColor: 'transparent !important',
        minWidth: 'auto !important',
        padding: '5px !important',
    },
    marginBottom: '0 ',
};

export const styledLabel = {
    '& .RaLabeled-label': {
        color: 'rgba(44, 56, 74, 0.95)',
        fontSize: '1rem',
    }
};

export const styledLabelShow = {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '12px',
    marginBottom: '0.2em',
    marginTop: '4px',
};

export const styledInput = {
    '& input': {
        maxHeight: BASE_HEIGHT,
        padding: '8.5px 14px',
    },
};

export const styledInputInline = {
    '& input': {
        maxHeight: BASE_HEIGHT,
        padding: '8.5px 14px',
    },
    marginBottom: '0',
};

export const styledResizeableTextArea = {
    ...styledInput,
    '& textarea': {
        resize: 'vertical'
    }
};

export const styledSelect = {
    '& .MuiOutlinedInput-root': {
        height: BASE_HEIGHT,
    },
    '& .MuiSelect-select': {
        padding: '8.5px 14px',
    },
    '& .MuiSelect-multiple:has(.RaSelectArrayInput-chips)': {
        padding: '14px'
    }
};

export const styledSelectInline = {
    ...styledSelect,
    '& label span span': {display: 'none'},
    marginBottom: '0'
};

export const styledAutocompleteInline = {
    '& .RaAutocompleteInput-textField': {
        marginBottom: '0 !important',
    },
    '&:not(.Mui-focused) .MuiAutocomplete-inputFocused': {
        height: '0 !important',
    },
    '& .MuiAutocomplete-inputFocused': {
        padding: '0 !important',
    },
    '& .MuiInputBase-formControl': {
        // paddingTop: '12px !important',
        // paddingBottom: '12px !important',
        minHeight: BASE_HEIGHT + ' !important'
    },
    '& .MuiInputBase-adornedStart .MuiChip-filled': {
        padding: '0 !important',
        fontSize: '0.8125rem !important',
        backgroundColor: 'rgba(0, 0, 0, 0.08) !important',
        borderRadius: '16px !important'
    },
    '& .MuiAutocomplete-endAdornment': {
        top: 0,
    },
    '& .MuiAutocomplete-endAdornment .MuiButtonBase-root': {
        backgroundColor: 'transparent !important',
        padding: '0 !important',
        minWidth: 'auto !important',
    },
    '& .MuiOutlinedInput-root': {
        paddingTop: '4px',
        paddingBottom: 0,
    }
};

export const styledFileField = {
    '& .RaFileInput-dropZone': {
        background: '#f0f4f7',
    }
};

export const styledButton ={
    borderRadius: '0.375rem',
    padding: '5px 15px',
    fontSize: '16px',
    lineHeight: '1.75 !important',
    minWidth: '64px',
    textTransform: 'none',
    '& svg': {
        fontSize: '18px',
    },
    '&:not(.MuiChip-root), &:not(.MuiButton-sizeSmall)': {
        height: BASE_HEIGHT,
    }
};

export const styledDeleteButton = {
    ...styledButton,
    color: '#cc1414',
    border: 'solid 1px',
    '&:hover': {
        backgroundColor: '#f25f5f',
        borderColor: '#f14b7d',
        color: '#000015',
    }
};

export const styledIconButton = {
    minWidth: 'unset',
    color: 'rgba(44, 56, 74, 0.95)',
    backgroundColor: '#e9e9e9',
    '& svg': {
        fontSize: '16px'
    }
};

export const styledPrimaryButton = {
    ...styledButton,
    color: 'white',
};

export const styledLabelSearchForm = {
    fontSize: '18px !important',
    fontWeight: 500,
};
