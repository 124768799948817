import React from 'react';
import {Datagrid, DateField, List, TextField} from 'react-admin';

import {BooleanIcon, EllipsisText, EventTypeBadge, PaginationCoreUI} from '../../components';
import {styledDatagrid, styledList} from '../../styles';

import {BodyField, ListActions} from './components';
import {defaultNotificationFiltersValues} from './filters';

export const NotificationList = () => {
    return (
        <List
            actions={<ListActions />}
            filterDefaultValues={defaultNotificationFiltersValues}
            pagination={<PaginationCoreUI />}
            sort={{field: 'created', order: 'DESC'}}
            sx={styledList}
        >
            <Datagrid sx={{...styledDatagrid, '& .MuiTableCell-paddingCheckbox': {display: 'none'}}}>
                {/* <TextField source="id" /> */}
                <EllipsisText source="title" label="Subject" />
                <BodyField source="body" />
                <EventTypeBadge source="eventType" />
                <TextField source="technology" label="Communication area" />
                <BooleanIcon source="read" />
                <EllipsisText source="recipient" max={40}></EllipsisText>
                <DateField source="created" />
                <DateField source="sent" />
            </Datagrid>
        </List>
    );
};
