import React from 'react';

import {CustomTagsHelper} from '../../../components';
import {RECOMMENDATIONS_VARAIBLE} from '../../../models';

const RecommendationVariables = () => {
    return (
        <CustomTagsHelper
            customTags={RECOMMENDATIONS_VARAIBLE}
            tooltipTitle="For recommendation you can use following custom tags:"
        />
    );
};

export default React.memo(RecommendationVariables);
