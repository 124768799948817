import * as React from 'react';
import {FC, memo} from 'react';
import get from 'lodash/get';
import {useRecordContext, useTranslate} from 'ra-core';
import {FieldProps} from 'react-admin';

import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import {Link, LinkProps} from '@mui/material';
import Typography from '@mui/material/Typography';

import theme from '../../../theme';


export const EmailField: FC<EmailFieldProps> = memo(props => {
    const {className, source, emptyText} = props;
    const record = useRecordContext(props);
    const value = get(record, source || '');
    const translate = useTranslate();

    if (value == null) {
        return emptyText ? (
            <Typography
                component="span"
                variant="body2"
                className={className}
            >
                {emptyText && translate(emptyText, {_: emptyText})}
            </Typography>
        ) : null;
    }

    return (
        <span title={record.allowEmails ? 'value' : 'Email notifications disabled'}>
            {!record.allowEmails && (
                <UnsubscribeIcon
                    sx={{
                        color: theme.palette.error.light,
                        marginRight: 1,
                        verticalAlign: 'middle'
                    }}
                />
            )}
            <Link
                className={className}
                href={`mailto:${value}`}
                onClick={stopPropagation}
                variant="body2"
            >
                {value}
            </Link>
        </span>

    );
});

// EmailField.propTypes = fieldPropTypes;
EmailField.displayName = 'EmailField';

export interface EmailFieldProps extends FieldProps, Omit<LinkProps, 'textAlign'> {}

// useful to prevent click bubbling in a Datagrid with rowClick
const stopPropagation = (e: React.SyntheticEvent) => e.stopPropagation();
