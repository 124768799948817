import React from 'react';
import {Datagrid, EditButton, List, TextField, useDataProvider} from 'react-admin';
import {useQuery} from 'react-query';

import {BooleanIcon, ColumnLabeled, EventSourceBadge, EventTypeBadge, PaginationCoreUI} from '../../components';
import {IEventType} from '../../models';
import {styledDatagrid, styledList, styledListShowButton} from '../../styles';

import {defaultFiltersValues} from './components/Filters/FilterForm';
import {ListActions} from './components/List/ListActions';


export const NotificationsRuleList = () => {
    const dataProvider = useDataProvider();

    // we need eventTypes inside a Filter and inside a Table
    const {data: eventTypes} = useQuery(
        ['notifications/event-types'],
        () => dataProvider.get('notifications', {id: 'event-types', area: ''}),
        {
            staleTime: 15 * 60 * 1000,
            select: (data: {data: {eventTypes: IEventType[]}}) => data.data.eventTypes
        }
    );

    return (
        <List
            actions={<ListActions />}
            filterDefaultValues={{...defaultFiltersValues}}
            pagination={<PaginationCoreUI />}
            sort={{field: 'id', order: 'DESC'}}
            sx={styledList}
        >
            <Datagrid rowClick="edit" sx={{
                ...styledDatagrid,
                '& .MuiTableCell-paddingCheckbox': {display: 'none'},
            }}>
                <TextField source="name"/>
                <EventTypeBadge source="eventType"/>
                <EventSourceBadge eventTypes={eventTypes} source="eventSource" sortable={false} />
                <BooleanIcon source="immediate" sortable={false} />
                <BooleanIcon source="delayed" sortable={false} />
                <ColumnLabeled source="actions" sortable={false}>
                    <EditButton label="" sx={styledListShowButton} />
                </ColumnLabeled>
            </Datagrid>
        </List>
    );
};
