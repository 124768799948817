import React, {useCallback, useEffect, useState} from 'react';
import {undoableEventEmitter, useDelete, useNotify, useRecordContext} from 'react-admin';

import {Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material';

import {EAttachmentType, IApplication, ICaseStudy, ICertificate, IReference} from '../../../models';

import {DocumentRow} from './DocumentRow';

type TDocument = ICertificate | ICaseStudy | IReference;

interface Props {
    onEdit: (item: ICertificate | ICaseStudy | IReference) => void;
}

export const DocumentsList: React.FC<Props> = ({onEdit}) => {
    const [hiddenDocuments, setHiddenDocuments]: any = useState<(TDocument)[]>([]);

    const [deleteOne] = useDelete();
    const record = useRecordContext<IApplication>();
    const notify = useNotify();

    const handleDeleteClick = useCallback((item: TDocument) => () => {
        const showAllDocuments = () => {
            setHiddenDocuments((prevState: TDocument[]) => prevState.filter((ps: TDocument) => ps.id !== item.id));
        };

        setHiddenDocuments((prevState: TDocument[]) => [...prevState, item]);

        undoableEventEmitter.once('end', ({isUndo}) => {
            if (isUndo) {
                showAllDocuments();
            }
        });

        deleteOne(
            `admin/applications/${record.id}/review/documents`,
            {id: item.id, previousData: record},
            {
                mutationMode: 'undoable',
                onSuccess: () => {
                    notify('Document removed', {undoable: true, autoHideDuration: 5000});
                },
                onError: (error: any) => {
                    notify(`Error: ${error.message}`, {type: 'warning'});
                    showAllDocuments();
                },
            },
        );
    }, [deleteOne, notify, record]);

    const handleEditClick = useCallback((item: ICertificate | ICaseStudy | IReference) => () => {
        onEdit(item);
    }, [onEdit]);

    useEffect(() => {
        if (!record) return;

        record.certificates?.forEach(item => item.type = EAttachmentType.certificate);
    }, [record, record?.certificates]);

    useEffect(() => {
        if (!record) return;

        record.references?.forEach(item => item.type = EAttachmentType.reference);
    }, [record, record?.references]);

    useEffect(() => {
        if (!record) return;

        record.caseStudies?.forEach(item => item.type = EAttachmentType.study);
    }, [record, record?.caseStudies]);

    if (!record) return null;

    return (
        <Table size="small" aria-label="a dense table">
            <TableHead>
                <TableRow sx={{'th': {color: 'rgba(44,56,74,.38)'}}}>
                    <TableCell>Notes</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Submitted on</TableCell>
                    <TableCell>Approved by</TableCell>
                    <TableCell>Approved on</TableCell>
                    <TableCell>Last contact date</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {record.certificates?.map((certificate) => (
                    !hiddenDocuments.find((hd: TDocument) => hd.id === certificate.id) &&
                    <DocumentRow
                        applicationId={record.id}
                        item={certificate}
                        key={certificate.id}
                        type="Certificate"
                        onDelete={handleDeleteClick(certificate)}
                        onEdit={handleEditClick(certificate)}
                    />
                ))}
                {record.references?.map((reference) => (
                    !hiddenDocuments.find((hd: TDocument) => hd.id === reference.id) &&
                    <DocumentRow
                        applicationId={record.id}
                        item={reference}
                        key={reference.id}
                        type="Reference"
                        onDelete={handleDeleteClick(reference)}
                        onEdit={handleEditClick(reference)}
                    />
                ))}
                {record.caseStudies?.map((caseStudy) => (
                    !hiddenDocuments.find((hd: TDocument) => hd.id === caseStudy.id) &&
                    <DocumentRow
                        applicationId={record.id}
                        item={caseStudy}
                        key={caseStudy.id}
                        type="Case Study"
                        onDelete={handleDeleteClick(caseStudy)}
                        onEdit={handleEditClick(caseStudy)}
                    />
                ))}
            </TableBody>
        </Table>
    );
};
