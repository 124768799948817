import React from 'react';
import {Edit, HttpError, required, SelectInput, SimpleForm, TextInput, useNotify, useRecordContext} from 'react-admin';

import {EditFormToolbarCoreUI} from '../../components';
import {moderationStatuses} from '../../models';
import {styledInput, styledSelect} from '../../styles';
import {getFieldErrors} from '../../utils';

const ToolTitle = () => {
    const record = useRecordContext();

    return <span>Tool {record ? `"${record.name}"` : ''}</span>;
};

export const ToolEdit = () => {
    const notify = useNotify();
    const onError = (error: unknown, variables: any, context: unknown) => {
        const errors = getFieldErrors(error);

        if (errors) {
            errors.forEach(error => notify((error as any).message, {type: 'warning'}));
        } else {
            notify((error as HttpError).message, {type: 'warning'});
        }
    };

    return (
        <Edit title={<ToolTitle />} mutationMode="pessimistic" mutationOptions={{onError}}>
            <SimpleForm toolbar={<EditFormToolbarCoreUI />}>
                {/* <TextInput source="id" disabled sx={styledInput} /> */}
                <TextInput source="name" validate={required()} sx={styledInput} />
                <SelectInput source="moderationStatus" choices={moderationStatuses} validate={required()} optionValue="name" sx={styledSelect} />
            </SimpleForm>
        </Edit>
    );
};
