import React, {FC} from 'react';

import {Box, styled, Typography} from '@mui/material';

import {ICaseStudy} from '../../../models';
import theme from '../../../theme';

import CaseStudyItem from './CaseStudyItem';

const PAGE_SIZE = 2;

interface IFreelancerCaseStudies {
    items: ICaseStudy[];
}

const Wrp = styled('div')``;

const FreelancerCaseStudies: FC<IFreelancerCaseStudies> = ({items}) => {
    // show only 2 items + load more button
    // by pressing load more - show all items
    const pagenabledItems = (items.length <= PAGE_SIZE) ? items : items.slice(0, 2);

    return (
        <Wrp>
            <Typography
                sx={{
                    mb: '24px',
                    fontWeight: 600,
                    fontSize: '14px',
                    lineHeight: '21px'
                }}
            >
                Case studies
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '16px',
                    width: '100%',
                    minWidth: 0,
                    transition: 'width .3s',
                    padding: 0,
                    [theme.breakpoints.up('md')]: {
                        gap: '24px'
                    }
                }}
            >
                {
                    pagenabledItems.map((caseStudy, index) => (
                        <CaseStudyItem
                            key={index}
                            data={caseStudy}
                            isMoreBtn={false}
                            sx={{
                                [theme.breakpoints.up('sm')]: {
                                    maxWidth: '324px'
                                }
                            }}
                        />
                    ))
                }
            </Box>
            {/* {
                !isLoadMore && items.length > PAGE_SIZE && (
                    <Box
                        sx={{
                            mt: '16px',
                            [theme.breakpoints.up('md')]: {
                                mt: '24px'
                            }
                        }}
                    >
                        <BlackBorderButton
                            size="small"
                            onClick={() => setIsLoadMore(true)}
                        >
                            Load more case studies
                        </BlackBorderButton>
                    </Box>
                )
            } */}

        </Wrp>
    );
};

export default React.memo(FreelancerCaseStudies);
