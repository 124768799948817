import React, {useEffect} from 'react';
import {ImageInput, useDataProvider, useGetIdentity} from 'react-admin';
import {useFormContext, useWatch} from 'react-hook-form';
import {useParams} from 'react-router-dom';

import {Avatar, Box, Typography} from '@mui/material';

import {RemoveButtonCoreUI} from '../../../../components';
import {MAX_FILE_SIZE_MB} from '../../../../constants';
import {styledFileField, styledLabel} from '../../../../styles';

const formatPhoto = (value: any) => {
    if (!value || typeof value === 'string') {
        return {url: value};
    } else {
        return value;
    }
};

export const PhotoField = (props: any) => {
    const dataProvider = useDataProvider();
    const photo = useWatch({name: 'photo'});
    const {id} = useParams();

    const {identity} = useGetIdentity();
    const isCurrentUser = identity?.id === id;

    const {setValue} = useFormContext();

    useEffect(() => {
        const uploadPhoto = async () => {
            const response = await dataProvider.postFormData(
                'admin/users/files',
                {data: {file: photo.rawFile}},
            );

            setValue('photo', response.json.url);
        };

        if (photo?.rawFile) {
            uploadPhoto();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [photo?.src]);

    return (
        <>
            {isCurrentUser && (
                <ImageInput
                    accept="image/*"
                    maxSize={MAX_FILE_SIZE_MB}
                    format={ formatPhoto }
                    label="Avatar"
                    source="photo"
                    sx={{
                        ...styledFileField,
                        '& .RaLabeled-label': {
                            fontSize: '1rem',
                        }
                    }}
                >
                    {/* <ImageField source="src" /> */}
                </ImageInput>
            )}

            {
                <Box sx={{
                    position: 'relative',
                    width: '120px',
                    mb: 3
                }}>
                    {!isCurrentUser && <Typography paragraph sx={styledLabel}>Avatar</Typography>}
                    <Avatar src={photo && !photo.src ? photo : undefined} sx={{width: 100, height: 100}}/>
                    {photo && <RemoveButtonCoreUI source="photo"/>}
                </Box>
            }
        </>
    );
};
