import React from 'react';
import {Show, useRecordContext} from 'react-admin';

import {IClientRole} from '../../models';

import UnwantedList from './components/Matching/IgnoreList/UnwantedList';
import AllMatchingList from './components/Matching/MatchingList';
import ResultList from './components/Matching/ResultList/ResultList';
import {ClientInfo, GlobalSettingsForm, RoleInfo} from './components';

const Title = () => {
    const record = useRecordContext<IClientRole>();
    const projectName = record?.projectName?.length > 35 ? record?.projectName.substring(0, 35) + '...' : record?.projectName;

    return <span>Matching Result List: [{record?.status}] {projectName || ''}</span>;
};

const Lists = () => {
    const record = useRecordContext<IClientRole>();

    return (
        <>
            {!!record?.matchings?.length && (
                <>
                    <ResultList/>
                    <UnwantedList/>

                    <AllMatchingList />
                </>
            )}
        </>
    );
};

export const RoleShow = () => {
    return (
        <Show title={<Title />}>
            <ClientInfo />
            <RoleInfo />

            <Lists/>

            <GlobalSettingsForm />
        </Show>
    );
};
