import React from 'react';
import {useDataProvider} from 'react-admin';
import {useQuery} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';

import WestIcon from '@mui/icons-material/West';
import {Box, IconButton} from '@mui/material';

import theme from '../../theme';
import LoadingMask from '../LoadingMask';

import FreelancerAboutInfo from './components/FreelancerAboutInfo';
import FreelancerCaseStudies from './components/FreelancerCaseStudies';
import FreelancerCertifications from './components/FreelancerCertifications';
import FreelancerFitsBudget from './components/FreelancerFitsBudget';
import FreelancerMatchesList from './components/FreelancerMatchesList';
import FreelancerPersonalRecommendation from './components/FreelancerPersonalRecommendation';
import FreelancerRateInfo from './components/FreelancerRateInfo';
import FreelancerTopInfo from './components/FreelancerTopInfo';
import FreelancerWhyThisMatch from './components/FreelancerWhyThisMatch';
import {WrpClient} from './components/WrpClient';
import {Item} from './components/WrpFreelancer';

const PublicProfileClient= () => {
    const {matchId} = useParams();
    const navigate = useNavigate();
    const dataProvider = useDataProvider();

    const {data} = useQuery(['matches', matchId || ''],
        () => dataProvider.get(`matches/${matchId}`, {area: 'admin'}),
        {
            staleTime: 30 * 60 * 1000,   // cache this request for 30 minutes
            select: (data: {data: any}) => data.data
        }
    );

    if (!data) {
        return <LoadingMask/>;
    }

    return (
        <Box
            sx={{
                p: 4,
                margin: '0 auto',
                maxWidth: 1652,
            }}
        >
            <WrpClient hasRecommendation={!!data?.recommendation}>
                <IconButton
                    disabled={true}
                    sx={{
                        gridArea: 'backButton',
                        alignSelf: 'flex-start',
                        justifySelf: 'flex-start',
                        opacity: 0,
                        [theme.breakpoints.up('lg')]: {
                            mb: '8px'
                        }
                    }}
                    onClick={() => navigate(-1)}
                >
                    <WestIcon/>
                </IconButton>
                <Item gridArea="nameBox">
                    {data && (
                        <FreelancerTopInfo
                            data={{
                                avatar: data.dynamicProfile.avatar,
                                businessModels: data.dynamicProfile.businessModels,
                                country: data.dynamicProfile.country || '',
                                isAvailableForNewProject: !!data.dynamicProfile.isAvailableForNewProject,
                                isOpenToHireRole: !!data.dynamicProfile.isOpenToHireRole,
                                timezones: data.dynamicProfile.timezones,
                                firstName: data.dynamicProfile.firstName,
                                lastName: data.dynamicProfile.lastName,
                                yearsOfExperience: data.dynamicProfile.yearsOfExperience,
                            }}
                        >

                        </FreelancerTopInfo>
                    )}
                </Item>
                <Item
                    gridArea="rateBox"
                    backgroundColor={theme.palette.purple.main}
                    widthXl="293"
                >
                    <FreelancerRateInfo
                        rate={data.dynamicProfile.rate}
                        workTypes={[...data.dynamicProfile.workTypes.common, ...data.dynamicProfile.workTypes.additional]}
                    />
                </Item>
                <Item
                    gridArea="fitBudgetBox"
                    boxShadow="0 0 50px rgba(126, 95, 255, 0.2)"
                    overflowVisible
                    widthXl="293"
                >
                    <FreelancerFitsBudget
                        isClient
                        firstName={data.dynamicProfile.firstName}
                        lastName={data.dynamicProfile.lastName}
                        ratesMatch={data.dynamicProfile.ratesMatch}
                    />
                </Item>
                <Item
                    gridArea="matchReasonBox"
                    borderColor={theme.palette.green.dark}
                    boxShadow="0px 0px 50px rgba(103, 221, 66, 0.15)"
                >
                    <FreelancerWhyThisMatch data={data.dynamicProfile}/>
                </Item>

                {
                    data.recommendation && (
                        <Item
                            gridArea="personalRecommendationBox"
                            boxShadow="0 24px 50px rgba(126, 95, 255, 0.12)"
                            backgroundImage="linear-gradient(259.97deg, rgba(126, 95, 255, 0.2) 1.64%, rgba(126, 95, 255, 0) 49.75%, rgba(255, 255, 255, 0.5))"
                            widthXl="540"
                        >
                            <FreelancerPersonalRecommendation data={data.recommendation}/>
                        </Item>
                    )
                }

                <Item gridArea="aboutBox" widthXl="540">
                    <FreelancerAboutInfo
                        bio={data.dynamicProfile.bio || ''}
                        brands={data.dynamicProfile.brands}
                    />
                </Item>
                <Item gridArea="matchesBox" widthXl="540">
                    <FreelancerMatchesList data={data.dynamicProfile}/>
                </Item>
                <Box
                    sx={{
                        gridArea: 'certificationsAndCaseStudiesBox',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                        [theme.breakpoints.up(1400)]: {
                            gap: '24px'
                        }
                    }}
                >
                    <Item gridArea="caseStudiesBox">
                        <FreelancerCaseStudies
                            items={data.dynamicProfile.caseStudies}
                        />
                    </Item>
                    <Item gridArea="certificationsBox">
                        <FreelancerCertifications
                            items={data.dynamicProfile.certificates}
                        />
                    </Item>
                </Box>
            </WrpClient>
        </Box>
    );
};

export default React.memo(PublicProfileClient);
