import React from 'react';

import {Stack, TableCell, TableRow, Typography} from '@mui/material';

import {IMatcherProperty} from '../../models';
import NumberInputCoreUI from '../NumberInputCoreUI';

interface Props {
    item: IMatcherProperty;
    onChange: (item: IMatcherProperty, value: number, prop: 'isCutting' | 'multiplier' | 'weight') => void;
}

const MultiplierRow = ({item, onChange}: Props) => {
    const handleChange = (e: KeyboardEvent) => {
        onChange(item, +(e.target as HTMLInputElement).value, 'multiplier');
    };

    return (
        <TableRow>
            <TableCell>{item.property}</TableCell>
            <TableCell>
                <Stack direction="row" spacing={1} alignItems="center">
                    <NumberInputCoreUI
                        allowNegative={false}
                        decimalSeparator="."
                        value={item.multiplier}
                        onChange={handleChange}
                    />
                    <Typography>%</Typography>
                </Stack>
            </TableCell>
        </TableRow>
    );
};

export default React.memo(MultiplierRow);