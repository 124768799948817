import React from 'react';

import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import {Stack} from '@mui/material';

import {SectionTitle} from '../../../../components';
import {formatterNumber} from '../../../../utils';
import {IAdminDashboardLeadAndFunnel} from '../../models';
import {Tile} from '..';

type SectionLeadAndFunnelProps = {
    data: IAdminDashboardLeadAndFunnel;
};

const SectionLeadAndFunnel: React.FC<SectionLeadAndFunnelProps> = ({data}) => {
    return (
        <Stack spacing={2}>
            <SectionTitle>Lead & Funnel</SectionTitle>
            <Stack direction="row" spacing={1}>
                <Tile
                    icon={<MedicalServicesOutlinedIcon/>}
                    title="Lead (client creates a role)"
                    value={formatterNumber.format(data.lead)}
                />
                <Tile
                    icon={<FavoriteBorderOutlinedIcon/>}
                    title="Roles matched"
                    value={formatterNumber.format(data.rolesMatched)}
                />
                <Tile
                    icon={<CheckCircleOutlineOutlinedIcon/>}
                    title="Roles closed"
                    value={formatterNumber.format(data.rolesClosed)}
                />
                <Tile
                    icon={<AssignmentOutlinedIcon/>}
                    title="SOW started"
                    value={formatterNumber.format(data.startedSOWs)}
                />
            </Stack>
        </Stack>
    );
};

export default React.memo(SectionLeadAndFunnel);
