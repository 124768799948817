export enum EPaymentMethod {
    ACH = 'ACH',
    CreditCard = 'CARD',
    ManualInvoicing = 'MANUAL_INVOICING',
    SEPA = 'SEPA',
}

export enum EPaymentStatus {
    REFUNDED = 'REFUNDED',
    FAILED = 'FAILED',
    NEW = 'NEW',
    PENDING = 'PENDING',
    SUCCESS = 'SUCCESS',
}

export enum EPaymentStatusDisplay {
    REFUNDED = 'Refunded',
    FAILED = 'Failed',
    NEW = 'Pending',        // GC-1024 should be shown as PENDING instead of new
    PENDING = 'Pending',
    SUCCESS = 'Success',
}

export enum EPayoutStatusDisplay {
    REFUNDED = 'Refunded',
    FAILED = 'Failed',
    NEW = 'Pending',
    PENDING = 'Processing',         // GC-1136 should be shown as Processing instead of PENDING
    SUCCESS = 'Success',
}

export enum EPaymentMethodStatus {
    PENDING = 'PENDING',
    FAILED = 'FAILED',
    VERIFIED = 'VERIFIED',
}

export interface IPaymentPreview {
    id: number;
    isPrimary: boolean;
    last4: string;
    name?: string;
    method: EPaymentMethod;
    redirectUrl?: string;       // 3D secury
    status: EPaymentMethodStatus;
}

export interface IPayment {
    amount: number;
    clientCompanyLogo: string;
    clientCompanyName: string;
    clientId: string;
    createdAt: string;
    description: string;
    freelancerAvatar: string;
    freelancerId: string;
    freelancerName: string;
    id: number;
    invoiceFile: string;
    invoiceName: string;
    payment: ICharge;
    payout: IPayout;
    rate: number;
    roleId: string;
    roleName: string;
    workType: string;
    manualInvoicing: boolean;
}

export interface ICharge {
    amount?: number;
    concludedAt: string;
    method: IPaymentPreview;
    problem: string;
    serviceFee: number;
    status: EPaymentStatus;
}

export interface IPayout {
    amount: number;
    concludedAt: string;
    gcFee: number;
    problem: string;
    status: EPaymentStatus;
}
