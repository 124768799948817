import React from 'react';

import BackupRounded from '@mui/icons-material/BackupRounded';
import CalendarTodayRounded from '@mui/icons-material/CalendarTodayRounded';
import FolderCopyRounded from '@mui/icons-material/FolderCopyRounded';
import HubRounded from '@mui/icons-material/HubRounded';
import {Stack, styled, Typography} from '@mui/material';

import theme from '../../theme';

interface IAsideMenu {
    collapsed?: boolean;
}

const Aside = styled('aside')(
    ({collapsed}: IAsideMenu) => (`
        width: ${collapsed ? '50px' : '256px'};
        overflow-y: auto;
        height: 100vh;
        background-color: ${theme.palette.white.main};
        color: ${theme.palette.labelColor.main};
        padding-top: 32px;
        padding-bottom: 32px; 
        padding-left: ${collapsed ? '6px' : '16px'};
        padding-right: ${collapsed ? '6px' : '16px'};
        transition: width .3s, padding .3s;
    `)
);

const AsideItem = styled('div')(
    ({collapsed}: IAsideMenu) => `
        display: flex;
        align-items: center;
        gap: 12px;
        padding: ${collapsed ? '16px 8px' : '16px 24px'};
        border-radius: 6px;
        background-color: ${theme.palette.white.main};
        color: ${theme.palette.labelColor.main};
        transition: background-color 0.3s, padding .3s;
        cursor: pointer;
        
        &:hover {
            background-color: ${theme.palette.primary.main};
            color: ${theme.palette.white.main};
        }
    `
);

const AsideMenu = ({collapsed}: IAsideMenu): JSX.Element => {
    return (
        <Aside collapsed={collapsed}>
            <Stack spacing={2} sx={{width: 224}}>
                <AsideItem collapsed={collapsed}>
                    <BackupRounded/>
                    <Typography
                        variant="body1"
                    >
                        Some aside item 1
                    </Typography>
                </AsideItem>
                <AsideItem collapsed={collapsed}>
                    <CalendarTodayRounded/>
                    <Typography
                        variant="body1"
                    >
                        Some aside item 2
                    </Typography>
                </AsideItem>
                <AsideItem collapsed={collapsed}>
                    <FolderCopyRounded/>
                    <Typography
                        variant="body1"
                    >
                        Some aside item 3
                    </Typography>
                </AsideItem>
                <AsideItem collapsed={collapsed}>
                    <HubRounded/>
                    <Typography
                        variant="body1"
                    >
                        Some aside item 4
                    </Typography>
                </AsideItem>
            </Stack>
        </Aside>
    );
};

export default AsideMenu;
