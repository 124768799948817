import React, {useMemo} from 'react';
import format from 'date-fns/format';
import capitalize from 'lodash/capitalize';
import {FormProvider, useForm, useWatch} from 'react-hook-form';
import {
    Bar,
    BarChart,
    Text,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';

import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import ShowChartOutlinedIcon from '@mui/icons-material/ShowChartOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import {Box, Stack, Typography} from '@mui/material';

import {RadioCoreUI, SectionTitle} from '../../../../components';
import {formatter, formatterWithoutCents} from '../../../../utils';
import {EDashboardType, IAdminDashboardGMV, IChartItem, ISearchParams} from '../../models';
import {styledLabel} from '../Tile/Tile';
import {Tile} from '..';

import CustomTooltip from './CustomTooltip';
import Legend from './Legend';

export enum EColors {
    Hourly = '#67DD42',
    Retainer = '#3C94F7',
    Fixed = '#7E5FFF',
}

const FONT_SIZE = 12;

const barStyles = {
    barSize: 8,
    radius: 3,
};

type SectionGMVProps = {
    data: IAdminDashboardGMV;
    values: ISearchParams;
    onChange: (values: ISearchParams) => void;
};

const SectionGMV: React.FC<SectionGMVProps> = ({data, values, onChange}) => {
    const methods = useForm<ISearchParams>({defaultValues: values});

    const {
        control,
        // handleSubmit,
        getValues,
        setValue,
        // setError,
    } = methods;

    const {type} = useWatch({control});

    const handleChange = (v: any) => {
        const values = getValues();

        console.log('submit', values);
        onChange(values);
    };

    const renderLabel = (e: any) => {
        const {payload: {value}} = e;

        switch (type) {
            case EDashboardType.Monthly:
                const [month, year] = value.split(' ');

                return (
                    <>
                        <Text {...e} fontSize={FONT_SIZE}>{capitalize(month.substring(0, 3))}</Text>
                        <Text {...e} x={e.x - 8} y={e.y + 18} fontSize={FONT_SIZE}>ˊ</Text>
                        <Text {...e} y={e.y + 18} fontSize={FONT_SIZE}>{year.slice(-2)}</Text>
                    </>
                );
            case EDashboardType.Weekly:
                const data = value.split(' - ');
                let from = '';
                let to = '';

                try {
                    from = format(new Date(data[0]), 'MMM dd ˊyy');
                    to = format(new Date(data[1]), 'MMM dd ˊyy');
                } catch (e) {}

                return (
                    <>
                        <Text {...e} fontSize={10}>{from}</Text>
                        <Text {...e} y={e.y + 15} fontSize={10}>{to}</Text>
                    </>
                );
            default:
                return <Text {...e} fontSize={FONT_SIZE}>{value}</Text>;
        }
    };
    const tickFormatter = (v: string) => formatterWithoutCents.format(Number(v));

    const items: IChartItem[] = useMemo(() =>
        data.chart.map(({label, ...rest}) => ({
            label,
            hourly: Math.round(rest.hourly / 100),
            fixed: Math.round(rest.fixed / 100),
            retainer: Math.round(rest.retainer / 100),
        })).reverse()
    , [data]);

    return (
        <Stack spacing={2}>
            <FormProvider {...methods}>
                <form
                    onChange={handleChange}
                    // onSubmit={handleSubmit(onSubmitHandler)}
                >
                    <Stack direction="row" spacing={2}>
                        <RadioCoreUI
                            color={type === EDashboardType.Weekly ? 'success' : 'primary'}
                            checked={type === EDashboardType.Weekly}
                            label="Weekly"
                            onClick={() => setValue('type', EDashboardType.Weekly)}
                        />

                        <RadioCoreUI
                            color={type === EDashboardType.Monthly ? 'success' : 'primary'}
                            checked={type === EDashboardType.Monthly}
                            label="Monthly"
                            onClick={() => setValue('type', EDashboardType.Monthly)}
                        />
                    </Stack>
                </form>
            </FormProvider>
            <Stack direction="row" spacing={1}>
                <Box
                    sx={{
                        background: 'white',
                        pt: 2,
                        px: 2,
                    }}
                >
                    <SectionTitle>
                        GMV <Typography component="span" sx={{...styledLabel, ml: 1}} variant='caption'>Gross Merchandise Value</Typography>
                    </SectionTitle>
                    <Box sx={{mb: 3}}/>
                    <BarChart
                        width={800}
                        height={200}
                        data={items}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5
                        }}
                    >
                        <XAxis axisLine={false} dataKey="label" interval={type === EDashboardType.Monthly ? 0 : Math.floor(items.length / 13)} tickLine={false} tick={renderLabel} />
                        <YAxis axisLine={false} tickFormatter={tickFormatter} tickLine={false} tick={{fontSize: 14}} />
                        <Tooltip content={<CustomTooltip type={type || EDashboardType.Monthly}/> as any} />

                        <Bar {...barStyles} dataKey="hourly" fill={EColors.Hourly}/>
                        <Bar {...barStyles} dataKey="retainer" fill={EColors.Retainer}/>
                        <Bar {...barStyles} dataKey="fixed" fill={EColors.Fixed}/>
                    </BarChart>
                    <Stack direction="row" spacing={2} sx={{mt: 3}}>
                        <Legend color={EColors.Hourly} label="Hourly rate"/>
                        <Legend color={EColors.Retainer} label="Retainer"/>
                        <Legend color={EColors.Fixed} label="Fixed rate"/>
                    </Stack>
                </Box>
                <Stack spacing={1}>
                    <Tile
                        icon={<AccessAlarmOutlinedIcon/>}
                        title="Hourly total"
                        value={formatter.format(data.totalHourly ? data.totalHourly / 100 : 0)}
                    />
                    <Tile
                        icon={<PaidOutlinedIcon/>}
                        title="Fixed total"
                        value={formatter.format(data.totalFixedPrice ? data.totalFixedPrice / 100 : 0)}
                    />
                </Stack>

                <Stack spacing={1}>
                    <Tile
                        icon={<TextSnippetOutlinedIcon/>}
                        title="Retainer total"
                        value={formatter.format(data.totalRetainer ? data.totalRetainer / 100 : 0)}
                    />
                    <Tile
                        icon={<ShowChartOutlinedIcon/>}
                        helperText="Average weekly GMV of last 12 weeks x 52"
                        title="GMV run rate"
                        value={formatter.format(data.runRate ? data.runRate / 100 : 0)}
                    />
                </Stack>
            </Stack>
        </Stack>
    );
};

export default React.memo(SectionGMV);
