import React, {useCallback, useMemo} from 'react';
import {Reorder, useDragControls} from 'framer-motion';
import {useDataProvider, useNotify, useRecordContext} from 'react-admin';
import {useQueryClient} from 'react-query';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import {Accordion, AccordionDetails, AccordionSummary, Box, Typography} from '@mui/material';

import {IClientRole, IIgnoreFreelancer, IMatchingResult} from '../../../../../models';
import {FreelancerDetails} from '../FreelancerDetails';

const variants = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
    },
    exit: {
        opacity: 0,
    },
    transition: {
        ease: 'easeInOut',
    }
};

interface Props {
    item: IIgnoreFreelancer;
    // onPropose?: (item: IIgnoreFreelancer) => void;
}

export const IgnoreItem: React.FC<Props> = React.memo(({item}) => {
    const record = useRecordContext<IClientRole>();

    const dragControls = useDragControls();
    const [expanded, setExpanded] = React.useState(false);

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const queryClient = useQueryClient();

    // prevent to expand Accordion when user select all text in the input
    const handleExpandChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
        event.stopPropagation();
        const selection = window.getSelection();

        if (!selection?.toString()) {
            setExpanded(isExpanded);
        } else {
            selection.empty();
        }
    };

    // remove from ignore and add to the Result list
    const handleRemove = useCallback(async () => {
        console.log('remove', item);

        try {
            await dataProvider.post(`admin/roles/${record.id}/results`, {data: {results: [{freelancerId: item.id}]}});
            notify('Freelancer was reverted successfully.');
            queryClient.invalidateQueries(`admin/roles/${record?.id}/results`);
        } catch (error: any) {
            notify(`Error: ${error.message || error?.body?.error}`, {type: 'warning'});
        }
    }, [dataProvider, queryClient, item, record, notify]);

    const data = useMemo(() => {
        return {
            freelancerEmail: item.email,
            freelancerId: item.id,
            freelancerName: `${item.firstName} ${item.lastName}`,
        } as IMatchingResult;
    }, [item]);

    return (
        <Reorder.Item
            {...variants}
            dragControls={dragControls}
            dragListener={false}
            key={item.id}
            value={item}
        >
            <Accordion
                expanded={expanded}
                sx={{
                    '& .MuiAccordionSummary-root': {paddingLeft: ''},
                    '& .MuiAccordionSummary-root .badge': {height: '19px'},
                    '& .MuiAccordionSummary-root.Mui-expanded': {minHeight: 'auto'},
                    '& .MuiAccordionSummary-content.Mui-expanded': {margin: '12px 0'},
                }}
                onChange={handleExpandChange}
            >
                <AccordionSummary
                    expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '14px'}} />}
                    aria-controls={'ignore-panel-' + item.id}
                    id={'ignore-freelancer-id-' + item.id}
                    sx={{
                        '& .MuiAccordionSummary-content': {alignItems: 'center'},
                        '& .MuiAccordionSummary-expandIconWrapper': {
                            transform: 'rotate(180deg)',
                        },
                        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                            transform: 'rotate(90deg)',
                        },
                    }}
                >

                    <Typography sx={{flexGrow: 1}}>
                        {item.firstName} {item.lastName}
                        <Typography component="span" sx={{ml: 2, opacity: 0.7}} variant="caption">{item.email}</Typography>
                    </Typography>

                    <Box sx={{width: 15}}/>

                </AccordionSummary>
                <AccordionDetails>
                    <FreelancerDetails
                        expanded={expanded}
                        item={data}
                        isIgnore={true}
                        isResult={false}
                        recommendation={false}
                        rejectionReason={data.rejectionReason}
                        onRemove={handleRemove}
                    />
                </AccordionDetails>
            </Accordion>
        </Reorder.Item>
    );
});
