import React, {useEffect, useMemo} from 'react';
import {AutocompleteArrayInput, CheckboxGroupInput, DateInput, TextInput, useListContext} from 'react-admin';
import {FormProvider, useForm} from 'react-hook-form';

import {Button, Grid, Stack} from '@mui/material';

import {SearchFiltersBottom} from '../../../../components';
import {NO_FILTER} from '../../../../constants';
import {useAsyncSearch, useMeta, useReviewers} from '../../../../hooks';
import {IChannel, ISelectOption, IUser, IVertical, optionsApplicationFullStatus} from '../../../../models';
import {styledAutocompleteInline, styledCheckbox, styledInputInline, styledPrimaryButton} from '../../../../styles';

import {ClearFiltersButton} from './ClearFiltersButton';

const filterDefaultValues = {
    // status: [
    //     EApplicationStatus.Submitted,
    //     EApplicationStatus['In Review'],
    //     EApplicationStatus.Accepted,
    //     EApplicationStatus.Rejected,
    // ]
    status: null
};

export const ApplicationFilterForm = () => {
    const {displayedFilters, filterValues, setFilters} = useListContext();
    const {businessModels: businessModelsData, channels: channelsData, verticals: verticalsData} = useMeta();
    const {reviewers} = useReviewers();
    const {
        isFilterValid: isFilterTools,
        options: optionsTools,
        setFilter: setFilterTools,
        setSelected: setSelectedTools
    } = useAsyncSearch(filterValues.tools, 'meta/tools');
    const {
        isFilterValid: isFilterBrands,
        options: optionsBrands,
        setFilter: setFilterBrands,
        setSelected: setSelectedBrands
    } = useAsyncSearch(filterValues.brands, 'meta/brands');

    const defaultValues = Object.keys(filterValues).length ? filterValues : filterDefaultValues;
    const form = useForm({defaultValues});
    const {
        // formState: {isDirty},
        getValues,
        setValue,
        watch
    } = form;

    const brandsIds = watch('brands');
    const channelIds = watch('channels');
    const subchannels = watch('subchannels');
    const toolsIds = watch('tools');

    const onSubmit = (values: Record<string, string>) => {
        if (Object.keys(values).length > 0) {
            setFilters(values, displayedFilters);
        }
    };

    const optionsAssignee = useMemo(() => {
        return reviewers ? reviewers.map((it: IUser) => ({name: it.firstName, id: it.email})) : undefined;
    }, [reviewers]);

    const optionsBusinessModels = useMemo(() => {
        return businessModelsData ? businessModelsData.map((it: string) => ({name: it, id: it})) : undefined;
    }, [businessModelsData]);

    const optionsChannels = useMemo(() => channelsData ? channelsData.map((it: IChannel) => ({id: it.id, name: it.name})) : undefined, [channelsData]);

    const optionsSubChannels = useMemo(() => {
        if (!channelIds || !channelsData) return;

        const result: ISelectOption[] = [];

        channelsData
            .filter((it) => channelIds.includes(it.id))
            .forEach(it => result.push(...it.subchannels.map(it => ({id: it.id, name: it.name}))));

        return result.length ? result.map((it: ISelectOption) => ({id: it.id, name: it.name})) : undefined;
    }, [channelIds, channelsData]);

    const optionsVerticals = useMemo(() => verticalsData ? verticalsData.map((it: IVertical) => ({id: it.id, name: it.name})) : undefined, [verticalsData]);

    useEffect(() => {
        onSubmit(getValues());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // erase subchannels by clean channel
    useEffect(() => {
        if (!channelIds?.length && subchannels?.length) {
            setValue('subchannels', null);
        }
    }, [channelIds, subchannels, setValue]);

    useEffect(() => {
        setSelectedTools(toolsIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toolsIds]);

    useEffect(() => {
        setSelectedBrands(brandsIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandsIds]);

    return (
        <FormProvider {...form}>
            <form style={{width: '100%'}} onSubmit={form.handleSubmit(onSubmit)}>
                <Grid container spacing={2} alignItems="flex-end">
                    <Grid item xs={3}>
                        <AutocompleteArrayInput
                            choices={optionsApplicationFullStatus}
                            helperText={false}
                            fullWidth
                            label="Application statuses"
                            limitTags={2}
                            key="status"
                            optionText="text"
                            optionValue="value"
                            source="status"
                            sx={{...styledAutocompleteInline}}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <AutocompleteArrayInput
                            choices={optionsAssignee}
                            helperText={false}
                            fullWidth
                            label="Assignee"
                            limitTags={2}
                            key="reviewers"
                            source="reviewers"
                            sx={{...styledAutocompleteInline}}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        {optionsChannels?.length && (   // fix GC-358
                            <AutocompleteArrayInput
                                choices={optionsChannels}
                                helperText={false}
                                fullWidth
                                key="channels"
                                limitTags={2}
                                source="channels"
                                sx={{...styledAutocompleteInline}}
                            />
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        {optionsChannels?.length && (
                            <AutocompleteArrayInput
                                choices={optionsSubChannels}
                                disabled={!optionsSubChannels?.length}
                                helperText={false}
                                fullWidth
                                label="Sub-channels"
                                limitTags={2}
                                key="subchannels"
                                source="subchannels"
                                sx={{...styledAutocompleteInline}}
                            />
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        {optionsVerticals && (
                            <AutocompleteArrayInput
                                choices={optionsVerticals}
                                helperText={false}
                                fullWidth
                                limitTags={2}
                                key="verticals"
                                source="verticals"
                                sx={{...styledAutocompleteInline}}
                            />
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        <AutocompleteArrayInput
                            choices={optionsTools}
                            helperText={false}
                            fullWidth
                            label="Tools"
                            limitTags={2}
                            key="tools"
                            noOptionsText={isFilterTools ? undefined : NO_FILTER}
                            setFilter={setFilterTools}
                            source="tools"
                            sx={{...styledAutocompleteInline}}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <AutocompleteArrayInput
                            choices={optionsBrands}
                            helperText={false}
                            fullWidth
                            label="Brands"
                            limitTags={2}
                            key="brands"
                            noOptionsText={isFilterBrands ? undefined : NO_FILTER}
                            setFilter={setFilterBrands}
                            source="brands"
                            sx={{...styledAutocompleteInline}}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        {optionsBusinessModels && (
                            <AutocompleteArrayInput
                                choices={optionsBusinessModels}
                                helperText={false}
                                fullWidth
                                label="Business model"
                                limitTags={2}
                                key="businessModels"
                                source="businessModels"
                                sx={{...styledAutocompleteInline}}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextInput
                            fullWidth
                            helperText={false}
                            label="Keyword"
                            resettable
                            source="q"
                            sx={{...styledInputInline}}
                        />
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <TextInput
                            fullWidth
                            helperText={false}
                            label="Min rate"
                            resettable
                            source="minRate"
                            type="number"
                            sx={{...styledInputInline}}
                        />
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <TextInput
                            fullWidth
                            helperText={false}
                            label="Max rate"
                            resettable
                            source="maxRate"
                            type="number"
                            sx={{...styledInputInline}}
                        />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Stack direction="row" justifyContent="flex-end" spacing={1}>
                            <CheckboxGroupInput
                                choices={[
                                    {id: 'true', name: 'Available for new projects'},
                                ]}
                                key="isAvailableForNewProject"
                                source="isAvailableForNewProject"
                                sx={{
                                    ...styledCheckbox,
                                    flexDirection: 'row',
                                    alignItems: 'flex-end'
                                }}
                            />
                            <ClearFiltersButton />
                            <Button
                                className="primary"
                                color="primary"
                                disableElevation
                                type="submit"
                                variant="contained"
                                sx={styledPrimaryButton}
                            >
                                Search
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
                <SearchFiltersBottom>

                    <DateInput
                        alwaysOn
                        helperText={false}
                        key="submittedAfter"
                        source="submittedAfter"
                        sx={{...styledInputInline}}
                    />

                    <DateInput
                        alwaysOn
                        helperText={false}
                        key="submittedBefore"
                        source="submittedBefore"
                        sx={{...styledInputInline}}
                    />
                </SearchFiltersBottom>

            </form>
        </FormProvider>
    );
};
