import React, {ReactNode} from 'react';
import clsx, {ClassValue} from 'clsx';

import {TextField, TextFieldProps} from '@mui/material';

type TInputProps = Omit<TextFieldProps, 'error'> & {
    classNames?: ClassValue[];
    error?: string;
    startAdornment?: ReactNode;
    endAdornment?: ReactNode;
};

const Input = ({classNames, error, helperText, margin, startAdornment, endAdornment, variant, ...props}: TInputProps): JSX.Element => {
    return (
        <TextField
            {...props}
            className={clsx(classNames)}
            error={!!error}
            helperText={error || helperText}
            InputLabelProps={{shrink: true}}
            InputProps={{startAdornment, endAdornment}}
            margin={margin || 'dense'}
            variant={variant || 'outlined'}
        />
    );
};

export default Input;
