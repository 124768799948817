import {regex} from 'react-admin';

import {IFormError} from '../models';

export const isValidEmail = (email: string) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const validateURL = regex(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, 'Must be a valid URL');

export const getFieldErrors = (error: any): IFormError[] =>
    (error?.body as any)?.errors;

export const findFieldError = (error: any, fieldName: string): IFormError =>
    (error?.body as any)?.errors?.find((e: IFormError) => e.field === fieldName);
