import React, {useCallback} from 'react';
import {AppBar, Logout, Menu, useDataProvider, useGetIdentity, UserMenu} from 'react-admin';
import {useQuery} from 'react-query';

import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {Avatar, Badge, IconButton, Typography} from '@mui/material';

import {ActionNotifacations, useNotificationContext} from '../../contexts';

const MyUserMenu = () => {
    const {identity} = useGetIdentity();
    const dataProvider = useDataProvider();

    const {data} = useQuery(
        ['users', 'me'],
        () => dataProvider.get('users/me', {area: 'admin'}),
        {
            staleTime: 60 * 60 * 1000,
            select: (response: any) => response.data
        }
    );

    return (
        <UserMenu
            icon={data?.photo ? <Avatar src={data.photo}/> : undefined}
        >
            <Menu.Item to={`/users/${identity?.id}`} primaryText="Profile" leftIcon={<ManageAccountsIcon />}/>
            <Logout />
        </UserMenu>
    );
};

export const AppBarCoreUI = () => {
    const {state: {badge, isOpen}, dispatch} = useNotificationContext();

    const handleOpenNotifications = useCallback(() => {
        dispatch({type: ActionNotifacations.SET_OPEN, payload: !isOpen});
    }, [dispatch, isOpen]);

    return (
        <AppBar
            color="primary"
            elevation={0}
            sx={{
                '& .RaAppBar-toolbar': {
                    height: '64px',
                    '& .RaUserMenu-userButton .MuiSvgIcon-fontSizeMedium': {
                        fontSize: '30px',
                    }
                },
            }}
            userMenu={<MyUserMenu />}
        >
            <Typography
                variant="h6"
                color="inherit"
                id="react-admin-title"
                sx={{flexGrow: 1}}
            />
            <IconButton onClick={handleOpenNotifications}>
                <Badge badgeContent={badge} color="secondary">
                    <NotificationsIcon sx={{fill: 'white'}} />
                </Badge>
            </IconButton>
        </AppBar>
    );
};
