import React, {useEffect} from 'react';
import {useDataProvider} from 'react-admin';
import {useQuery} from 'react-query';

import {INotification} from '../models';

export const PAGE_SIZE = 15;

export enum ActionNotifacations {
    ADD_NOTIFICATIONS = 'ADD_NOTIFICATIONS',
    SET_NOTIFICATION_READ = 'SET_NOTIFICATION_READ',

    SET_BADGE = 'SET_BADGE',
    SET_OPEN = 'SET_OPEN',
    SET_NEXT_PAGE = 'SET_NEXT_PAGE',
}

type State = {
    badge: number;
    isOpen: boolean;
    items: INotification[];
    page: number;
    total: number;
};

type IActionAddNotifications = {
    type: ActionNotifacations.ADD_NOTIFICATIONS;
    payload: {
        items: INotification[];
        total: number;
    };
};

type IActionReadNotification = {
    type: ActionNotifacations.SET_NOTIFICATION_READ;
    payload: INotification;
};

type IActionSetBadge = {
    type: ActionNotifacations.SET_BADGE;
    payload: number;
};

type IActionSetOpen = {
    type: ActionNotifacations.SET_OPEN;
    payload: boolean;
};

type IActionSetNextPage = {
    type: ActionNotifacations.SET_NEXT_PAGE;
};


type Action = IActionAddNotifications | IActionReadNotification | IActionSetBadge | IActionSetOpen | IActionSetNextPage;

type Dispatch = (action: Action) => void;

const initialState: State = {
    badge: 0,
    isOpen: false,
    items: [],
    page: 0,
    total: 0,
};

type NotificationContextProviderProps = { children: React.ReactNode };

const NotificationContext = React.createContext<
{ state: State; dispatch: Dispatch } | undefined>(undefined);

const stateReducer = (state: State, action: Action): State => {
    // console.log(action, state);
    switch (action.type) {
        case ActionNotifacations.ADD_NOTIFICATIONS: {
            const {items, total} = action.payload;

            items.forEach(it => {
                const notification = state.items.find(prev => prev.id === it.id);

                if (notification) {
                    Object.assign(notification, it);
                } else {
                    state.items.push(it);
                }
            });

            return {
                ...state,
                items: [...state.items],
                total
            };
        }

        case ActionNotifacations.SET_NOTIFICATION_READ: {
            const items = state.items.map(it => it.id === action.payload.id ? {...it, read: true} : it);

            return {
                ...state,
                items,
            };
        }

        case ActionNotifacations.SET_BADGE: {
            return {
                ...state,
                badge: action.payload
            };
        }

        case ActionNotifacations.SET_NEXT_PAGE: {
            return {
                ...state,
                page: state.page + 1,
            };
        }

        case ActionNotifacations.SET_OPEN: {
            return {
                ...state,
                isOpen: action.payload,
                items: [],
                page: 0
            };
        }

        default: {
            throw new Error('Unhandled action type');
        }
    }
};

const NotificationContextProvider = ({children}: NotificationContextProviderProps) => {
    const dataProvider = useDataProvider();
    const [state, dispatch] = React.useReducer(stateReducer, initialState);
    const value = {state, dispatch};

    // polling every 10 sec
    /*const {data, total, isLoading, error} = */ useQuery(
        ['notifications/badge'],
        () => dataProvider.get('notifications', {id: 'badge', area: ''}),
        {
            refetchInterval: 10 * 1000,
            select: (data: {data: {badge: number}}) => data.data.badge,
            onSuccess: (badge) => {
                if (badge !== state.badge) {
                    dispatch({type: ActionNotifacations.SET_BADGE, payload: badge});
                }
            }
        }
    );

    const {refetch /* data, total, isLoading, error*/} = useQuery(
        ['notifications'],
        () => dataProvider.getList('notifications', {
            area: '',
            filter: null,
            pagination: {page: state.page + 1, perPage: PAGE_SIZE},
            sort: {field: 'id', order: 'DESC'}
        } as any),
        {
            staleTime: 0,
            enabled: state.isOpen,
            select: (data) => data,
            onSuccess: (response) => {
                const {data, total} = response;

                dispatch({type: ActionNotifacations.ADD_NOTIFICATIONS, payload: {items: data, total: total || 0}});
            }
        }
    );

    useEffect(() => {
        if (!state.page) return;
        refetch();
    }, [state.page, refetch]);

    return (
        <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>
    );
};


const useNotificationContext = () => {
    const context = React.useContext(NotificationContext);

    if (context) {
        return context;
    }

    throw new Error('useStateContext must be used within a StateContextProvider');
};

export {NotificationContextProvider, useNotificationContext};
