import {ICaseStudy} from './case-study.model';
import {ICertificate} from './certificate.model';
import {IRecommendation} from './matcher.model';
import {IBrand} from './verticals-brands.model';
import {EWorkType} from './work.model';

export enum EMatchStatus {
    ACCEPTED = 'ACCEPTED',
    HIDDEN = 'HIDDEN',
    IN_TOUCH = 'IN_TOUCH',       // means the client started conversation
    LIKED = 'LIKED',
    LIKED_IN_TOUCH = 'LIKED_IN_TOUCH',
    PROPOSED = 'PROPOSED',
    REJECTED_BY_CLIENT = 'REJECTED_BY_CLIENT',
    REJECTED_BY_FREELANCER = 'REJECTED_BY_FREELANCER',
    REJECTED_IN_TOUCH = 'REJECTED_IN_TOUCH',
    REJECTED_LIKED = 'REJECTED_LIKED',
    UNLIKED = 'UNLIKED',
}

export interface IDynamicProfiles {
    avatar?: string;
    bio?: string;
    brands: IBrand[];
    businessModels: IMatchMetaStrings;
    caseStudies: ICaseStudy[];
    certificates: ICertificate[];
    channels: IMatchMeta;
    country?: string;
    description: string;
    firstName: string;
    freelancerId: number;
    id: number;
    isAvailableForNewProject?: boolean;
    isOpenToHireRole?: boolean;
    languages?: IMatchMetaStrings;
    lastName: string;
    promo: string[];
    rate: number;
    ratesMatch: boolean;
    status: EMatchStatus;
    subchannels: IMatchMeta;
    tools: IMatchMeta;
    timezones: IMatchMeta;
    verticals: IMatchMeta;
    workTypes: IMatchWorkType;
    yearsOfExperience: number;
    referencesCount: number;
}

export interface IMatchMeta {
    additional: IName[];
    common: IName[];
}

export interface IMatchWorkType {
    additional: EWorkType[];
    common: EWorkType[];
}

export interface IName {
    id: number;
    name: string;
}

export interface IMatchMetaStrings {
    additional: string[];
    common: string[];
}

export interface IMatchWithRecommendation {
    id: number;
    askForNewRole: boolean;
    dynamicProfile: IDynamicProfiles;
    forceRejectionFeedback: boolean;
    recommendation: IRecommendation;
    roleId: number;
}
