import React, {useCallback, useState} from 'react';
import {useRecordContext} from 'ra-core';

import {Box, Typography} from '@mui/material';

import {Accordion, AccordionDetails, AccordionSummaryInfo} from '../../../../components';
import {EMatchingResultStatus, IClientRole, IMatching} from '../../../../models';
import theme from '../../../../theme';

import {formatTitle} from './list.helper';
import {Matching} from './Matching';


const MatchingList: React.FC = () => {
    const record = useRecordContext<IClientRole>();
    const [openIds, setOpenIds] = useState<Record<number, boolean>>({});

    const handleChange = useCallback((matchingId: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setOpenIds({
            ...openIds,
            [matchingId]: isExpanded
        });
    }, [openIds]);

    if (!record) return null;

    // sort by date but if it not Inactive then show at the top of the list
    const matchings = record.matchings?.map(matching => ({...matching, sortOrder: matching.status === EMatchingResultStatus.Inactive ? 0 : 1}))
        .sort((a, b) => b.sortOrder - a.sortOrder || b.id - a.id);

    return (
        <Box>
            <Typography sx={{color: theme.palette.grey[500], px: 3, pt: 5, pb: 1}} variant="body1">All Matchings</Typography>
            {matchings?.map((matching: IMatching) => (
                <Accordion key={matching.id} onChange={handleChange(matching.id)}>
                    <AccordionSummaryInfo
                        id="matching-results"
                        isSubTitleStatic={true}
                        subtitle={formatTitle(matching)}
                        title="Match Result"
                    >
                    </AccordionSummaryInfo>
                    <AccordionDetails sx={{p: 0, background: '#e7ebf0'}}>
                        {openIds[matching.id] && <Matching data={matching} />}
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
};

export default React.memo(MatchingList);
