import React, {useCallback, useEffect, useState} from 'react';
import format from 'date-fns/format';
import subMonths from 'date-fns/subMonths';
import {Title, useDataProvider, useNotify} from 'react-admin';

import {Box, Divider, Stack} from '@mui/material';

import {LoadingMask, PageTitle} from '../../components';

import {SearchForm, SectionClient, SectionFreelancer, SectionGMV, SectionLeadAndFunnel} from './components';
import {EDashboardType, IAdminDashboard, ISearchParams} from './models';

export const Dashboard = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<IAdminDashboard | null>(null);
    const [filterValues, setFilterValues] = useState<ISearchParams>({
        from: format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
        to: format(new Date(), 'yyyy-MM-dd'),
        type: EDashboardType.Weekly
    });

    const dataProvider = useDataProvider();
    const notify = useNotify();

    const fetchData = useCallback(async () => {
        // setIsLoading(true);

        try {
            const response = await dataProvider.get('dashboard?'+ new URLSearchParams(filterValues as any), {area: 'admin'});

            setData(response.data);
        } catch (error: any) {
            notify(`Error: ${error.message}`, {type: 'warning'});
        }

        setIsLoading(false);
    }, [dataProvider, filterValues, notify]);

    useEffect(() => {
        fetchData();
    }, [filterValues, fetchData]);

    return (
        <>
            <Title title="Growth Collective - Admin Panel" />
            <Box
                sx={{
                    p: 5
                }}
            >
                <Stack direction="column" spacing={3}>
                    <Stack direction="row" justifyContent="space-between">
                        <PageTitle>Dashboard</PageTitle>
                        <SearchForm values={filterValues} onChange={setFilterValues}/>
                    </Stack>

                    {isLoading && (
                        <Box display="flex" justifyContent="center" padding={30}>
                            <LoadingMask/>
                        </Box>
                    )}

                    {
                        !isLoading && data && filterValues.from && filterValues.to && (
                            <>

                                {/* CLIENTS section */}
                                <SectionClient data={data.clients}/>
                                <Divider/>

                                {/* FREELANCERS section */}
                                <SectionFreelancer data={data.freelancers}/>
                                <Divider/>

                                <SectionGMV data={data.gmv} values={filterValues} onChange={setFilterValues}/>
                                <Divider/>

                                {/* Lead & Funnel */}
                                <SectionLeadAndFunnel data={data.leadAndFunnel}/>
                            </>
                        )
                    }

                </Stack>
            </Box>
        </>
    );
};
