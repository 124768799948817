import React from 'react';
import {DateInput, TextInput, useListContext} from 'react-admin';
import {FormProvider, useForm} from 'react-hook-form';

import {Box, Stack} from '@mui/material';

import {Button, SearchFiltersBottom} from '../../../components';
import {styledInputInline} from '../../../styles';

import {ClearFiltersButton} from './ClearFiltersButton';
import {EventTypesFilter} from './EventTypesFilter';

export const NotificationFilterForm = () => {
    const {displayedFilters, filterValues, setFilters} = useListContext();
    const form = useForm({defaultValues: filterValues});

    const onSubmit = (values: any) => {
        if (Object.keys(values).length > 0) {
            setFilters(values, displayedFilters);
        }
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <Stack direction="row" spacing={2} alignItems="flex-end">
                    <TextInput
                        helperText={false}
                        label="Search"
                        resettable
                        source="q"
                        sx={{...styledInputInline}}
                    />

                    <EventTypesFilter
                        alwaysOn
                        key="eventTypes"
                        source="eventTypes"
                    />
                    <Box component="span" mr={2}>
                        <ClearFiltersButton />
                        <Button
                            className="primary"
                            color="primary"
                            disableElevation
                            type="submit"
                            variant="contained"
                        >
                            Search
                        </Button>
                    </Box>
                </Stack>
                <SearchFiltersBottom>
                    <DateInput
                        alwaysOn
                        key="createdAfter"
                        source="createdAfter"
                        helperText={false}
                        sx={{...styledInputInline}}
                    />
                    <DateInput
                        alwaysOn
                        key="createdBefore"
                        source="createdBefore"
                        helperText={false}
                        sx={{...styledInputInline}}
                    />
                </SearchFiltersBottom>
            </form>
        </FormProvider>
    );
};