import React from 'react';
import {Labeled} from 'react-admin';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {Avatar, Box, IconButton, Stack, Typography} from '@mui/material';

import {IRecommendation} from '../../../../../models';
import theme from '../../../../../theme';

interface RecommendationProps {
    data: IRecommendation;
    fullWidth?: boolean;
    label?: string;
    onDelete?: () => void;
    onEdit?: () => void;
}

const RecommendationPreview: React.FC<RecommendationProps> = ({data: {author, authorAvatar, authorPosition, header, text}, fullWidth, label, onDelete, onEdit}) => {
    return (
        <Labeled label={label || 'Recommendation'} sx={{width: '100%'}}>
            <Box display="flex" gap={2} alignItems="center">
                <Box
                    sx={{
                        borderRadius: 3,
                        p: 2,
                        border: '1px solid',
                        borderColor: theme.palette.inputBorderColor.main,
                        minWidth: fullWidth ? '100%' : 350,
                        background: 'linear-gradient(259.97deg, rgba(126, 95, 255, 0.2) 1.64%, rgba(126, 95, 255, 0) 49.75%), rgba(255, 255, 255, 0.5)'
                    }}
                >
                    <Typography sx={{fontSize: 10, fontWeight: 500, textTransform: 'uppercase'}} variant="body1">
                        <img src="/assets/images/icon-recommendation.svg" width="24" style={{verticalAlign: 'middle'}}/>&nbsp;
                        {header || 'HEADER'}
                    </Typography>
                    <Stack direction="row" spacing={2} sx={{py: 1}}>
                        <Avatar
                            src={authorAvatar}
                        >
                        </Avatar>
                        <Box>
                            <Typography sx={{fontWeight: 500}} variant="body2">{author || 'Name'}</Typography>
                            <Typography
                                sx={{fontWeight: 500, color: theme.palette.labelColor.main}}
                                variant="caption"
                            >{authorPosition || 'Position'}</Typography>
                        </Box>
                    </Stack>
                    <Typography
                        sx={{maxWidth: 500, whiteSpace: 'pre-wrap'}}
                        variant="body2"
                    >
                        {text || 'Description ...'}
                    </Typography>
                </Box>

                {(onDelete || onEdit) && (
                    <Box>
                        {onEdit && (
                            <IconButton title="Edit Recommendation" onClick={onEdit}>
                                <EditIcon/>
                            </IconButton>
                        )}
                        {onDelete && (
                            <IconButton title="Delete Recommendation" onClick={onDelete}>
                                <DeleteIcon/>
                            </IconButton>
                        )}
                    </Box>
                )}

            </Box>
        </Labeled>
    );
};

export default React.memo(RecommendationPreview);
