import {useDataProvider} from 'react-admin';
import {useQuery} from 'react-query';

import {IChannel, ICountry, IVertical} from '../models';

interface IMeta {
    businessModels?: string[];
    channels?: IChannel[];
    countries?: ICountry[];
    languages?: string[];
    verticals?: IVertical[];
}

export const useMeta = (): IMeta => {
    const dataProvider = useDataProvider();

    const {data: channels} = useQuery(['meta/channels'],
        () => dataProvider.get('meta/channels', {area: ''}),
        {
            staleTime: 30 * 60 * 1000,   // cache this request for 30 minutes
            select: (data: {data: IChannel[]}) => data.data.sort((a: IChannel, b: IChannel) => a.name.localeCompare(b.name))
        }
    );

    const {data: verticals} = useQuery(['meta/verticals'],
        () => dataProvider.get('meta/verticals', {area: ''}),
        {
            staleTime: 30 * 60 * 1000,   // cache this request for 30 minutes
            select: (data: {data: {verticals: IVertical[]}}) => data.data.verticals.sort((a: IVertical, b: IVertical) => a.name.localeCompare(b.name))
        }
    );

    const {data: businessModels} = useQuery(['meta/business-models'],
        () => dataProvider.get('meta/business-models', {area: ''}),
        {
            staleTime: 30 * 60 * 1000,   // cache this request for 30 minutes
            select: (data: {data: {businessModels: string[]}}) => data.data.businessModels
        }
    );

    const {data: locationsData} = useQuery(['meta/locations'],
        () => dataProvider.get('meta/locations', {area: ''}),
        {
            staleTime: 30 * 60 * 1000,   // cache this request for 30 minutes
            select: (data: {data: {countries: ICountry[]; languages: string[] }}) => data.data
        }
    );

    return {
        businessModels,
        channels,
        verticals,

        countries: locationsData?.countries,
        languages: locationsData?.languages,
    };
};
