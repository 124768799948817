import React from 'react';
import {useRecordContext} from 'react-admin';

import {Typography} from '@mui/material';

export const GroupsFieldPreview = ({source}: any) => {
    const record = useRecordContext();
    const data: string[] = record ? record[source] : null;

    if (!data) {
        return null;
    }

    return (
        <>
            <Typography variant="body1">Groups</Typography>
            <ul>
                {data.map(it => (
                    <li key={it}>{it.replace('/Administrators/', '')}</li>
                ))}
            </ul>
        </>

    );
};
