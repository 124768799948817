import React from 'react';
import {Edit, SimpleForm, TextInput, useRecordContext} from 'react-admin';

import {EditFormToolbarCoreUI} from '../../components';
import {styledInput} from '../../styles';

const VerticalTitle = () => {
    const record = useRecordContext();

    return <span>Vertical {record ? `"${record.name}"` : ''}</span>;
};

export const VerticalEdit = () => (
    <Edit title={<VerticalTitle />}>
        <SimpleForm toolbar={<EditFormToolbarCoreUI />}>
            <TextInput source="id" disabled sx={styledInput} />
            <TextInput source="name" sx={styledInput} />
        </SimpleForm>
    </Edit>
);
