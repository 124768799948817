import React, {useCallback, useEffect, useRef, useState} from 'react';
import debounce from 'lodash/debounce';
import {Create, ImageField, ImageInput, required, SaveButton, SelectInput, SimpleForm, TextInput, Toolbar, useDataProvider} from 'react-admin';
import {useFormContext, useWatch} from 'react-hook-form';

import {DuplicateList} from '../../components';
import {MAX_FILE_SIZE_MB} from '../../constants';
import {EModerationStatus, IBrand, moderationStatuses} from '../../models';
import {styledFileField, styledInput, styledSelect} from '../../styles';

export const BrandCreate = (props: any) => {
    const [isValid, setIsValid] = useState(false);

    return (
        <Create {...props} redirect="list">
            <SimpleForm
                defaultValues={{moderationStatus: EModerationStatus.NEW}}
                toolbar={<BrandCreateToolbar disabled={!isValid}/>}
                sx={{maxWidth: 600}}
            >
                <BrandCreateForm onValid={setIsValid}/>
            </SimpleForm>
        </Create>
    );
};

const BrandCreateForm = ({onValid}: any) => {
    const [duplicates, setDuplicates] = useState<IBrand[] | null>(null);
    const dataProvider = useDataProvider();
    const searchRef = useRef('');

    const {
        control,
    } = useFormContext();

    const {name} = useWatch({control});
    const isExist = name && duplicates ? !!duplicates.find(it => it.name.toLowerCase() === name.toLowerCase()) : false;

    searchRef.current = name;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const search = useCallback(debounce(async () => {
        if (!searchRef.current || searchRef.current.length < 3) return;

        const response = await dataProvider.get(`admin/meta/brands?q=${searchRef.current}`, {area: ''});
        const isExist = !!response.data.content?.find((it: IBrand) => it.name.toLowerCase() === searchRef.current.toLowerCase());

        // already exists
        if (response.data.content.length) {
            setDuplicates(response.data.content);
        }

        onValid(!isExist);
    }, 1000), []);

    useEffect(() => {
        setDuplicates(null);

        if (!name) return;

        onValid(false);
        search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name]);

    return (
        <>
            <TextInput source="name" sx={styledInput} validate={required()}/>
            <SelectInput
                choices={moderationStatuses}
                optionValue="name"
                source="moderationStatus"
                sx={styledSelect}
                validate={required()}
            />

            <ImageInput
                accept="image/*"
                maxSize={MAX_FILE_SIZE_MB}
                source="logo"
                label="Logo"
                sx={{
                    ...styledFileField,
                    '& .RaLabeled-label': {
                        fontSize: '1rem',
                    }
                }}>
                <ImageField source="src" title="title" />
            </ImageInput>

            {duplicates && (
                <DuplicateList error={isExist} items={duplicates}/>
            )}

        </>
    );
};

const BrandCreateToolbar = ({disabled}: {disabled: boolean}) => {
    return (
        <Toolbar>
            <SaveButton
                disabled={disabled}
                label="Save"
            />
        </Toolbar>
    );
};
