import {useDataProvider} from 'react-admin';
import {useQuery} from 'react-query';

import {EMatcherType, IMatcherProperty} from '../models';

export function useMatcher(configurationId?: number) {
    const dataProvider = useDataProvider();

    const {data: properties} = useQuery(['admin/matchings/properties'],
        () => dataProvider.get('admin/matchings/properties', {area: ''}),
        {
            staleTime: 30 * 60 * 1000,   // cache this request for 30 minutes
            select: (data: {data: {properties: IMatcherProperty[]}}) => data.data.properties
        }
    );

    const configUrl = `admin/matchings/configuration${ configurationId ? ('/' + configurationId) : '' }`;
    const {data: settings, refetch} = useQuery([configUrl],
        () => dataProvider.get(configUrl, {area: ''}),
        {
            enabled: !!properties,
            staleTime: 30 * 60 * 1000,   // cache this request for 30 minutes
            select: (data: {data: {settings: IMatcherProperty[]}}) => {
                const configuration = data.data.settings;

                return properties?.map((prop: IMatcherProperty) => {
                    const el = configuration.find(
                        (conf: IMatcherProperty) => conf.property === prop.property
                    );

                    if (el) {
                        return {
                            ...el,
                            property: prop.property,
                            types: prop.types,
                        };
                    }

                    const result: IMatcherProperty = {
                        property: prop.property,
                        types: prop.types,
                    };

                    if (prop.types.includes(EMatcherType.Cutting)) {
                        result.isCutting = false;
                    }

                    if (prop.types.includes(EMatcherType.Scoring)) {
                        result.weight = 0;
                    }

                    if (prop.types.includes(EMatcherType.Multiplying)) {
                        result.multiplier = 0;
                    }

                    return result;
                });
            }
        }
    );

    return {
        settings: settings || [],
        refetch
    };
}