import React, {useMemo} from 'react';
import {DateField, Show, Tab, TabbedShowLayout, TextField, useRecordContext} from 'react-admin';

import {Grid, Stack, Typography} from '@mui/material';

import {ButtonRedirect, EmptyDate, EngagementDetailsField, PLACEHOLDER_ID} from '../../components';
import {SetFeeDialog} from '../../components/SetFeeDialog';
import {IEngagement, IHistoryProposal, IWorkWeek} from '../../models';
import {styledLabelShow} from '../../styles';
import {PaymentsList} from '../Payments';

import RoleNameField from './components/RoleNameField';
import {ProposalHistory, Timesheet} from './components';

const Title = () => {
    const record = useRecordContext<IEngagement>();
    const projectName = record?.name?.length > 35 ? record?.name.substring(0, 35) + '...' : record?.name;

    return <span>Engagement #{record?.id}: {projectName}</span>;
};

const Fee = () => {
    const record = useRecordContext<IEngagement>();

    return record && <SetFeeDialog endpoint={`admin/engagements/${record.id}/set-fee`} value={record.gcFeePercent} required/> || <></>;
};

export const EngagementShow = () => {
    return (
        <Show title={<Title />}>
            <TabbedShowLayout syncWithLocation={false}>
                <Tab label="summary">
                    <Grid container spacing={2} sx={{maxWidth: 800}}>
                        <Grid item xs={12} md={6}>
                            <Typography sx={styledLabelShow}>Role</Typography>
                            <Stack alignItems="center" direction="row" spacing={1}>
                                <RoleNameField/>
                                <ButtonRedirect source="roleId" path={`/roles/${PLACEHOLDER_ID}/show`}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography sx={styledLabelShow}>GC Fee % (to be used on next payment)</Typography>
                            <Stack alignItems="center" direction="row" spacing={1}>
                                <Fee/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography sx={styledLabelShow}>Freelancer</Typography>
                            <TextField source="freelancerName" />
                        &nbsp;
                            <ButtonRedirect source="freelancerId" path={`/freelancers/${PLACEHOLDER_ID}/show`}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography sx={styledLabelShow}>Client</Typography>
                            <TextField source="clientCompanyName" />
                        &nbsp;
                            <ButtonRedirect source="clientId" path={`/clients/${PLACEHOLDER_ID}/show`}/>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={styledLabelShow}>Start date</Typography>
                            <DateField source="startDate"/>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={styledLabelShow}>End date</Typography>
                            <EmptyDate source="endDate"/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={styledLabelShow}>Engagement details</Typography>
                            <EngagementDetailsField variant="body2"/>
                        </Grid>

                        <ProposalVersions/>

                        <Timesheets/>

                    </Grid>
                </Tab>
                <Tab label="Transactions">
                    <Transactions/>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

const ProposalVersions = () => {
    const {history} = useRecordContext();

    return (
        <>
            {history?.length > 1 && history.map((item: IHistoryProposal) => (
                <Grid item xs={12} key={item.id}>
                    <ProposalHistory data={item}/>
                </Grid>
            ))}
        </>
    );
};

const Transactions = () => {
    const record = useRecordContext<IEngagement>();

    if (!record) {
        return null;
    }

    return (
        <PaymentsList roleId={record.roleId}/>
    );
};

const Timesheets = () => {
    const {timesheets} = useRecordContext();

    const items = useMemo(() => {
        timesheets?.sort((a: any, b: any) => a.id - b.id).reverse();

        return timesheets;
    }, [timesheets]);

    return (
        <>
            {items?.map((item: IWorkWeek) => (
                <Grid item key={item.id} xs={12}>
                    <Timesheet data={item}/>
                </Grid>
            ))}
        </>
    );
};
