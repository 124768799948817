import {useCallback} from 'react';
import {useDataProvider, usePermissions} from 'react-admin';
import {useQuery} from 'react-query';

import {ERole} from '../models';

export const useAdminGroups = () => {
    const dataProvider = useDataProvider();
    const {permissions} = usePermissions();

    const hasPermissions = useCallback((roles: ERole[]) => !!roles.find(role => permissions.includes(role)), [permissions]);

    const {data} = useQuery(
        ['admin/groups'],
        () => dataProvider.get('groups', {id: '', area: 'admin'}),
        {
            enabled: hasPermissions([ERole.SuperAdmin]),
            staleTime: 60 * 60 * 1000,
            select: (data: any) => data.data.groups.sort((a: string, b: string) => a.localeCompare(b))
        }
    );

    return {
        data,
        hasPermissions
    };
};
