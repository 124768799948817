import React, {SyntheticEvent} from 'react';
import {Button, Datagrid, Empty as RaEmpty, ExportButton, Identifier, ImageField, List, RaRecord, TextField, TopToolbar} from 'react-admin';

import AddIcon from '@mui/icons-material/Add';
import {Box} from '@mui/material';

import {PaginationCoreUI} from '../../../components';
import {styledButton, styledDatagrid, styledList} from '../../../styles';

interface IProps {
    onClick: (record: RaRecord) => any;
    onCreate: () => any;
}

export const RecommendationList: React.FC<IProps> = ({onClick, onCreate}) => (
    <List
        actions={<ListActions onCreate={onCreate}/>}
        empty={<Empty onCreate={onCreate}/>}
        pagination={<PaginationCoreUI />}
        resource="admin/recommendations"
        sx={styledList}
        title="Matcher Recommendation Templates List"
    >
        <Datagrid
            rowClick={(id: Identifier, resource: string, record: RaRecord) => onClick(record)}
            sx={styledDatagrid}
        >
            <TextField source="id" />
            <TextField source="author" />
            <ImageField
                source="authorAvatar"
                sx={{'& img': {maxWidth: 50, maxHeight: 50, objectFit: 'contain'}}}
                title="author"
            />
            <TextField source="authorPosition" />
            <TextField source="header" />
            <TextField source="text" />
        </Datagrid>
    </List>
);

interface IListActionsProps {
    onCreate: () => any;
}

const ListActions = ({onCreate}: IListActionsProps) => {
    const handleCreate = (event: SyntheticEvent) => {
        event.stopPropagation();
        onCreate();
    };

    return (
        <TopToolbar>
            <Button label="Create" startIcon={<AddIcon/>} onClick={handleCreate}/>
            <ExportButton/>
        </TopToolbar>
    );
};

interface IEmptyProps {
    onCreate: () => any;
}
const Empty = ({onCreate}: IEmptyProps) => {
    const handleCreate = (event: SyntheticEvent) => {
        event.stopPropagation();
        onCreate();
    };

    return (
        <Box textAlign="center" m={3} sx={{margin: '20px auto'}}>
            <RaEmpty/>
            <Button label="Create" startIcon={<AddIcon/>} sx={styledButton} variant="contained" onClick={handleCreate}/>
        </Box>
    );
};
