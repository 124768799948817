import React from 'react';
import {Datagrid, DateField, FunctionField, List, ShowButton, TextField} from 'react-admin';

import {Box} from '@mui/material';

import {HubspotCompanyIdField, HubspotIdField, PaginationCoreUI} from '../../components';
import {styledDatagrid, styledList, styledListShowButton} from '../../styles';

import {defaultFiltersValues, ListActions} from './components';

export const RoleList = () => (
    <List
        actions={<ListActions />}
        filterDefaultValues={defaultFiltersValues}
        pagination={<PaginationCoreUI />}
        sort={{field: 'created', order: 'DESC'}}
        sx={styledList}
    >
        <Datagrid bulkActionButtons={false} sx={{...styledDatagrid,
            '& .MuiTableCell-paddingCheckbox': {display: 'none'},
            '& td:last-of-type': {whiteSpace: 'nowrap', width: '120px'},
            '& .column-projectName': {maxWidth: 300, overflowWrap: 'break-word'}
        }}
        >
            <TextField source="client.companyName" label="Company name" />
            <TextField source="projectName" />
            <TextField source="tier" label="Job type" />
            <TextField source="status" label="Job status" />
            <TextField source="leadSource" label="Lead Source" />
            <DateField source="submitted" />
            <DateField source="lastMatching" />
            <TextField source="matchesFound" sortable={false} />
            <FunctionField label="Hubspot Id" render={(record: any) => (
                <HubspotIdField label="Hubspot Id" recordLocal={record.client} source="hubspotId" />
            )} />

            <FunctionField label="Company Hubspot Id" render={(record: any) => (
                <HubspotCompanyIdField label="Hubspot Id" recordLocal={record.client} source="companyHubspotId" />
            )} />

            <Actions source="actions" sortable={false} />
        </Datagrid>
    </List>
);

const Actions = ({source}: any) => {
    return (
        <Box>
            <ShowButton label="" sx={styledListShowButton} />
        </Box>
    );
};
