import React from 'react';
import {AutocompleteArrayInput, DateInput, SelectInput, TextInput, useListContext} from 'react-admin';
import {FormProvider, useForm} from 'react-hook-form';

import {Button, Grid, Stack} from '@mui/material';

import {SearchFiltersBottom} from '../../../../components';
import {ERoleStatus, ETier, optionsRoleStatus} from '../../../../models';
import {styledAutocompleteInline, styledInputInline, styledPrimaryButton, styledSelectInline} from '../../../../styles';

import {ClearFiltersButton} from './ClearFiltersButton';


export const RoleFilterForm = () => {
    const {displayedFilters, filterValues, setFilters} = useListContext();
    const form = useForm({defaultValues: filterValues});

    const onSubmit = (values: any) => {
        if (Object.keys(values).length > 0) {
            setFilters(values, displayedFilters);
        }
    };

    return (
        <FormProvider {...form}>
            <form style={{width: '100%'}} onSubmit={form.handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <TextInput
                            fullWidth
                            helperText={false}
                            label="Search"
                            resettable
                            source="q"
                            sx={styledInputInline}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <AutocompleteArrayInput
                            choices={optionsRoleStatus}
                            helperText={false}
                            fullWidth
                            label="Status"
                            limitTags={2}
                            key="statuses"
                            source="statuses"
                            sx={styledAutocompleteInline}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <SelectInput
                            choices={[
                                {id: ETier.Basic, name: ETier.Basic},
                                {id: ETier.Premium, name: ETier.Premium},
                            ]}
                            emptyText="All"
                            emptyValue="ALL"
                            fullWidth
                            helperText={false}
                            label="Tier"
                            source="tier"
                            sx={styledSelectInline}
                        />
                    </Grid>
                    <Grid item xs sx={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end'}}>
                        <Stack direction="row" spacing={1}>
                            <ClearFiltersButton />
                            <Button
                                className="primary"
                                color="primary"
                                disableElevation
                                type="submit"
                                variant="contained"
                                sx={styledPrimaryButton}
                            >
                                Search
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>

                <SearchFiltersBottom>

                    <DateInput
                        helperText={false}
                        source="submittedAfter"
                        sx={{styledInputInline}}
                    />

                    <DateInput
                        helperText={false}
                        source="submittedBefore"
                        sx={{styledInputInline}}
                    />

                    <DateInput
                        helperText={false}
                        source="lastMatchingAfter"
                        sx={{styledInputInline}}
                    />

                    <DateInput
                        helperText={false}
                        source="lastMatchingBefore"
                        sx={{styledInputInline}}
                    />

                </SearchFiltersBottom>
            </form>
        </FormProvider>
    );
};

export const defaultFiltersValues = {
    lastMatchingAfter: null,
    lastMatchingBefore: null,
    q: '',
    statuses: [ERoleStatus.Submitted],
    submittedAfter: null,
    submittedBefore: null,
    tier: 'ALL',
};
