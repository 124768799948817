import React from 'react';
import {Link} from 'react-router-dom';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {Button} from '@mui/material';

import {styledIconButton} from '../../styles';

type ButtonRedirectUrlProps = {
    path: string;
};

const ButtonRedirectUrl: React.FC<ButtonRedirectUrlProps> = ({path}) => {
    return (
        <Link to={path}>
            <Button sx={styledIconButton} >
                <OpenInNewIcon />
            </Button>
        </Link>
    );
};

export default React.memo(ButtonRedirectUrl);
