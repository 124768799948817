// 250099 -> $2500.99
export const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

// 250099 -> $2501
export const formatterWithoutCents = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

// 123456.789 -> 123.456,789
export const formatterNumber = new Intl.NumberFormat('en-US', {
});
