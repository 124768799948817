import React, {useState} from 'react';
import {useDataProvider, useNotify} from 'react-admin';
import {useFormContext} from 'react-hook-form';

import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField as MUITextField} from '@mui/material';

import {styledInput} from '../../../styles';
import {isValidEmail} from '../../../utils';

interface Props {
    disabled: boolean;
}

const SendTestEmailButton: React.FC<Props> = ({disabled}) => {
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);

    const {getValues} = useFormContext();
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const {emailSender, emailSubject, emailTemplate} = getValues();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (value: string) => {
        setInputValue(value);
        setIsEmailValid(Boolean(isValidEmail(value)));
    };

    const handleSubmitClick = async () => {
        const payload = {
            sender: emailSender,
            to: inputValue,
            subject: emailSubject,
            template: emailTemplate,
        };

        dataProvider
            .post('admin/notifications/rules/test/email', {
                data: payload
            })
            .then(
                (response: any) => {
                    if (response.status === 200) {
                        setOpen(false);
                        setInputValue('');
                        setIsEmailValid(false);

                        notify('Mail sent successfully');
                    }
                },
            )
            .catch(() => {
                notify(
                    'Something went wrong. Please check your email.',
                    {
                        type: 'error',
                        autoHideDuration: 5000
                    }
                );
            });
    };

    return (
        <>
            <Button
                disabled={disabled}
                variant="outlined"
                sx={{marginLeft: '10px'}}
                onClick={handleClickOpen}
            >
                Send Email
            </Button>
            <Dialog
                fullWidth
                maxWidth="xs"
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Send Email</DialogTitle>
                <DialogContent dividers>
                    <MUITextField
                        autoComplete="off"
                        fullWidth={true}
                        label="Send to"
                        placeholder="e.g. test@gmail.com"
                        value={inputValue}
                        sx={styledInput}
                        onChange={(e) => handleChange(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={!isEmailValid}
                        variant="contained"
                        onClick={handleSubmitClick}
                    >
                        Send
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default React.memo(SendTestEmailButton);
