import React, {ReactNode} from 'react';

import MUIBadge from '@mui/material/Badge';

interface BadgeProps {
    bgColor: string;
    children: ReactNode;
}

const Badge: React.FC<BadgeProps> = ({bgColor, children}) => {
    return (
        <MUIBadge
            className="badge"
            sx={{
                backgroundColor: bgColor,
                color: '#fff',
                borderRadius: '6px',
                fontSize: '11px',
                padding: '4px 7px',
                fontWeight: '700',
                lineHeight: '1',
            }}>
            {children}
        </MUIBadge>
    );
};

export default React.memo(Badge);
