import React, {useState} from 'react';
import {Edit, FormDataConsumer, HttpError, ImageField, ImageInput, required, SelectInput, SimpleForm, TextInput, useNotify, useRecordContext} from 'react-admin';

import {Box} from '@mui/material';

import {EditFormToolbarCoreUI, RemoveButtonCoreUI} from '../../components';
import {moderationStatuses} from '../../models';
import {styledFileField, styledInput, styledSelect} from '../../styles';
import {getFieldErrors} from '../../utils';

const formatLogo = (value: any) => {
    if (!value || typeof value === 'string') {
        return {url: value};
    } else {
        return value;
    }
};

const BrandTitle = () => {
    const record = useRecordContext();

    return <span>Brand {record ? `"${record.name}"` : ''}</span>;
};

export const BrandEdit = () => {
    const [logoDeleted, setLogoDeleted] = useState(false);

    const logo = (
        <Box sx={{position: 'relative'}}>
            <ImageField source="logo"/>
            <RemoveButtonCoreUI onRemove={setLogoDeleted} source="logo" />
        </Box>
    );

    const notify = useNotify();
    const onError = (error: unknown, variables: any, context: unknown) => {
        const errors = getFieldErrors(error);

        if (errors) {
            errors.forEach(error => notify((error as any).message, {type: 'warning'}));
        } else {
            notify((error as HttpError).message, {type: 'warning'});
        }
    };

    return (
        <Edit title={<BrandTitle />} mutationOptions={{meta: {logoDeleted}, onError}} mutationMode="pessimistic">
            <SimpleForm toolbar={<EditFormToolbarCoreUI alwaysEnable={true} />} sx={{maxWidth: 600}}>
                {/* <TextInput source="id" disabled sx={styledInput} /> */}
                <TextInput source="name" validate={required()} sx={styledInput} />
                <ImageInput
                    format={ formatLogo }
                    label="Logo"
                    source="logo"
                    sx={{
                        ...styledFileField,
                        '& .RaLabeled-label': {
                            fontSize: '1rem',
                        }
                    }}
                >
                    <ImageField source="src" />
                </ImageInput>

                <FormDataConsumer>
                    {({formData}) => {
                        if (formData.logo && !formData.logo.src) {
                            return !logoDeleted ? logo : null;
                        }
                    }}
                </FormDataConsumer>

                <SelectInput
                    choices={moderationStatuses}
                    source="moderationStatus"
                    optionValue="name"
                    validate={required()}
                    sx={styledSelect} />
            </SimpleForm>
        </Edit>
    );
};
