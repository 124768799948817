import React, {ReactNode, useState} from 'react';
import {Form, required, SelectInput, useDataProvider, useEditContext, useListContext, useNotify, useRecordContext} from 'react-admin';

import {DialogConfirm} from '../../../../components';
import {EApplicationStatus, EDocumentStatus, EModerationStatus, IApplication, optionsApplicationMutateStatus} from '../../../../models';
import {styledSelect} from '../../../../styles';

interface Props {
    compact?: boolean;
    inlineForm?: boolean;
    label?: ReactNode;
    source?: string;
}

const errorMsg = (name: string) => `Application has ${name} which hasn't been reviewed yet.`;
const errorDocsMsg = (name: string) => `Change ${name} “submitted“ statuses to “accepted” or “rejected” to be able to accept the applicant.`;

export const ApplicationStatusField = (props: Props) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const record = useRecordContext<IApplication>();
    const {refetch: refetchRecord} = useEditContext();                 // is Select in the Details page
    const {refetch: refetchList} = useListContext();                   // is Select inside the Table at the List page
    const [openConfirm, setOpenConfirm] = useState(false);
    const [needUpdate, setNeedUpdate] = useState(false);

    const handleChange = async ({target}: any) => {
        const newStatus = target.value;
        const statusesNeedReviewed = [EDocumentStatus.Doubtful, EDocumentStatus.InReview, EDocumentStatus.Submitted];

        const needReviewCaseStudies = record.caseStudies.filter(it => statusesNeedReviewed.includes(it.status));
        const needReviewCertificates = record.certificates.filter(it => statusesNeedReviewed.includes(it.status));
        const needReviewReferences = record.references.filter(it => statusesNeedReviewed.includes(it.status));

        const newBrands = record.brands.filter(it => it.moderationStatus === EModerationStatus.NEW);
        const newTools = record.tools.filter(it => it.moderationStatus === EModerationStatus.NEW);

        if (newStatus === EApplicationStatus.Accepted) {
            if (newBrands.length) {
                notify(errorMsg('brands'), {type: 'warning'});
            } else if (newTools.length) {
                notify(errorMsg('tools'), {type: 'warning'});
            } else if (needReviewCaseStudies.length) {
                notify(errorDocsMsg('Case Study'), {type: 'warning'});
            } else if (needReviewCertificates.length) {
                notify(errorDocsMsg('Certificate'), {type: 'warning'});
            } else if (needReviewReferences.length) {
                notify(errorDocsMsg('Reference'), {type: 'warning'});
            } else {
                setOpenConfirm(true);
            }
        } else {
            mutate(newStatus);
        }
    };

    const handleConfirm = () => {
        setOpenConfirm(false);
        mutate(EApplicationStatus.Accepted);
    };

    const handleDecline = () => {
        setOpenConfirm(false);
        refetch();
        setNeedUpdate(true);
        setTimeout(() => {
            setNeedUpdate(false);   // Select does not update in the table if user Decline new status 'Accepted'
        }, 100);
    };

    const mutate = async (status: EApplicationStatus) => {
        try {
            await dataProvider.put(
                `admin/applications/${record.id}/status`,
                {
                    data: {
                        status
                    }
                }
            );
            notify('Status successfully updated');
        } catch (error: any) {
            notify(`Error: ${error?.body?.error}`, {type: 'warning'});
        }

        refetch();
    };

    const refetch = () => {
        if (refetchRecord) refetchRecord();
        if (refetchList) refetchList();
    };

    const content = (
        <>
            {props.label || null}
            <SelectInput
                choices={optionsApplicationMutateStatus}
                disableValue="disable"
                fullWidth
                helperText={false}
                label=""
                source="status"
                disabled={[EApplicationStatus['In Progress'], EApplicationStatus.Accepted].includes(record.status)}
                sx={!props.compact ? styledSelect : null}
                optionText="text"
                optionValue="value"
                validate={required()}
                onChange={handleChange}
            />
            <DialogConfirm
                ariaDescribedby="Confirm accepting applicant"
                confirmationText={<>Are you sure you want to accept this application?<br/>You can not undo this action.</>}
                open={openConfirm}
                title="Confirmation"
                onNo={handleDecline}
                onYes={handleConfirm}
            />
        </>
    );

    if (needUpdate) {
        return null;    // Select does not update in the table if user Decline new status 'Accepted'
    } else if (props.inlineForm) {
        return (
            <Form>
                {content}
            </Form>
        );
    } else {
        return content;
    }
};
