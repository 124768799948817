import React from 'react';
import {useFormContext} from 'react-hook-form';

import RemoveCircleOutlinedIcon from '@mui/icons-material/RemoveCircleOutlined';

const RemoveButtonCoreUI = ({onRemove, source}: any) => {
    const {setValue} = useFormContext();
    const handleRemove = () => {
        setValue(source, '');
        onRemove && onRemove(true);
    };

    return (
        <RemoveCircleOutlinedIcon
            color="error"
            sx={{
                position: 'absolute',
                top: '-5px',
                right: '-5px',
                cursor: 'pointer'
            }} onClick={handleRemove}/>
    );
};

export default RemoveButtonCoreUI;
