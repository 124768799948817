import React, {useCallback, useState} from 'react';
import {useDataProvider, useNotify, useRecordContext, useShowContext} from 'react-admin';

import {Dialog, DialogContent, DialogTitle} from '@mui/material';

import {Button, MatcherSettingsForm} from '../../../../components';
import {ERoleStatus, IClientRole, IMatcherConfig} from '../../../../models';


export const GlobalSettingsForm: React.FC = React.memo(() => {
    const [open, setOpen] = useState(false);
    const record = useRecordContext<IClientRole>();
    const dataProvider = useDataProvider();
    const {refetch}= useShowContext();
    const notify = useNotify();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = useCallback(async (settings: IMatcherConfig[]) => {
        try {
            await dataProvider.post(`admin/roles/${record?.id}/matchings`, {data: {settings}});

            notify('Matching successfully created');
            handleClose();

            refetch && refetch();
        } catch (error: any) {
            const message = error.body?.errors?.map((it: {message: string}) => it.message).join(', ');

            notify(`Error: ${message || error.message}`, {type: 'warning'});
        }
    }, [dataProvider, notify, record?.id, refetch]);

    const isEnabled = record && ![
        ERoleStatus.Draft,
        ERoleStatus.EngagementActive,
        ERoleStatus.EngagementPaused,
        ERoleStatus.EngagementPending,
        ERoleStatus.Closed
    ].includes(record.status);

    return (
        <>
            <Button disabled={!isEnabled} sx={{my: 5, mx: 3}} variant="contained" onClick={handleClickOpen}>
                + New matching
            </Button>
            <Dialog
                maxWidth="lg"
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    Matcher Default Settings
                </DialogTitle>
                <DialogContent dividers sx={{minWidth: '1100px', minHeight: '400px', p: 0}}>
                    <MatcherSettingsForm
                        buttonLabel="Start New matching"
                        onSubmit={handleSubmit}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
});