import * as React from 'react';
import {ChangeEvent, useState} from 'react';
import clsx from 'clsx';

import {FormControlLabel, Radio as MUIRadio, RadioProps as MuiRadioProps} from '@mui/material';

interface RadioProps extends MuiRadioProps {
    label?: string;
}

export const Radio = ({label, ...props}: RadioProps): JSX.Element => {
    const [checked, setChecked] = useState(!!props.checked);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setChecked(e.target.checked);
    };

    return (
        <FormControlLabel
            control={<MUIRadio
                checked={checked}
                className={clsx(props.className)}
                sx={{
                    color: `${props.color ? props.color : 'primary'}.main`,
                    '&.Mui-disabled': {
                        color: `${props.color ? props.color : 'primary'}.main`,
                        opacity: .65
                    }
                }}
                {...props}
                onChange={handleChange}
            />}
            label={label}
        />
    );
};
