import React, {useMemo} from 'react';
import {useDataProvider} from 'react-admin';
import {useQuery} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';

import WestIcon from '@mui/icons-material/West';
import {Box, IconButton} from '@mui/material';

import {EWorkType, IFreelancer, IName} from '../../models';
import theme from '../../theme';
import LoadingMask from '../LoadingMask';

import FreelancerAboutInfo from './components/FreelancerAboutInfo';
import FreelancerCaseStudies from './components/FreelancerCaseStudies';
import FreelancerCertifications from './components/FreelancerCertifications';
import FreelancerFitsBudget from './components/FreelancerFitsBudget';
import FreelancerMatchesList from './components/FreelancerMatchesList';
import FreelancerRateInfo from './components/FreelancerRateInfo';
import FreelancerTopInfo from './components/FreelancerTopInfo';
import {Item, WrpFreelancer} from './components/WrpFreelancer';

const PublicProfileFreelancer = () => {
    const {freelancerId} = useParams();
    const navigate = useNavigate();
    const dataProvider = useDataProvider();

    const {data} = useQuery(['freelancers', freelancerId || ''],
        () => dataProvider.get(`freelancers/${freelancerId}`, {area: 'admin'}),
        {
            staleTime: 30 * 60 * 1000,   // cache this request for 30 minutes
            select: (data: {data: IFreelancer}) => data.data
        }
    );

    const publishCaseStudies = useMemo(() => data?.profile.caseStudies
        .filter((it: any) => !!it.publicVersion).map((it: any) => ({
            ...it.publicVersion,
            id: it.id
        })), [data]);

    if (!data) {
        return <LoadingMask/>;
    }

    return (
        <Box
            sx={{
                p: 4,
                margin: '0 auto',
                maxWidth: 1652,
            }}
        >
            <WrpFreelancer>
                <IconButton
                    disabled={true}
                    sx={{
                        gridArea: 'backButton',
                        alignSelf: 'flex-start',
                        justifySelf: 'flex-start',
                        opacity: 0,
                        [theme.breakpoints.up('lg')]: {
                            mb: '8px'
                        }
                    }}
                    onClick={() => navigate(-1)}
                >
                    <WestIcon/>
                </IconButton>
                <Item gridArea="nameBox">
                    {data && (
                        <FreelancerTopInfo
                            data={{
                                avatar: data.avatar,
                                businessModels: {common: data.profile.businessModels, additional: []},
                                country: data.profile.country || '',
                                isAvailableForNewProject: !!data.profile.isAvailableForNewProject,
                                isOpenToHireRole: !!data.profile.isOpenToHireRole,
                                // timezones: data.profile.timezones,
                                firstName: data.firstName,
                                lastName: data.lastName,
                                yearsOfExperience: data.profile.yearsOfExperience,
                            }}
                        >

                        </FreelancerTopInfo>
                    )}
                </Item>
                <Item
                    gridArea="rateBox"
                    backgroundColor={theme.palette.purple.main}
                    widthXl="293"
                >
                    <FreelancerRateInfo
                        rate={data.profile.fixedRate}
                        workTypes={data.profile.workTypes}
                    />
                </Item>
                <Item
                    gridArea="fitBudgetBox"
                    boxShadow="0 0 50px rgba(126, 95, 255, 0.2)"
                    overflowVisible
                    widthXl="293"
                >
                    <FreelancerFitsBudget/>
                </Item>

                <Item gridArea="aboutBox" widthXl="540">
                    <FreelancerAboutInfo
                        bio={data.profile.bio || ''}
                        brands={data.profile.brands}
                    />
                </Item>
                <Item gridArea="matchesBox" widthXl="540">
                    <FreelancerMatchesList
                        data={{
                            channels: {common: data.profile.channels, additional: []},
                            verticals: {common: data.profile.verticals as unknown as IName[], additional: []},
                            languages: {common: data.profile.languages, additional: []},
                            tools: {common: data.profile.tools as unknown as IName[], additional: []},
                            workTypes: {common: data.profile.workTypes as unknown as EWorkType[], additional: []}
                        }}
                    />
                </Item>
                <Box
                    sx={{
                        gridArea: 'certificationsAndCaseStudiesBox',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                        [theme.breakpoints.up(1400)]: {
                            gap: '24px'
                        }
                    }}
                >
                    <Item gridArea="caseStudiesBox">
                        <FreelancerCaseStudies
                            items={publishCaseStudies || []}
                        />
                    </Item>
                    <Item gridArea="certificationsBox">
                        <FreelancerCertifications
                            items={data.profile.certificates}
                        />
                    </Item>
                </Box>
            </WrpFreelancer>
        </Box>
    );
};

export default React.memo(PublicProfileFreelancer);
