import isString from 'lodash/isString';
import {RaRecord} from 'react-admin';

import {darken} from '@mui/material';

export const getByPath = (record: RaRecord | null, source: string) => {
    const paths = source.split('.');
    let field: any;

    if (!record) return null;

    // suport depth paths: source="channel.subchannels"
    if (paths.length > 1) {
        field = record;
        paths.forEach((path: string) => {
            if (field[path]) {
                field = field[path];
            } else {
                field = '';
                console.warn('path not found', source);
            }
        });
    } else {
        field = record[source];
    }

    return field || null;
};

export const stringToColor = (string: string): string => {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;

        color += `00${value.toString(16)}`.slice(-2);
    }

    // do not return black color
    if (!string || color === '#000000') {
        return '#ECF4FE';
    }

    return darken(isString(color) ? color : 'white', .2);
};
