import React from 'react';
import {DeleteButton, SaveButton, Toolbar} from 'react-admin';

import {styledButton, styledDeleteButton} from '../../styles';

const EditFormToolbarCoreUI = (props: any) => {
    return (
        <Toolbar sx={{justifyContent: 'space-between', gap: 2}}>
            <SaveButton alwaysEnable={props.alwaysEnable} sx={styledButton} />
            <DeleteButton sx={styledDeleteButton} />
        </Toolbar>
    );
};

export default EditFormToolbarCoreUI;
