import React from 'react';

import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import PortraitIcon from '@mui/icons-material/Portrait';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import {Stack} from '@mui/material';

import {SectionTitle} from '../../../../components';
import {formatter, formatterNumber} from '../../../../utils';
import {IAdminDashboardClients} from '../../models';
import {Tile} from '..';

type SectionClientProps = {
    data: IAdminDashboardClients;
};

const SectionClient: React.FC<SectionClientProps> = ({data}) => {
    return (
        <Stack spacing={2}>
            <SectionTitle>Clients</SectionTitle>
            <Stack direction="row" spacing={1}>
                <Stack spacing={1}>
                    <Tile
                        icon={<PortraitIcon/>}
                        title="Active clients"
                        value={formatterNumber.format(data.activeUsers)}
                    />
                    <Tile
                        icon={<DataUsageIcon/>}
                        title="Active roles"
                        value={formatterNumber.format(data.activeRoles)}
                    />
                </Stack>

                <Tile
                    icon={<ThumbUpOutlinedIcon/>}
                    items={data.topGainers}
                    title="Top gainers"
                />

                <Tile
                    icon={<ThumbDownAltOutlinedIcon/>}
                    items={data.topLosers}
                    title="Top losers"
                />

                <Stack spacing={1}>
                    <Tile
                        icon={<BusinessCenterOutlinedIcon/>}
                        title="GMV per client"
                        value={formatter.format(data.averageGMV ? data.averageGMV / 100 : 0)}
                    />
                    <Tile
                        icon={<AssignmentOutlinedIcon/>}
                        title="SOW per client"
                        value={formatterNumber.format(data.averageSOW)}
                    />
                </Stack>
            </Stack>
        </Stack>
    );
};

export default React.memo(SectionClient);
