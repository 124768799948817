import React from 'react';
import {Datagrid, DateField, List, TextField, useRecordContext} from 'react-admin';

import {ColumnLabeled, HubspotCompanyIdField, HubspotIdField, PaginationCoreUI} from '../../components';
import {styledDatagrid, styledList} from '../../styles';

import {EmailField} from './components/EmailField';
import ClientNameField from './components/fields/ClientNameField';
import {ListActions} from './components/List/ListActions';

const RolesField = () => {
    const record = useRecordContext();

    if (!record) {
        return null;
    }

    return (
        <>
            {record?.rolesCount}
        </>
    );
};

export const ClientList = () => (
    <List
        actions={<ListActions />}
        pagination={<PaginationCoreUI />}
        sort={{field: 'created', order: 'DESC'}}
        sx={styledList}
    >
        <Datagrid
            rowClick="edit"
            sx={{
                ...styledDatagrid,
                '& .MuiTableCell-paddingCheckbox': {display: 'none'},
            }}>
            <ColumnLabeled label="Company name" source="companyName">
                <ClientNameField/>
            </ColumnLabeled>
            <TextField source="companyWebsite" />
            <EmailField source="email" />
            <DateField source="created" />
            <ColumnLabeled source="Roles">
                <RolesField/>
            </ColumnLabeled>
            <HubspotIdField label="Hubspot Id" source="hubspotId"/>
            <HubspotCompanyIdField label="Company Hubspot Id" source="companyHubspotId"/>
        </Datagrid>
    </List>
);
