export const ACCEPTED_IMAGE_TYPES = {
    'image/png': ['.png'],              // The value must be an object with a common MIME type as keys and an array of file extensions as values
    'image/jpg': ['.jpeg', '.jpg'],
};

export const ACCEPTED_DOCUMENTS_TYPES = {
    ...ACCEPTED_IMAGE_TYPES,
    'application/pdf': ['.pdf']
};

export const SETTING_PATH = 'admin/settings';

export const DELAY_API_CALL = 1000;

export const MAX_FILE_SIZE = 5;
export const MAX_FILE_SIZE_MB = MAX_FILE_SIZE * 1024 * 1024; // 5Mb;

export const NO_FILTER = 'e.g. Hub...';

export const urlRegex = new RegExp('^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?', 'ig');

export const getAcceptedExtentions = (values: {[key: string]: string[]}) => Object.values(values)
    .reduce((arr: string[], extensions) => {
        arr.push(...extensions);

        return arr;
    }, []);