import React, {useCallback, useEffect, useState} from 'react';
import {Reorder} from 'framer-motion';
import {undoableEventEmitter, useDataProvider, useDelete, useNotify, useRecordContext, useShowContext, useUnselectAll} from 'react-admin';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useQuery} from 'react-query';

import {Box, InputAdornment, Stack, styled, TextField, Typography} from '@mui/material';

import {Button, DialogDateTimePresets, LoadingMask} from '../../../../../components';
import {DELAY_API_CALL} from '../../../../../constants';
import {useDebounce} from '../../../../../hooks';
import {EMatchStatus, IClientRole, IMatchingResult} from '../../../../../models';
import {FreelancerItem} from '../FreelancerItem';

import DialogAddFreelancer from './DialogAddFreelancer';
import {Notes} from './Notes';

export const PAGE_SIZE = 15;

const DragableList = styled(Reorder.Group)(({theme}) => ({
    listStyle: 'none',
    padding: 0,
    paddingBottom: 20,
    margin: 0,
    '& li': {
        padding: '2px 0px'
    }
}));

export const FResultList = React.memo(() => {
    const record = useRecordContext<IClientRole>();

    const [items, setItems] = useState<IMatchingResult[]>([]);
    const [openFreelancers, setOpenFreelancers] = useState(false);
    const [openPlannedShare, setOpenPlannedShare] = useState(false);
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');

    const [deleteOne] = useDelete();
    const {refetch} = useShowContext();

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const unselectAll = useUnselectAll('freelancers');

    const q = useDebounce(searchTerm, DELAY_API_CALL, 0);

    const {data, isFetching, refetch: refetchFreelancers} = useQuery([`admin/roles/${record?.id}/results`],
        () => dataProvider.getList(`admin/roles/${record?.id}/results`, {
            filter: {q},
            pagination: {page, perPage: PAGE_SIZE},
            sort: {field: 'position', order: 'ASC'}
        }),
        {
            staleTime: 30 * 60 * 1000,   // cache this request for 30 minutes
            select: (data) => data,
            onSuccess: (response) => {
                // if it is first page then replace an array items
                if (page === 1) {
                    setItems(response.data);

                // add new page to the end of the list
                } else {
                    const newItems = [...items];

                    response.data.forEach(it => {
                        if (!items.find(item => item.id === it.id)) {
                            newItems.push(it);
                        }
                    });

                    setItems(newItems);
                }
            }
        }
    );

    const updateList = useCallback(() => {
        if (q) {
            setSearchTerm('');
        } else {
            setPage(1);
            setTimeout(() => refetchFreelancers(), 100);
        }
    }, [q, refetchFreelancers]);

    const handleClose = useCallback((isNeedRefetch?: boolean) => {
        if (isNeedRefetch) {
            if (refetch) refetch();
            updateList();
        }

        unselectAll();
        setOpenFreelancers(false);
    }, [refetch, unselectAll, updateList]);

    const handleAutoRecommendationsClick = useCallback(async () => {
        try {
            await dataProvider.post(`admin/roles/${record.id}/results/recommendations`);

            if (refetch) refetch();
            refetchFreelancers();

            notify('Successfully updated');
        } catch (error: any) {
            console.log(error);
            notify(`Error: ${error?.body?.error}`, {type: 'warning'});
        }
    }, [dataProvider, record, notify, refetch, refetchFreelancers]);

    const handlePlannedShare = useCallback(async (plannedShare: string) => {
        setOpenPlannedShare(false);

        const payload = (!plannedShare || plannedShare === '1970-01-01T00:00:00.000Z') ? null : {data: {plannedShare}};

        try {
            await dataProvider.patch(`admin/roles/${record.id}/results`, payload);

            if (refetch) refetch();
            refetchFreelancers();

            notify('Successfully updated');
        } catch (error: any) {
            console.log(error);
            notify(`Error: ${error?.body?.error}`, {type: 'warning'});
        }
    }, [dataProvider, record, notify, refetch, refetchFreelancers]);

    // autosave position
    const handlePosition = useCallback(async (item: IMatchingResult, position: number) => {
        const {notes, recommendation, status} = item;
        const data = {notes, recommendation, position, status};

        if (item.position === position) return;

        await dataProvider.put(`admin/roles/${record?.id}/results/${item.id}`, {data});
        updateList();
        notify('Successfully saved.');
    }, [dataProvider, record?.id, notify, updateList]);

    const handlePropose = useCallback(async (item: IMatchingResult) => {
        const {notes, position, recommendation} = item;
        const data = {
            notes,
            position,
            recommendation,
            status: item.status === EMatchStatus.HIDDEN ? EMatchStatus.PROPOSED : EMatchStatus.HIDDEN
        };

        await dataProvider.put(`admin/roles/${record?.id}/results/${item.id}`, {data});
        updateList();
        notify('Successfully saved.');
    }, [dataProvider, record?.id, notify, updateList]);

    const handleDragEnd = useCallback(async (item: IMatchingResult) => {
        const index = items.findIndex(it => it.id === item.id);

        handlePosition(item, index + 1);
    }, [items, handlePosition]);

    const handleRefresh = useCallback(() => {
        setItems([...items]);
    }, [items]);

    const handleRemove = useCallback((item: IMatchingResult) => {
        item.isRemoved = true;
        setItems([...items]);

        undoableEventEmitter.once('end', ({isUndo}) => {
            if (isUndo) {
                setItems(items.map(item => ({...item, isRemoved: false})));
            } else if (refetch) {
                setTimeout(() => {
                    refetch();
                    updateList();
                }, 1000);
            }
        });

        deleteOne(
            `admin/roles/${record?.id}/results`,
            {id: item.id, previousData: item},
            {
                mutationMode: 'undoable',
                onSuccess: () => {
                    notify('Freelancer removed', {undoable: true, autoHideDuration: 5000});
                },
                onError: (error: any) => {
                    notify(`Error: ${error.message}`, {type: 'warning'});
                    setItems(items.map(item => ({...item, isRemoved: false})));
                },
            },
        );
    }, [items, record?.id, deleteOne, notify, refetch, updateList]);

    const fetchMoreData = useCallback(() => {
        setPage(page => page + 1);
        setTimeout(() => refetchFreelancers(), 100);
    }, [refetchFreelancers]);

    useEffect(() => {
        setPage(1);
        setTimeout(() => refetchFreelancers(), 100);
    }, [q, refetchFreelancers]);

    return (
        <Box sx={{py: 2, px: 2, background: '#e7ebf0', overflow: 'hidden'}}>
            <Stack alignItems="flex-end" direction="row" spacing={2} sx={{mb: 2}}>
                <TextField
                    autoComplete="off"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {isFetching && (q || !items.length) && <LoadingMask size={16} />}
                            </InputAdornment>
                        ),
                    }}
                    placeholder="Search by name, surname, email ..."
                    sx={{
                        marginBottom: 0,
                        width: 300,
                        '& input': {padding: '8px'}
                    }}
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                />
                <Box sx={{flexGrow: 1}} />
                <Button color="primary" onClick={() => setOpenFreelancers(true)}>+ Add new Freelancer</Button>
                <Button
                    color="primary"
                    onClick={handleAutoRecommendationsClick}
                >
                    Auto recommendations
                </Button>
                <Button
                    color="primary"
                    onClick={() => handlePlannedShare('')}
                >
                    Share results now
                </Button>
                <Button
                    color="primary"
                    onClick={() => setOpenPlannedShare(true)}
                >
                    Schedule share time
                </Button>
                {/* <ActionShare matching={} onAction={handleAction}/> */}
            </Stack>

            {!isFetching && !data?.total && <Typography align="center" sx={{p: 5, opacity: 0.5}}>Records not found</Typography>}

            <Box id={'scrollable-body-result'} sx={{maxHeight: '60vh', overflowY: 'auto'}}>
                <InfiniteScroll
                    dataLength={items.length}
                    next={fetchMoreData}
                    hasMore={!!items.length && !!data?.total && data?.total !== items.length}
                    loader={(
                        <Stack direction="row" spacing={2} sx={{py: 2, opacity: 0.5}} >
                            <LoadingMask size={16} />
                            <Typography component="p" variant="caption">
                                Loading...
                            </Typography>
                        </Stack>
                    )}
                    scrollableTarget={'scrollable-body-result'}
                >
                    <DragableList axis="y" values={items} onReorder={setItems}>
                        {items.filter(item => !item.isRemoved)
                            .map(item => (
                                <FreelancerItem
                                    isDragable={!q}
                                    isResult={true}
                                    item={item}
                                    key={item.freelancerId}
                                    onDragEnd={handleDragEnd}
                                    onPosition={handlePosition}
                                    onPropose={handlePropose}
                                    onRemove={handleRemove}
                                    onRefresh={handleRefresh}
                                />
                            ))}
                    </DragableList>
                </InfiniteScroll>
            </Box>

            <Notes/>

            <DialogAddFreelancer open={openFreelancers} onClose={handleClose} />

            <DialogDateTimePresets
                open={openPlannedShare}
                title="Schedule a time"
                subtitle="For example in 3 days or pick a current time and send it right away."
                onCancel={() => setOpenPlannedShare(false)}
                onSave={handlePlannedShare}
            />
        </Box>
    );
});
