import React, {useEffect} from 'react';
import {BooleanInput, Title, Toolbar, useDataProvider, useNotify} from 'react-admin';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {useQuery} from 'react-query';
import {boolean, number, object, TypeOf} from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {CardContent, Grid, InputAdornment} from '@mui/material';

import {Button} from '../../../components';
import {InputForm} from '../../../components/Forms';
import {SETTING_PATH} from '../../../constants';
import {IGlobalSetting} from '../../../models';


const DEFAULT_ERROR = {
    required_error: 'Value is required',
    invalid_type_error: 'Value is invalid',
};

const schema = object({
    automaticMatchingSharingForBasicTierRolesEnabled: boolean(),

    lotsOfRejectedMatchesEventThreshold: number(DEFAULT_ERROR).nonnegative(),

    matchRejectsWithoutFeedback: number(DEFAULT_ERROR).nonnegative(),

    roleBudgetPremiumTierThreshold: number(DEFAULT_ERROR).nonnegative(),

    sendNewMatchesDelayMinutes: number(DEFAULT_ERROR).nonnegative(),

    //Shared freelancer number in the matches
    roleBasicTierResultsLimit: number().nonnegative(),
    rolePremiumTierResultsLimit: number().nonnegative(),

    // fee
    growthCollectiveFeePercent: number().nonnegative(),
    serviceFeePercent: number().nonnegative(),
});

type SettingInput = TypeOf<typeof schema>;

type GlobalSettingsProps = {
    title: string;
};

const GlobalSettings: React.FC<GlobalSettingsProps> = ({title}) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const {data, refetch} = useQuery([SETTING_PATH],
        () => dataProvider.get(SETTING_PATH, {area: ''}),
        {
            staleTime: 30 * 60 * 1000,   // cache this request for 30 minutes
            select: (data: {data: IGlobalSetting[]}) => data.data
        }
    );

    const methods = useForm<SettingInput>({
        resolver: zodResolver(schema)
    });

    const {
        handleSubmit,
        formState: {isDirty},
        getValues,
        reset,
    } = methods;

    const onSubmitHandler: SubmitHandler<SettingInput> = async () => {
        const values: any = getValues();
        const arr: Promise<IGlobalSetting>[] = [];

        const payload = {...values};

        // format with cents
        if (payload.roleBudgetPremiumTierThreshold) {
            payload.roleBudgetPremiumTierThreshold *= 100;
        }

        console.log('submit', payload);
        data?.forEach(async prev => {
            const newValue = payload[prev.key];

            if (prev.value !== newValue.toString()) {
                arr.push(dataProvider.put(`${SETTING_PATH}/${prev.id}`, {data: {...prev, value: newValue}}));
            }
        });

        if (arr.length) {
            await Promise.all(arr);

            reset(values);
            refetch();

            notify('Successfully updated.');
        }
    };

    useEffect(() => {
        if (!data) return;
        const formValues: Partial<SettingInput> = {};

        data.forEach(setting => {
            switch (setting.key) {
                case 'automaticMatchingSharingForBasicTierRolesEnabled':
                    formValues.automaticMatchingSharingForBasicTierRolesEnabled = setting.value === 'true';
                    break;
                case 'roleBudgetPremiumTierThreshold':
                    formValues.roleBudgetPremiumTierThreshold = Number(setting.value) / 100;    // convert without cents
                    break;

                default:
                    (formValues as any)[setting.key as keyof SettingInput] = Number(setting.value);
            }

            reset(formValues);
        });
    }, [data, reset]);


    return (
        <>
            <Title title={title} />
            <FormProvider {...methods}>
                <form
                    autoComplete="off"
                    noValidate
                    onSubmit={handleSubmit(onSubmitHandler)}
                >
                    <CardContent>
                        <Grid container spacing={2} sx={{maxWidth: 850}}>
                            <Grid item xs={6}>
                                <InputForm
                                    label="Premium tier budget threshold"
                                    name="roleBudgetPremiumTierThreshold"
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    type="number"
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <InputForm
                                    label="Delay in sending new matches in minutes"
                                    name="sendNewMatchesDelayMinutes"
                                    startAdornment={<InputAdornment position="start">🕙</InputAdornment>}
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputForm
                                    label="Growth Collective Fee Percent"
                                    name="growthCollectiveFeePercent"
                                    startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                    type="number"
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <InputForm
                                    label="Service Fee Percent"
                                    name="serviceFeePercent"
                                    startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputForm
                                    label="Reject matches to push clients for feedback"
                                    name="matchRejectsWithoutFeedback"
                                    startAdornment={<InputAdornment position="start"></InputAdornment>}
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputForm
                                    label="Reject matches number to propose new role creation"
                                    name="lotsOfRejectedMatchesEventThreshold"
                                    startAdornment={<InputAdornment position="start"></InputAdornment>}
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputForm
                                    label="Published number for regular matches"
                                    name="roleBasicTierResultsLimit"
                                    startAdornment={<InputAdornment position="start"></InputAdornment>}
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputForm
                                    label="Published number for premium matches"
                                    name="rolePremiumTierResultsLimit"
                                    startAdornment={<InputAdornment position="start"></InputAdornment>}
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <BooleanInput label="Automatically share matches for the Basic tier" source="automaticMatchingSharingForBasicTierRolesEnabled" />
                            </Grid>
                            <Grid item xs={6}/>
                        </Grid>
                    </CardContent>
                    <Toolbar>
                        <Button
                            disabled={!isDirty}
                            type="submit"
                            variant="contained"
                        >
                            Save
                        </Button>
                    </Toolbar>
                </form>
            </FormProvider>
        </>
    );
};

export default GlobalSettings;
