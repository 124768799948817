import React from 'react';

import {Button as MUIButton, ButtonProps} from '@mui/material';

import {styledButton} from '../../styles';

const Button = ({children, ...props}: ButtonProps): JSX.Element => {
    return (
        <MUIButton
            {...props}
            sx={[
                ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
                styledButton,
            ]}
            disableElevation
        >
            {children}
        </MUIButton>
    );
};

export default Button;
