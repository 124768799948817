import React, {memo} from 'react';
import {useRecordContext} from 'react-admin';

import {Stack} from '@mui/material';

import {IPayment} from '../../../../models';
import {PayoutStatus} from '..';

export const PayoutField = memo(({source}: {source: string}) => {
    const record = useRecordContext<IPayment>();

    if (!record) {
        return null;
    }

    return (
        <Stack>
            <PayoutStatus data={record.payout}/>
        </Stack>
    );
});