import React from 'react';

import {Box, Typography} from '@mui/material';

import {styledLabelSearchForm} from '../../../../styles';
import {FilterForm} from '../Filters/FilterForm';


export const ListActions = () => {
    return (
        <Box width="100%">
            <Typography sx={{...styledLabelSearchForm, py: 1}} variant="h6">Filters</Typography>
            <FilterForm />
        </Box>
    );
};
