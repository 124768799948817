import React, {useEffect} from 'react';
import {AutocompleteArrayInput, DateInput, SelectInput, useListContext} from 'react-admin';
import {FormProvider, useForm} from 'react-hook-form';

import {Button, Grid, Stack} from '@mui/material';

import {SearchFiltersBottom} from '../../../../components';
import {useAsyncSearch} from '../../../../hooks';
import {EPaymentStatus, EPaymentStatusDisplay} from '../../../../models';
import {styledAutocompleteInline, styledInputInline, styledPrimaryButton, styledSelectInline} from '../../../../styles';

import {ClearFiltersButton} from './ClearFiltersButton';

const optionsStatus = [
    {id: EPaymentStatus.FAILED, name: EPaymentStatusDisplay.FAILED},
    {id: EPaymentStatus.NEW, name: EPaymentStatusDisplay.PENDING},
    {id: EPaymentStatus.SUCCESS, name: EPaymentStatusDisplay.SUCCESS},
    {id: EPaymentStatus.REFUNDED, name: EPaymentStatusDisplay.REFUNDED},
];

export const ListFilterForm = ({isCompact = false}: {isCompact: boolean}) => {
    const {displayedFilters, filterValues, setFilters} = useListContext();

    const {
        isFilterValid: isFilterClients,
        options: optionsClients,
        setFilter: setFilterClients,
        setSelected: setSelectedClients
    } = useAsyncSearch(filterValues.clients, 'clients', ['companyName', 'email']);

    const {
        isFilterValid: isFilterFreelancers,
        options: optionsFreelancers,
        setFilter: setFilterFreelancers,
        setSelected: setSelectedFreelancers
    } = useAsyncSearch(filterValues.freelancers, 'freelancers', ['email', 'firstName', 'lastName', 'phone']);

    const {
        isFilterValid: isFilterRole,
        options: optionsRoles,
        setFilter: setFilterRole,
        setSelected: setSelectedRoles
    } = useAsyncSearch(filterValues.roles, 'roles', 'projectName');

    const form = useForm({defaultValues: filterValues});
    const {
        // formState: {isDirty},
        getValues,
        // setValue,
        watch
    } = form;

    const clientsIds = watch('clients');
    const freelancersIds = watch('freelancers');
    const rolesIds = watch('roles');

    const onSubmit = (values: any) => {
        if (Object.keys(values).length > 0) {
            setFilters(values, displayedFilters);
        }
    };

    useEffect(() => {
        onSubmit(getValues());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setSelectedClients(clientsIds);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientsIds]);

    useEffect(() => {
        setSelectedFreelancers(freelancersIds);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [freelancersIds]);

    useEffect(() => {
        if (rolesIds)
            setSelectedRoles(rolesIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rolesIds]);

    return (
        <FormProvider {...form}>
            <form style={{width: '100%'}} onSubmit={form.handleSubmit(onSubmit)}>
                <Grid container spacing={2} alignItems="flex-end">

                    {!isCompact && (
                        <>
                            <Grid item xs={4}>
                                <AutocompleteArrayInput
                                    choices={optionsClients}
                                    helperText={false}
                                    fullWidth
                                    label="Clients"
                                    limitTags={2}
                                    noOptionsText={isFilterClients ? undefined : 'e.g. Hubspot...'}
                                    optionText="q"
                                    optionValue="id"
                                    setFilter={setFilterClients}
                                    source="clients"
                                    sx={{...styledAutocompleteInline}}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <AutocompleteArrayInput
                                    choices={optionsRoles}
                                    helperText={false}
                                    fullWidth
                                    label="Roles"
                                    limitTags={2}
                                    noOptionsText={isFilterRole ? undefined : 'e.g. Market...'}
                                    optionText="projectName"
                                    optionValue="id"
                                    setFilter={setFilterRole}
                                    source="roles"
                                    sx={{...styledAutocompleteInline}}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <AutocompleteArrayInput
                                    choices={optionsFreelancers}
                                    helperText={false}
                                    fullWidth
                                    label="Freelancers"
                                    limitTags={2}
                                    noOptionsText={isFilterFreelancers ? undefined : 'e.g. Jhon...'}
                                    optionText="q"
                                    optionValue="id"
                                    setFilter={setFilterFreelancers}
                                    source="freelancers"
                                    sx={{...styledAutocompleteInline}}
                                />
                            </Grid>
                        </>
                    )}

                    <Grid item xs={2}>
                        <SelectInput
                            choices={optionsStatus}
                            emptyText="All"
                            emptyValue="ALL"
                            fullWidth
                            helperText={false}
                            label="Payment status"
                            source="statusPayment"
                            sx={styledSelectInline}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <SelectInput
                            choices={optionsStatus}
                            emptyText="All"
                            emptyValue="ALL"
                            fullWidth
                            helperText={false}
                            label="Payout status"
                            source="statusPayout"
                            sx={styledSelectInline}
                        />
                    </Grid>

                    <Grid item xs sx={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end'}}>
                        <Stack direction="row" spacing={1}>
                            <ClearFiltersButton />
                            <Button
                                className="primary"
                                color="primary"
                                disableElevation
                                type="submit"
                                variant="contained"
                                sx={styledPrimaryButton}
                            >
                                Search
                            </Button>
                        </Stack>
                    </Grid>

                </Grid>
                <SearchFiltersBottom>

                    <DateInput
                        alwaysOn
                        key="createdAfter"
                        source="createdAfter"
                        helperText={false}
                        sx={{...styledInputInline}}
                    />

                    <DateInput
                        alwaysOn
                        key="createdBefore"
                        source="createdBefore"
                        helperText={false}
                        sx={{...styledInputInline}}
                    />

                </SearchFiltersBottom>
            </form>
        </FormProvider>
    );
};

export const defaultFiltersValues = {
    clients: null,
    createdAfter: null,
    createdBefore: null,
    freelancers: null,
    q: '',
    roles: null,
    statusPayment: 'ALL',
    statusPayout: 'ALL',
};
