import {EWorkType, IBrand, ICaseStudy, ICertificate, IChannel, IReference, ITool, IVertical} from './';

export interface IApplication {
    id: number;
    status: EApplicationStatus;
    step: number;
    success: boolean;

    // Common info
    businessModels: string[];
    brands: IBrand[];
    channels: IChannel[];
    tools: ITool[];
    verticals: IVertical[];
    yearsOfExperience: number;

    // Work Preference
    workTypes: EWorkType[];
    fixedRate: number;
    rateRange: number[];

    // Others
    bio?: string;
    country: string;
    isAvailableForNewProject: boolean;
    isOpenToHireRole: boolean;
    languages: string[];
    timezones: string[];

    // Application
    progressIndicatorValue: number;

    certificates: ICertificate[];
    references: IReference[];
    caseStudies: ICaseStudy[];
}

export interface IOthersRequest {
    country: string;
    timezones: string[];
    isAvailableForNewProject: boolean;
    isOpenToHireRole: boolean;
}

export enum EApplicationStatus {
    'Accepted' = 'ACCEPTED',
    'In Progress' = 'IN_PROGRESS',
    'In Review' = 'IN_REVIEW',
    'Rejected' = 'REJECTED',
    'Submitted' = 'SUBMITTED',
}

export enum EDocumentStatus {
    Accepted = 'ACCEPTED',
    Doubtful = 'DOUBTFUL',
    InReview = 'IN_REVIEW',
    Rejected = 'REJECTED',
    Submitted = 'SUBMITTED',
}

export const optionsApplicationFullStatus = Object.entries(EApplicationStatus).map(([key, value]) => ({text: key, value}));     // Show all statuses
export const optionsApplicationMutateStatus = optionsApplicationFullStatus.map(it => it.value === EApplicationStatus['In Progress'] ? ({...it, disable: true}) : it);  // Hide IN_PROGRESS for Manual Review
export const optionsDocumentStatus = Object.entries(EDocumentStatus).map(([key, value]) => ({text: key, value}));
