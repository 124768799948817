import {EChannel, ESendTo, ETime, INotificationChannel, INotificationRule} from '../../models';

// `Growth Collective <backend@dev.v1.growthcollective.com>`
const DEFAULT_EMAIL_SENDER = `Growth Collective <backend@${process.env.REACT_APP_ENV}.v1.growthcollective.com>`;
const ADMIN_ROLE_PREFIX = '/Administrators';

export const transform = (data: any): INotificationRule => {
    const payload: INotificationRule = {
        eventType: data.eventType,
        name: data.name,
        delayed: data.time === 'delayed',
        immediate: data.time === 'immediate',
        options: [],
    };

    if (payload.delayed) {
        payload.delayMinutes = data.delayMinutes;
    }

    if (data.internalUserSubject) {
        payload.options.push({
            channel: EChannel.INTERNAL_USER,
            enabled: data.internalUserEnabled,
            subject: data.internalUserSubject,
            template: data.internalUserTemplate
        });
    }

    if (data.internalAdminSubject) {
        const recipients: string[] = [];

        if (data.internalAdminRecipients?.length) {
            recipients.push(...data.internalAdminRecipients);
        }

        if (data.internalAdminRoleRecipients?.length) {
            recipients.push(...data.internalAdminRoleRecipients);
        }

        payload.options.push({
            channel: EChannel.INTERNAL_ADMIN,
            enabled: data.internalAdminEnabled,
            subject: data.internalAdminSubject,
            template: data.internalAdminTemplate,
            recipients
        });
    }

    if (data.emailSubject) {
        const recipients: string[] = [];

        if (data.emailRecipients?.length) {
            recipients.push(...data.emailRecipients);
        }

        if (data.emailRoleRecipients?.length) {
            recipients.push(...data.emailRoleRecipients);
        }

        payload.options.push({
            channel: data.sendTo === ESendTo.user ? EChannel.EMAIL_USER : EChannel.EMAIL_ADMIN,
            enabled: data.isEmail,
            sender: data.emailSender,
            subject: data.emailSubject,
            template: data.emailTemplate,
            recipients
        });
    }

    if (data.slackSubject) {
        const recipients: string[] = [];

        if (data.slackChannel) {
            recipients.push(data.slackChannel);
        }

        payload.options.push({
            channel: EChannel.SLACK_ADMIN,
            enabled: data.isSlack,
            subject: data.slackSubject,
            template: data.slackTemplate,
            recipients
        });
    }

    return payload;
};

export const prepareData = (record: INotificationRule): Record<string, string | boolean | string[]> => {
    const INTERNAL_USER = record?.options?.find(rule => rule.channel === EChannel.INTERNAL_USER) || false;
    const INTERNAL_ADMIN = record?.options?.find(rule => rule.channel === EChannel.INTERNAL_ADMIN) || false;
    const EMAIL_USER = record?.options?.find(rule => rule.channel === EChannel.EMAIL_USER) || false;
    const EMAIL_ADMIN = record?.options?.find(rule => rule.channel === EChannel.EMAIL_ADMIN) || false;
    const SLACK_ADMIN = record?.options?.find(rule => rule.channel === EChannel.SLACK_ADMIN) || false;

    const values: Record<string, string | boolean | string[]> = {
        emailSender: DEFAULT_EMAIL_SENDER,
        time: (!record || record.immediate) ? ETime.immediate : ETime.delayed,
        INTERNAL_ADMIN: !!INTERNAL_ADMIN,
        INTERNAL_USER: !!INTERNAL_USER,
        EMAIL_USER: !!EMAIL_USER,
        EMAIL_ADMIN: !!EMAIL_ADMIN,
        SLACK_ADMIN: !!SLACK_ADMIN
    };

    if (INTERNAL_USER) {
        values.internalUserEnabled = INTERNAL_USER.enabled;
        values.internalUserSubject = INTERNAL_USER.subject;
        values.internalUserTemplate = INTERNAL_USER.template;
    }

    if (INTERNAL_ADMIN) {
        values.internalAdminEnabled = INTERNAL_ADMIN.enabled;
        values.internalAdminSubject = INTERNAL_ADMIN.subject;
        values.internalAdminTemplate = INTERNAL_ADMIN.template;
        values.internalAdminRecipients = INTERNAL_ADMIN.recipients?.filter(it => !it.startsWith(ADMIN_ROLE_PREFIX)) || [];
        values.internalAdminRoleRecipients = INTERNAL_ADMIN.recipients?.filter(it => it.startsWith(ADMIN_ROLE_PREFIX)) || [];
    }

    if (EMAIL_USER || EMAIL_ADMIN) {
        const channel = (EMAIL_USER || EMAIL_ADMIN) as INotificationChannel;

        values.isEmail = channel.enabled;
        values.sendTo = EMAIL_USER ? ESendTo.user : ESendTo.admin;
        values.emailSender = channel.sender || '';
        values.emailSubject = channel.subject || '';
        values.emailTemplate = channel.template || '';
        values.emailRecipients = channel.recipients?.filter(it => !it.startsWith(ADMIN_ROLE_PREFIX)) || [];
        values.emailRoleRecipients = channel.recipients?.filter(it => it.startsWith(ADMIN_ROLE_PREFIX)) || [];
    }

    if (SLACK_ADMIN) {
        values.isSlack = SLACK_ADMIN.enabled;
        values.slackSubject = SLACK_ADMIN.subject;
        values.slackTemplate = SLACK_ADMIN.template;
        values.slackChannel = SLACK_ADMIN.recipients?.[0] || '';
    }

    return values;
};
