import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

import './index.scss';

const root = ReactDOM.createRoot(
    document.getElementById('app') as HTMLElement
);

root.render(
    <App />
);

let version = '';

function checkVersion() {
    const t = Date.now();

    fetch(`/v?${t}`, {cache: 'no-cache'})
        .then(result => {
            if (result.status != 200) {
                console.error('Unable to fetch version information', result);

                return;
            }
            result.text()
                .then(s => s.trim())
                .then(newVersion => {
                    if (!version) {
                        version = newVersion;
                    }
                    if (version != newVersion) {
                        console.log('New version is different, refresh requested');
                        const href = window.location.href;

                        document.location.href = href + (href.includes('?') ? '&' : '?') + `__=${t}`;
                    }
                });
        })
        .catch(error => {
            console.log('fetch v', error);
        })
        .finally(() => {
            setTimeout(checkVersion, 60000);
        });
}

if (process.env.NODE_ENV !== 'development') {
    checkVersion();
}

