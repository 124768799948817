import React, {ChangeEvent, ReactNode} from 'react';
import clsx, {ClassValue} from 'clsx';
import get from 'lodash/get';
import {Controller, useFormContext} from 'react-hook-form';

import {TextFieldProps as MUITextFieldProps} from '@mui/material';

import {Input} from '../..';

const transform = {
    input: (value: number) =>
        isNaN(value) || value === 0 ? '' : value.toString(),
    output: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const output = parseFloat(e.target.value);

        return isNaN(output) ? '' : output;
    }
};

type TextFieldProps = MUITextFieldProps & {
    classNames?: ClassValue[];
    startAdornment?: ReactNode;
}

export const InputForm = ({
    classNames,
    fullWidth = true,
    name = '',
    variant,
    ...props
}: TextFieldProps): JSX.Element => {
    const {
        control,
        formState: {errors},
    } = useFormContext();

    return (
        <Controller
            control={control}
            defaultValue=''
            name={name}
            render={({field: {ref, ...field}}) => (
                <Input
                    {...props}
                    {...field}
                    className={clsx(classNames)}
                    error={get(errors, name)?.message as any}
                    fullWidth
                    inputRef={ref}
                    onChange={(e) => props.type === 'number' ? field.onChange(transform.output(e)) : field.onChange(e)}
                />
            )}
        />
    );
};
