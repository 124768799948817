import React, {useEffect} from 'react';
import {DateTimeInput} from 'ra-ui-materialui';
import {useDataProvider, useEditContext, useNotify, useRecordContext} from 'react-admin';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {any, number, object, string, TypeOf} from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {DialogActions, DialogContent, Grid} from '@mui/material';

import {Button} from '../../../../components';
import {InputForm, SelectForm} from '../../../../components/Forms';
import {EApplicationStatus, IReference, optionsDocumentStatus} from '../../../../models';
import {styledInput, styledSelect} from '../../../../styles';

const PRESENT_DATE = 'Present';

const schema = object({
    id: number().optional(),
    companyName: string()
        .min(1, 'Company Name is required')
        .max(100, 'Value is too long'),
    description: string(),
    email: string()
        .min(1, 'Email Address is required')
        .max(100, 'Value is too long')
        .email('Email Address is invalid'),
    lastContactDate: any(),
    name: string()
        .min(1, 'Name is required')
        .max(100, 'Value is too long')
        .regex(/[a-zA-Z ]+/gm, 'Name can contain only latin characters'),
    position: string(),
    status: string()
        .min(1, 'Status is required'),
    type: string().optional(),
});

type ReferenceInput = TypeOf<typeof schema>;

interface Props {
    item: Partial<IReference>;
    onClose: () => void;
}

const ReferenceForm: React.FC<Props> = ({item, onClose}) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const {refetch} = useEditContext();
    const {id: recordId} = useRecordContext();

    const methods = useForm<ReferenceInput>({
        resolver: zodResolver(schema),
        defaultValues: item || undefined
    });

    const {
        formState: {isDirty},
        handleSubmit,
        getValues,
        reset,
    } = methods;

    const onSubmitHandler: SubmitHandler<ReferenceInput> = async () => {
        const data = getValues();

        delete data.type;

        if (!data.id) {
            data.status = EApplicationStatus.Submitted;
        }

        if (data.lastContactDate instanceof Date) {
            data.lastContactDate = data.lastContactDate.toISOString();
        }

        await dataProvider.put(
            `admin/applications/${recordId}/review/references`,
            {data},
        );

        if (data.id) {
            notify('Successfully updated.');
        } else {
            notify('Successfully created.');
        }

        if (refetch) refetch();
        onClose();
    };

    useEffect(() => {
        const data = item ? {...item} : {};

        if (item && !item.validTo) {
            data.validTo = PRESENT_DATE;
        }
        reset(data);
    }, [item, reset]);

    return (
        <>
            <DialogContent dividers>
                <FormProvider {...methods}>
                    <form
                        onSubmit={handleSubmit(onSubmitHandler)}
                        noValidate
                        autoComplete="off"
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <SelectForm
                                    helperText=""
                                    label="Status"
                                    variant="standard"
                                    listItems={optionsDocumentStatus}
                                    name="status"
                                    sx={styledSelect}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DateTimeInput
                                    fullWidth
                                    helperText={false}
                                    name="lastContactDate"
                                    source="lastContactDate"
                                    sx={styledInput}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <InputForm label="Reference’s First and Last Name" name="name" sx={styledInput}/>
                            </Grid>
                            <Grid item xs={6}>
                                <InputForm label="Reference’s Company Name" name="companyName" sx={styledInput}/>
                            </Grid>
                            <Grid item xs={6}>
                                <InputForm label="Reference’s Email Address" name="email" sx={styledInput}/>
                            </Grid>

                            <Grid item xs={12}>
                                <InputForm label="Applicant Job Title (optional)" name="position" sx={styledInput}/>
                            </Grid>

                            <Grid item xs={12}>
                                <InputForm
                                    label="Describe briefly what the reference is for (optional)"
                                    name="description"
                                    minRows={4}
                                    multiline
                                />
                            </Grid>

                        </Grid>
                    </form>
                </FormProvider>

            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={onClose}
                >
                    Close
                </Button>
                <Button
                    disabled={!isDirty}
                    variant="contained"
                    onClick={handleSubmit(onSubmitHandler)}
                >
                    Save
                </Button>
            </DialogActions>
        </>
    );
};

export default ReferenceForm;
