import React from 'react';
import {Datagrid, DateField, FunctionField, List, TextField} from 'react-admin';

import {PaginationCoreUI} from '../../components';
import {useAdminGroups} from '../../hooks';
import {ERole} from '../../models';
import {styledDatagrid, styledList} from '../../styles';

import {Actions, ApplicantField, ApplicationStatusField, AssigneeField, ListActions} from './components';

export const ApplicationList = () => {
    const {hasPermissions} = useAdminGroups();

    return (
        <List
            actions={<ListActions/>}
            bulkActionButtons={false}
            pagination={<PaginationCoreUI/>}
            sort={{field: 'submitted', order: 'DESC'}}
            sx={styledList}
        >
            <Datagrid sx={{
                ...styledDatagrid,
                '& .RaDatagrid-rowCell': {
                    padding: '0 .75rem',
                    borderBottom: 'solid 1px #ebedef',
                },
                '& .MuiTableCell-paddingCheckbox': {
                    display: 'none'
                },
                '& .MuiTableCell-root:nth-of-type(3)': {
                    maxWidth: '250px',
                    width: '250px'
                },
                '& .MuiTableCell-root:nth-of-type(4)': {
                    maxWidth: hasPermissions([ERole.SuperAdmin]) ? '400px' : 'unset',
                    width: hasPermissions([ERole.SuperAdmin]) ? '400px' : 'unset'
                },
                '& .MuiTableCell-root:last-of-type': {
                    maxWidth: '100px',
                    width: '100px'
                },
                '& .MuiFormControl-root, & .MuiFormLabel-root': {
                    margin: '0'
                }
            }}>
                <ApplicantField source="applicant"/>
                <ApplicationStatusField source="status" inlineForm compact/>
                <AssigneeField/>
                <DateField source="submitted"/>
                <FunctionField
                    label="Rate"
                    render={({fixedRate, rateRangeMin, rateRangeMax}: { fixedRate: number; rateRangeMin: number; rateRangeMax: number }) => {
                        if (!fixedRate && !rateRangeMin && !rateRangeMax) {
                            return <></>;
                        }
                        if (fixedRate && !rateRangeMin && !rateRangeMax) {
                            return <>{(fixedRate / 100).toFixed()}</>;
                        }
                        if (!fixedRate && rateRangeMin && rateRangeMax) {
                            return <>{(rateRangeMin / 100).toFixed()} - {(rateRangeMax / 100).toFixed()}</>;
                        }

                        return <>{((fixedRate ?? 0) / 100).toFixed()} ({((rateRangeMin ?? 0) / 100).toFixed()} - {((rateRangeMax ?? 0) / 100).toFixed()})</>;
                    }}/>
                <TextField source="reviewNotes" sortable={false}/>
                <Actions source="actions" sortable={false}/>
            </Datagrid>
        </List>
    );
};

