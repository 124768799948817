import React from 'react';
import {useRecordContext} from 'react-admin';

import {EEventType} from '../../models';
import {formatNameToCapital, stringToColor} from '../../utils';
import {Badge} from '..';


const EventTypeBadge = ({source}: any) => {
    const record = useRecordContext();

    const status = record[source];

    const statusNoUnderscore = status.replace(/_/g, ' ');

    let bgColor = '';

    switch (status) {
        case EEventType.APPLICATION_ACCEPTED:
            bgColor = '#349962';
            break;
        case EEventType.APPLICATION_DROPPED:
            bgColor = '#ed3b70';
            break;
        case EEventType.APPLICATION_REJECTED:
            bgColor = '#f50b0b';
            break;
        case EEventType.APPLICATION_SUBMITTED:
            bgColor = '#57cb8c';
            break;
        case EEventType.APPLICANT_REGISTERED:
            bgColor = '#4a46cc';
            break;
        case EEventType.CLIENT_REGISTERED:
            bgColor = '#837fe9';
            break;
        case EEventType.MATCHING_SHARED:
            bgColor = '#00adff';
            break;
        case EEventType.ROLE_SUBMITTED:
            bgColor = '#87daae';
            break;
        case EEventType.ROLE_DROPPED:
            bgColor = '#f1749a';
            break;

        default:
            bgColor = stringToColor(status);
    }

    return (
        <Badge bgColor={bgColor}>
            {formatNameToCapital(statusNoUnderscore)}
        </Badge>
    );
};

export default EventTypeBadge;
