import {useCallback, useEffect, useRef, useState} from 'react';
import {useDataProvider} from 'react-admin';

import {ISelectOption} from '../models';

type SearchFn = (selected: string[], url: string, source?: string | string[]) => {
    isFilterValid: boolean;
    options: ISelectOption[];
    setFilter: React.Dispatch<React.SetStateAction<string>>;
    setSelected: (ids: number[]) => void;
}

export const useAsyncSearch: SearchFn = (selected, url, source = 'name') => {
    const dataProvider = useDataProvider();
    const [optionsSelected, setOptionsSelected] = useState<ISelectOption[]>([]);
    const [options, setOptions] = useState<ISelectOption[]>([]);
    const [filter, setFilter] = useState<string>('');
    const isInitRef = useRef(false);

    const isFilterValid = filter.trim().length > 2;

    // at the BE search can be done by multi columns
    // but dropdown can be populated only from single column
    // try to found which column was used
    const findSearchedField = useCallback(
        (it: Record<string, string>) => {
            if (Array.isArray(source)) {
                // user reload a page and we have only ID and no search string
                if (!filter) {
                    return {q: it[source[0]]};
                }

                const fieldName = source.find(fieldName => it[fieldName].toLowerCase().includes(filter.toLowerCase()));

                if (fieldName) {
                    return {q: it[fieldName]};
                }
            } else {
                return {[source]: it[source]};
            }
        }, [filter, source]);

    // init func
    // after reload a page query string includes ids
    // get these values by ids and populate options
    useEffect(() => {
        let isMount = true;
        const fetch = async () => {
            const arr = selected.map(id => dataProvider.get(`${url}/${id}`, {area: 'admin'}));
            const responses = await Promise.all(arr);

            if (!isMount) return;
            const optionsSelected = responses.map(({data}) => ({id: data.id, ...findSearchedField(data)}));

            setOptionsSelected(optionsSelected as any);

            isInitRef.current = true; // start listen change events
        };

        if (selected?.length) {
            fetch();
        } else {
            isInitRef.current = true;
        }

        return () => {
            isMount = false;
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // async search
    useEffect(() => {
        let isMount = true;
        const search = async () => {
            const response = await dataProvider.get(`${url}?q=${filter}`, {area: 'admin'});

            if (!isMount) return;

            if (Array.isArray(source)) {
                response.data.content.forEach((it: Record<string, string>) => {
                    const result = findSearchedField(it);

                    if (result) {
                        Object.assign(it, result);
                    }
                });
            }

            setOptions(response.data.content);
        };

        // waiting for 3 characters and then search
        if (isFilterValid) {
            search();
        }

        return () => {
            isMount = false;
        };
        // }, [isFilterValid, filter, dataProvider, source, url, findSearchedField]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    const setSelected = useCallback((ids: number[]) => {
        const prevIds = optionsSelected.map(it => it.id);

        // not updated
        if (!isInitRef.current || ids?.join(',') === prevIds?.join(',')) return;

        const result: ISelectOption[] = optionsSelected.filter(it => ids?.includes(Number(it.id)));

        ids?.forEach(id => {
            const isExists = optionsSelected.some(it => it.id === id);

            if (!isExists) {
                const option = options.find(it => it.id === id);

                if (option) {
                    result.push(option);
                }
            }
        });

        setOptionsSelected(result);
    }, [options, optionsSelected]);

    return {
        isFilterValid,
        options: [...optionsSelected, ...options],
        setFilter,
        setSelected
    };
};
