import React, {FocusEvent, KeyboardEvent} from 'react';
import {useDataProvider, useNotify, useRecordContext, useShowContext} from 'react-admin';

import {Box, TextField, Typography} from '@mui/material';

import {IClientRole} from '../../../../../models';
import {styledInput, styledLabelShow} from '../../../../../styles';

export const Notes = () => {
    const record = useRecordContext<IClientRole>();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const {refetch} = useShowContext();

    const {notes, id} = record;

    const handleBlur = async (e: FocusEvent<HTMLInputElement>) => {
        const newValue = e.target.value.trim();

        if (notes === newValue) return;

        await dataProvider.put(
            `admin/roles/${id}`,
            {
                data: {
                    ...record,
                    notes: newValue,
                }
            }
        );

        if (refetch) refetch();

        notify('Notes successfully updated');
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            (e.target as HTMLElement).blur();
        }
    };

    return (
        <Box sx={{pt: 2, flexGrow: 1}}>
            <Typography sx={{...styledLabelShow}}>Notes</Typography>
            <TextField
                autoComplete="off"
                fullWidth
                label=""
                multiline
                rows={2}
                defaultValue={notes}
                sx={styledInput}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
            />
        </Box>
    );
};
