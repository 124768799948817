import React, {ReactNode} from 'react';

import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import {Box, Stack, Tooltip, Typography} from '@mui/material';

import theme from '../../../../theme';
import {IClientWithGMV} from '../../models';

export const styledLabel = {
    color: theme.palette.secondary.dark,
    fontSize: 13,
    fontWeight: 400,
};

type TileProps = {
    icon: ReactNode;
    items?: IClientWithGMV[];
    helperText?: string;
    title: string;
    value?: string;
};

const Tile: React.FC<TileProps> = ({icon, items, helperText, title, value}) => {
    return (
        <Stack
            sx={{
                width: 260,
                p: 3,
                pr: 1,
                background: 'white',
                borderRadius: 1,
            }}
        >
            <Box
                sx={{
                    width: 48,
                    height: 48,
                    p: '12px',
                    mb: 2,
                    background: theme.palette.lightGray.light,
                    borderRadius: 1,
                    '& svg path': {
                        fill: theme.palette.primary.main
                    }
                }}
            >{icon}</Box>

            <Typography sx={{fontSize: 24, fontWeight: 600}} variant='h6'>{items ? title : value}</Typography>

            {items ? (
                items.map((it, index) => (
                    <Typography
                        key={it.id}
                        sx={{
                            py: 1,
                            mt: index ? 0 : 2,
                            fontSize: 13,
                            fontWeight: 400,
                            '& span': {
                                color: theme.palette.lightGray.dark,
                                pr: 2
                            },
                            '& span.value': {
                                fontSize: 11
                            },
                        }}
                        variant="caption"
                    >
                        <span>{index + 1}</span> {it.name} {it.gain ? <span className="value">({it.gain.toFixed(Math.abs(it.gain) === 100 ? 0 : 2)}%)</span> : null}
                    </Typography>
                ))
            ) : (
                <Typography sx={{...styledLabel}} variant='caption'>
                    {title}
                    {helperText && (
                        <Tooltip title={helperText}>
                            <HelpOutlineOutlinedIcon sx={{fontSize: 17, ml: 1, verticalAlign: 'middle'}}/>
                        </Tooltip>
                    )}
                </Typography>
            )}

        </Stack>
    );
};

export default Tile;
