import React, {useCallback, useReducer} from 'react';
import {Edit, EmailField, FunctionField, NumberField, SimpleForm, TextField, useRecordContext} from 'react-admin';

import {Box, Grid, Typography} from '@mui/material';

import {BooleanIcon, ListField, ModerationListField} from '../../components';
import {useAdminGroups} from '../../hooks';
import {EAttachmentType, ERole, ICaseStudy, ICertificate, IReference} from '../../models';
import {styledLabelShow} from '../../styles';

import {ActionsTop, ApplicationNotes, ApplicationStatusField, DialogDocuments, DocumentsList, SubChannelsField} from './components';

export interface PageState {
    error: Error | null;
    loading: boolean;
    open: boolean;          // Open Add/Edit Document dialog
    selected: Partial<ICertificate | ICaseStudy | IReference> | null;
}

const Title = () => {
    const record = useRecordContext();
    const title = record ? `${record.applicant?.firstName} ${record.applicant?.lastName}` : '';

    return <span>Application: {title.length > 35 ? title.substring(0, 35) + '...' : title}</span>;
};

export const ApplicationEdit = () => {
    const {hasPermissions} = useAdminGroups();
    const [{open, selected}, dispatch] = useReducer(
        (state: PageState, newState: Partial<PageState>) => ({
            ...state,
            ...newState,
        }),
        {
            error: null,
            loading: false,
            open: false,
            selected: null,
        }
    );

    const handleAddCaseStudyClick = useCallback(() => {
        dispatch({open: true, selected: {type: EAttachmentType.study}});
    }, []);

    const handleAddCertificateClick = useCallback(() => {
        dispatch({open: true, selected: {type: EAttachmentType.certificate}});
    }, []);

    const handleAddReferenceClick = useCallback(() => {
        dispatch({open: true, selected: {type: EAttachmentType.reference}});
    }, []);

    const handleCloseClick = useCallback(() => {
        dispatch({open: false});
        // first off all close dialog and then erase selection
        // for preventing flicking
        setTimeout(() => {
            dispatch({selected: null});
        }, 150);
    }, []);

    const handleEditClick = useCallback((item: ICertificate | ICaseStudy | IReference) => {
        console.log('selected', item);
        dispatch({open: true, selected: item});
    }, []);

    return (

        <Edit
            actions={
                <ActionsTop
                    onAddCaseStudy={handleAddCaseStudyClick}
                    onAddCertificate={handleAddCertificateClick}
                    onAddReference={handleAddReferenceClick}
                />
            }
            title={<Title />}
        >
            <SimpleForm toolbar={false}>
                <Grid container spacing={2} sx={{marginBottom: '50px'}}>
                    <Grid item sm={12} md={6} lg={2}>
                        <Typography sx={styledLabelShow}>Name</Typography>
                        <FunctionField render={(record: any) => (
                            <Typography variant="body1">{record?.applicant?.firstName} {record?.applicant?.lastName}</Typography>
                        )} />
                    </Grid>
                    <Grid item md={12} lg={4}>
                        <EmailField source="applicant.email" variant="body2" />
                        <Box >
                            <Typography sx={{opacity: 0.5}} variant="caption">Phone number: </Typography>
                            <TextField source="applicant.phone" variant="body1" />
                        </Box>
                    </Grid>
                    <Grid item sm={12} md={6} lg={2}>
                        <Typography sx={styledLabelShow}>Location</Typography>
                        <TextField source="country" variant="body1" />
                    </Grid>
                    <Grid item sm={12} md={6} lg={2}>
                        <Typography sx={styledLabelShow}>Time zones</Typography>
                        <ListField source="timezones" />
                    </Grid>
                    <Grid item md={12} lg={2} order={{md: 1, lg: 0}}>
                        <ApplicationStatusField label={<Typography sx={styledLabelShow}>Status</Typography>} />
                    </Grid>
                    <Grid item sm={12} md={6} lg={2}>
                        <Typography sx={styledLabelShow}>Channels</Typography>
                        <ListField source="channels" />
                    </Grid>
                    <Grid item sm={12} md={6} lg={2}>
                        <Typography sx={styledLabelShow}>Subchannels</Typography>
                        <SubChannelsField />
                    </Grid>
                    <Grid item sm={12} md={6} lg={2}>
                        <Typography sx={styledLabelShow}>Verticals</Typography>
                        <ListField source="verticals" />
                    </Grid>
                    <Grid item sm={12} md={6} lg={2}>
                        <Typography sx={styledLabelShow}>Brands</Typography>
                        <ModerationListField
                            isEditable={hasPermissions([ERole.SuperAdmin, ERole.Admin])}
                            source="brands"
                        />
                    </Grid>
                    <Grid item sm={12} md={6} lg={2}>
                        <Typography sx={styledLabelShow}>Tools</Typography>
                        <ModerationListField
                            isEditable={hasPermissions([ERole.SuperAdmin, ERole.Admin])}
                            source="tools"
                        />
                    </Grid>
                    <Grid item sm={12} md={6} lg={2}></Grid>
                    <Grid item sm={12} md={6} lg={2}>
                        <Typography sx={styledLabelShow}>Available for new projects</Typography>
                        <BooleanIcon source="isAvailableForNewProject" />
                    </Grid>
                    <Grid item sm={12} md={6} lg={2}>
                        <Typography sx={styledLabelShow}>Years of experience</Typography>
                        <NumberField source="yearsOfExperience" />
                    </Grid>
                    <Grid item sm={12} md={6} lg={2}>
                        <Typography sx={styledLabelShow}>Business models</Typography>
                        <ListField source="businessModels" />
                    </Grid>
                    <Grid item sm={12} md={6} lg={2}>
                        <Typography sx={styledLabelShow}>Work types</Typography>
                        <ListField source="workTypes" />
                    </Grid>
                    <Grid item sm={12} md={6} lg={4}>
                        <Typography sx={styledLabelShow}>Languages</Typography>
                        <ListField source="languages" />
                    </Grid>
                </Grid>
                <DocumentsList onEdit={handleEditClick} />
                <ApplicationNotes />
                <DialogDocuments item={selected} open={open} dispatch={dispatch} onClose={handleCloseClick} />
            </SimpleForm>
        </Edit>
    );
};
