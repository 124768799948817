import * as React from 'react';
import {Create, SimpleForm, TextInput} from 'react-admin';

import {styledInput} from '../../styles';

export const VerticalCreate = (props: any) => (
    <Create {...props} redirect="list">
        <SimpleForm>
            <TextInput source="name" sx={styledInput} />
        </SimpleForm>
    </Create>
);
