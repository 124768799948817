import React, {useState} from 'react';

import {Button, Dialog, DialogContent} from '@mui/material';

const PreviewButton = ({template}: {template: string}) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button
                disabled={!template?.replace(/^\s+|\s+$/g, '').length}
                variant="outlined"
                onClick={handleClickOpen}
            >
                Preview
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogContent sx={{minWidth: '600px', minHeight: '400px'}}>
                    <div dangerouslySetInnerHTML={{__html: replacePlaceholders(template)}} />
                </DialogContent>
            </Dialog>
        </>
    );
};

const replacePlaceholders = (template: string = '') => {
    if (!template) return '';

    return template
        .replace('${applicationId}', 'Aks51d4')
        .replace('${companyName}', 'Company')
        .replace('${firstName}', 'John')
        .replace('${lastName}', 'Doe')
        .replace('${matchingId}', 'MM00ab77')
        .replace('${now}', 'Now')
        .replace('${projectName}', 'Project')
        .replace('${roleId}', 'Bc12xs7')
        .replace('${step}', '1')
        .replace('${view}', 'View');
};

export default React.memo(PreviewButton);