import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Reorder} from 'framer-motion';
import {useDataProvider, useNotify, useRecordContext} from 'react-admin';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useQuery, useQueryClient} from 'react-query';

import {Box, InputAdornment, Stack, styled, TextField, Typography} from '@mui/material';

import {Button, DialogConfirm, LoadingMask} from '../../../../components';
import {DELAY_API_CALL} from '../../../../constants';
import {useDebounce} from '../../../../hooks';
import {IClientRole, IMatching, IMatchingResult} from '../../../../models';

import {FreelancerItem} from './FreelancerItem';

export const PAGE_SIZE = 15;

const DragableList = styled(Reorder.Group)(({theme}) => ({
    listStyle: 'none',
    padding: 0,
    paddingBottom: 20,
    margin: 0,
    '& li': {
        padding: '2px 0px'
    }
}));

interface Props {
    matching: IMatching;
}

export const FreelancerList: React.FC<Props> = React.memo(({matching}) => {
    const record = useRecordContext<IClientRole>();
    const queryClient = useQueryClient();

    const {id: matchingId /*, notes, status*/} = matching;

    const [items, setItems] = useState<IMatchingResult[]>([]);
    const itemsRef = useRef<IMatchingResult[]>([]);

    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedIds, setSelectedIds] = useState<Record<number, number | null>>({});
    const [openConfirm, setOpenConfirm] = useState(false);

    const dataProvider = useDataProvider();
    const notify = useNotify();

    const q = useDebounce(searchTerm, DELAY_API_CALL, 0);

    itemsRef.current = items;

    const {data, isFetching, refetch: refetchFreelancers} = useQuery([`admin/matchings/${matchingId}/results`, page, PAGE_SIZE],
        () => dataProvider.getList(`admin/matchings/${matchingId}/results`, {
            filter: {q},
            pagination: {page, perPage: PAGE_SIZE},
            sort: {field: 'position', order: 'ASC'}
        }),
        {
            staleTime: 30 * 60 * 1000,   // cache this request for 30 minutes
            select: (data) => data,
            onSuccess: (response) => {
                // if it is first page then replace an array items
                if (page === 1) {
                    setItems(response.data);

                // add new page to the end of the list
                } else {
                    const newItems = [...itemsRef.current];

                    response.data.forEach(it => {
                        if (!itemsRef.current.find(item => item.id === it.id)) {
                            newItems.push(it);
                        }
                    });

                    setItems(newItems);
                }
            }
        }
    );

    const updateList = useCallback(() => {
        if (q) {
            setSearchTerm('');
            setSelectedIds({});
        } else {
            setPage(1);
            setSelectedIds({});
            setTimeout(() => refetchFreelancers(), 100);
        }
    }, [q, refetchFreelancers]);

    const handleAddToResult = async () => {
        const {unwantedFreelancersIds} = record;
        let ids = Object.entries(selectedIds)
            .filter(([id, freelancerId]) => !!freelancerId)
            .map(([id, freelancerId]) => freelancerId);

        // if nothing selected then select all
        if (!ids.length) {
            const response = await dataProvider.get(`admin/matchings/${matchingId}/results?page=0&size=${data?.total}`, {meta: ''});

            ids = response.data.content.map((it: {freelancerId: number}) => it.freelancerId);
            setItems(response.data.content);
        }

        // waiting for state update
        setTimeout(() => {
            const isIgnored = ids.find(freelancerId => unwantedFreelancersIds.includes(Number(freelancerId)));

            if (isIgnored) {
                setOpenConfirm(true);
            } else {
                mutate();
            }
        }, 100);
    };

    const mutate = async () => {
        let results = Object.entries(selectedIds)
            .filter(([resultId, freelancerId]) => !!freelancerId)
            .map(([resultId]) => ({resultId: Number(resultId)}));

        if (!results.length) {
            results = itemsRef.current.map(it => ({resultId: it.id}));
        }

        try {
            await dataProvider.post(`admin/roles/${record.id}/results`, {data: {results}});
            notify(`${results.length} item(s) were added successfully.`);
            queryClient.invalidateQueries(`admin/roles/${record?.id}/results`);
            updateList();
        } catch (error: any) {
            notify(`Error: ${error.message || error?.body?.error}`, {type: 'warning'});
        }
    };

    const handleConfirm = () => {
        setOpenConfirm(false);
        mutate();
    };

    // autosave position
    const handlePosition = useCallback(async (item: IMatchingResult, position: number) => {
        // const {recommendation, notes} = item;
        // const data = {notes, recommendation, position};

        // if (item.position === position) return;

        // await dataProvider.put(`admin/matchings/${matchingId}/results/${item.id}`, {data});
        // updateList();
        // notify('Successfully saved.');
    }, [/*dataProvider, matchingId, notify, updateList*/]);

    const handleDragEnd = useCallback(async (item: IMatchingResult) => {
        // const index = items.findIndex(it => it.id === item.id);

        // handlePosition(item, index + 1);
    }, []);

    const handleRefresh = useCallback(() => {
        setItems([...items]);
    }, [items]);

    const handleRemove = useCallback((item: IMatchingResult) => {
        // item.isRemoved = true;
        // setItems([...items]);

        // undoableEventEmitter.once('end', ({isUndo}) => {
        //     if (isUndo) {
        //         setItems(items.map(item => ({...item, isRemoved: false})));
        //     } else if (refetch) {
        //         refetch();
        //         updateList();
        //     }
        // });

        // deleteOne(
        //     `admin/matchings/${matchingId}/results`, // deprecated
        //     {id: item.id, previousData: item},
        //     {
        //         mutationMode: 'undoable',
        //         onSuccess: () => {
        //             notify('Freelancer removed', {undoable: true, autoHideDuration: 5000});
        //         },
        //         onError: (error: any) => {
        //             notify(`Error: ${error.message}`, {type: 'warning'});
        //             setItems(items.map(item => ({...item, isRemoved: false})));
        //         },
        //     },
        // );
    }, [/*items, matchingId, deleteOne, notify, refetch, updateList*/]);

    const handleSelect = useCallback((item: IMatchingResult, selected: boolean) => {
        setSelectedIds(ids => ({
            ...ids,
            [item.id]: selected ? item.freelancerId : null
        }));
    }, []);

    const fetchMoreData = useCallback(() => {
        setPage(page => page + 1);
        setTimeout(() => refetchFreelancers(), 100);
    }, [refetchFreelancers]);

    const selectedCount = useMemo(() => Object.values(selectedIds).filter(it => it).length, [selectedIds]);

    useEffect(() => {
        setPage(1);
        setTimeout(() => refetchFreelancers(), 100);
    }, [q, refetchFreelancers]);

    return (
        <>
            <Box sx={{py: 2, px: 2, background: '#e7ebf0', overflow: 'hidden'}}>
                <Stack alignItems="flex-end" direction="row" spacing={2} sx={{mb: 2}}>
                    <TextField
                        autoComplete="off"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {isFetching && (q || !items.length) && <LoadingMask size={16} />}
                                </InputAdornment>
                            ),
                        }}
                        placeholder="Search by name, surname, email ..."
                        sx={{
                            marginBottom: 0,
                            width: 300,
                            '& input': {padding: '8px'}
                        }}
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                    />
                    <Box sx={{flexGrow: 1}} />

                    <Button color="primary" onClick={handleAddToResult}>
                    + Add {selectedCount || 'All'} Freelancer{!selectedCount || selectedCount > 1 ? '(s)' : ''}
                    </Button>
                </Stack>

                {!isFetching && !data?.total && <Typography align="center" sx={{p: 5, opacity: 0.5}}>Records not found</Typography>}

                <Box id={`scrollable-body-${matchingId}`} sx={{maxHeight: '60vh', overflowY: 'auto'}}>
                    <InfiniteScroll
                        dataLength={items.length}
                        next={fetchMoreData}
                        hasMore={!!items.length && !!data?.total && data?.total !== items.length}
                        loader={(
                            <Stack direction="row" spacing={2} sx={{py: 2, opacity: 0.5}} >
                                <LoadingMask size={16} />
                                <Typography component="p" variant="caption">
                                Loading...
                                </Typography>
                            </Stack>
                        )}
                        scrollableTarget={`scrollable-body-${matchingId}`}
                    >
                        <DragableList axis="y" values={items} onReorder={setItems}>
                            {items.filter(item => !item.isRemoved)
                                .map(item => (
                                    <FreelancerItem
                                        isDragable={false}
                                        isIgnored={record.unwantedFreelancersIds.includes(item.freelancerId)}
                                        isSelected={!!selectedIds[item.id]}
                                        isResult={false}
                                        item={item}
                                        key={item.freelancerId}
                                        onDragEnd={handleDragEnd}
                                        onPosition={handlePosition}
                                        onRemove={handleRemove}
                                        onRefresh={handleRefresh}
                                        onSelect={handleSelect}
                                    />
                                ))}
                        </DragableList>
                    </InfiniteScroll>
                </Box>

            </Box>

            <DialogConfirm
                ariaDescribedby="Confirm adding unwanted freelancer to result list"
                confirmationText={<>Are you sure you want to adding unwanted freelancer to the result list?</>}
                open={openConfirm}
                title="Confirmation"
                onNo={() => setOpenConfirm(false)}
                onYes={handleConfirm}
            />
        </>

    );
});
