import React from 'react';
import {Create, maxLength, required, SaveButton, SimpleForm, TextInput, Toolbar, ToolbarProps, useNotify, useRefresh} from 'react-admin';

import {Grid, Stack} from '@mui/material';

import {Button} from '../../../components';
import {styledButton, styledInput} from '../../../styles';
import {validateURL} from '../../../utils';

import RecommendationVariables from './RecommendationVariables';

interface IProps {
    onClose: (value: boolean) => void;
}

export const RecommendationCreate: React.FC<IProps> = ({onClose}) => {
    const notify = useNotify();
    const refresh = useRefresh();

    const onSuccess = () => {
        notify('New item created');
        refresh();
        onClose(false);
    };

    return (
        <Create
            mutationOptions={{onSuccess}}
            resource="admin/recommendations"
            title="Create Recommendation Template"
        >
            <SimpleForm sx={{maxWidth: 700}} toolbar={<RecommendationCreateToolbar onCancel={() => onClose(false)}/>}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextInput fullWidth source="header" sx={styledInput} validate={[required(), maxLength(50)]}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextInput fullWidth label="Name" source="author" sx={styledInput} validate={[required(), maxLength(50)]}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextInput fullWidth label="Position" source="authorPosition" sx={styledInput} validate={[required(), maxLength(50)]}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput label="Avatar URL" fullWidth placeholder="https://..." source="authorAvatar" sx={styledInput} validate={validateURL}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            helperText={<RecommendationVariables/>}
                            multiline rows={4}
                            source="text"
                            sx={styledInput}
                            validate={[required(), maxLength(400)]}
                        />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    );
};

interface IRecommendationCreateToolbarProps extends ToolbarProps {
    onCancel: () => void;
}

const RecommendationCreateToolbar: React.FC<IRecommendationCreateToolbarProps> = ({onCancel, ...props}) => {
    return (
        <Toolbar {...props} sx={{justifyContent: 'space-between'}}>
            <Stack direction="row" spacing={2}>
                <SaveButton sx={styledButton}/>
                <Button
                    sx={styledButton}
                    variant="outlined"
                    onClick={onCancel}
                >
                    Cancel
                </Button>
            </Stack>
        </Toolbar>
    );
};


