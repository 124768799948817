import {styled} from '@mui/material';

import theme from '../../../theme';

export const SM = `${theme.breakpoints.values.sm}px`;
export const MD = `${theme.breakpoints.values.md}px`;
export const LG = `${theme.breakpoints.values.lg}px`;
export const XL = `${theme.breakpoints.values.xl}px`;

export const Item = styled('div')<{
    gridArea: string;
    padding?: string;
    backgroundColor?: string;
    backgroundImage?: string;
    boxShadow?: string;
    borderColor?: string;
    overflowVisible?: boolean;
    widthXl?: string;
}>`
  grid-area: ${props => props.gridArea};
  padding: ${props => props.padding ? props.padding : '24px'};
  background-color: ${props => props.backgroundColor ? props.backgroundColor : theme.palette.white.main};
  background-image: ${props => props.backgroundImage ? props.backgroundImage : 'none'};
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 24px;
  border: ${props => props.borderColor ? `2px solid ${props.borderColor}` : 'unset'};
  box-shadow: ${props => props.boxShadow ? props.boxShadow : 'unset'};
  overflow: ${props => props.overflowVisible ? 'visible' : 'hidden'};

  @media (min-width: 1400px) {
    width: ${props => props.widthXl ? props.widthXl + 'px' : 'auto'};
  }
`;

export const WrpFreelancer = styled('div')<{ hasRecommendation?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 40px;
  padding-bottom: 170px;
  padding-right: 20px;
  margin-right: -20px;
  overflow: hidden;

  @media (min-width: ${SM}) {
    display: grid;
    grid-template-areas: 
    'backButton .'
    'nameBox nameBox'
    'rateBox fitBudgetBox'
    'aboutBox aboutBox '
    'matchesBox matchesBox '
    'certificationsAndCaseStudiesBox certificationsAndCaseStudiesBox'
  ;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${LG}) {
    padding-bottom: 48px;
    grid-template-areas: 
    'backButton . .'
    'nameBox nameBox nameBox'
    'rateBox fitBudgetBox fitBudgetBox'
    'aboutBox certificationsAndCaseStudiesBox certificationsAndCaseStudiesBox'
    'matchesBox certificationsAndCaseStudiesBox certificationsAndCaseStudiesBox'
  ;
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1400px) {
    grid-template-areas: 
    'backButton . . .'
    'nameBox nameBox nameBox rateBox'
    'nameBox nameBox nameBox fitBudgetBox'
    'aboutBox certificationsAndCaseStudiesBox certificationsAndCaseStudiesBox certificationsAndCaseStudiesBox'
    'matchesBox certificationsAndCaseStudiesBox certificationsAndCaseStudiesBox certificationsAndCaseStudiesBox'
  ;
    grid-template-columns: 589px auto 223px 341px;
    gap: 24px;
  }

  @media (min-width: ${LG}) {
    padding-top: 56px;
  }

  @media (min-width: 1728px) {
    overflow: visible;
  }
`;
