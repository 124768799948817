import * as React from 'react';
import {ChangeEvent, useState} from 'react';
import clsx from 'clsx';

import {Box, FormControlLabel, Radio as MUIRadio, RadioProps as MuiRadioProps, styled} from '@mui/material';

import theme from '../../theme';

import RadioCheckedCircleIcon from './RadioCheckedCircleIcon';

interface RadioProps extends MuiRadioProps {
    label?: string;
}

const EmptyCircle = styled('span')<{ selected?: boolean }>`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 50%;
  border: 2px solid ${theme.palette.lightGray.main};
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 24px;
    height: 24px;
    opacity: ${props => props.selected ? 1 : 0};
    transition: opacity .3s;
  }
`;

export const RadioCoreUI = ({label, ...props}: RadioProps): JSX.Element => {
    const [checked, setChecked] = useState(!!props.checked);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setChecked(e.target.checked);
    };

    return (
        <FormControlLabel
            sx={{
                border: '2px solid',
                borderColor: props.checked ? theme.palette.primary.main : theme.palette.lightGray.main,
                borderRadius: '50px',
                margin: 0,
                padding: '6px 9px 6px 10px'
            }}
            control={<MUIRadio
                checked={checked}
                className={clsx(props.className)}
                sx={{
                    display: 'none'
                }}
                color="primary"
                {...props}
                onChange={handleChange}
            />}
            label={
                <Box sx={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    gap: '12px',
                    alignItems: 'center',
                    'span': {
                        fontWeight: 400,
                        fontSize: '13px',
                        lineHeight: '16px'
                    }
                }}>
                    <EmptyCircle selected={props.checked}>
                        <RadioCheckedCircleIcon/>
                    </EmptyCircle>
                    <span>{label}</span>
                </Box>
            }
        />
    );
};
