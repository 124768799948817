import React, {useState} from 'react';
import {Button, SaveButton, Toolbar, useDataProvider, useNotify, useRecordContext, useRefresh} from 'react-admin';

import MoneyOffCsredIcon from '@mui/icons-material/MoneyOffCsred';
import PaymentsIcon from '@mui/icons-material/Payments';

import {ButtonPortal} from '../../../components';
import {EPaymentMethod, ERole, IPaymentPreview} from '../../../models';
import {styledButton, styledDeleteButton} from '../../../styles';
import {hasPermissions} from '../..';

const EditFormToolbarCoreUI = (props: any) => {
    const [submitting, setSubmitting] = useState(false);
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const refetch = useRefresh();

    const notify = useNotify();

    const paymentMethodInvoice = record?.paymentMethods?.find((it: IPaymentPreview) => it.method === EPaymentMethod.ManualInvoicing);

    const handleInvoiceClick = async () => {
        setSubmitting(true);
        try {
            await dataProvider.post(
                `admin/clients/${record.id}/payments/methods`,
                {
                    data: {
                        method: EPaymentMethod.ManualInvoicing
                    }
                }
            );
            notify('Payment method successfully updated');
            refetch();
        } catch (error: any) {
            notify(`Error: ${error.message}`, {type: 'warning'});
        }
        setSubmitting(false);
    };

    const handleRemoveInvoiceClick = async () => {
        setSubmitting(true);
        try {
            await dataProvider.delete(`admin/clients/${record.id}/payments/methods`,
                {id: paymentMethodInvoice.id}
            );
            notify('Payment method successfully removed');
            refetch();
        } catch (error: any) {
            notify(`Error: ${error.message}`, {type: 'warning'});
        }
        setSubmitting(false);
    };

    return (
        <Toolbar sx={{justifyContent: 'flex-start', gap: 2}}>
            <SaveButton alwaysEnable={props.alwaysEnable} sx={styledButton} />

            {/* Impersonal Portal view */}
            <ButtonPortal/>

            {hasPermissions([ERole.SuperAdmin]) ? (
                <>
                    {paymentMethodInvoice ? (
                        <Button
                            disabled={submitting}
                            label="Remove Pay by Invoice"
                            startIcon={<MoneyOffCsredIcon/>}
                            sx={styledDeleteButton}
                            variant="outlined"
                            onClick={handleRemoveInvoiceClick}
                        />
                    ) : (
                        <Button
                            disabled={submitting}
                            label="Pay by Invoice"
                            startIcon={<PaymentsIcon/>}
                            sx={styledButton}
                            variant="outlined"
                            onClick={handleInvoiceClick}
                        />
                    )}
                </>
            ) : null}

            {/* <DeleteButton sx={styledDeleteButton} /> */}
        </Toolbar>
    );
};

export default EditFormToolbarCoreUI;
