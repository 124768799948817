import * as React from 'react';
import {ReactNode} from 'react';
import clsx from 'clsx';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Typography} from '@mui/material';
import MuiAccordion, {AccordionProps} from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import {styled, SxProps} from '@mui/material/styles';

import {ellipsisText} from '../../utils';


export const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.9rem'}} />}
        {...props}
    />
))(({theme}) => ({
    backgroundColor: 'rgba(0, 0, 0, .03)',
    '& .MuiAccordionSummary-expandIconWrapper': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(0deg)',
    },
    '& .Mui-expanded .info-text': {
        display: 'none'
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

interface SummaryProps {
    actions?: ReactNode;
    background?: string;
    children: React.ReactNode;
    color?: string;
    id: string;
    indicator?: React.ReactNode;
    isSubTitleStatic?: boolean;
    sx?: SxProps;
    subtitle: string;
    title: string;
}

const INFO_LIMIT = 120;

export const AccordionSummaryInfo: React.FC<SummaryProps> = ({
    actions,
    background,
    color,
    id,
    indicator,
    isSubTitleStatic = false,
    sx,
    subtitle,
    title
}) => {
    const displayInfo = ellipsisText(subtitle, INFO_LIMIT);

    return (
        <AccordionSummary
            className={id}
            expandIcon={<ExpandMoreIcon />}
            id={id}
            sx={{
                background,
                '&.Mui-focusVisible': {
                    backgroundColor: background || ''
                },
                '& .MuiAccordionSummary-content': {
                    alignItems: 'center',
                },
                ...sx,
            }}
        >
            {indicator}
            <Typography
                sx={{
                    color,
                    width: '150px',
                    flexShrink: 0
                }}
            >
                {title}
            </Typography>
            <Typography
                className={clsx({'info-wrapper': true, 'info-text': isSubTitleStatic})}
                noWrap
                sx={{
                    color,
                    opacity: color ? 1 : 0.5
                }}
            >{displayInfo}</Typography>
            {actions}
        </AccordionSummary>
    );
};
