import React, {useState} from 'react';
import {useCreate, useRecordContext, useRefresh} from 'react-admin';

import {Grid, TextField as MUITextField,} from '@mui/material';

import {Button} from '../../../components';
import {styledInput} from '../../../styles';

export const AddSubChannelButton = () => {
    const [inputValue, setInputValue] = useState('');
    const record = useRecordContext();
    const refresh = useRefresh();

    const payload = {channelId: record.id, name: inputValue};
    const [create, {isLoading, error}] = useCreate();
    const handleClick = () => {
        create('channels', {data: payload}, {
            onSuccess: () => {
                refresh();
                setInputValue('');
            }
        });
    };

    if (error) { return <p>ERROR</p>; }

    return (
        <Grid container spacing={1} direction="row">
            <Grid item>
                <MUITextField
                    placeholder="New Sub-channel"
                    size="small"
                    value={inputValue}
                    sx={styledInput}
                    onChange={(e) => setInputValue(e.target.value)}
                />
            </Grid>
            <Grid item>
                <Button
                    disabled={isLoading || !inputValue}
                    onClick={handleClick}
                >
                    + Add
                </Button>
            </Grid>
        </Grid>
    );
};