import React, {FC} from 'react';
import {Datagrid, DateField, List, TextField} from 'react-admin';

import {MoneyField, PaginationCoreUI} from '../../components';
import {styledDatagrid, styledList} from '../../styles';

import {CompanyNameField, defaultFiltersValues, ListActions, PaymentField, PayoutField, TransactionPanel} from './components';

// TransactionList don't have roleId at all
// EngagementShow has Transactions tab and use roleId there
// ClientShow has Billing tab and use role ids array there
interface IProps {
    isCompact?: boolean;
    roleId: number | number[];
}

export const PaymentsList: FC<IProps> = ({isCompact, roleId}) => (
    <List
        actions={
            roleId && !Array.isArray(roleId)
                ? undefined
                : <ListActions isCompact={!!isCompact}/>
        }
        disableSyncWithLocation={!!roleId || Array.isArray(roleId)}
        filterDefaultValues={defaultFiltersValues}
        filter={roleId ? ({roles: Array.isArray(roleId) ? roleId : [roleId]}) : undefined}
        pagination={<PaginationCoreUI/>}
        resource={roleId ? 'payments' : undefined}
        sort={{field: 'createdAt', order: 'DESC'}}
        sx={styledList}
    >
        <Datagrid
            bulkActionButtons={false}
            expand={<TransactionPanel/>}
            // rowClick="show"
            sx={{
                ...styledDatagrid,
                '& th:first-of-type': {
                    padding: 0,
                },
                '& .column-clientCompanyName, & .column-payment, & .column-payout, & .column-fee': {
                    padding: '2px'
                },
                '& .MuiTableCell-root:nth-of-type(1)': {
                    // maxWidth: '30%',
                    // width: '30%'
                },
                '& .RaDatagrid-expandedPanel td': {
                    padding: 0,
                }
            }}
        >
            <CompanyNameField isRedirectBtn={!Array.isArray(roleId)} label="Company name" source="clientCompanyName" sortable={false}/>
            <TextField source="engagementId"/>
            <PaymentField source="payment"/>
            <PayoutField source="payout"/>
            <MoneyField source="amount"/>
            <DateField source="createdAt"/>
            <DateField source="updatedAt"/>

            {/* <ColumnLabeled source="actions" sortable={false}>
                <ShowButton label="" sx={styledListShowButton} />
            </ColumnLabeled> */}
        </Datagrid>
    </List>
);
