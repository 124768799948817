import React from 'react';
import {Layout as RALayout, LayoutProps} from 'react-admin';

import {CssBaseline} from '@mui/material';

import {NotificationContextProvider} from '../../contexts';
import {AppBarCoreUI} from '../AppBar/AppBarCoreUI';
import MenuCoreUI from '../MenuCoreUI';
import {NotificationDialog} from '../Notifications';

export const Layout = (props: LayoutProps) => (
    <NotificationContextProvider>
        <CssBaseline />
        <RALayout
            {...props}
            appBar={AppBarCoreUI}
            menu={MenuCoreUI}
            sx={styledLayout}
        />
        <NotificationDialog />
    </NotificationContextProvider>
);

const styledLayout = {
    '& #main-content': {
        marginTop: '16px',
        paddingTop: 1,
        backgroundColor: '#f8fafb'
    },
    '& .RaSidebar-fixed': {
        '& .MuiButtonBase-root': {
            padding: '1rem 16px',
        },
        '& .MuiButtonBase-root:not(.RaMenuItemLink-active)': {
            color: 'rgba(44, 56, 74, 0.681)',

            '& .RaMenuItemLink-icon': {
                color: 'rgba(44, 56, 74, 0.681)',
            }
        },
        '& .MuiButtonBase-root.RaMenuItemLink-active': {
            color: 'rgba(44, 56, 74, 0.95)',

            '& .RaMenuItemLink-icon': {
                color: '#5046e5',
            }
        }
    }
};
