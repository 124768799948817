import React, {FocusEvent, KeyboardEvent} from 'react';
import {TextInput, useDataProvider, useNotify, useRecordContext} from 'react-admin';

import {Typography} from '@mui/material';

import {EApplicationStatus} from '../../../../models';
import {styledInput, styledLabelShow} from '../../../../styles';

export const ApplicationNotes = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const handleBlur = async (e: FocusEvent<HTMLInputElement>) => {
        if (record.reviewNotes !== e.target.value) {
            await dataProvider.put(
                `admin/applications/${record.id}/review/notes`,
                {
                    data: {
                        reviewNotes: e.target.value,
                    }
                }
            );

            notify('Note successfully updated');
        }
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            (e.target as HTMLElement).blur();
        }
    };

    return (
        <>
            <Typography sx={{...styledLabelShow, marginTop: '30px'}}>Notes</Typography>
            <TextInput
                autoComplete="off"
                disabled={[EApplicationStatus['In Progress'], EApplicationStatus.Accepted].includes(record.status)}
                fullWidth
                helperText="Max 255 characters"
                inputProps={{maxLength: 225}}
                label=""
                multiline
                rows={2}
                source="reviewNotes"
                sx={styledInput}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
            />
        </>
    );
};
