import React from 'react';
import {Menu} from 'react-admin';

import AddRoadOutlinedIcon from '@mui/icons-material/AddRoadOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import CopyrightOutlinedIcon from '@mui/icons-material/CopyrightOutlined';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import DynamicFeedOutlinedIcon from '@mui/icons-material/DynamicFeedOutlined';
import HistoryIcon from '@mui/icons-material/History';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import PaidIcon from '@mui/icons-material/Paid';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import RuleOutlinedIcon from '@mui/icons-material/RuleOutlined';
import WidgetsIcon from '@mui/icons-material/Widgets';
import {Typography} from '@mui/material';

import {ERole} from '../../models';
import {hasPermissions} from '../../modules/Auth/keycloak';

const styledDivider = {
    padding: '16px',
    fontSize: '12px',
    fontWeight: '700',
    marginTop: '1rem',
    color: 'rgba(44, 56, 74, 0.681)',
    borderTop: 'solid 1px transparent',
    transition: '0.3s ease all',
};

const styledDividerCollapsedMenu = {
    color: 'transparent',
    borderColor: '#ebedef',
    padding: '0 16px',
    paddingTop: '0',
};

const MenuCoreUI = () => {
    return (
        <Menu sx={{marginTop: '16px', '&.RaMenu-closed .menu-divider': styledDividerCollapsedMenu}}>
            {hasPermissions([ERole.SuperAdmin], true) && <AdminMenuItems />}
            {hasPermissions([ERole.Billing], true) && <BillingMenuItems />}
            {hasPermissions([ERole.Matcher], true) && <MatcherMenuItems />}
            {hasPermissions([ERole.Reviewer], true) && <ReviewerMenuItems />}
        </Menu>
    );
};

const AdminMenuItems = () => (
    <>
        <Menu.DashboardItem />
        <Typography sx={styledDivider} className="menu-divider">
            CLIENT
        </Typography>
        <Menu.Item to="/clients" primaryText="Clients" leftIcon={<PeopleOutlinedIcon />}/>
        <Menu.Item to="/roles" primaryText="Roles" leftIcon={<AssignmentIndOutlinedIcon />}/>
        <Menu.Item to="/engagements" primaryText="Engagements" leftIcon={<MoreTimeIcon />}/>
        <Menu.Item to="/payments" primaryText="Transactions" leftIcon={<PaidIcon />}/>
        <Menu.Item to="/matcher-settings" primaryText="Matcher settings" leftIcon={<RuleOutlinedIcon />}/>
        <Typography sx={styledDivider} className="menu-divider">
            FREELANCER
        </Typography>
        <Menu.Item to="/applications" primaryText="Applications" leftIcon={<AssignmentOutlinedIcon />}/>
        <Menu.Item to="/freelancers" primaryText="Freelancers" leftIcon={<PeopleOutlinedIcon />}/>
        <Menu.Item to="/timesheets" primaryText="Timesheets" leftIcon={<MoreTimeIcon />}/>
        <Typography sx={styledDivider} className="menu-divider">
            MARKETING
        </Typography>
        <Menu.Item to="/widgets" primaryText="Widgets" leftIcon={<WidgetsIcon />}/>
        <Typography sx={styledDivider} className="menu-divider">
            NOTIFICATIONS
        </Typography>
        <Menu.Item to="/notifications-rules" primaryText="Rules" leftIcon={<DesignServicesIcon />}/>
        <Menu.Item to="/notifications" primaryText="History" leftIcon={<HistoryIcon />}/>
        <Typography sx={styledDivider} className="menu-divider">
            META
        </Typography>
        <Menu.Item to="/channels" primaryText="Channels" leftIcon={<DynamicFeedOutlinedIcon />}/>
        <Menu.Item to="/verticals" primaryText="Verticals" leftIcon={<AddRoadOutlinedIcon />}/>
        <Menu.Item to="/tools" primaryText="Tools" leftIcon={<CategoryOutlinedIcon />}/>
        <Menu.Item to="/brands" primaryText="Brands" leftIcon={<CopyrightOutlinedIcon />}/>
        <Typography sx={styledDivider} className="menu-divider">
            Settings
        </Typography>
        <Menu.Item to="/users" primaryText="Admins" leftIcon={<PeopleAltIcon />}/>
    </>
);

const BillingMenuItems = () => (
    <>
        <Typography sx={styledDivider} className="menu-divider">
            CLIENT
        </Typography>
        <Menu.Item to="/engagements" primaryText="Engagements" leftIcon={<MoreTimeIcon />}/>
        <Menu.Item to="/payments" primaryText="Transactions" leftIcon={<PaidIcon />}/>
    </>
);

const ReviewerMenuItems = () => (
    <>
        <Typography sx={styledDivider} className="menu-divider">
            FREELANCER
        </Typography>
        <Menu.Item to="/applications" primaryText="Applications" leftIcon={<AssignmentOutlinedIcon />}/>
    </>
);

const MatcherMenuItems = () => (
    <>
        <Typography sx={styledDivider} className="menu-divider">
            CLIENT
        </Typography>
        <Menu.Item to="/roles" primaryText="Roles" leftIcon={<AssignmentIndOutlinedIcon />}/>
    </>
);

export default MenuCoreUI;
