import format from 'date-fns/format';
import {parse} from 'duration-fns';

export const convertDateToString = (date: Date) => normalizeDateFormat(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`, '-');

export const convertDateTimeToString = (date: Date) => `${convertDateToString(date)}T${normalizeDateFormat(date.getHours() + ':' + date.getMinutes(), ':')}`;

const normalizeDateFormat = (dateStr: string, divider: '-' | ':') => dateStr.split(divider)
    .map((num: string) => num.length < 2 ? `0${num}` : num)
    .join(divider);

// isCompact                    not compact
// P0H0M   -> 00:00             P0H0M   -> 0h 0m
// P3H15M  ->  3:15             P3H15M  -> 3h 15m
// P21H15M -> 21:15             P21H15M -> 21h 15m
export const formatHoursFromDuration = (value: string, isCompact = true): string => {
    const {hours, minutes} = parse(value);

    if (isCompact) {
        return `${!hours && !minutes ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
    } else {
        return `${hours}h ${minutes < 10 ? '0' : ''}${minutes}m`;
    }
};

const dateWithouTimezone = (date: string | Date) => {
    if (typeof date === 'string' && !date.includes('T')) {
        const dt = new Date(date);

        return new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
    }

    return date instanceof Date ? date : new Date(date);
};

export const formatWeek = (date: string | Date): string => {
    const dtDateOnly = dateWithouTimezone(date);

    return format(dtDateOnly, 'E, MMM d');
};
