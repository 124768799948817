import React, {FC, ReactNode} from 'react';

import {Grid, Typography} from '@mui/material';

import {ButtonRedirectUrl} from '../../../../components';
import {styledLabelShow} from '../../../../styles';
import {formatter} from '../../../../utils';

const styledLabel = {
    ...styledLabelShow,
    fontSize: '12px !important',
    fontWeight: 900,
};

interface IRowProps {
    amount?: number;
    isClient: boolean;
    fee: number;
    name: string;
    payment?: ReactNode;
    problem: string;
    redirect: string;
    status: ReactNode;
}

export const TransactionRow: FC<IRowProps> = ({
    amount, isClient, fee, name, payment, problem, redirect, status
}) => {
    return (
        <>
            <Grid item xs={2}>
                <Typography sx={styledLabel}>Status</Typography>
                {status}
            </Grid>
            <Grid item xs={3}>
                <Typography sx={styledLabel}>
                    {isClient ? 'Role name' : 'Freelancer'}
                </Typography>
                {name} &nbsp;
                <ButtonRedirectUrl path={redirect}/>
            </Grid>
            <Grid item xs={3}>
                <Typography sx={styledLabel}>
                    {isClient ? 'Payment' : 'Payout'}
                </Typography>
                {payment}
            </Grid>
            <Grid item xs={2}>
                <Typography sx={styledLabel}>
                    {isClient ? 'Amount charged' : 'Amount paid'}
                </Typography>
                {amount === undefined ? 'n/a' : formatter.format(amount ? (amount / 100) : 0)}
            </Grid>
            <Grid item xs={2}>
                <Typography sx={styledLabel}>Fee</Typography>
                {fee !== undefined ? (<>
                    -{formatter.format(fee / 100)}
                </>) : null}
            </Grid>
            {problem && (
                <Grid item xs={12}>
                    <Typography
                        color="error"
                        sx={{fontSize: '12px !important'}}
                        variant="caption"
                    >
                        {problem}
                    </Typography>
                </Grid>
            )}
        </>
    );
};

export default React.memo(TransactionRow);
