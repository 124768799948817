import React, {ReactNode} from 'react';

import GradeIcon from '@mui/icons-material/Grade';
import ScheduleIcon from '@mui/icons-material/Schedule';
import {Badge, Box} from '@mui/material';

import {LoadingMask} from '../../../../components';
import {EMatchingResultStatus, EMatchStatus, IMatching} from '../../../../models';
import theme from '../../../../theme';
import {formatNameToCapital} from '../../../../utils';

export const formatTitle = (matching: IMatching) => {
    const suffix = `- Found ${matching.resultsCount} freelancers`;

    switch (matching.status) {
        case EMatchingResultStatus.PlannedShare:
            return `${new Date(matching.plannedShare).toLocaleString()} ${suffix}`;
        default:
            return `${new Date(matching.created).toLocaleString()} ${suffix}`;
    }
};

export const getBGByStatus = (status: EMatchingResultStatus): string => {
    switch (status) {
        case EMatchingResultStatus.Active:
            return '#1fb546';
        case EMatchingResultStatus.InProgress:
            return '#bcebbc';
        case EMatchingResultStatus.PlannedShare:
            return '#ffbe05';
        case EMatchingResultStatus.Shared:
        case EMatchingResultStatus.SharedAfterReview:
            return '#00adff';
        default:
            return '';
    }
};

export const getColorByStatus = (status: EMatchingResultStatus): string => {
    switch (status) {
        case EMatchingResultStatus.Active:
        case EMatchingResultStatus.Shared:
        case EMatchingResultStatus.SharedAfterReview:
            return 'white!important';
        case EMatchingResultStatus.PlannedShare:
            return 'black!important';
        default:
            return '';
    }
};

export const getColorByMatchingStatus = (status: EMatchStatus): string => {
    switch (status) {
        case EMatchStatus.ACCEPTED:
            return theme.palette.primary.main;
        case EMatchStatus.HIDDEN:
            return theme.palette.inputDisabledBorderColor.main;
        case EMatchStatus.LIKED:
        case EMatchStatus.LIKED_IN_TOUCH:
        case EMatchStatus.IN_TOUCH:
            return '#7E5FFF';
        case EMatchStatus.PROPOSED:
        case EMatchStatus.UNLIKED:
            return '#000000';
        case EMatchStatus.REJECTED_BY_CLIENT:
        case EMatchStatus.REJECTED_BY_FREELANCER:
        case EMatchStatus.REJECTED_IN_TOUCH:
        case EMatchStatus.REJECTED_LIKED:
            return theme.palette.error.main;
        default:
            return '';
    }
};

export const getTitleByMatchStatus = (status: EMatchStatus): string => {
    switch (status) {
        case EMatchStatus.IN_TOUCH:
        case EMatchStatus.LIKED_IN_TOUCH:
            return 'Messaged';

        case EMatchStatus.REJECTED_BY_FREELANCER:
            return 'Refused';

        case EMatchStatus.REJECTED_BY_CLIENT:
        case EMatchStatus.REJECTED_IN_TOUCH:
        case EMatchStatus.REJECTED_LIKED:
            return 'Rejected';

        case EMatchStatus.PROPOSED:
        case EMatchStatus.UNLIKED:
            return 'Shared';

        default:
            return formatNameToCapital(status);
    }
};

export const getIndicatorByStatus = (status: EMatchingResultStatus): ReactNode => {
    switch (status) {
        case EMatchingResultStatus.Active:
            return (
                <Badge
                    variant="dot"
                    sx={{
                        '& .MuiBadge-badge': {
                            background: '#ff1b1b',
                            transform: 'scale(1) translate(-90%, -50%)'
                        }
                    }}
                />
            );
        case EMatchingResultStatus.InProgress:
            return (
                <Box sx={{left: '2px', top: '13px', position: 'absolute'}}>
                    <LoadingMask size={10} />
                </Box>
            );
        case EMatchingResultStatus.PlannedShare:
            return (
                <Box sx={{left: '2px', top: '13px', position: 'absolute'}}>
                    <ScheduleIcon fontSize="small" />
                </Box>
            );
        case EMatchingResultStatus.Shared:
            return (
                <Box sx={{left: '2px', top: '13px', position: 'absolute', color: 'yellow'}}>
                    <GradeIcon fontSize="small" />
                </Box>
            );
        default:
            return null;
    }
};

// export const getTitleByStatus = (status: EMatchingResultStatus): string => {
//     switch (status) {
//         case EMatchingResultStatus.Active:
//             return 'Active List';
//         case EMatchingResultStatus.InProgress:
//             return 'In Progress';
//         case EMatchingResultStatus.PlannedShare:
//             return 'Planned Share';
//         case EMatchingResultStatus.Shared:
//             return 'Shared List';
//         default:
//             return 'Match Result';
//     }
// };
