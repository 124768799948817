import React from 'react';
import {SaveButton, Toolbar, TopToolbar, useRecordContext} from 'react-admin';

import {Button} from '@mui/material';

import {EApplicationStatus} from '../../../models';

interface Props {
    onAddCaseStudy: () => void;
    onAddCertificate: () => void;
    onAddReference: () => void;
}
export const ActionsTop = ({onAddCaseStudy, onAddCertificate, onAddReference}: Props) => {
    const record = useRecordContext();

    if (!record) return null;

    if ([EApplicationStatus['In Progress'], EApplicationStatus.Accepted].includes(record.status)) {
        return null;
    }

    return (
        <TopToolbar>
            <Button color="primary" onClick={onAddCertificate}>+ Add Certificate</Button>
            <Button color="primary" onClick={onAddReference}>+ Add Reference</Button>
            <Button color="primary" onClick={onAddCaseStudy}>+ Add Case study</Button>
            {/* <Button color="primary">Send Email</Button> */}
        </TopToolbar>
    );
};

export const ActionsBottom = (props: any) => (
    <Toolbar sx={{justifyContent: 'space-between'}}>
        <SaveButton />
    </Toolbar>
);
