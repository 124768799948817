import React, {useCallback, useState} from 'react';
import {useDataProvider, useEditContext, useNotify, useRecordContext} from 'react-admin';

import {FormControlLabel, Grid, Switch} from '@mui/material';

import {LoadingMask} from '../../../components';
import {IClientRole} from '../../../models';


export const NotificationSettings = () => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const record: IClientRole = useRecordContext();
    const {refetch} = useEditContext();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = useCallback(async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setIsSubmitting(true);
        try {
            await dataProvider.put(
                `admin/clients/${record.id}`,
                {
                    data: {
                        ...record,
                        allowEmails: checked
                    }
                }
            );
            notify('Settings successfully updated');
            refetch && refetch();
        } catch (error: any) {
            notify(`Error: ${error?.body?.error}`, {type: 'warning'});
        }
        setIsSubmitting(false);
    }, [dataProvider, record, notify, refetch]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Switch checked={record.allowEmails} disabled={isSubmitting} onChange={handleChange}/>
                    }
                    label={
                        <Grid container spacing={1}>
                            <Grid item>
                                Enable email notifications</Grid>
                            <Grid item>
                                {isSubmitting && <LoadingMask size={16}/>}
                            </Grid>
                        </Grid>
                    }
                />
            </Grid>
        </Grid>
    );
};
