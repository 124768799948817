import React from 'react';

import {Box, Grid, Typography} from '@mui/material';

import {Pill} from '../../../components';
import {EWorkType, EWorkTypeDisplay, IHistoryProposal} from '../../../models';
import {styledLabelShow} from '../../../styles';
import {formatter} from '../../../utils';

type ProposalHistoryProps = {
    data: IHistoryProposal;
};

const ProposalHistory: React.FC<ProposalHistoryProps> = ({data}) => {
    return (
        <Box
            sx={{
                border: '1px solid #7E5FFF',
                background: '#F5F2FF',
                borderRadius: '16px',
                p: 1
            }}
        >
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <Typography sx={styledLabelShow}>Start date</Typography>
                    <Typography variant="body2">{data.startDate || '/'}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography sx={styledLabelShow}>Rate</Typography>
                    <Pill
                        variant="purple"
                    >
                        {formatter.format((data.rate || 0) / 100)}{data.budgetType === EWorkType.hourly ? '/hour' : ''}
                    </Pill>
                </Grid>
                <Grid item xs={3}>
                    <Typography sx={styledLabelShow}>Budget type</Typography>
                    <Pill
                        variant="white"
                    >
                        {EWorkTypeDisplay[data.budgetType]}
                    </Pill>
                </Grid>
                <Grid item xs={3}>
                    <Typography sx={styledLabelShow}>Is Monthly</Typography>
                    <Typography variant="body2">{data.isMonthly ? 'Yes' : 'No'}</Typography>
                </Grid>

            </Grid>
        </Box>
    );
};

export default ProposalHistory;
