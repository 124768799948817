import React, {useEffect, useRef, useState} from 'react';
import Keycloak from 'keycloak-js';
import {httpClient, keycloakAuthProvider} from 'ra-keycloak';
import {Admin, AuthProvider, CustomRoutes, DataProvider, localStorageStore, Resource} from 'react-admin';
import {Route} from 'react-router-dom';

import AddRoadIcon from '@mui/icons-material/AddRoad';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import CategoryIcon from '@mui/icons-material/Category';
import CopyrightIcon from '@mui/icons-material/Copyright';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import PaidIcon from '@mui/icons-material/Paid';
import PeopleIcon from '@mui/icons-material/People';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import WidgetsIcon from '@mui/icons-material/Widgets';
import {Typography} from '@mui/material';

import springBootProvider from './contexts/dataProvider';
import {AdminNewPassword, AdminsCreate, AdminsEdit, AdminsList} from './modules/Admins';
import {PaymentShow, PaymentsList} from './modules/Payments';
import {TimesheetsList} from './modules/Timesheets';
import {WidgetCreate, WidgetEdit, WidgetList} from './modules/Widget';
import {Layout, PageWrp, PublicProfileClient, PublicProfileFreelancer} from './components';
import {ERole} from './models';
import {
    ApplicationEdit, ApplicationList,
    BrandCreate, BrandEdit, BrandList,
    ChannelCreate, ChannelEdit, ChannelList, checkToken,
    ClientEdit, ClientList, configKeycloak,
    Dashboard,
    EngagementList, EngagementShow,
    FreelancerList, FreelancerShow,
    hasPermissions,
    initOptionsKeycloak,
    MatcherSettings,
    NotificationList,
    NotificationRuleCreate,
    NotificationsRuleEdit,
    NotificationsRuleList,
    raKeycloakOptions,
    RoleList, RoleShow,
    ToolCreate, ToolEdit, ToolList,
    UIToolboxPage,
    VerticalCreate, VerticalEdit, VerticalList} from './modules';
import theme from './theme';

const STORE_VERSION = '4';
const noAuthRoutes = [
    '#/set-password'
];

const App = () => {
    const [keycloak, setKeycloak] = useState<Keycloak>();
    const authProvider = useRef<AuthProvider>();
    const dataProvider = useRef<DataProvider>();

    useEffect(() => {
        const initKeyCloakClient = async () => {
            const keycloakClient = new Keycloak(configKeycloak);

            if (!noAuthRoutes.includes(location.hash.split('?')[0])) {
                await keycloakClient.init(initOptionsKeycloak);
            }

            authProvider.current = keycloakAuthProvider(
                keycloakClient,
                raKeycloakOptions
            );
            authProvider.current.getToken = () => keycloakClient.token;

            dataProvider.current = springBootProvider(
                location.hostname === 'localhost' ? '' : process.env.REACT_APP_BASE_URL,
                httpClient(keycloakClient)
            );
            setKeycloak(keycloakClient);

            (window as any).GC_Identity = await (authProvider.current as any).getIdentity();
        };

        if (!keycloak) {
            initKeyCloakClient();
        }
    }, [keycloak]);

    useEffect(() => {
        if (!keycloak) {
            return;
        }
        const id = setInterval(() => {
            checkToken(keycloak.updateToken(30)); // if token will be expired after 30 seconds try to refresh
        }, 5000);

        return () => clearInterval(id);
    }, [keycloak]);

    // hide the admin until the keycloak client is ready
    if (!keycloak) {
        return (
            <PageWrp>
                <Typography sx={{paddingLeft: '140px', opacity: 0.2}} variant="caption">Loading...</Typography>
            </PageWrp>
        );
    }

    return (
        <Admin
            authProvider={authProvider.current}
            dataProvider={dataProvider.current}
            dashboard={Dashboard}
            disableTelemetry
            layout={Layout}
            store={localStorageStore(STORE_VERSION)}
            theme={theme}
            title="Growth Collective - Admin Panel"
        >
            {permissions => (
                <>

                    {/* USERS Routes */}
                    {
                        hasPermissions([ERole.SuperAdmin], true)
                            ? <Resource name="users" create={AdminsCreate} edit={AdminsEdit} list={AdminsList} icon={PeopleAltIcon} />
                            : <Resource name="users" edit={AdminsEdit}/>
                    }
                    {
                        hasPermissions([ERole.SuperAdmin, ERole.Billing], true) &&
                        <>
                            {/* ENGAGEMENTS Routes */}
                            <Resource name="engagements" list={EngagementList} show={EngagementShow} icon={MoreTimeIcon} />

                            {/* TRANSACTIONS Routes */}
                            <Resource name="payments" list={PaymentsList} show={PaymentShow} icon={PaidIcon} />

                        </>
                    }
                    {
                        hasPermissions([ERole.SuperAdmin, ERole.Matcher], true) &&
                        // ROLES route
                        <Resource name="roles" list={RoleList} show={RoleShow} icon={AssignmentIndIcon} />
                    }
                    {
                        hasPermissions([ERole.SuperAdmin, ERole.Reviewer], true) &&
                        // APPLICATIONS route
                        <Resource name="applications" edit={ApplicationEdit} list={ApplicationList} icon={AssignmentIcon} />
                    }
                    {
                        hasPermissions([ERole.SuperAdmin]) &&
                        <>
                            {/* CLIENTS Routes */}
                            <Resource name="clients" list={ClientList} edit={ClientEdit} icon={PeopleIcon} />
                            <CustomRoutes>
                                <Route path="matcher-settings" element={<MatcherSettings />}></Route>
                            </CustomRoutes>

                            {/* FREELANCERS Routes */}
                            <Resource name="freelancers" list={FreelancerList} show={FreelancerShow} icon={PeopleIcon} />
                            <Resource name="timesheets" list={TimesheetsList} icon={MoreTimeIcon} />

                            {/* NOTIFICATIONS Routes */}
                            <Resource name="notifications" list={NotificationList} />
                            <Resource name="notifications-rules" create={NotificationRuleCreate} edit={NotificationsRuleEdit} list={NotificationsRuleList} />

                            {/* Marketing */}
                            <Resource name="widgets" create={WidgetCreate} edit={WidgetEdit} list={WidgetList} icon={WidgetsIcon} />

                            {/* META Routes */}
                            <Resource name="channels" create={ChannelCreate} edit={ChannelEdit} list={ChannelList} icon={DynamicFeedIcon} />
                            <Resource name="verticals" create={VerticalCreate} edit={VerticalEdit} list={VerticalList} icon={AddRoadIcon} />
                            <Resource name="tools" create={ToolCreate} edit={ToolEdit} list={ToolList} icon={CategoryIcon} />
                            <Resource name="brands" create={BrandCreate} edit={BrandEdit} list={BrandList} icon={CopyrightIcon} />

                        </>
                    }

                    <CustomRoutes noLayout>
                        <Route
                            path="/components"
                            element={
                                <UIToolboxPage />
                            }
                        />

                        <Route
                            path="/public-profile-client/:matchId"
                            element={
                                <PublicProfileClient />
                            }
                        />

                        <Route
                            path="/public-profile-freelancer/:freelancerId"
                            element={
                                <PublicProfileFreelancer />
                            }
                        />

                        <Route
                            path="/set-password"
                            element={
                                <AdminNewPassword />
                            }
                        />
                    </CustomRoutes>
                </>
            )}
        </Admin>
    );
};

export default App;
