import {styledButton} from './form-controls';

export const styledList = {
    '& .RaList-content': {
        boxShadow: 'none',
        borderRadius: '0.375rem',
    },
    '& .MuiPaper-root': {
        boxShadow: 'none',
        borderRadius: '0.375rem',
    },
    '& .MuiToolbar-root:not(.MuiTablePagination-toolbar) .MuiButtonBase-root:not(.primary):not(.MuiCheckbox-root):not(.MuiChip-root):not(.RaResettableTextField-clearButton)': {
        ...styledButton,
        color: 'rgba(44, 56, 74, 0.95)',
        backgroundColor: '#ebedef',
        fontWeight: '400',
        // marginBottom: '4px'
    },
    '& .MuiToolbar-root .RaBulkActionsToolbar-title .RaBulkActionsToolbar-icon:not(:hover)': {
        backgroundColor: 'transparent !important',
    },
    '& .MuiTypography-root:not(.MuiTypography-overline)': {
        fontSize: '15px',
    }
};

export const styledDatagrid = {
    '& .RaDatagrid-table': {
        color: 'rgba(44, 56, 74, 0.95)',
    },
    '& .RaDatagrid-headerCell': {
        padding: '1rem .75rem',
        borderBottom: 'solid 1px #ebedef',
    },
    '& .RaDatagrid-expandIconCell': {
        borderBottom: 'solid 1px #ebedef',
    },
    '& .RaDatagrid-headerCell, & .MuiTableSortLabel-root': {
        color: 'rgba(44,56,74,.38)',
    },
    '& .Mui-active .MuiTableSortLabel-icon': {
        color: 'rgba(44,56,74,.8)',
    },
    '& .RaDatagrid-rowCell': {
        padding: '1rem .75rem',
        borderBottom: 'solid 1px #ebedef',
    },
    '& .RaDatagrid-tableWrapper': {
        padding: '10px 24px 24px 24px',
    },
    '& .MuiTableCell-paddingCheckbox': {
        padding: '0 12px',
    }
};

export const styledListButton = {
    padding: '0.375rem 0.75rem',
    minWidth: 'unset',
    height: '38px',
    marginRight: '4px',
    marginBottom: '4px',
    color: 'rgba(44, 56, 74, 0.95)',
    '& .MuiButton-startIcon': {
        margin: '0 4px',
    }
};

export const styledListEditButton = {
    ...styledListButton,
    backgroundColor: '#e9e9e9',
    '&:hover': {
        backgroundColor: '#77edae',
        color: 'rgba(44, 56, 74, 0.95)',
    }
};

export const styledListShowButton = {
    ...styledListButton,
    backgroundColor: '#e9e9e9',
    '&:hover': {
        backgroundColor: '#c5dced',
        color: 'rgba(44, 56, 74, 0.95)',
    }
};

export const styledListDeleteButton = {
    ...styledListButton,
    backgroundColor: '#e9e9e9',
    '&:hover': {
        backgroundColor: '#f9876d',
        color: '#000015',
    }
};
