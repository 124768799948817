import React from 'react';
import {Datagrid, EditButton, List, TextField} from 'react-admin';

import {ColumnLabeled, PaginationCoreUI, StatusBadge} from '../../components';
import {styledDatagrid, styledList, styledListEditButton} from '../../styles';

import {ListActions} from './components/ListActions';
import {defaultFiltersValues} from './components/ListFilterForm';


export const ToolList = () => (
    <List
        actions={<ListActions />}
        filterDefaultValues={{...defaultFiltersValues}}
        pagination={<PaginationCoreUI />}
        sort={{field: 'name', order: 'ASC'}}
        sx={styledList}
    >
        <Datagrid
            rowClick="edit"
            sx={{
                ...styledDatagrid,
                '& .MuiTableCell-root:nth-of-type(3)': {
                    maxWidth: '200px',
                    width: '200px'
                },
                '& .MuiTableCell-root:last-of-type': {
                    maxWidth: '100px',
                    width: '100px'
                },
            }}
        >
            <TextField source="name" />
            <StatusBadge source="moderationStatus" />
            <ColumnLabeled source="actions" sortable={false}>
                <EditButton label="" sx={styledListEditButton}/>
            </ColumnLabeled>
        </Datagrid>
    </List>
);
