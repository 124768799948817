import React, {useCallback, useMemo, useState} from 'react';
import format from 'date-fns/format';
import {useRecordContext} from 'ra-core';
import {useDataProvider} from 'react-admin';
import {useQuery} from 'react-query';

import {Box} from '@mui/material';

import {Accordion, AccordionDetails, AccordionSummaryInfo} from '../../../../../components';
import {IClientRole} from '../../../../../models';
import theme from '../../../../../theme';

import {FResultList} from './FResultList';


const ResultList: React.FC = () => {
    const record = useRecordContext<IClientRole>();
    const [open, setOpen] = useState<boolean>(false);

    const dataProvider = useDataProvider();

    const {data} = useQuery([`admin/roles/${record?.id}/results`],
        () => dataProvider.getList(`admin/roles/${record?.id}/results`, {
            filter: null,
            pagination: {page: 1, perPage: 1},
            sort: {field: 'position', order: 'ASC'}
        }),
        {
            staleTime: 30 * 60 * 1000,   // cache this request for 30 minutes
            select: (data) => data,
        }
    );

    const subtitle = useMemo(() => {
        if (!record.matchesReplenishmentDate) {
            return data ? `Freelancers(${data.total})` : '';
        } else {
            return `🕙 Sharing new results planned to: ${format(new Date(record.matchesReplenishmentDate), 'Pp')}`;
        }
    }, [data, record.matchesReplenishmentDate]);

    const handleChange = useCallback((event: React.SyntheticEvent, isExpanded: boolean) => {
        setOpen(isExpanded);
    }, []);

    if (!record) return null;

    return (
        <Box>
            <Accordion onChange={handleChange}>
                <AccordionSummaryInfo
                    id="results"
                    isSubTitleStatic={true}
                    subtitle={subtitle}
                    title="Result"
                    sx={{
                        '& .info-wrapper': {
                            color: theme.palette.primary.main,
                            opacity: 1,
                        }
                    }}
                >
                </AccordionSummaryInfo>
                <AccordionDetails sx={{p: 0, background: '#e7ebf0'}}>
                    {open && <FResultList/>}
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default React.memo(ResultList);
