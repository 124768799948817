import React, {useEffect} from 'react';
import {FileInput, useAuthProvider, useDataProvider, useEditContext, useNotify, useRecordContext} from 'react-admin';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {any, boolean, number, object, string, TypeOf} from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {Button, DialogActions, DialogContent, Grid, Typography} from '@mui/material';
import {Stack} from '@mui/system';

import {Button as CoreUIButton} from '../../../../components';
import {InputForm, SelectForm} from '../../../../components/Forms';
import {ACCEPTED_DOCUMENTS_TYPES, getAcceptedExtentions, MAX_FILE_SIZE, MAX_FILE_SIZE_MB, urlRegex} from '../../../../constants';
import {EApplicationStatus, ICertificate, optionsDocumentStatus} from '../../../../models';
import {styledButton, styledFileField, styledInput, styledSelect} from '../../../../styles';

export const ACCEPTED_EXTENSIONS = getAcceptedExtentions(ACCEPTED_DOCUMENTS_TYPES);

const schema = object({
    id: number().optional(),
    isFile: boolean().optional(),
    fileName: string().optional(),
    file: any()
        .refine((file) => file ? file.rawFile.size <= MAX_FILE_SIZE_MB : true,
            `Max file size is ${MAX_FILE_SIZE}MB.`
        )
        .refine(
            (file) => file && file.rawFile.size ? !!ACCEPTED_EXTENSIONS.find(ext => file.rawFile.name?.toLowerCase().endsWith(ext)) : true,
            '.jpg, .jpeg, .png and .pdf files are accepted.'
        ),
    name: string()
        .min(1, 'Name is required')
        .max(100, 'Name is too long'),
    status: string()
        .min(1, 'Status is required'),
    type: string().optional(),
    url: any()
        .refine((url) => url ? url.match(urlRegex) : true,
            'Url is invalid.'
        ),

}).refine((data) => data.file || data.url, {
    path: ['file'],
    message: 'Upload file or paste a link',
}).refine((data) => data.file || data.url, {
    path: ['url'],
    message: 'Upload file or paste a link',
});

type DocumentInput = TypeOf<typeof schema>;

interface Props {
    item: Partial<ICertificate>;
    onClose: () => void;
}

const CertificateForm: React.FC<Props> = ({item, onClose}) => {
    const auth = useAuthProvider();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const {refetch} = useEditContext();
    const {id: recordId} = useRecordContext();

    const methods = useForm<DocumentInput>({
        resolver: zodResolver(schema),
        defaultValues: item || undefined
    });

    const getFileNameFromPath = (path: string): string => {
        const data = path?.split('/certificates/');

        return data?.length > 1 ? data[1] : path;
    };

    const {
        handleSubmit,
        formState: {isDirty},
        getValues,
        setValue,
        reset,
        trigger,
        watch,
    } = methods;

    const id = watch('id');
    // const isFile = watch('isFile');
    const newFile = watch('file');
    const filePath = watch('fileName');
    const url = watch('url');
    const fileName = /*isFile &&*/ filePath ? getFileNameFromPath(filePath) : '';

    const handleDownload = async () => {
        const token = auth.getToken();

        await dataProvider.downloadFile('admin/files/' + filePath, fileName, token);
    };

    const handlePreview = async () => {
        const token = auth.getToken();

        await dataProvider.previewFile('admin/files/' + filePath, fileName, token);
    };

    const onSubmitHandler: SubmitHandler<DocumentInput> = async () => {
        const data = getValues();

        delete data.type;

        if (!data.id) {
            data.status = EApplicationStatus.Submitted;
        }

        if (!data.file) {
            delete data.file;
        } else if (data.file.rawFile) {
            // remove fake File with size:0 it is already uploaded
            if (!data.file?.rawFile?.size) {
                delete data.file;

            // new File should be uploaded
            } else {
                data.file = data.file.rawFile;
            }
        }

        await dataProvider.putFormData(
            `admin/applications/${recordId}/review/certificates`,
            {data},
        );

        if (data.id) {
            notify('Successfully updated.');
        } else {
            notify('Successfully created.');
        }

        if (refetch) refetch();
        onClose();
    };

    // erase File errors
    useEffect(() => {
        if (url) {
            trigger('file');
        }
    }, [url, trigger]);

    // erase URL errors
    useEffect(() => {
        if (newFile) {
            trigger('url');
        }
    }, [newFile, trigger]);

    useEffect(() => {
        const data: any = {...item};

        if (item && /*item.isFile && */ item.fileName) {
            data.file = {rawFile: new File([], item.fileName)};
        }

        reset(data || {});
    }, [item, reset]);

    useEffect(() => {
        if (newFile && url) {
            setValue('url', '');
        }
    }, [newFile, url, setValue]);

    return (
        <>
            <DialogContent dividers>
                <FormProvider {...methods}>
                    <form
                        onSubmit={handleSubmit(onSubmitHandler)}
                        noValidate
                        autoComplete="off"
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <InputForm label="Name" name="name" sx={styledInput}/>
                            </Grid>
                            <Grid item xs={6}>
                                <SelectForm
                                    label="Status"
                                    variant="standard"
                                    listItems={optionsDocumentStatus}
                                    name="status"
                                    sx={styledSelect}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FileInput
                                    label="Certificate PDF"
                                    name="file"
                                    maxSize={MAX_FILE_SIZE_MB}
                                    source="file"
                                    sx={{
                                        ...styledFileField,
                                        '& .RaLabeled-label': {
                                            fontSize: '1rem',
                                        }
                                    }}
                                />

                                <Stack direction="row" alignItems="center" spacing={2}>
                                    {newFile && (
                                        <Typography sx={{fontWeight: 'bold'}} variant="body1">{getFileNameFromPath(newFile?.rawFile?.name)}</Typography>
                                    )}
                                    {
                                        newFile && !newFile?.rawFile?.size && (
                                            <>
                                                <CoreUIButton
                                                    disabled={!!url}
                                                    variant="outlined"
                                                    onClick={handlePreview}
                                                >
                                                    Preview
                                                </CoreUIButton>
                                                <CoreUIButton
                                                    disabled={!!url}
                                                    variant="outlined"
                                                    onClick={handleDownload}
                                                >
                                                    Download
                                                </CoreUIButton>
                                            </>
                                        )
                                    }
                                    {
                                        newFile && (
                                            <CoreUIButton
                                                disabled={!!url}
                                                color="error"
                                                variant="outlined"
                                                onClick={() => setValue('file', null)}
                                            >
                                                Delete
                                            </CoreUIButton>
                                        )
                                    }

                                </Stack>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography align="center" sx={{opacity: 0.5}} variant="body1"> - OR -</Typography>
                            </Grid>

                            <Grid item xs={10}>
                                <InputForm
                                    disabled={!!newFile}
                                    label="Certificate Link"
                                    name="url"
                                />
                            </Grid>

                            <Grid item xs={2}>
                                <Button
                                    component="a"
                                    disabled={!id || isDirty || !!newFile || !url}
                                    href={url}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    sx={{...styledButton, mt: '40px'}}
                                    variant="outlined"
                                >Preview</Button>
                            </Grid>
                        </Grid>
                    </form>
                </FormProvider>

            </DialogContent>
            <DialogActions>
                <CoreUIButton
                    variant="outlined"
                    onClick={onClose}
                >
                    Close
                </CoreUIButton>
                <CoreUIButton
                    disabled={!isDirty}
                    variant="contained"
                    onClick={handleSubmit(onSubmitHandler)}
                >
                    Save
                </CoreUIButton>
            </DialogActions>
        </>
    );
};

export default CertificateForm;
