import React from 'react';
import {SelectInput, TextInput, useListContext} from 'react-admin';
import {FormProvider, useForm} from 'react-hook-form';

import {Button, Grid, Stack} from '@mui/material';

import {SearchFiltersBottom} from '../../../../components';
import {ETimesheetStatus, ETimesheetStatusDisplay} from '../../../../models';
import {styledInputInline, styledPrimaryButton, styledSelectInline} from '../../../../styles';

import {ClearFiltersButton} from './ClearFiltersButton';


const optionsStatus = [
    {id: ETimesheetStatus.submitted, name: ETimesheetStatusDisplay.submitted},
    {id: ETimesheetStatus.approved, name: ETimesheetStatusDisplay.approved},
    {id: ETimesheetStatus.rejected, name: ETimesheetStatusDisplay.rejected},
    {id: ETimesheetStatus.waitingForPayment, name: ETimesheetStatusDisplay.waiting_for_payment},
    {id: ETimesheetStatus.paid, name: ETimesheetStatusDisplay.paid},
    {id: ETimesheetStatus.payoutInProgress, name: ETimesheetStatusDisplay.payout_in_progress},
    {id: ETimesheetStatus.payoutComplete, name: ETimesheetStatusDisplay.payout_complete},
];

export const filterDefaultValues = {
    status: null,
};

export const FilterForm = () => {
    const {displayedFilters, filterValues, setFilters} = useListContext();
    const form = useForm({defaultValues: filterValues});

    const onSubmit = (values: any) => {
        if (Object.keys(values).length > 0) {
            setFilters(values, displayedFilters);
        }
    };

    return (
        <FormProvider {...form}>
            <form style={{width: '100%'}} onSubmit={form.handleSubmit(onSubmit)}>
                <Grid container spacing={2} alignItems="flex-end">
                    <Grid item xs={3}>
                        <TextInput
                            fullWidth
                            helperText={false}
                            label="Search"
                            resettable
                            source="q"
                            sx={{...styledInputInline}}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <SelectInput
                            choices={optionsStatus}
                            emptyText="All"
                            emptyValue="ALL"
                            fullWidth
                            helperText={false}
                            label="Timecards status"
                            source="status"
                            sx={styledSelectInline}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="flex-end" spacing={1}>
                            <ClearFiltersButton />
                            <Button
                                className="primary"
                                color="primary"
                                disableElevation
                                type="submit"
                                variant="contained"
                                sx={styledPrimaryButton}
                            >
                                Search
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>

                <SearchFiltersBottom></SearchFiltersBottom>

            </form>
        </FormProvider>
    );
};
