import React, {FC} from 'react';

import {Box, styled, Typography} from '@mui/material';

import {IBrand} from '../../../models';

import ImagePlaceholder from './ImagePlaceholder';

const Wrp = styled('div')``;

const CompaniesList = styled('ul')`
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  list-style: none;
  padding: 0;

  img {
    display: block;
    margin: 0 auto 4px;
    width: 64px;
    height: 64px;
    object-fit: contain;
    aspect-ratio: 1/1;
    border-radius: 50%;
    overflow: hidden;
  }

  li {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: black;
  }
`;

interface IFreelancerAboutInfo {
    bio: string;
    brands: IBrand[];
}

const FreelancerAboutInfo: FC<IFreelancerAboutInfo> = ({bio, brands}) => {
    return (
        <Wrp>
            <Typography
                sx={{
                    mb: '12px',
                    fontWeight: 600,
                    fontSize: '14px',
                    lineHeight: '21px'
                }}
            >
                About me
            </Typography>
            <Typography
                sx={{
                    mb: '24px',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: 1.65,
                    color: '#999999',
                    whiteSpace: 'pre-wrap'
                }}
            >
                {bio}
            </Typography>
            <Typography
                sx={{
                    mb: '12px',
                    fontWeight: 600,
                    fontSize: '14px',
                    lineHeight: '21px'
                }}
            >
                Worked in
            </Typography>
            <CompaniesList>
                {
                    brands.map((brand: IBrand) => (
                        <li key={brand.id}>
                            {
                                brand.logo ? (
                                    <img src={brand.logo} width="64" height="64" alt={brand.name}/>
                                ) : (
                                    <Box sx={{mb: 1}}>
                                        <ImagePlaceholder width="64" height="64"/>
                                    </Box>
                                )
                            }
                            {brand.name}
                        </li>
                    ))
                }
            </CompaniesList>
        </Wrp>
    );
};

export default React.memo(FreelancerAboutInfo);
