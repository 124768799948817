import React from 'react';
import {useRecordContext} from 'react-admin';

import {Typography} from '@mui/material';

import {IUser} from '../../../../models';

export const GroupsField = () => {
    const record = useRecordContext<IUser>();

    if (!record) {
        return null;
    }

    return (
        <ul>
            {record.groups.map((it, index) => (
                <li key={index}>
                    <Typography
                        sx={{
                            fontSize: '13px !important'
                        }}
                        variant="caption"
                    >{it.replace('/Administrators/', '')}</Typography>
                </li>
            ))}
        </ul>
    );
};
