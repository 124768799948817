import React, {useMemo} from 'react';
import {required, SelectInput, useDataProvider} from 'react-admin';
import {useQuery} from 'react-query';

import {IEventType} from '../../../models';
import {styledSelectInline} from '../../../styles';

export const EventTypesFilter= (props: any) => {
    const dataProvider = useDataProvider();

    const {data: eventTypes} = useQuery(
        ['notifications/event-types'],
        () => dataProvider.get('notifications', {id: 'event-types', area: ''}),
        {select: (data: any) => data.data.eventTypes}
    );

    const optionsEventType = useMemo(() => {
        return eventTypes ? [
            {id: 'ALL', name: 'All'},
            ...eventTypes
                .sort((a: IEventType, b: IEventType) => a.type.localeCompare(b.type))
                .map((it: IEventType) => ({id: it.type, name: it.type}))
        ] : [];
    }, [eventTypes]);

    return (
        eventTypes &&
        <SelectInput
            {...props}
            choices={optionsEventType}
            helperText={false}
            sx={styledSelectInline}
            validate={required()}
        />
    );
};