import React, {useCallback} from 'react';
import {useDataProvider, useNotify, useRecordContext, useShowContext} from 'react-admin';

import {Tab, Tabs} from '@mui/material';

import {MatcherSettingsForm} from '../../../../components';
import {IMatcherConfig, IMatching} from '../../../../models';

import {FreelancerList} from './FreelancerList';

enum EDisplayMode {
    ActiveList,
    Settings,
}

interface Props {
    data: IMatching;
}

export const Matching: React.FC<Props> = React.memo(({data}) => {
    const [activeTab, setActiveTab] = React.useState(EDisplayMode.ActiveList);
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const {refetch}= useShowContext();

    const handleChange = (event: React.SyntheticEvent, newValue: EDisplayMode) => {
        setActiveTab(newValue);
    };

    const handleSubmit = useCallback(async (settings: IMatcherConfig[]) => {
        try {
            await dataProvider.post(`admin/roles/${record.id}/matchings`, {data: {settings}});

            notify('Matching successfully created');

            refetch && refetch();
        } catch (error: any) {
            const message = error.body?.errors?.map((it: {message: string}) => it.message).join(', ');

            notify(`Error: ${message || error.message}`, {type: 'warning'});
        }
    }, [record?.id, dataProvider, notify, refetch]);

    return (
        <>
            <Tabs
                aria-label="matching result"
                sx={{background: '#e7ebf0'}}
                value={activeTab}
                onChange={handleChange}
            >
                <Tab label={`Matched Freelancers (${data.resultsCount})`} />
                <Tab label="APPLIED MATCHING RULES" />
            </Tabs>

            {activeTab === EDisplayMode.ActiveList && data && (
                <FreelancerList matching={data} />
            )}

            {activeTab === EDisplayMode.Settings && (
                <MatcherSettingsForm
                    buttonLabel="Re-use the rules for new matching"
                    configurationId={data.configurationId}
                    onSubmit={handleSubmit}
                />
            )}

        </>
    );
});
