import React from 'react';
import {useRecordContext} from 'react-admin';

import {EllipsisText} from '../../../components';

export const BodyField = ({source}: {source: string}) => {
    const record = useRecordContext();

    if (!record[source]) {
        return null;
    }

    const text = record[source].replace(/<\/?[^>]+(>|$)/g, '');

    return <EllipsisText text={text}></EllipsisText>;
};