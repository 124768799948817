import React, {useMemo} from 'react';

import {Typography} from '@mui/material';

import theme from '../../../theme';
import {formatWeek} from '../../../utils';


type DayLabelProps = {
    date: string;
};

const DayLabel: React.FC<DayLabelProps> = ({date}) => {
    const renderDate = useMemo(() => formatWeek(date).split(','), [date]);

    return (
        <>
            <Typography
                sx={{
                    display: 'inline-block',
                    fontWeight: 500,
                    fontSize: '14px',
                    minWidth: 40
                }}
                variant="body1"
            >
                {renderDate[0]},
            </Typography>
            <Typography
                sx={{
                    display: 'inline-block',
                    fontWeight: 500,
                    fontSize: '14px',
                    color: theme.palette.grey[500]
                }}
                variant="body1"
            >
                {renderDate[1]}
            </Typography>
        </>
    );
};

export default React.memo(DayLabel);
