import React from 'react';
import {CreateButton, TextInput, useDataProvider, useListContext} from 'react-admin';
import {FormProvider, useForm} from 'react-hook-form';
import {useQuery} from 'react-query';

import {Button, Grid, Stack} from '@mui/material';

import {SearchFiltersBottom} from '../../../../components';
import {IEventType} from '../../../../models';
import {styledInputInline, styledPrimaryButton} from '../../../../styles';

import {ClearFiltersButton} from './ClearFiltersButton';
import EventSourcesFilter from './EventSourcesFilter';
import EventTypesFilter from './EventTypesFilter';


export const FilterForm = () => {
    const dataProvider = useDataProvider();
    const {displayedFilters, filterValues, setFilters} = useListContext();
    const form = useForm({defaultValues: filterValues});

    const {data: eventTypes} = useQuery(
        ['notifications/event-types'],
        () => dataProvider.get('notifications', {id: 'event-types', area: ''}),
        {
            staleTime: 15 * 60 * 1000,
            select: (data: {data: {eventTypes: IEventType[]}}) => data.data.eventTypes
        }
    );

    const onSubmit = (values: any) => {
        if (Object.keys(values).length > 0) {
            setFilters(values, displayedFilters);
        }
    };

    return (
        <FormProvider {...form}>
            <form style={{width: '100%'}} onSubmit={form.handleSubmit(onSubmit)}>
                <Grid container spacing={2} alignItems="flex-end">
                    <Grid item xs={2}>
                        <TextInput
                            fullWidth
                            helperText={false}
                            label="Search"
                            resettable
                            source="q"
                            sx={{...styledInputInline}}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <EventTypesFilter
                            alwaysOn
                            items={eventTypes || []}
                            key="eventTypes"
                            source="eventTypes"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <EventSourcesFilter
                            alwaysOn
                            key="eventSources"
                            source="eventSources"
                        />
                    </Grid>
                    <Grid item xs sx={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end'}}>
                        <Stack direction="row" spacing={1}>
                            <ClearFiltersButton />
                            <Button
                                className="primary"
                                color="primary"
                                disableElevation
                                type="submit"
                                variant="contained"
                                sx={styledPrimaryButton}
                            >
                                Search
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>

                <SearchFiltersBottom>
                    <CreateButton/>
                </SearchFiltersBottom>

            </form>
        </FormProvider>
    );
};

export const defaultFiltersValues = {
    q: '',
    eventSources: 'ALL',
    eventTypes: 'ALL',
};
