import React from 'react';
import {ImageField, useRecordContext} from 'react-admin';

export const ImageColumn = () => {
    const {logo} = useRecordContext();
    const url = logo?.src ? 'logo.src' : 'logo';

    return (
        <ImageField
            source={url}
            sx={{
                '& .RaImageField-image': {
                    objectPosition: 'left',
                    height: '45px',
                    width: '80px',
                }
            }}/>
    );
};
