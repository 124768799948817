import React, {useState} from 'react';
import {useRecordContext} from 'react-admin';

import {Typography} from '@mui/material';

export const EllipsisText = ({text = '', source, max = 50}: any) => {
    const [elipsis, setElipsis] = useState(true);
    const record = useRecordContext();

    if (source && !record[source]) {
        return null;
    }

    if (source) {
        text = record[source];
    }

    const handleClick = () => {
        setElipsis(prevValue => !prevValue);
    };

    return (
        <Typography sx={{cursor: 'pointer', fontSize: 'inherit'}} onClick={handleClick}>
            {(elipsis && text.length > max) ? `${text?.substring(0, max)}...` : text}
        </Typography>
    );
};
