export const getMIME = (fileName: string): string => {
    const ext = fileName.split('.').pop();

    switch (ext) {
        case 'pdf':
            return 'application/pdf';
        case 'jpg':
        case 'jpeg':
            return 'image/jpg';
        case 'png':
            return 'image/png';
        default:
            console.warn(ext, 'not support yet');

            return '';
    }
};
