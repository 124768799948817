export interface IWorkWeek {
    concludedAt: string;
    fromDate: string;
    id?: number;
    isApprovedAutomatically: boolean;
    isOverdue?: boolean;
    money?: number;
    rejectionReasons?: string[];
    status?: EWorkWeekStatus;
    submittedAt: string;
    toDate: string;
    workDays: IWorkDay[];
    workDescription?: string;
    workTime: string;
}

export interface IWorkDay {
    id?: number;
    date: string;
    workTime?: string;
    workDescription?: string;
}

export enum EWorkWeekStatus {
    APPROVED = 'APPROVED',
    DRAFT = 'DRAFT',
    REJECTED = 'REJECTED',
    REJECTED_DRAFT = 'REJECTED_DRAFT',      // used for don't allow Re-submit the same form. user should change and save something new
    SENT = 'SENT',
}

export enum EWorkWeekStatusDisplay {
    APPROVED = 'Approved',
    DRAFT = 'In progress',
    REJECTED = 'Rejected',
    REJECTED_DRAFT = 'Rejected',
    SENT = 'Submitted',
}

export const getVariantByStatus = (status: string) => {
    switch (status) {
        case EWorkWeekStatus.DRAFT:
            return 'blue';
        case EWorkWeekStatus.SENT:
            return 'gray';
        case EWorkWeekStatus.APPROVED:
            return 'green';
        case EWorkWeekStatus.REJECTED:
        case EWorkWeekStatus.REJECTED_DRAFT:
            return 'red';
        default:
            return 'gray';
    }
};
