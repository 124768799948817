import * as React from 'react';
import {useCallback} from 'react';
import {useDataProvider} from 'react-admin';
import InfiniteScroll from 'react-infinite-scroll-component';

import DraftsIcon from '@mui/icons-material/Drafts';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import {Avatar, Dialog, DialogContent, DialogTitle, List, ListItem, ListItemAvatar, ListItemText, Typography} from '@mui/material';

import {ActionNotifacations, useNotificationContext} from '../../contexts';

import UnReadBadge from './UnReadBadge';


export function NotificationDialog() {
    const dataProvider = useDataProvider();
    const {state: {badge, isOpen, items, total}, dispatch} = useNotificationContext();

    const handleClose = async () => {
        dispatch({type: ActionNotifacations.SET_OPEN, payload: false});

        // set all as read = true
        if (badge) {
            await dataProvider.post('notifications/ack');
            dispatch({type: ActionNotifacations.SET_BADGE, payload: 0});
        }
    };

    const fetchMoreData = useCallback(() => {
        dispatch({type: ActionNotifacations.SET_NEXT_PAGE});
    }, [dispatch]);

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={isOpen}
            scroll="paper"
            sx={{
                '& .MuiDialog-container': {
                    justifyContent: 'flex-end',
                    alignItems: 'flex-start',
                    marginTop: 4,
                    maxHeight: 'calc(100% - 64px)'
                },
                '& .MuiDialogContent-root': {
                    padding: 0
                }
            }}
            onClose={handleClose}
        >
            <DialogTitle>Notifications</DialogTitle>
            <DialogContent id="scrollable-body" dividers>
                <InfiniteScroll
                    dataLength={items.length}
                    next={fetchMoreData}
                    hasMore={total !== items.length}
                    loader={<Typography align="center" component="p" paragraph variant="caption">Loading...</Typography>}
                    scrollableTarget="scrollable-body"
                >
                    <List sx={{pt: 0}}>
                        {items.map((notification) => (
                            <ListItem
                                key={notification.id}
                                sx={{
                                    '&: hover': {
                                        background: '#f0f8ff'
                                    }
                                }}
                            >
                                <ListItemAvatar color="primary">
                                    {!notification.read ? (
                                        <UnReadBadge
                                            overlap="circular"
                                            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                                            variant="dot"
                                        >
                                            <Avatar>
                                                <MailOutlineIcon />
                                            </Avatar>
                                        </UnReadBadge>
                                    ) : (
                                        <Avatar>
                                            <DraftsIcon />
                                        </Avatar>
                                    )}

                                </ListItemAvatar>
                                <ListItemText
                                    primary={notification.title}
                                    secondary={<span dangerouslySetInnerHTML={{__html: notification.body}} />}
                                    sx={{
                                        '& .MuiListItemText-primary': {
                                            fontWeight: notification.read ? '' : 700
                                        }
                                    }}
                                />
                            </ListItem>
                        ))}
                    </List>
                </InfiniteScroll>
            </DialogContent>

        </Dialog>
    );
}
