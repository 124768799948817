import React, {useMemo} from 'react';
import {FunctionField, Labeled, useRecordContext} from 'react-admin';

import {Grid, Typography} from '@mui/material';

import {Accordion, AccordionDetails, AccordionSummaryInfo, EmptyText, ListField, ModerationListField, MoneyField} from '../../../../components';
import {SetFeeDialog} from '../../../../components/SetFeeDialog';
import {useAdminGroups} from '../../../../hooks';
import {ERole, IClientRole} from '../../../../models';
import {styledLabelShow} from '../../../../styles';
import theme from '../../../../theme';
import {ellipsisText, formatter} from '../../../../utils';
import RoleNameField from '../../../Engagements/components/RoleNameField';

interface IRole {
    id: number;
    created: string;
    status: string;
    view: string;
    channel: Channel;
    verticals?: (SubchannelsEntityOrVerticalsEntity)[] | null;
    businessModels?: (string)[] | null;
    tools?: (ToolsEntity)[] | null;
    countries?: (string)[] | null;
    languages?: (string)[] | null;
    workType: string;
    rateRange?: (number)[] | null;
    expectedEngagementHours?: (number)[] | null;
    projectName: string;
    projectDescription: string;
    submitted: string;
    hasRejectedTimesheets: boolean;
    hasOverdueTimesheets: boolean;
    budget: number;
    tier: string;
    hubspotId: string;
    client: Client;
    unwantedFreelancersIds?: (null)[] | null;
    matchings?: (null)[] | null;
    unwantedFreelancers?: (null)[] | null;
    channelId: number;
    workTypeOrdinal: number;
    gcFeePercent?: number;
}

interface Channel {
    id: number;
    name: string;
    subchannels?: (SubchannelsEntityOrVerticalsEntity)[] | null;
    saveAndExit: boolean;
}

interface SubchannelsEntityOrVerticalsEntity {
    id: number;
    name: string;
}

interface ToolsEntity {
    id: number;
    name: string;
    moderationStatus: string;
}

interface Client {
    id: number;
    created: string;
    hubspotId: string;
    firstName: string;
    lastName: string;
    companyName: string;
    companyHubspotId: string;
    companyWebsite: string;
    position: string;
    email: string;
    phone: string;
    hasAnyFailedPayments: boolean;
    billingContact: BillingContact;
    allowEmails: boolean;
}

interface BillingContact {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    position: string;
}


export const RoleInfo: React.FC = React.memo(() => {
    const record = useRecordContext<IClientRole>();
    const {hasPermissions} = useAdminGroups();

    const roleSubtitle = useMemo(() => {
        if (!record) return '';

        const rowLimit = 30;
        const result = [];

        result.push(`[${record.status}]`);
        result.push(ellipsisText(record.projectName, rowLimit * 2));
        result.push(record.tier);
        if (record.budget) {
            result.push(formatter.format(record.budget / 100)); // convert without cents
        }

        // result.push(ellipsisText(record.projectDescription, rowLimit));
        result.push(record.channel?.name);
        // result.push(record.channel?.subchannels?.map(it => it.name).join('|'));
        // result.push(record.verticals?.map((it: IVertical) => it.name).join('|'));

        return result.filter(it => !!it).join(', ');
    }, [record]);

    if (!record) return null;

    return (
        <Accordion>
            <AccordionSummaryInfo
                id="role-information"
                subtitle={roleSubtitle}
                title="Role Information"
            >
                <Typography>Role Information:</Typography>
            </AccordionSummaryInfo>
            <AccordionDetails sx={{background: '#e7ebf0'}}>
                <Grid container spacing={2}>
                    <Grid item sm={4}>
                        <Labeled label="Project Name">
                            <FunctionField render={(record: any) => (
                                <>
                                    <RoleNameField
                                        recordLocal={{
                                            ...record,
                                            name: record.projectName
                                        }}
                                        variant="body1"
                                    /><br/>
                                    <EmptyText source="status" sx={{color: theme.palette.primary.main}} variant="caption"/>
                                </>
                            )}/>
                        </Labeled>
                    </Grid>
                    <Grid item sm={8}>
                        <Labeled label="Project Description">
                            <EmptyText source="projectDescription"/>
                        </Labeled>
                    </Grid>
                    <Grid item sm={4}>
                        <Labeled label="Channel">
                            <EmptyText source="channel.name"/>
                        </Labeled>
                    </Grid>
                    <Grid item sm={8}>
                        <Labeled label="Sub-Channels">
                            <ListField source="channel.subchannels"/>
                        </Labeled>
                    </Grid>
                    <Grid item sm={4}>
                        <Labeled label="Verticals">
                            <ListField source="verticals"/>
                        </Labeled>
                    </Grid>
                    <Grid item sm={8}>
                        <Typography sx={styledLabelShow}>Tools</Typography>
                        <ModerationListField
                            isEditable={hasPermissions([ERole.SuperAdmin, ERole.Admin])}
                            source="tools"
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <Labeled label="Countries">
                            <ListField source="countries"/>
                        </Labeled>
                    </Grid>
                    <Grid item sm={4}>
                        <Labeled label="Time Zone">
                            <EmptyText source="timezone"/>
                        </Labeled>
                    </Grid>
                    <Grid item sm={2}>
                        <Labeled label="Languages">
                            <ListField source="languages"/>
                        </Labeled>
                    </Grid>
                    <Grid item sm={2}>
                        <Labeled label="Business Models">
                            <ListField source="businessModels"/>
                        </Labeled>
                    </Grid>
                    <Grid item sm={4}>
                        <Labeled label="Engagement Type">
                            <EmptyText source="workType"/>
                        </Labeled>
                    </Grid>
                    <Grid item sm={4}>
                        <Labeled label="Expected Budget">
                            <MoneyField source="budget" startAdorment="$"/>
                        </Labeled>
                    </Grid>
                    <Grid item sm={2}>
                        <Labeled label="Tier">
                            <EmptyText source="tier"/>
                        </Labeled>
                    </Grid>
                    <Grid item sm={2}>
                        <Labeled label="GC Fee %">
                            <FunctionField render={({id, gcFeePercent}: IRole) => {
                                return <SetFeeDialog endpoint={`admin/roles/${id}/set-fee`} value={gcFeePercent}/>;
                            }}/>
                        </Labeled>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
});
