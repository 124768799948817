export enum EDashboardType {
    Monthly = 'monthly',
    Weekly = 'weekly',
}

export interface ISearchParams {
    from: string;
    to: string;
    type: EDashboardType;
}

export interface IAdminDashboard {
    clients: IAdminDashboardClients;
    freelancers: IAdminDashboardFreelancers;
    gmv: IAdminDashboardGMV;
    leadAndFunnel: IAdminDashboardLeadAndFunnel;
}

export interface IAdminDashboardClients {
    activeUsers: number;
    activeRoles: number;
    averageGMV: number;
    averageSOW: number;
    topGainers: IClientWithGMV[];
    topLosers: IClientWithGMV[];
}

export interface IAdminDashboardFreelancers {
    totalUsers: number;
    activeUsers: number;
    applicantsInQueue: number;
}

export interface IAdminDashboardGMV {
    chart: IChartItem[];
    totalHourly: number;
    totalRetainer: number;
    totalFixedPrice: number;
    runRate: number;
}

export interface IAdminDashboardLeadAndFunnel {
    lead: number;
    rolesMatched: number;
    rolesClosed: number;
    startedSOWs: number;
}

export interface IClientWithGMV {
    id: number;
    name: string;
    gain: number;
}

export interface IChartItem {
    fixed: number;
    hourly: number;
    label: string;
    retainer: number;
}
