import React from 'react';
import {useNotify} from 'react-admin';

import {TableCell, TableRow} from '@mui/material';

import {EMatcherType, IMatcherProperty} from '../../models';
import {Checkbox, NumberInputCoreUI} from '..';

interface Props {
    item: IMatcherProperty;
    onChange: (item: IMatcherProperty, value: number | boolean, prop: 'isCutting' | 'multiplier' | 'weight') => void;
}

const CuttingRow = ({item, onChange}: Props) => {
    const notify = useNotify();
    const handleCheckboxChange = async () => {
        onChange(item, !item.isCutting, 'isCutting');
    };

    const handleWeightChange = (e: KeyboardEvent) => {
        const value = +(e.target as HTMLInputElement).value;
        const newValue = value > 100 ? 100 : value;

        if (value !== newValue) {
            notify('Scoring properties are limited to 100. The higher value will not be saved.', {type: 'warning'});
        }

        onChange(item, newValue, 'weight');
    };

    return (
        <TableRow>
            <TableCell>{item.property}</TableCell>
            <TableCell>
                <Checkbox
                    checked={item.isCutting}
                    color="primary"
                    label=""
                    sx={{marginTop: '-1px', marginBottom: '-1px'}}
                    onChange={handleCheckboxChange}
                />
            </TableCell>
            <TableCell>
                <NumberInputCoreUI
                    allowNegative={false}
                    decimalSeparator="."
                    disabled={!item.types.includes(EMatcherType.Scoring)}
                    value={item.weight}
                    onChange={handleWeightChange}
                />
            </TableCell>
        </TableRow>
    );
};

export default React.memo(CuttingRow);