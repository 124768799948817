import React from 'react';
import {useNotify} from 'react-admin';

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {Button, List, ListItem, Stack, Tooltip, tooltipClasses, TooltipProps, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';

interface Props {
    customTags: string[];
    tooltipTitle: string;
}

const CustomTagsHelper = (props: Props) => {
    const notify = useNotify();

    const handleCustomTagClick = (value: string) => {
        navigator.clipboard.writeText(`${'$'}{${value}}`);

        notify('Copied to clipboard', {autoHideDuration: 2000});
    };

    const listOfTags = props.customTags.map((tag: string) => (
        <Button
            color="secondary"
            disableElevation
            disableFocusRipple
            disableTouchRipple
            disableRipple
            key={tag}
            size="small"
            title="Copy to clipboard"
            variant="contained"
            sx={{
                height: 'auto !important',
                fontSize: '12px !important',
                textTransform: 'none',
                marginBottom: '4px !important'
            }}
            onClick={() => handleCustomTagClick(tag)}>
            $&#123;{tag}&#125;
            &nbsp;&nbsp;
            <ContentCopyOutlinedIcon
                sx={{
                    width: '14px',
                    color: '#9f9f9f',
                }}/>
        </Button>
    ));

    return (
        <Stack component="span" direction="row" spacing={1} alignItems="center" flexWrap="wrap">
            <HtmlTooltip
                title={
                    <React.Fragment>
                        <Typography color="inherit">Custom tags</Typography>
                        <b>{props.tooltipTitle}</b>
                        <List
                            sx={{
                                '& li': {
                                    paddingLeft: '0'
                                }
                            }}>
                            {props.customTags.map((tag: string) =>
                                <ListItem key={tag}>
                                    - $&#123; {tag} &#125;
                                </ListItem>
                            )}
                        </List>
                    </React.Fragment>
                }
            >
                <InfoOutlinedIcon
                    sx={{
                        cursor: 'pointer',
                        zIndex: '10',
                    }} />
            </HtmlTooltip>
            {listOfTags}
        </Stack>
    );
};

const HtmlTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}} />
))(({theme}: any) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

export default React.memo(CustomTagsHelper);
