import React, {FC} from 'react';

import {Avatar, Box, styled, Theme, Typography, useMediaQuery} from '@mui/material';

import {IRecommendation} from '../../../models';
import theme from '../../../theme';
import {getNameLetters, stringToColor} from '../../../utils';

import CheckCurvyIcon from './CheckCurvyIcon';
import ImagePlaceholder from './ImagePlaceholder';

const Wrp = styled('div')``;

const TopTitle = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 23px;

  div {
    width: 24px;
    height: 24px;

    svg {
      width: inherit;
      height: inherit;
    }
  }

  path {
    &:first-of-type {
      fill: ${theme.palette.purple.main};
    }

    &:last-of-type {
      fill: ${theme.palette.white.main};
    }
  }
`;

const avatarStyles = {
    width: '48px',
    height: '48px',
    backgroundColor: stringToColor('You'),
    fontSize: '16px',
    lineHeight: 1.2,
    fontWeight: 500,
    'img': {
        display: 'block',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        aspectRatio: '1/1'
    }
};

interface IFreelancerPersonalRecommendation {
    data: IRecommendation;
}

const FreelancerPersonalRecommendation: FC<IFreelancerPersonalRecommendation> = ({data: {
    author,
    authorAvatar,
    authorPosition,
    header,
    text,
}}) => {
    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    return (
        <Wrp>
            <TopTitle>
                <CheckCurvyIcon/>
                <Typography
                    sx={{
                        fontWeight: 500,
                        fontSize: '10px',
                        lineHeight: 1.5,
                        textTransform: 'uppercase'
                    }}
                >
                    {header}
                </Typography>
            </TopTitle>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px',
                    mb: '16px'
                }}
            >

                {authorAvatar ? (
                    <Avatar
                        alt={author}
                        className="avatar"
                        src={authorAvatar}
                        sx={{...avatarStyles, backgroundColor: author ? stringToColor(author) : theme.palette.inputBorderColor.main}}
                    >
                        {getNameLetters(author || '')}
                    </Avatar>
                ) : (
                    <ImagePlaceholder width={mdUp ? '48' : '24'} height={mdUp ? '48' : '24'}/>
                )}

                <Typography
                    sx={{
                        fontWeight: 500,
                        fontSize: '13px',
                        lineHeight: '20px',
                        'span': {
                            display: 'block',
                            fontWeight: 400,
                            fontSize: '12px',
                            lineHeight: '18px',
                            color: '#999999'
                        }
                    }}
                >
                    {author}
                    <span>{authorPosition}</span>
                </Typography>
            </Box>
            <Typography
                sx={{
                    fontWeight: 400,
                    fontSize: '13px',
                    lineHeight: '20px'
                }}
            >
                {text}
            </Typography>
        </Wrp>
    );
};

export default React.memo(FreelancerPersonalRecommendation);
