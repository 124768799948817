import React, {EventHandler} from 'react';

import AccessAlarm from '@mui/icons-material/AccessAlarm';
import ArrowForwardIosRounded from '@mui/icons-material/ArrowForwardIosRounded';
import HubRounded from '@mui/icons-material/HubRounded';
import {Container, IconButton, styled} from '@mui/material';

import {Logo} from '../index';

interface IHeader {
    onHeaderCollapse: EventHandler<any>;
    collapsed?: boolean;
}

const HeaderWrp = styled('header')(
    ({theme}) => `
    position: sticky;
    top: 0;
    width: 100%;
    height: 58px;
    color: ${theme.palette.white.main};
    background-color: ${theme.palette.primary.main};
    padding: 8px 0;
    z-index: 100000;
    `,
);

const Nav = styled('nav')(
    () => `
    margin-left: auto;
    `,
);

const HeaderList = styled('ul')(
    () => `
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 0;
    margin-bottom: 0;
    `
);

const HeaderItem = styled('li')(
    ({theme}) => `
    list-style: none;
    color: ${theme.palette.white.main};
    `
);

const headerItems = [
    {
        link: '',
        icon: <AccessAlarm/>,
        action: () => console.log('atata')
    },
    {
        link: '',
        icon: <HubRounded/>,
        action: () => console.log('atata')
    },
];

const Header = (props: IHeader): JSX.Element => {
    return (
        <HeaderWrp>
            <Container maxWidth={false} sx={{
                display: 'flex',
                alignItems: 'center'
            }}>
                <Logo color="white"/>
                <IconButton
                    onClick={props.onHeaderCollapse}
                    sx={{
                        marginLeft: '72px',
                    }}
                >
                    <ArrowForwardIosRounded
                        color="secondary"
                        sx={{
                            transform: `scale(${props.collapsed ? -1 : 1})`
                        }}
                    />
                </IconButton>
                <Nav>
                    <HeaderList>
                        {headerItems.map((item, i) =>
                            <HeaderItem key={i}>
                                <IconButton color="inherit" href={item.link} onClick={item.action}>
                                    {item.icon}
                                </IconButton>
                            </HeaderItem>
                        )}
                    </HeaderList>
                </Nav>
            </Container>
        </HeaderWrp>
    );
};

export default Header;
