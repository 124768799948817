import React, {memo} from 'react';
import {useRecordContext} from 'react-admin';

import {ButtonRedirectUrl, EngagementDetailsField} from '../../../../components';
import {IPayment} from '../../../../models';

interface IProps {
    isRedirectBtn: boolean;
    label: string;
    sortable: boolean;
    source: any;
}

export const CompanyNameField = memo(({isRedirectBtn, source}: IProps) => {
    const record = useRecordContext<IPayment>();

    if (!record) {
        return null;
    }

    const data = (record as any)[source];

    return (
        <>
            {data}&nbsp;
            {isRedirectBtn && <ButtonRedirectUrl path={`/clients/${record.clientId}/show`}/>}
            <EngagementDetailsField variant="caption"/>
        </>
    );
});