import React from 'react';
import {Datagrid, Edit, ReferenceManyField, SimpleForm, TextField, TextInput, useRecordContext} from 'react-admin';

import {Divider, Typography} from '@mui/material';

import {EditFormToolbarCoreUI} from '../../components';
import {styledInput} from '../../styles';

import {AddSubChannelButton} from './components';

export const ChannelEdit = () => {
    return (
        <Edit title={<ChannelTitle />}>
            <SimpleForm toolbar={<EditFormToolbarCoreUI />}>
                <TextInput disabled source="id" sx={styledInput} />
                <TextInput source="name" sx={styledInput} />

                <Divider flexItem />

                <Typography sx={{py: 2}} variant="h6">Sub-сhannels</Typography>

                <AddSubChannelButton />

                <ReferenceManyField label="Sub-сhannels" reference="subchannels" target="channelId">
                    <Datagrid sx={{width: 500}}>
                        <TextField source="id" sx={styledInput} />
                        <TextField source="name" sx={styledInput} />
                    </Datagrid>
                </ReferenceManyField>

            </SimpleForm>
        </Edit>
    );
};

const ChannelTitle = () => {
    const record = useRecordContext();

    return <span>Channel {record ? `"${record.name}"` : ''}</span>;
};
