import React, {useMemo, useState} from 'react';
import {DateField, RaRecord} from 'react-admin';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SmsIcon from '@mui/icons-material/Sms';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {Box, Divider, Grid, Typography} from '@mui/material';

import {EmptyDate, Pill} from '../../../components';
import {EWorkWeekStatus, EWorkWeekStatusDisplay, getVariantByStatus, IWorkWeek} from '../../../models';
import {styledLabelShow} from '../../../styles';
import theme from '../../../theme';

import WorkDayGrid from './WorkDayGrid';

type TimesheetProps = {
    data: IWorkWeek;
};

const Timesheet: React.FC<TimesheetProps> = ({data}) => {
    const [isCollapsed, setCollapsed] = useState(true);
    const renderRejectReason = useMemo(() => {
        const {length, [length - 1]: last} = data.rejectionReasons as string[];

        // 2023-03-29T08:39:00Z: because i ...
        // substring without date
        return last?.substring(22) || '';
    }, [data]);

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={8}>
                    <Typography sx={styledLabelShow}>Billing period</Typography>
                    <EmptyDate recordLocal={data as RaRecord} source="fromDate"/> - <EmptyDate recordLocal={data as RaRecord} source="toDate"/>
                </Grid>
                <Grid item xs={4} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                    {data.status ? (
                        <Pill variant={getVariantByStatus(data.status)}>
                            {EWorkWeekStatusDisplay[data.status]}
                        </Pill>
                    ): '/'}

                    {isCollapsed
                        ? <KeyboardArrowRightIcon sx={{m: 1, cursor: 'pointer'}} onClick={() => setCollapsed(!isCollapsed)}/>
                        : <KeyboardArrowDownIcon sx={{m: 1, cursor: 'pointer'}} onClick={() => setCollapsed(!isCollapsed)}/>
                    }
                </Grid>

                {data.isOverdue && (
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                mb: '8px',
                                display: 'flex',
                                gap: '8px',
                                alignItems: 'center'
                            }}
                        >
                            <ErrorOutlineIcon color="primary"/>
                            <Typography color="primary" variant="body2">
                                Freelancer failed to submit this time card on time
                            </Typography>
                        </Box>
                    </Grid>
                )}

                {data.status === EWorkWeekStatus.REJECTED && renderRejectReason && (
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                mb: '8px',
                                display: 'flex',
                                gap: '8px',
                                alignItems: 'center'
                            }}
                        >
                            <WarningAmberIcon color="error"/>
                            <Typography color="error" variant="body2">
                                The client rejected some of the dates.
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                p: '16px',
                                mb: '8px',
                                borderRadius: '8px',
                                backgroundColor: theme.palette.lightGray.light
                            }}
                        >
                            <Typography
                                sx={{
                                    mb: '4px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px',
                                    fontWeight: 400,
                                    fontSize: '12px',
                                    lineHeight: 1.5,
                                    color: theme.palette.grey[500]
                                }}
                            >
                                <SmsIcon/>
                                Comment from the client:
                            </Typography>
                            <Typography
                                component="q"
                                sx={{
                                    fontWeight: 400,
                                    fontSize: '13px',
                                    lineHeight: 1.5
                                }}
                            >
                                {renderRejectReason}
                            </Typography>
                        </Box>
                    </Grid>
                )}

                {
                    isCollapsed ? null : (
                        <>
                            <Grid item xs={12}>
                                <Typography sx={styledLabelShow}>Description</Typography>
                                {data.workDescription ? (
                                    <Box sx={{maxWidth: 800}}>
                                        <Typography variant="body2">{data.workDescription}</Typography>
                                    </Box>
                                ) : '-'}
                            </Grid>

                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        p: '24px',
                                        mb: '16px',
                                        backgroundColor: theme.palette.blue.light,
                                        borderRadius: '24px',
                                        maxWidth: 800
                                    }}
                                >
                                    {
                                        data.workDays.map(workDay => (
                                            <WorkDayGrid
                                                isCompact
                                                key={workDay.id}
                                                date={workDay.date}
                                                workDescription={workDay.workDescription}
                                                workTime={workDay.workTime}
                                            />
                                        ))
                                    }
                                </Box>
                            </Grid>

                            <Grid item xs={2}>
                                <Typography sx={styledLabelShow}>Submitted At</Typography>
                                {data.submittedAt ? <DateField record={data} source="submittedAt"/> : '-'}
                            </Grid>
                            <Grid item xs={2}>
                                <Typography sx={styledLabelShow}>
                                    {data.concludedAt && data.status === EWorkWeekStatus.REJECTED && renderRejectReason
                                        ? 'Rejected At'
                                        : 'Approved At'}
                                </Typography>
                                {data.concludedAt ? <DateField record={data} source="concludedAt"/> : '-'}
                            </Grid>
                            <Grid item xs={8}>
                                <Typography sx={styledLabelShow}>
                                    The card was automatically approved
                                </Typography>
                                <Typography variant="body2">{data.isApprovedAutomatically ? 'YES' : 'NO'}</Typography>
                            </Grid>
                        </>
                    )
                }

            </Grid>
        </Box>

    );
};

export default React.memo(Timesheet);
