import React from 'react';
import {RaRecord, useRecordContext} from 'react-admin';

import {Typography} from '@mui/material';

import {getByPath} from '../../../utils/record.helper';

interface SimpleValue {
    id: number;
    name : string;
}

interface Props {
    field?: SimpleValue[];
    recordLocal?: RaRecord;
    source: string;
}

export const ListField = React.memo((props: Props) => {
    const record = useRecordContext();

    if (!record) return null;

    const field = props.field || getByPath(props.recordLocal || record, props.source);

    const list = field?.map(
        (item: string | SimpleValue, index: number) => (
            <Typography
                key={index}
                sx={{display: 'contents'}}
            >
                {typeof item === 'string' ? item : item.name}
                {index < field.length - 1 && ', '}
            </Typography>
        ));

    return list?.length ? list : '/';
});
