import React from 'react';
import {Datagrid, EditButton, List, TextField} from 'react-admin';

import {ColumnLabeled, DatagridEmpty} from '../../components';
import {styledDatagrid, styledList, styledListEditButton} from '../../styles';

import {GroupsField} from './components/GroupsField';
import {defaultFiltersValues, ListActions} from './components';

export const AdminsList = () => (
    <List
        actions={<ListActions/>}
        bulkActionButtons={false}
        filterDefaultValues={defaultFiltersValues}
        pagination={false}
        sort={{field: 'firstName', order: 'ASC'}}
        sx={styledList}
    >
        <Datagrid
            rowClick="edit"
            empty={<DatagridEmpty />}
            sx={{
                ...styledDatagrid,
                '& .MuiTableCell-paddingCheckbox': {display: 'none'},
                '& .MuiTableCell-root:last-of-type': {
                    maxWidth: '100px',
                    width: '100px'
                },
            }}
        >
            <TextField source="firstName" />
            <TextField source="lastName" />
            <TextField source="email" />

            <ColumnLabeled source="Groups" sortable={false}>
                <GroupsField/>
            </ColumnLabeled>

            <ColumnLabeled source="actions" sortable={false}>
                <EditButton label="" sx={styledListEditButton}/>
            </ColumnLabeled>
        </Datagrid>
    </List>
);
