import React from 'react';
import {useRecordContext} from 'react-admin';

import {RateRange} from '../../../components';

export const RateField = () => {
    const {profile} = useRecordContext();

    if (!profile) {
        return null;
    }

    return <RateRange recordLocal={profile} />;
};