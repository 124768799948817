import React from 'react';
import {RaRecord, useResourceContext} from 'react-admin';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {Box, Stack, Typography} from '@mui/material';

import {ButtonRedirectUrl, StatusBadge} from '..';

type DuplicateListProps = {
    error: boolean;
    items: Array<{id?: number; name: string}>;
};

const DuplicateList: React.FC<DuplicateListProps> = ({error, items}) => {
    const resource = useResourceContext();

    return (
        <Box
            sx={{
                border: `1px solid ${error ? 'red' : '#ddd'}`,
                borderRadius: 1,
                p: 3,
                width: 400,
            }}
        >
            <Stack direction="row" spacing={2} alignItems="center" sx={{mb: 2}}>
                {error ? (
                    <>
                        <WarningAmberIcon color="error"/>
                        <Typography color="error" paragraph variant="body1">Already exists</Typography>
                    </>
                ) : (
                    <>
                        <ErrorOutlineIcon color="primary"/>
                        <Typography color="primary" paragraph variant="body1">It is looks like something we already have</Typography>
                    </>
                )}

            </Stack>

            {items?.map((tool, index) => (
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    key={tool.id}
                    spacing={1}
                    sx={{
                        py: 1,
                        borderBottom: items.length - 1 !== index ? '1px solid #ddd' : 'none',
                    }}
                >
                    <Typography variant="body2">{tool.name}</Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <StatusBadge recordLocal={tool as RaRecord} source="moderationStatus" />
                        <ButtonRedirectUrl path={`/${resource}/${tool.id}`}/>
                    </Stack>

                </Stack>
            ))}

        </Box>

    );
};

export default React.memo(DuplicateList);
