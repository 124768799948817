import React, {useState} from 'react';
import {RaRecord} from 'react-admin';

import {RecommendationCreate} from './RecommendationCreate';
import {RecommendationEdit} from './RecommendationEdit';
import {RecommendationList} from './RecommendationList';

const Recommendations = () => {
    const [selected, setSelected] = useState<RaRecord | null>(null);
    const [isNew, setIsNew] = useState<boolean>(false);

    // Create mode
    if (isNew) {
        return (
            <RecommendationCreate onClose={setIsNew}/>
        );
    }

    // Edit mode
    if (selected) {
        return (
            <RecommendationEdit record={selected} onClose={setSelected}/>
        );
    }

    // Show List
    return (
        <RecommendationList onCreate={() => setIsNew(true)} onClick={(record) => setSelected(record)}/>
    );
};

export default Recommendations;
