import React, {ReactNode} from 'react';

import {Box, Breakpoint, Slide, styled, SxProps} from '@mui/material';
import MUIDialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {TransitionProps} from '@mui/material/transitions';

const ActionsWrp = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
`;

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
    actions?: ReactNode;
    children: ReactNode;
    fullScreen?: boolean;
    open: boolean;
    maxWidth?: Breakpoint;
    subtitle?: string;
    sx?: SxProps;
    title: string;
    onClose: () => void;
}

const Dialog: React.FC<Props> = ({actions, children, fullScreen, maxWidth = 'xs', open, sx, subtitle, title, onClose}) => {
    return (
        <MUIDialog
            aria-describedby="dialog-slide-description"
            // keepMounted
            fullScreen={fullScreen}
            maxWidth={maxWidth}
            open={open}
            sx={sx}
            TransitionComponent={Transition}
            onClose={onClose}
        >
            <DialogTitle
                textAlign="center"
                sx={{
                    pb: 0,
                    mb: '4px',
                    fontWeight: '700',
                    fontSize: '32px',
                    lineHeight: '44px',
                    color: '#000'
                }}
            >
                {title}
            </DialogTitle>
            <DialogContent dividers sx={{p: 0}}>
                {subtitle &&
                  <DialogContentText
                      textAlign="center"
                      sx={{
                          pb: 0,
                          px: 5,
                          mb: '40px',
                          fontWeight: '400',
                          fontSize: '16px',
                          lineHeight: '22px',
                          color: 'rgba(0, 0, 0, 0.5)',
                      }}
                  >
                      {subtitle}
                  </DialogContentText>
                }
                <Box sx={{p: 2}}>
                    {children}
                </Box>
            </DialogContent>
            {actions && (
                <DialogActions
                    sx={{

                        justifyContent: 'stretch'
                    }}
                >
                    <ActionsWrp>
                        {actions}
                    </ActionsWrp>

                </DialogActions>
            )}

        </MUIDialog>
    );
};

export default React.memo(Dialog);
