import React from 'react';
import {Create, maxLength, required, SimpleForm, TextInput, useNotify, useRedirect} from 'react-admin';

import {IWidget} from '../../models/widget.model';
import {styledInput} from '../../styles';
import {validateURL} from '../../utils';


export const WidgetCreate = (props: any) => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = (response: IWidget) => {
        notify('New widget successfuly created');
        redirect('/widgets/' + response.id);
    };

    return (
        <Create {...props} redirect="list" mutationOptions={{onSuccess}}>
            <SimpleForm sx={{maxWidth: 600}}>
                <TextInput source="title" fullWidth validate={[required(), maxLength(100)]} sx={styledInput} />
                <TextInput source="description" fullWidth multiline rows={4} validate={[required(), maxLength(1000)]} sx={styledInput} />
                <TextInput source="location" fullWidth placeholder="https://..." validate={[required(), maxLength(1000), validateURL]} sx={styledInput} />
            </SimpleForm>
        </Create>
    );
};

