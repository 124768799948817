import React from 'react';
import {useRecordContext} from 'react-admin';

import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

export const BooleanIcon = ({source}: { label?: string; sortable?: boolean; source: string }) => {
    const record = useRecordContext();

    return (
        record[source] ?
            <CheckOutlinedIcon sx={{color: '#57cb8c'}} /> :
            <CloseOutlinedIcon sx={{color: '#ed3b70'}} />
    );
};
