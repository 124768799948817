import React from 'react';
import {DateField, RaRecord, useRecordContext} from 'react-admin';

import {TypographyProps} from '@mui/material';

import {getByPath} from '../../../utils/record.helper';

type Props = {
    component?: string;
    source: string;
    startAdorment?: string;
    recordLocal?: RaRecord;
} & TypographyProps;

export const EmptyDate = React.memo(({recordLocal, source, startAdorment, ...props}: Props) => {
    const record = useRecordContext();

    if (!record) return null;

    const field = getByPath(recordLocal || record, source);

    return (
    // <Typography {...props}>
    //     {field
    //         ? `${startAdorment || ''}${field}`
    //         : '/'
    //     }
    // </Typography>
        <>

            {field
                ? <DateField source="field" record={{field}}/>
                : '/'
            }
        </>
    );
});
