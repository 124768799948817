import {
    KeycloakConfig,
    KeycloakInitOptions,
    KeycloakTokenParsed,
} from 'keycloak-js';

import {ERole} from '../../models';

export const configKeycloak: KeycloakConfig = {
    url: process.env.REACT_APP_KEYCLOAK_URL,
    realm: process.env.REACT_APP_KEYCLOAK_REALM || '',
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || '',
};

export const initOptionsKeycloak: KeycloakInitOptions = {
    onLoad: 'login-required',
    enableLogging: location.pathname === 'localhost',
    checkLoginIframe: true,
};

export const checkToken = (promise: Promise<boolean>) => {
    return promise.then(function(refreshed: boolean) {
        if (refreshed) {
            process.env.NODE_ENV !== 'production' && console.log('Token was successfully refreshed');
        }
    }).catch(function() {
        console.log('Failed to refresh the token, or the session has expired');
    });
};

const state = {
    currentRoles: [] as ERole[],
};

export const getPermissions = (decoded: KeycloakTokenParsed) => {
    const roles = decoded?.roles;

    state.currentRoles = roles || [];

    // if (!roles) {
    //     return false;
    // }

    // // FIXME should be roles array ['Billing', 'Matcher']
    // if (roles.includes(ERole.SuperAdmin)) return ERole.SuperAdmin;
    // if (roles.includes(ERole.Billing)) return ERole.Billing;
    // if (roles.includes(ERole.Matcher)) return ERole.Matcher;
    // if (roles.includes(ERole.Reviewer)) return ERole.Reviewer;

    // return false;
    return roles || false;
};

// @params
// isStrict: boolean
// show all for SuperAdmin
// but if isStrict - only if ERole.SuperAdmin exists in the arr
export const hasPermissions = (roles: ERole[], isStrict?: boolean) => {
    const isAdmin = state.currentRoles.includes(ERole.SuperAdmin) || state.currentRoles.includes(ERole.Admin);

    if (!isStrict && isAdmin) {
        return true;
    }

    return roles.find(role => state.currentRoles.includes(role));
};

export const raKeycloakOptions = {
    onPermissions: getPermissions,
};
