import React, {FC, Fragment, useMemo} from 'react';

import {styled} from '@mui/material';

import {EWorkTypeDisplay, IChannel, IDynamicProfiles} from '../../../models';
import theme from '../../../theme';
import {Pill} from '../../index';

const MD = `${theme.breakpoints.values.md}px`;

const Wrp = styled('div')``;

const PillsWrapper = styled('div')<{ smallerGap?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${props => props.smallerGap ? '4px' : '8px'};
  margin-bottom: 12px;

  @media (min-width: ${MD}) {
    display: ${props => props.smallerGap ? 'flex' : 'inline-flex'};
    gap: ${props => props.smallerGap ? '7px' : '8px'};
    margin-bottom: ${props => props.smallerGap ? '0' : '8px'};
    position: relative;
    padding-right: 24px;
    margin-right: 24px;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 1px;
      height: 16px;
      background-color: ${theme.palette.lightGray.main};
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }
`;

const BoxItem = styled('div')`
  justify-content: space-between;
  margin: 0 -24px 32px;
  padding: 0 24px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Title = styled('p')`
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: black;
`;

const SubTitle = styled('p')`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #999999;
  margin-bottom: 8px;
`;

interface FreelancerMatchesList {
    data: Partial<IDynamicProfiles>;
}

const FreelancerMatchesList: FC<FreelancerMatchesList> = ({data}) => {
    const subChannelCommonIds = useMemo(() => data.subchannels?.common.map(it => it.id), [data]);
    const channelsList = data.channels && [...data.channels?.common, ...data.channels?.additional];

    const isSubchannelsExist = channelsList?.find(channel => !!(channel as IChannel).subchannels.length);
    const isLanguagesExist = !!data.languages?.common.length || !!data.languages?.additional.length;
    const isToolsExist = !!data.tools?.common.length || !!data.tools?.additional.length;

    return (
        <Wrp>
            <BoxItem>
                <Title>Channels</Title>
                <PillsWrapper>
                    {
                        data.channels?.common?.map(channel =>
                            <Pill
                                key={channel.id}
                                hasIcon
                                hasOutline
                                variant="green"
                            >
                                {channel.name}
                            </Pill>
                        )
                    }

                    {
                        data.channels?.additional?.map(channel =>
                            <Pill
                                key={channel.id}
                                variant="gray"
                            >
                                {channel.name}
                            </Pill>
                        )
                    }
                </PillsWrapper>
            </BoxItem>
            {
                isSubchannelsExist && (
                    <BoxItem>
                        <Title>Sub-channels</Title>
                        {channelsList?.map((channel) =>
                            <Fragment key={channel.id}>
                                <SubTitle>{channel.name}</SubTitle>
                                <PillsWrapper>
                                    {(channel as IChannel).subchannels?.map(subchannel => (
                                        <Pill
                                            key={subchannel.id}
                                            hasIcon
                                            hasOutline
                                            variant={subChannelCommonIds?.includes(subchannel.id) ? 'green' : 'gray'}
                                        >
                                            {subchannel.name}
                                        </Pill>
                                    ))}
                                </PillsWrapper>
                            </Fragment>
                        )}
                    </BoxItem>
                )
            }

            <BoxItem>
                <Title>Verticals</Title>
                <PillsWrapper>
                    {
                        data.verticals?.common?.map(vertical =>
                            <Pill
                                key={vertical.id}
                                hasIcon
                                hasOutline
                                variant="green"
                            >
                                {vertical.name}
                            </Pill>
                        )
                    }

                    {
                        data.verticals?.additional?.map(vertical =>
                            <Pill
                                key={vertical.id}
                                variant="gray"
                            >
                                {vertical.name}
                            </Pill>
                        )
                    }
                </PillsWrapper>
            </BoxItem>

            {
                isLanguagesExist && (
                    <BoxItem>
                        <Title>Languages</Title>
                        <PillsWrapper>
                            {
                                data.languages?.common?.map(language =>
                                    <Pill
                                        key={language}
                                        hasIcon
                                        hasOutline
                                        variant="green"
                                    >
                                        {language}
                                    </Pill>
                                )
                            }

                            {
                                data.languages?.additional?.map(language =>
                                    <Pill
                                        key={language}
                                        variant="gray"
                                    >
                                        {language}
                                    </Pill>
                                )
                            }
                        </PillsWrapper>
                    </BoxItem>
                )
            }

            {
                isToolsExist && (
                    <BoxItem>
                        <Title>Tools</Title>
                        <PillsWrapper>
                            {
                                data.tools?.common?.map(tool =>
                                    <Pill
                                        key={tool.id}
                                        hasIcon
                                        hasOutline
                                        variant="green"
                                    >
                                        {tool.name}
                                    </Pill>
                                )
                            }

                            {
                                data.tools?.additional?.map(tool =>
                                    <Pill
                                        key={tool.id}
                                        variant="gray"
                                    >
                                        {tool.name}
                                    </Pill>
                                )
                            }
                        </PillsWrapper>
                    </BoxItem>
                )
            }

            <BoxItem>
                <Title>Budget type</Title>
                <PillsWrapper>
                    {
                        data.workTypes?.common?.map(workType =>
                            <Pill
                                key={workType}
                                hasIcon
                                hasOutline
                                variant="green"
                            >
                                {EWorkTypeDisplay[workType]}
                            </Pill>
                        )
                    }

                    {
                        data.workTypes?.additional?.map(workType =>
                            <Pill
                                key={workType}
                                variant="gray"
                            >
                                {EWorkTypeDisplay[workType]}
                            </Pill>
                        )
                    }
                </PillsWrapper>
            </BoxItem>
        </Wrp>
    );
};

export default React.memo(FreelancerMatchesList);
