export enum ETimesheetStatus {
    submitted = 'SUBMITTED',
    approved = 'APPROVED',
    rejected = 'REJECTED',
    waitingForPayment = 'WAITING_FOR_PAYMENT',
    paid = 'PAID',
    payoutInProgress = 'PAYOUT_IN_PROGRESS',
    payoutComplete = 'PAYOUT_COMPLETE'
}

export enum ETimesheetStatusDisplay {
    submitted = 'Submitted',
    approved = 'Approved',
    rejected = 'Rejected',
    waiting_for_payment = 'Waiting For Payment',
    paid = 'Paid',
    payout_in_progress = 'Payout In Progress',
    payout_complete = 'Payout Complete'
}
