import React, {useMemo} from 'react';
import {AutocompleteArrayInput, DateInput, SelectInput, TextInput, useListContext} from 'react-admin';
import {FormProvider, useForm} from 'react-hook-form';

import {Button, Grid, Stack} from '@mui/material';

import {SearchFiltersBottom} from '../../../components';
import {EEngagementStatus, EWorkType, EWorkTypeDisplay, optionsEngagementStatus} from '../../../models';
import {styledAutocompleteInline, styledInputInline, styledPrimaryButton, styledSelectInline} from '../../../styles';

import {ClearFiltersButton} from './ClearFiltersButton';

export const ListFilterForm = () => {
    const {displayedFilters, filterValues, setFilters} = useListContext();
    const form = useForm({defaultValues: filterValues});

    const onSubmit = (values: any) => {
        if (Object.keys(values).length > 0) {
            setFilters(values, displayedFilters);
        }
    };

    const optionsWorkTypes = useMemo(() => [
        {id: EWorkType.fixed, name: EWorkTypeDisplay[EWorkType.fixed]},
        {id: EWorkType.hourly, name: EWorkTypeDisplay[EWorkType.hourly]},
        {id: EWorkType.retainer, name: EWorkTypeDisplay[EWorkType.retainer]},
    ], []);

    return (
        <FormProvider {...form}>
            <form style={{width: '100%'}} onSubmit={form.handleSubmit(onSubmit)}>
                <Grid container spacing={2} alignItems="flex-end">
                    <Grid item xs={2}>
                        <TextInput
                            fullWidth
                            helperText={false}
                            label="Search"
                            resettable
                            source="q"
                            sx={{...styledInputInline}}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <AutocompleteArrayInput
                            choices={optionsEngagementStatus}
                            helperText={false}
                            fullWidth
                            label="Status"
                            limitTags={2}
                            key="statuses"
                            source="statuses"
                            sx={{...styledAutocompleteInline}}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <SelectInput
                            choices={optionsWorkTypes}
                            emptyText="All"
                            emptyValue="ALL"
                            fullWidth
                            helperText={false}
                            label="Budget type"
                            source="budgetType"
                            sx={styledSelectInline}
                        />
                    </Grid>

                    <Grid item xs sx={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end'}}>
                        <Stack direction="row" spacing={1}>
                            <ClearFiltersButton />
                            <Button
                                className="primary"
                                color="primary"
                                disableElevation
                                type="submit"
                                variant="contained"
                                sx={styledPrimaryButton}
                            >
                                Search
                            </Button>
                        </Stack>
                    </Grid>

                </Grid>

                <SearchFiltersBottom>
                    <DateInput
                        helperText={false}
                        source="startDate"
                        sx={{...styledInputInline}}
                    />
                </SearchFiltersBottom>
            </form>
        </FormProvider>
    );
};

export const defaultFiltersValues = {
    budgetType: 'ALL',
    q: '',
    statuses: [EEngagementStatus.ACTIVE],
    startDate: null,
};
