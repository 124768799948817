import React, {useCallback} from 'react';
import {Datagrid, FilterForm, ListBase, Pagination, TextField as RaTextField, TextInput, useDataProvider, useListContext, useNotify, useRecordContext} from 'react-admin';

import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from '@mui/material';

import {IClientRole} from '../../../../../models';


const searchFilters = [
    <TextInput label="Search" source="q" alwaysOn key="search-filter" />,
];

const ListToolbar = () => (
    <Stack direction="row" justifyContent="space-between">
        <FilterForm filters={searchFilters} />
    </Stack>
);

const BulkActionButtons = ({onAdd}: {onAdd: (selectedIds: number[]) => void}) => {
    const {selectedIds} = useListContext();

    return (
        <>
            <Button onClick={() => onAdd(selectedIds)}>+ Add</Button>
        </>
    );
};

interface Props {
    open: boolean;
    onClose: (isNeedRefetch?: boolean) => void;
}

export const DialogAddFreelancer = ({open, onClose}: Props) => {
    const dataProvider = useDataProvider();
    const record = useRecordContext<IClientRole>();
    const notify = useNotify();

    const handleAdd = useCallback(async (selectedIds: number[]) => {
        if (!selectedIds.length) return;

        const results = selectedIds.map(id => ({freelancerId: id}));

        try {
            await dataProvider.post(`admin/roles/${record.id}/results`, {data: {results}});
            onClose(true);
            notify(`${results.length} item(s) were added successfully.`);
        } catch (error: any) {
            notify(`Error: ${error?.body?.error}`, {type: 'warning'});
        }
    }, [dataProvider, record.id, notify, onClose]);

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={open}
            onClose={() => onClose()}
        >
            <DialogTitle>Add new freelancer</DialogTitle>

            <DialogContent dividers sx={{minHeight: '550px'}}>
                <ListBase
                    disableSyncWithLocation
                    resource="freelancers"
                >
                    <ListToolbar />
                    <Datagrid bulkActionButtons={<BulkActionButtons onAdd={handleAdd} />}>
                        <RaTextField source="firstName" />
                        <RaTextField source="lastName" />
                        <RaTextField source="email" />
                    </Datagrid>
                    <Pagination />
                </ListBase>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={() => onClose()}
                >
                    Close
                </Button>
            </DialogActions>

        </Dialog>
    );
};

export default React.memo(DialogAddFreelancer);
