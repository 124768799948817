import React from 'react';
import {DateField, EmailField, FunctionField, NumberField, Show, Tab, TabbedShowLayout, TextField, useRecordContext} from 'react-admin';
import {useParams} from 'react-router-dom';

import VisibilityIcon from '@mui/icons-material/Visibility';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {Box, Grid, Stack, Typography} from '@mui/material';

import {Button, ButtonPortal, LegalDocumentsField, ListField, ModerationListField, SubChannelsField} from '../../components';
import {SetFeeDialog} from '../../components/SetFeeDialog';
import {useAdminGroups} from '../../hooks';
import {ERole} from '../../models';
import {styledLabelShow} from '../../styles';
import {formatterWithoutCents} from '../../utils';
import RoleNameField from '../Engagements/components/RoleNameField';

import {ButtonDeactivate} from './components/ButtonDeactivate';
import FreelancerEngagements from './components/Engagements/FreelancerEngagements';
import {RateField} from './components';

const Fee = () => {
    const record = useRecordContext();

    return record && <SetFeeDialog endpoint={`admin/freelancers/${record.id}/set-fee`} value={record.gcFeePercent}/> || <></>;
};

export const FreelancerShow = () => {
    const {id} = useParams();

    return (
        <Show>
            <TabbedShowLayout syncWithLocation={false}>
                <Tab label="summary">
                    <ExcludedFromMatchingInfo/>
                    <Grid container spacing={2}>
                        <Grid item sm={12} md={6} lg={3}>
                            <Typography sx={styledLabelShow}>Name</Typography>
                            <FunctionField render={(record: any) => (
                                <RoleNameField
                                    recordLocal={{
                                        ...record,
                                        name: `${record.firstName} ${record.lastName}`
                                    }}
                                    variant="body1"
                                />
                            )} />
                        </Grid>
                        <Grid item md={12} lg={5}>
                            <EmailField source="email" variant="body2" />
                            <Box >
                                <Typography sx={{opacity: 0.5}} variant="caption">Phone number: </Typography>
                                <TextField source="phone" variant="body1" />
                            </Box>
                        </Grid>
                        <Grid item sm={12} lg={4}>
                            <Typography sx={styledLabelShow}>Created</Typography>
                            <DateField source="created" />
                        </Grid>

                        <Grid item sm={12} md={6} lg={4}>
                            <Typography sx={styledLabelShow}>Location</Typography>
                            <TextField source="profile.country" variant="body1" />
                        </Grid>
                        <Grid item sm={12} md={6} lg={4}>
                            <Typography sx={styledLabelShow}>Time zones</Typography>
                            <ListField source="profile.timezones" />
                        </Grid>
                        <Grid item sm={12} md={6} lg={4}>
                            <Typography sx={styledLabelShow}>Languages</Typography>
                            <ListField source="profile.languages" />
                        </Grid>

                        <Grid item sm={12} md={4}>
                            <Typography sx={styledLabelShow}>Channels</Typography>
                            <ListField source="profile.channels" />
                        </Grid>
                        <Grid item sm={12} md={8}>
                            <Typography sx={styledLabelShow}>Subchannels</Typography>
                            <SubChannelsField />
                        </Grid>
                        <Grid item sm={12} md={4}>
                            <Typography sx={styledLabelShow}>Verticals</Typography>
                            <ListField source="profile.verticals" />
                        </Grid>
                        <Grid item sm={12} md={4}>
                            <BrandWrapper/>
                        </Grid>
                        <Grid item sm={12} md={4}>
                            <ToolsWrapper/>
                        </Grid>
                        <Grid item sm={12} md={6} lg={2}>
                            <Typography sx={styledLabelShow}>Years of experience</Typography>
                            <NumberField source="profile.yearsOfExperience" />
                        </Grid>
                        <Grid item sm={12} md={6} lg={2}>
                            <Typography sx={styledLabelShow}>Business models</Typography>
                            <ListField source="profile.businessModels" />
                        </Grid>
                        <Grid item sm={12} md={6} lg={2}>
                            <Typography sx={styledLabelShow}>Work types</Typography>
                            <ListField source="profile.workTypes" />
                        </Grid>
                        <Grid item sm={12} md={6} lg={2}>
                            <Typography sx={styledLabelShow}>Rate range</Typography>
                            <RateField />
                        </Grid>
                        <Grid item sm={12} md={6} lg={2}>
                            <Typography sx={styledLabelShow}>Fixed rate</Typography>
                            <FunctionField sx={{fontSize: '16px'}} render={(record: any) => record.profile?.fixedRate
                                ? formatterWithoutCents.format(record.profile.fixedRate / 100)
                                : '/'}
                            />
                        </Grid>
                        <Grid item sm={12} md={8}>
                            <Typography sx={styledLabelShow}>Legal documents</Typography>
                            <LegalDocumentsField source="legalDocuments"/>
                        </Grid>
                        <Grid item sm={12} md={4}>
                            <Typography sx={styledLabelShow}>GC Fee %</Typography>
                            <Stack alignItems="center" direction="row" spacing={1}>
                                <Fee/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={2}>
                                <Button
                                    startIcon={<VisibilityIcon />}
                                    variant="outlined"
                                    onClick={() => window.open(location.origin + '/#/public-profile-freelancer/' + id)}
                                >
                                    Public profile
                                </Button>

                                {/*
                                    As a GC Admin, I need to check freelancer and client portals encase of any troubles.
                                    See the portal in the same way as a user, with read-only access. It should be opened in a new tab.
                                */}
                                <ButtonPortal/>

                                {/*
                                    I want to be able to deactivate the Freelancer Account,
                                    So Such a Freelancer would not be included in the matching process.
                                */}
                                <ButtonDeactivate/>

                            </Stack>

                        </Grid>
                    </Grid>
                </Tab>

                <Tab label="Engagements">
                    <FreelancerEngagements/>
                </Tab>

            </TabbedShowLayout>
        </Show>
    );
};

const BrandWrapper = () => {
    const {hasPermissions} = useAdminGroups();
    const record = useRecordContext();

    return (
        <>
            <Typography sx={styledLabelShow}>Brands</Typography>
            <ModerationListField
                isEditable={hasPermissions([ERole.SuperAdmin, ERole.Admin])}
                recordLocal={record.profile}
                source="brands"
            />
        </>
    );
};

const ExcludedFromMatchingInfo = () => {
    const record = useRecordContext();

    if (!record?.profile?.isExcludedFromMatching) {
        return null;
    }

    return (
        <Grid item xs={12}>
            <Typography color="error" variant="body2">
                <Box
                    component="span"
                    sx={{
                        verticalAlign: 'middle',
                        mr: 1
                    }}
                >
                    <WarningAmberIcon color="error"/>
                </Box>
                    This Freelancer excluded from matching
            </Typography>
        </Grid>
    );
};

const ToolsWrapper = () => {
    const {hasPermissions} = useAdminGroups();
    const record = useRecordContext();

    return (
        <>
            <Typography sx={styledLabelShow}>Tools</Typography>
            <ModerationListField
                isEditable={hasPermissions([ERole.SuperAdmin, ERole.Admin])}
                recordLocal={record.profile}
                source="tools"
            />
        </>
    );
};
