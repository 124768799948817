import {EEventType} from './event-type.model';

export interface INotification {
    body: string;
    eventType: EEventType;
    id: number;
    read: boolean;
    title: string;
}

export interface INotificationRule {
    id?: number;
    eventType: EEventType;
    name: string;
    immediate: boolean;
    delayed: boolean;
    delayMinutes?: number;
    options: INotificationChannel[];
}

export interface INotificationChannel {
    id?: number;
    channel: EChannel;
    enabled: boolean;
    subject: string;
    sender?: string;
    template: string;
    recipients?: string[];
}

export enum EChannelTechnology {
    Internal = 'INTERNAL',
    Email = 'EMAIL',
    Slack = 'SLACK',
}

export enum EChannel {
    INTERNAL_USER = 'INTERNAL_USER',
    INTERNAL_ADMIN = 'INTERNAL_ADMIN',
    EMAIL_USER = 'EMAIL_USER',
    EMAIL_ADMIN = 'EMAIL_ADMIN',
    SLACK_ADMIN = 'SLACK_ADMIN'
}

export enum ETime {
    immediate = 'immediate',
    delayed = 'delayed',
}

export enum ESendTo {
    admin = 'admin',
    user = 'user',
}
