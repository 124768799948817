import React from 'react';
import {Datagrid, DateField, EmailField, FunctionField, List, TextField} from 'react-admin';

import {PaginationCoreUI} from '../../components';
import {styledDatagrid, styledList} from '../../styles';
import RoleNameField from '../Engagements/components/RoleNameField';

import {filterDefaultValues} from './components/Filters/FilterForm';
import {ListActions} from './components/List/ListActions';


export const FreelancerList = () => (
    <List
        actions={<ListActions />}
        filterDefaultValues={filterDefaultValues}
        pagination={<PaginationCoreUI />}
        sort={{field: 'created', order: 'DESC'}}
        sx={styledList}
    >
        <Datagrid
            bulkActionButtons={false}
            rowClick="show"
            sx={styledDatagrid}
        >
            <FunctionField label="Name" render={(record: any) => (
                <RoleNameField
                    recordLocal={{
                        ...record,
                        name: `${record.firstName} ${record.lastName}`
                    }}
                />
            )} />
            <EmailField source="email" />
            <TextField source="phone" />
            <DateField source="created" />
        </Datagrid>
    </List>
);
