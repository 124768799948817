import React, {FC} from 'react';
import {useRecordContext} from 'react-admin';

import {Typography} from '@mui/material';

import {EWorkType} from '../../../models';
import theme from '../../../theme';
import {formatterWithoutCents} from '../../../utils';

interface IProps {
    variant: 'caption' | 'body2';
}

export const EngagementDetailsField: FC<IProps> = React.memo(({variant}) => {
    const record = useRecordContext();

    const renderEngagementDetails = () => {
        switch (record.workType || record.budgetType) {
            case EWorkType.fixed:
                return `Fixed ${formatterWithoutCents.format(record.rate / 100)}`;
            case EWorkType.hourly:
                return `Hourly at ${formatterWithoutCents.format(record.rate / 100)} per hour`;
            case EWorkType.retainer:
                return `Retainer at ${formatterWithoutCents.format(record.rate / 100)} per ${record.isMonthly ? 'month' : 'week'}`;
        }
    };

    return (
        <>
            <Typography
                sx={variant === 'caption' ? {color: theme.palette.text.secondary, fontSize: '13px !important'} : null}
                variant="body2"
            >
                {renderEngagementDetails()}
            </Typography>
        </>
    );
});
